import { FC, HTMLAttributes, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  MutationUserRequestResetPasswordRequest,
  mutationUserResetPassword,
} from '@sportsyou/api'
import { Button, TextInput, useDialog } from '@sportsyou/react-dom-ui'
import { isEmailValid } from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'

interface Props {
  email?: string
  onSuccess?: () => void
  phoneNumber?: string
  showEmailInput?: boolean
  token?: string
}

type NativeAttributes = Omit<HTMLAttributes<any>, keyof Props>
export type ForgotPasswordProps = Props & NativeAttributes

const PASSWORD_MIN_LENGTH = 6

export const UpdatePassword: FC<any> = (props: ForgotPasswordProps) => {
  const { sendBanner } = useDialog()

  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [showValidation, setShowValidation] = useState<boolean>(false)
  const [requestParams, setRequestParams] =
    useState<MutationUserRequestResetPasswordRequest>()

  const resetPassword = useFetchApi(async () =>
    mutationUserResetPassword({
      password,
      resetToken: props.token ?? '',
      ...requestParams,
    })
  )

  useEffect(() => {
    if (props.email) {
      setRequestParams({
        email: props.email,
      })
    }
    if (props.phoneNumber) {
      setRequestParams({
        phoneNumber: props.phoneNumber,
        phoneNumberCountryCode: 'US',
      })
    }
  }, [props.email, props.phoneNumber])

  const formIsValid = useMemo(() => {
    const isPasswordValid =
      password.length >= PASSWORD_MIN_LENGTH &&
      password === passwordConfirmation
    if (props.showEmailInput) {
      return isEmailValid(requestParams?.email ?? '') && isPasswordValid
    }
    return isPasswordValid
  }, [
    props.showEmailInput,
    password,
    passwordConfirmation,
    requestParams?.email,
  ])

  const handleOnChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowValidation(false)
    setPassword(event.target.value)
  }

  const handleOnChangePasswordConfirm = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowValidation(false)
    setPasswordConfirmation(event.target.value)
  }

  const handleOnClickUpdate = async () => {
    await resetPassword.fetch().then((response) => {
      if (response.ok) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 5000,
          message: 'Password reset successful',
          status: 'success',
        })
        props.onSuccess?.()
      } else {
        console.error({ apiError: response.error })
        const errMessage = Array.isArray(response.error)
          ? JSON.stringify(response.error.shift())
          : JSON.stringify(response.error)
        console.error(errMessage)
        sendBanner({
          autoDismiss: true,
          dismissTime: 5000,
          message:
            'There was a problem setting a new password. Please contact support.',
          status: 'danger',
        })
      }
    })
  }

  return (
    <>
      <Header>Update Your Password</Header>
      <SubText>
        Your password must be at least <b>6 characters</b>.
      </SubText>

      {props.showEmailInput && (
        <TextInput
          autoFocus
          label='Your Email Address'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setRequestParams({
              email: event.target.value,
            })
          }
          placeholder='Enter email'
          containerStyle={{ marginBottom: 10 }}
        />
      )}

      <TextInput
        autoCapitalize='off'
        autoComplete='new-password'
        label='New Password'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChangePassword(event)
        }
        placeholder='Enter password'
        showPasswordToggle
        type='password'
        value={password}
        status={showValidation ? 'error' : undefined}
        showValidationMessage={showValidation}
        validationMessage={
          password !== passwordConfirmation
            ? 'Passwords do not match'
            : 'Please enter a valid password'
        }
      />

      <TextInput
        autoCapitalize='off'
        autoComplete='new-password'
        label='Confirm Password'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChangePasswordConfirm(event)
        }
        placeholder='Enter password'
        showPasswordToggle
        containerStyle={{ marginTop: 10 }}
        type='password'
        value={passwordConfirmation}
        status={showValidation ? 'error' : undefined}
        showValidationMessage={showValidation}
        validationMessage={
          password !== passwordConfirmation
            ? 'Passwords do not match'
            : 'Please enter a valid password'
        }
      />

      <StyledButton disabled={!formIsValid} onClick={handleOnClickUpdate}>
        Update
      </StyledButton>
    </>
  )
}

const Header = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`
const SubText = styled.p`
  text-align: center;
`
const StyledButton = styled(Button)`
  margin-top: 20px;
`

export default UpdatePassword
