import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { mutationUserVerify } from '@sportsyou/api'
import { Colors, formatPhoneNumber } from '@sportsyou/core'
import { Button, Link, TextInput } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import UpdatePassword from './update-password'

interface Props {
  className?: string
  /** Fn to run when code is verified */
  onCodeVerified: () => void
  /** Fn to run when password reset is successful */
  onSuccess: () => void
  phoneNumber: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ForgotPasswordProps = Props & NativeAttributes

// Timer count down for resending sms verification code
const DEFAULT_TIMER = 30

export const ForgotPasswordSMS: React.FC<ForgotPasswordProps> = ({
  onCodeVerified,
  onSuccess,
  phoneNumber,
}: ForgotPasswordProps) => {
  const timerRef = useRef<number | undefined>()

  const [canResend, setCanResend] = useState<boolean>(true)
  const [isVerified, setIsVerified] = useState(false)
  const [showValidationMessage, setShowValidationMessage] =
    useState<boolean>(false)
  const [smsVerificationCode, setSmsVerificationCode] = useState<string>('')
  const [timerDisplay, setTimerDisplay] = useState(DEFAULT_TIMER)

  const verifyToken = useFetchApi(async () =>
    mutationUserVerify({
      phoneNumber,
      phoneNumberCountryCode: 'US',
      tokenType: 'resetPassword',
      verifyToken: smsVerificationCode,
      verifyType: 'sms',
    })
  )

  useEffect(() => {
    return () => {
      // make sure interval is deleted on unmount to prevent any memory leaks
      timerRef.current && clearInterval(timerRef.current)
    }
  }, [])

  useEffect(() => {
    // When timer ends, unblock resend button and reset timer state
    if (timerDisplay === 0) {
      timerRef.current && clearInterval(timerRef.current)
      setCanResend(true)
      setTimerDisplay(DEFAULT_TIMER)
    }
  }, [timerDisplay])

  useEffect(() => {
    if (isVerified) {
      onCodeVerified && onCodeVerified()
    }
  }, [isVerified, onCodeVerified])

  const handleOnChangeSMSCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowValidationMessage(false)
    setSmsVerificationCode(event.target.value)
  }

  const handleOnClickResendCode = () => {
    // Block resend button to prevent spam clicking
    setCanResend(false)

    timerRef.current = window.setInterval(() => {
      setTimerDisplay((num) => num - 1)
    }, 1000)
  }

  const handleOnClickVerifyCode = async () => {
    console.log('Verify SMS Token')

    await verifyToken.fetch().then((response) => {
      if (response.ok) {
        setIsVerified(true)
        console.log('Token verified successfully')
      } else {
        console.error('Token invalid')
        setIsVerified(false)
        setShowValidationMessage(true)
      }
    })
  }

  return isVerified ? (
    <UpdatePassword
      onSuccess={onSuccess}
      phoneNumber={phoneNumber}
      token={smsVerificationCode}
    />
  ) : (
    <>
      <Header>Check your phone!</Header>
      <SubText>A verification code has been sent to:</SubText>
      <SubTextAlternate>{formatPhoneNumber(phoneNumber)}</SubTextAlternate>
      <SubText>
        Enter the code below. If you didn't receive one, check the number and
        click the button to resend a new one.
      </SubText>
      <TextInput
        autoFocus
        inputStyle={{ textTransform: 'uppercase' }}
        label='Verification Code'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChangeSMSCode(e)
        }
        placeholder='Code'
        showValidationMessage={showValidationMessage}
        status={showValidationMessage ? 'error' : undefined}
        validationMessage={'Error verifying the code'}
        value={smsVerificationCode}
      />

      <StyledButton
        disabled={smsVerificationCode.length < 6}
        onClick={handleOnClickVerifyCode}
      >
        Next
      </StyledButton>

      {!canResend ? (
        <ResendVerificationCodeText>
          Didn't get the code? Resend in {timerDisplay}
        </ResendVerificationCodeText>
      ) : (
        <ResendVerificationCodeLink
          onClick={handleOnClickResendCode}
          tabIndex={0}
          title='Resend SMS Verification Code'
        >
          Didn't get the code? Resend
        </ResendVerificationCodeLink>
      )}
    </>
  )
}

const Header = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`
const SubText = styled.p`
  text-align: center;
`
const SubTextAlternate = styled(SubText)`
  background-color: ${Colors.CATSKILL_WHITE};
  border-radius: 6px;
  color: ${Colors.MINE_SHAFT};
  margin-top: 0;
  padding-bottom: 8px;
  padding-top: 8px;
  font-weight: 500;
`
const StyledButton = styled(Button)`
  margin-top: 20px;
`
const ResendVerificationCodeTextCss = css`
  align-self: center;
  font-size: 14px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
`
const ResendVerificationCodeText = styled.span`
  ${ResendVerificationCodeTextCss};
  color: ${Colors.SHUTTLE_GRAY};
`
const ResendVerificationCodeLink = styled(Link)`
  ${ResendVerificationCodeTextCss}
`

export default ForgotPasswordSMS
