import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Post as PostProps } from '@sportsyou/api'

export interface SponsoredPostsSliceProps {
  posts: PostProps[]
}

const initialState: SponsoredPostsSliceProps = {
  posts: [],
}

const SponsoredPostsSlice = createSlice({
  name: 'sponsoredPosts',
  initialState,
  reducers: {
    clearSponsoredPosts(state) {
      state.posts = initialState.posts
    },
    setSponsoredPosts(
      state: SponsoredPostsSliceProps,
      action: PayloadAction<PostProps[]>
    ) {
      state.posts = action.payload
    },
    updateSponsoredPost(state, action: PayloadAction<PostProps>) {
      const index = state.posts.findIndex(
        (post) => post.id === action.payload.id
      )
      if (index !== -1) {
        state.posts[index] = action.payload
      }
    },
  },
})

export const { clearSponsoredPosts, setSponsoredPosts, updateSponsoredPost } =
  SponsoredPostsSlice.actions
export default SponsoredPostsSlice.reducer

export const selectSponsoredPosts = (state: any) => state.sponsoredPosts.posts

export const selectSponsoredPostsById = createSelector(
  selectSponsoredPosts,
  (state, id) => id,
  (posts: Array<PostProps>, id) => posts.find((post) => post.id === id)
)
