import { createSlice } from '@reduxjs/toolkit'
import { ChatIncomingMessage } from '../../services/Chat/ChatBase'
import { RootState } from '../rootReducer'

export interface ChatEventInfo {
  chatEvent?: ChatIncomingMessage
}

const initialState: ChatEventInfo = {
  chatEvent: undefined,
}

const chatEventSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    chatEvent(state, action: { payload: ChatIncomingMessage }) {
      state.chatEvent = action.payload
    },
    clearChatEvent(state) {
      state.chatEvent = initialState.chatEvent
    },
  },
})

export const { chatEvent, clearChatEvent } = chatEventSlice.actions
export default chatEventSlice.reducer

export const selectChatEvent = (state: RootState) => state.chat.chatEvent
