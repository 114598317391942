import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Chevron } from '@sportsyou/react-dom-ui'

interface ToggleProps {
  className?: string
  isExpanded: boolean
  onClick?: () => any
}

export default function Toggle(props: ToggleProps) {
  return (
    <Container className={props.className} onClick={props.onClick}>
      <Chevron
        direction={props.isExpanded ? 'down' : 'right'}
        fill={Colors.DUSTY_GRAY}
        size={30}
      />
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 0 20px 0 10px;
  position: relative;
  width: 45px;
`

const Rotator = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  transform: rotate(${(props) => (props.isExpanded ? '90deg' : '0deg')});
  transition: all 0.2s ease-in-out;
`

const StyledChevron = styled(Chevron)`
  /* width: 30px;
  height: 30px; */
`
