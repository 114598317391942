import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  MutationSetUserAuthRequest,
  mutationSetUserAuth,
  mutationUserResendVerificationToken,
} from '@sportsyou/api'
import { Colors, isEmailValid } from '@sportsyou/core'
import {
  BORDER_RADIUS,
  Button,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import { ForgotPasswordModal } from '../Modals/ForgotPassword/ForgotPassword'
import { getFakeUser } from '../../../constants'

interface Props {
  currentEmail: string
  isVerified?: boolean
  phoneNumberCountryCode?: string
  phoneNumber?: string
  onCancel?: () => void
  onSuccess?: () => void
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ChangeEmailFormProps = Props & NativeAttributes

export const ChangeEmailForm: React.FC<ChangeEmailFormProps> = ({
  onCancel,
  onSuccess,
  currentEmail,
  isVerified,
  phoneNumberCountryCode,
  phoneNumber,
  ...props
}: ChangeEmailFormProps) => {
  const DEFAULT_TIMER = 20

  const timerRef = useRef<number | undefined>()

  const { sendBanner } = useDialog()

  const { fetch: changeEmail } = useFetchApi(mutationSetUserAuth)
  const { fetch: resendVerification } = useFetchApi(
    mutationUserResendVerificationToken
  )

  const [isLoading, setIsLoading] = useState(false)
  const [timerDisplay, setTimerDisplay] = useState(DEFAULT_TIMER)
  const [password, setPassword] = useState<string>('')
  const [newEmail, setNewEmail] = useState<string>('')
  const [confirmedNewEmail, setConfirmedNewEmail] = useState<string>('')
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [showPasswordValidation, setShowPasswordValidation] =
    useState<boolean>(false)
  const [showEmailValidation, setShowEmailValidation] = useState<boolean>(false)
  const [emailValidationMessage, setEmailValidationMessage] = useState<string>()

  useEffect(() => {
    return () => {
      // clean up interval
      timerRef.current && clearInterval(timerRef.current)
    }
  }, [])

  useEffect(() => {
    // When timer ends, unblock resend button and reset timer state
    if (timerDisplay === 0) {
      timerRef.current && clearInterval(timerRef.current)
      setIsResendDisabled(false)
      setTimerDisplay(DEFAULT_TIMER)
    }
  }, [timerDisplay])

  useEffect(() => {
    if (password !== '' && newEmail !== '' && confirmedNewEmail !== '') {
      setIsButtonDisabled(false)
    }
  }, [password, newEmail, confirmedNewEmail])

  const validateEmail = async () => {
    let isValid = false
    // password is empty
    if (!password || password === '') {
      setShowPasswordValidation(true)
    }

    // check email fields
    const _isEmailValid = isEmailValid(newEmail)
    if (_isEmailValid) {
      // check if email is matching
      if (newEmail !== confirmedNewEmail) {
        isValid = false
        setEmailValidationMessage('Emails do not match')
        setShowEmailValidation(true)
      } else {
        isValid = true
        setShowEmailValidation(false)
      }
    } else {
      isValid = false
      setEmailValidationMessage('Please enter a valid email')
      setShowEmailValidation(true)
    }

    if (!isValid) {
      setIsButtonDisabled(true)
    }

    return isValid
  }

  const handleOnChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value)
    setShowPasswordValidation(false)
  }

  const handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value)
    setShowEmailValidation(false)
  }

  const handleOnChangeEmailConfirmation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmedNewEmail(event.target.value)
    setShowEmailValidation(false)
  }

  const handleOnClickCancel = () => {
    onCancel && onCancel()
  }

  const handleOnClickSave = async () => {
    const _isValid = await validateEmail()

    if (!_isValid) return

    const params: MutationSetUserAuthRequest = {
      newEmail,
      password,
    }
    const { error, ok } = await changeEmail(params)

    if (error) {
      setEmailValidationMessage(error as string)
      setShowEmailValidation(true)
    }

    if (ok) {
      sendBanner({
        autoDismiss: true,
        dismissTime: 6000,
        status: 'success',
        message:
          'Email has been changed succesfully, please verify your new email address by clicking the link in the email.',
      })
      handleOnSuccess()
    }
  }

  const resetForm = () => {
    setPassword('')
    setNewEmail('')
    setConfirmedNewEmail('')

    setIsButtonDisabled(true)
    setShowPasswordValidation(false)
    setShowEmailValidation(false)
    setEmailValidationMessage('')
  }

  const handleOnSuccess = () => {
    onSuccess && onSuccess()

    resetForm()
  }

  const handleOnClickResendVerification = async () => {
    setIsLoading(true)
    const { error, data, ok } = await resendVerification({
      email: currentEmail,
    })
    if (error) {
      console.log({ error })
      setIsLoading(false)
    }
    if (ok && data) {
      setIsResendDisabled(true)
      sendBanner({
        message:
          "You've got mail! Check your email for instructions on how to verify your account.",
        status: 'success',
        autoDismiss: true,
        dismissTime: 5000,
      })
      setIsLoading(false)
      timerRef.current = window.setInterval(() => {
        setTimerDisplay((num) => num - 1)
      }, 1000)
    }
  }

  return (
    <>
      {!isVerified && (
        <VerificationCallout>
          <div>
            Please verify your email address, to get the most out of sportsYou.
          </div>
          <Button
            style={{ width: 120 }}
            loading={isLoading}
            disabled={isResendDisabled}
            onClick={handleOnClickResendVerification}
          >
            {isResendDisabled ? `Resend in ${timerDisplay}` : 'Resend Email'}
          </Button>
        </VerificationCallout>
      )}
      <div
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StyledTextInput
          autoCapitalize='off'
          label='Current Password'
          onChange={(e) => handleOnChangePassword(e)}
          placeholder='Enter Your Password'
          showPasswordToggle
          showValidationMessage={showPasswordValidation}
          status={showPasswordValidation ? 'error' : undefined}
          type='password'
          validationMessage={
            password.trim() === ''
              ? 'Please Enter Your Password'
              : 'Incorrect Password'
          }
          value={password}
        />
        <span style={{ marginBottom: 20, marginTop: 10 }}>
          <ForgotPasswordModal style={{ fontSize: 14 }} />
        </span>
        <StyledTextInput
          autoComplete='email'
          label='New Email'
          onChange={(e) => handleOnChangeEmail(e)}
          placeholder={`${getFakeUser('email')}`}
          showValidationMessage={showEmailValidation}
          status={showEmailValidation ? 'error' : undefined}
          validationMessage={emailValidationMessage}
          value={newEmail}
        />
        <StyledTextInput
          autoComplete='email'
          label='Confirm Email'
          onChange={(e) => handleOnChangeEmailConfirmation(e)}
          placeholder={`${getFakeUser('email')}`}
          value={confirmedNewEmail}
          showValidationMessage={showEmailValidation}
          status={showEmailValidation ? 'error' : undefined}
          validationMessage={showEmailValidation ? emailValidationMessage : ''}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <StyledButton
          onClick={handleOnClickCancel}
          variant='primary'
          appearance='ghost'
        >
          Cancel
        </StyledButton>
        <StyledButton
          disabled={isButtonDisabled}
          onClick={handleOnClickSave}
          variant='primary'
        >
          Save
        </StyledButton>
      </div>
    </>
  )
}

const VerificationCallout = styled.div`
  background-color: ${Colors.PERANO};
  border 2px solid ${Colors.HAVELOCK_BLUE};
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  @media all and (max-width: 767px) {
    & > button {
      align-self: flex-end;
      margin-top: 20px;
    }
  }
  @media all and (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`
// & + & {
const StyledTextInput = styled(TextInput)`
  &:not(:first-child) + & {
    margin-top: 10px;
  }
`

const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-left: 6px;
  }
`

export default ChangeEmailForm
