import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import styled from 'styled-components'

import {
  Community,
  Post as PostProps,
  QueryPollVotesRequest,
  User,
  queryPollVotes,
} from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'
import { Activity, PollChoiceReturnData, Post } from '@sportsyou/react-dom-ui'

import { PollChoiceData, PollVotesModal } from '../Modals'
import { RootState } from '../../../store/rootReducer'
import useLightbox from '../Lightbox/useLightbox'
import usePages from '../../pages/Pages/UsePages'

export interface CommunitiesPostListProps {
  className?: string
  communityId?: string
  currentUser?: User
  hideFollowButton?: boolean
  style?: React.CSSProperties
}

export const CommunitiesPostList: React.FC<CommunitiesPostListProps> = ({
  // className,
  // currentUser,
  // style,
  communityId,
  hideFollowButton,
}: CommunitiesPostListProps) => {
  const shouldLoadMore = useRef(true)

  const { showWithItems: showLightboxWithItems } = useLightbox()
  const navigate = useNavigate()

  const { fetch: getPollVotes } = useFetchApi(queryPollVotes)

  const { posts: reduxPosts } = useSelector((state: RootState) => state.feed)

  const {
    fetchAllPosts,
    fetchPosts,
    isLoading,
    lastFetchResult,
    posts,
    removePollVote,
    setPollVote,
    setPagePostLiked,
    setPagePostViewed,
  } = usePages({
    communityId,
  })

  // const getPosts = useCallback(
  //   () =>
  //     communityId ? reduxPosts[communityId] ?? [] : reduxPosts.pages ?? [],
  //   [communityId, reduxPosts],
  // )

  // const [currentPosts, setCurrentPosts] = useState<PostProps[]>(getPosts())

  const [currentPollData, setCurrentPollData] = useState<PollChoiceData>({
    index: -1,
    choices: [],
  })
  const [isPollModalVisible, setIsPollModalVisible] = useState(false)

  // useEffect(() => {
  //   console.log({ currentPosts })
  // }, [currentPosts])

  useEffect(() => {
    if (!communityId) return
    console.log('posts:', reduxPosts[communityId])
  }, [communityId, reduxPosts])

  useEffect(() => {
    // initial fetch
    if (communityId) {
      fetchPosts({ communityId, perPage: 10 })
    } else {
      fetchAllPosts()
    }
  }, [communityId])

  useEffect(() => {
    console.log(
      { lastFetchResult },
      !((lastFetchResult?.posts?.length ?? 0) < 10)
    )

    shouldLoadMore.current = !((lastFetchResult?.posts?.length ?? 0) < 10)
  }, [lastFetchResult])

  // useEffect(() => {
  //   if (!isLoading && !isEqual(posts, currentPosts)) {
  //     setCurrentPosts(posts)
  //   }
  // }, [currentPosts, isLoading, posts])

  // function fetchFirstPage(postIdToRemove?: string, forceFetch?: boolean) {
  //   const fetchCriteria: FetchPostCriteria = {}
  //   if (postIdToRemove) {
  //     let posts = getPosts()
  //     posts = posts.filter(post => post.id !== postIdToRemove)
  //     dispatch(setFeed({ id: communityId ?? 'pages', posts }))
  //     // setCurrentPosts(posts)
  //   }
  // }

  const handleOnClickComm = (community?: Community) => {
    console.log({ community })
    if (!community) return
    navigate(`/pages/${community.communityUrl}`, {
      state: { communityId: community.id, community },
    })
  }

  const openPollVotesModal = useCallback(
    async (choiceId: string, postId: string) => {
      /**
       * Query current votes on poll
       */
      const { data } = await getPollVotes({ postId } as QueryPollVotesRequest)
      /**
       * Set data so we can display it properly in a modal
       */
      if (data) {
        const index = data.findIndex((choice) => choice.id === choiceId)
        setCurrentPollData({ choices: data, index })
      }
      setIsPollModalVisible(true)
    },
    []
  )

  const handleOnClickPollChoice = useCallback(
    async (poll: PollChoiceReturnData, postId: string) => {
      if (!postId) return
      const { id: choiceId, isActive, isCreator } = poll
      // Check if the poll is still active or the user is the poll creator
      if (!isActive || isCreator) {
        // Open modal
        choiceId && openPollVotesModal(choiceId, postId)
      } else {
        // if user has not voted, set the vote
        await setPollVote(poll, postId)
      }
    },
    [openPollVotesModal, setPollVote]
  )

  const handleOnClickRemovePollVote = useCallback(
    async (postId: string) => {
      await removePollVote(postId)
    },
    [removePollVote]
  )

  const handleOnClickLike = async (post: PostProps) => {
    setPagePostLiked(post)
  }

  const handlePostOnViewed = async (post: PostProps) => {
    setPagePostViewed(post)
  }

  const handleEndReached = async () => {
    if (shouldLoadMore.current) {
      const lastPostId = [...posts].pop()?.id

      if (communityId) {
        await fetchPosts({
          communityId,
          direction: 'older',
          perPage: 10,
          startPostId: lastPostId,
        })
      } else {
        await fetchAllPosts({
          direction: 'older',
          perPage: 10,
          startPostId: lastPostId,
        })
      }
    }
  }

  const handleOnClosePollModal = useCallback(() => {
    setIsPollModalVisible(false)
  }, [])

  const onClickViewUpload = useCallback(
    (upload?: any) => {
      const items = upload.allUploads ?? [upload]
      const index = upload.currentIndex ?? 0
      showLightboxWithItems({ items, index })
    },
    [showLightboxWithItems]
  )

  const renderPost = (index: number, post: PostProps) => (
    <PostContainer key={post.id ?? index}>
      <Post
        {...post}
        allowComments={false}
        disableLikesModal
        hideFollowButton={!!hideFollowButton}
        onClickPollChoice={handleOnClickPollChoice}
        onClickRemoveVote={handleOnClickRemovePollVote}
        onClickLikeButton={() => handleOnClickLike(post)}
        onViewed={() => handlePostOnViewed(post)}
        onClickProfileName={() =>
          handleOnClickComm(post.community ?? undefined)
        }
        onClickUpload={onClickViewUpload}
        onClickViewUpload={onClickViewUpload}
      />
    </PostContainer>
  )

  const renderFooterElement = () =>
    isLoading ? <Activity position='fixed' /> : null

  return (
    <>
      {/* {isLoading && <Activity />} */}
      {/* <div className={className} style={{ ...style, display: 'none' }}>
        {posts.map(post => (
          <Post
            key={`${post.id}--`}
            {...post}
            allowComments={false}
            disableLikesModal
            hideFollowButton={!!hideFollowButton}
            onClickLikeButton={() => handleOnClickLike(post)}
            onClickProfileName={() =>
              handleOnClickComm(post.community ?? undefined)
            }
          />
        ))}
      </div> */}

      <Virtuoso
        components={{ Footer: renderFooterElement }}
        data={posts}
        endReached={handleEndReached}
        itemContent={renderPost}
        useWindowScroll
      />
      <PollVotesModal
        data={currentPollData}
        isVisible={isPollModalVisible}
        onClose={handleOnClosePollModal}
      />
    </>
  )
}

const PostContainer = styled.div`
  padding-bottom: 10px;
`

export default CommunitiesPostList
