// TODO: Add mobile friendly styles for starting new chats
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'

import {
  Colors,
  getProfileImage,
  getTranscodeUrl,
  isFile,
  isGif,
} from '@sportsyou/core'
import {
  Avatar,
  BORDER_RADIUS,
  Button,
  Checkbox,
  FileTypeIcon,
  Icon,
  Modal,
  Radio,
  RenameInput,
  Spinner,
} from '@sportsyou/react-dom-ui'
import {
  Chat,
  // Friend,
  Image,
  Profile,
  // QueryChatableUsersRequest,
  Team,
  TeamMember,
  // Teammate,
  Upload,
  queryChatableUsers,
  queryTeam,
} from '@sportsyou/api'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'

import { convertUploadDataPropsToChatAttachment } from '../../../pages/Chat/ChatRoom'
import { RootState } from '../../../../store/rootReducer'
import { getUsername } from '../../../../utils/user'
import {
  selectTeamById,
  selectTeamsAndGroupsUserCanChat,
} from '../../../../store/slices/TeamsSlice'
import {
  fetchFriends,
  fetchTeammates,
  selectCurrentUser,
  selectCurrentUserChatableUsers,
  updateChatableUsers,
} from '../../../../store/slices/UserSlice'
import {
  addChatBox,
  setChatsScreenActiveChatId,
} from '../../../../store/slices/ChatBoxSlice'
import { fetchChats } from '../../../../store/slices/ChatListSlice'
import ChatMessageInput from '../../../pages/Chat/ChatMessageInput'

import { Person } from '../../SelectUserList/SelectUserList'
import SelectUserListSectioned from '../../SelectUserList/SelectUserListSectioned'

import {
  CHAT_MESSAGE_FILTER,
  ChatCreateProps,
  useChat,
} from '../../../hooks/useChat'
import { useAnalytics } from '../../../hooks/useAnalytics'

interface ChatCreateModalProps {
  isOpen: boolean
  message?: string
  onClose: () => void
  onCreateNewChat?: (data: Chat) => void
  showTeamPicker?: boolean
  teamId?: string
  title?: string
  uploads?: ExtendedUpload[]
}

export const ChatCreateModal: React.FC<ChatCreateModalProps> = (
  props: ChatCreateModalProps
) => {
  const { isOpen } = props
  const { logEvent } = useAnalytics()
  const dispatch = useDispatch()

  const chatClient = useChat({
    establishConnection: false,
    filter: CHAT_MESSAGE_FILTER.LISTEN_FOR_ALL,
  })

  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
    props.teamId
  )

  const teamsAndGroupsUserCanChat = useSelector(selectTeamsAndGroupsUserCanChat)
  const currentUser = useSelector(selectCurrentUser)
  const selectedTeam = useSelector((state: RootState) =>
    selectTeamById(state, selectedTeamId as string)
  )
  // const friends = useSelector(selectCurrentUserFriends)
  // const teammates = useSelector(selectCurrentUserTeammates)
  // const groupmates = useSelector(selectCurrentUserGroupmates)
  const chatableUsers = useSelector(selectCurrentUserChatableUsers)

  const contacts = useMemo(() => {
    const _contacts = [
      // ...(friends ?? []),
      // ...(teammates ?? []),
      // ...(groupmates ?? []),
      ...chatableUsers,
    ]
    // sort by name
    _contacts.sort((a, b) => {
      const aName = getUsername(a)
      const bName = getUsername(b)
      return aName.localeCompare(bName)
    })
    // remove duplicates
    return _contacts.filter(
      (contact, index, self) =>
        contact.id !== currentUser.id &&
        index === self.findIndex((c) => c.id === contact.id)
    )
  }, [chatableUsers, currentUser.id])

  const [customName, setCustomName] = useState(props.title ?? '')
  const [disableReplies, setDisableReplies] = useState(false)
  // const [filteredUsers, setFilteredUsers] = useState<Person[]>()
  const [filterQuery, setFilterQuery] = useState<string>('')
  const [includeAllMembers, setIncludeAllMembers] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isSettingCustomName, setIsSettingCustomName] = useState(false)
  const [messageText, setMessageText] = useState(props.message ?? '')
  const [selectedUsers, setSelectedUsers] = useState<Person[]>()
  const [uploads, setUploads] = useState<Upload[]>(props.uploads ?? [])
  const [users, setUsers] = useState<Person[]>([])
  const [canRenameChat, setCanRenameChat] = useState(false)
  const [uploadFromShared, setUploadFromShared] = useState(
    !!props.uploads?.length
  )

  const { fetch: getTeam } = useFetchApi(queryTeam)
  const { fetch: getChattable } = useFetchApi(queryChatableUsers)

  useEffect(() => {
    const fetch = async () => {
      const { data, error, ok } = await getChattable()
      if (data && ok) {
        dispatch(updateChatableUsers)
      }
      if (error) {
        console.log({ error })
      }
    }

    props.isOpen && fetch()
  }, [props.isOpen])

  const filteredUsers = useMemo(
    () =>
      filterQuery
        ? users?.filter((user) => {
            if (user.id === currentUser?.id) {
              return false
            }
            return getUsername(user)
              ?.toLowerCase()
              .includes(filterQuery.toLowerCase())
          })
        : users,
    [currentUser?.id, filterQuery, users]
  )

  const uniqueSelectedUsers = useMemo(() => {
    const unique = selectedUsers
      ?.filter(
        (user, index, self) =>
          self.findIndex((u) => {
            const u_id = (u as TeamMember).userId ?? u.id
            const user_id = (user as TeamMember).userId ?? user.id
            return u_id === user_id
          }) === index
      )
      .sort((a, b) => {
        const aName = getUsername(a)
        const bName = getUsername(b)
        return aName.localeCompare(bName)
      })

    return unique
  }, [selectedUsers])

  const onClickSetCustomName = useCallback(() => {
    setIsSettingCustomName(true)
  }, [])

  const onCancelSetCustomName = useCallback(() => {
    setIsSettingCustomName(false)
  }, [])

  const onSubmitSetCustomName = useCallback((name: string) => {
    setCustomName(name)
    setIsSettingCustomName(false)
  }, [])

  const reset = useCallback(() => {
    setCustomName('')
    // setFilteredUsers([])
    setFilterQuery('')
    setSelectedUsers([])
  }, [])

  const onClose = useCallback(() => {
    props.onClose()
    reset()
    setIsSaving(false)
    setDisableReplies(false)
    setSelectedTeamId(undefined)
    setIsSettingCustomName(false)
    setUsers([])
  }, [reset, props])

  // On selected team change, fetch team members, and set all as selected
  const changeTeam = useCallback(async () => {
    setIsLoading(true)
    if (selectedTeamId?.startsWith('te-')) {
      const { data: team } = await getTeam({ id: selectedTeamId })
      if (team) {
        // remove current user from team members
        const teammembers =
          (team.members as TeamMember[])?.filter(
            (m) => m?.userId !== currentUser?.id
          ) ?? []
        setUsers(teammembers)
        setSelectedUsers(teammembers)
      }
    }
    setIsLoading(false)
  }, [currentUser?.id, getTeam, selectedTeamId])

  const fetchContacts = useCallback(async () => {
    dispatch(fetchFriends())
    dispatch(fetchTeammates())
  }, [dispatch])

  const onClickTeam = useCallback(
    (team: Team) => {
      if (team.id !== selectedTeamId) {
        setSelectedTeamId(team.id!)
        setUsers((team.members as Person[]) ?? [])
        // setFilteredUsers((team.members as Person[]) ?? [])
      }
    },
    [selectedTeamId]
  )

  const onCreate = useCallback(async () => {
    setIsSaving(true)
    const params: ChatCreateProps = {
      fullTeam: includeAllMembers,
      chatDirection: disableReplies ? 'unilateral' : 'bilateral',
      chatType: !!selectedTeamId
        ? 'team'
        : selectedUsers?.length === 1
        ? 'direct'
        : 'group',
      participantIds: (selectedUsers ?? []).map(
        (user) => (user as TeamMember).userId! ?? user.id!
      ),
      teamId: selectedTeamId,
    }
    if (!selectedTeamId) {
      if (selectedUsers?.length === 1) {
        params.chatTitle = selectedUsers.map(
          (user) => `${user.firstName!} ${user.lastName}`
        )[0]
      } else {
        params.chatTitle = selectedUsers
          ?.map((user) => user.firstName)
          .join(', ')
      }
    }
    if (customName || props.title) {
      params.chatTitle = customName ?? props.title
    }

    if (!!selectedTeamId) {
      // Teammembers have userIds
      params.participantIds =
        (selectedUsers as TeamMember[])?.map((u) => u.userId as string) ?? []
    } else {
      // Friends have ids
      params.participantIds = selectedUsers?.map((u) => u.id as string) ?? []
    }

    // remove dupe ids
    params.participantIds = Array.from(new Set(params.participantIds))

    const { data: newChat } = await chatClient.chatCreate(params)
    if (newChat) {
      logEvent('chatCreate', {
        createdFromNewChatDialog: true,
        createdFromChatIcon: false,
        isDirectChat: newChat.chatType === 'direct',
        isFriendsGroupChat: newChat.chatType === 'group',
        isTeamChat: newChat.chatType === 'team',
        isBilateralChat: newChat.chatDirection === 'bilateral',
        numberOfParticipants: newChat.participants?.length,
      })

      const sendMessage = async ({
        message,
        upload,
      }: {
        message?: string
        upload?: Upload
      }) => {
        const chatAttachment = upload
          ? convertUploadDataPropsToChatAttachment([upload])[0]
          : undefined
        return await chatClient.chatSend({
          chatAttachment,
          message,
          token: newChat?.token!,
          uploadId: upload?.id ?? undefined,
          uploadFromShared,
        })
      }

      // Send initial messages
      if (messageText.length > 0) {
        await sendMessage({ message: messageText })
      }
      if (uploads.length > 0) {
        for (const upload of uploads) {
          await sendMessage({ upload })
        }
      }

      // Update chat list and active chat
      chatClient.updateUnreadCount()
      await dispatch(fetchChats())
      dispatch(setChatsScreenActiveChatId(newChat.id))

      dispatch(
        addChatBox({
          chatId: newChat.id!,
        })
      )

      onClose()
      props.onCreateNewChat?.(newChat)
      setIsSaving(false)
    }
  }, [
    customName,
    disableReplies,
    includeAllMembers,
    messageText,
    onClose,
    props,
    selectedTeamId,
    selectedUsers,
    uploads,
  ])

  const handleOnChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target
      setMessageText(value)
    },
    []
  )

  const handleOnUploadsChange = useCallback((_uploads: Upload[]) => {
    setUploads(_uploads)
  }, [])

  const toggleDisableReplies = useCallback(() => {
    setDisableReplies(!disableReplies)
  }, [disableReplies])

  const toggleIncludeAllMembers = useCallback(() => {
    const newValue = !includeAllMembers
    setIncludeAllMembers(newValue)
    if (newValue) {
      setSelectedUsers(users)
    }
  }, [includeAllMembers, users])

  const canStartChat = useMemo(() => {
    return messageText.trim() !== '' && !isSaving && selectedUsers?.length
  }, [isSaving, messageText, selectedUsers])

  const userSectionsByType = useMemo(() => {
    if (!props.showTeamPicker && !selectedTeamId) {
      return [{ title: 'Teammates/Contacts', data: filteredUsers as Person[] }]
    }

    if (selectedTeam?.type === 'group') {
      return [{ title: 'Members', data: filteredUsers as TeamMember[] }]
    }

    const _users = (filteredUsers as TeamMember[]) ?? []
    const sections = [
      {
        title: 'Coaches',
        data: _users?.filter((u) => u.role === 'coach'),
      },
      { title: 'Players', data: _users?.filter((u) => u.role === 'player') },
      { title: 'Family', data: _users?.filter((u) => u.role === 'parent') },
    ]
    return sections.filter((section) => section.data.length > 0)
  }, [filteredUsers, props.showTeamPicker, selectedTeam?.type, selectedTeamId])

  useEffect(() => {
    props.teamId && setSelectedTeamId(props.teamId)
  }, [props.teamId])

  useEffect(() => {
    if (!props.showTeamPicker) {
      setUsers(contacts ?? [])
    }
  }, [contacts, props.showTeamPicker])

  useEffect(() => {
    if (isOpen) {
      reset()
      if (!props.teamId) {
        selectedTeam?.type === 'group' && fetchContacts()
      }
      setCustomName(props.title ?? '')
      setUploads(props.uploads ?? [])
      setUploadFromShared(!!props.uploads?.length)
    } else {
      setMessageText(props.message ?? '')
    }
  }, [
    dispatch,
    fetchContacts,
    isOpen,
    props.message,
    props.teamId,
    props.title,
    props.uploads,
    reset,
    selectedTeam?.type,
  ])

  // useEffect(() => {
  //   if (filterQuery) {
  //     setFilteredUsers(
  //       users?.filter(user => {
  //         if (user.id === currentUser?.id) {
  //           return false
  //         }
  //         return getUsername(user)
  //           ?.toLowerCase()
  //           .includes(filterQuery.toLowerCase())
  //       }),
  //     )
  //   } else {
  //     setFilteredUsers(users)
  //   }
  // }, [currentUser?.id, filterQuery, users])

  useEffect(() => {
    setIncludeAllMembers(selectedUsers?.length === users?.length)
  }, [selectedUsers, users?.length])

  useEffect(() => {
    if (selectedTeamId) {
      changeTeam()
    }
  }, [changeTeam, selectedTeamId])

  useEffect(() => {
    setCanRenameChat(!!selectedTeam?.name)
  }, [selectedTeam?.name, selectedUsers])

  let chatTitle = ''
  if (selectedTeamId) {
    chatTitle = `${selectedTeam?.name}: New ${
      selectedTeam?.type === 'team' ? 'Team' : 'Group'
    } Chat`
  } else {
    chatTitle = props.showTeamPicker ? 'New Team/Group Chat' : 'New Chat'
  }

  const handleOnClickRemoveRecipient = (user: Person) => {
    const id = (user as TeamMember).userId ?? user.id
    console.log('sect', { id })
    setSelectedUsers((prev) =>
      prev?.filter((u) => {
        const _id = (u as TeamMember).userId ?? u.id
        return _id !== id
      })
    )
  }

  return (
    <Modal visible={isOpen} onClose={onClose} contentWidth={900}>
      <Modal.Header>
        <HeaderContainer>
          {isSettingCustomName ? (
            <RenameInput
              onCancel={onCancelSetCustomName}
              onSubmit={onSubmitSetCustomName}
              saveButtonText='Set Chat Name'
              value={customName}
            />
          ) : (
            <>
              <b>{chatTitle}</b>
              {canRenameChat && (
                <SetCustomNameButton onClick={onClickSetCustomName}>
                  {!!customName?.length && <> titled "{customName}"</>}
                  <Icon name={'Pencil'} width={15} />
                </SetCustomNameButton>
              )}
            </>
          )}
        </HeaderContainer>
      </Modal.Header>
      <Modal.Body>
        <ModalBodyContainer>
          <ChatCreateFormContainer>
            <ColumnHeader>Message</ColumnHeader>
            <ChatMessageInput
              autoGrow={false}
              boxMode={false}
              disableAttachment={uploadFromShared}
              hideTypingIndicator
              initialRows={10}
              isNewChatModal
              maximumRows={10}
              messageText={messageText}
              minimumRows={10}
              onChange={handleOnChangeInput}
              onMessageUploadsChange={handleOnUploadsChange}
              placeholder={'What are you up to?'}
              pressEnterToSend={false}
              selectAttachmentsButtonText={''}
            />
            {uploadFromShared &&
              !!uploads.length &&
              uploads.map((upload, index) => {
                let uploadSrc = getTranscodeUrl({
                  upload: upload.transcodes as Image[],
                  transcodeTypes: ['media', 'feed', 'THUMB'],
                })
                if (isGif(upload.contentType ?? '')) {
                  uploadSrc = upload.viewUrl ?? ''
                }
                return (
                  <UploadContainer
                    key={`${index}-${upload.id}`}
                    title={upload.fileName!}
                  >
                    {isFile(upload?.contentType ?? '') ? (
                      <UploadFileContainer>
                        <FileTypeIcon
                          contentType={upload.contentType!}
                          fileName={upload.fileName!}
                          nameCase='pascal'
                          size={80}
                        />
                        <FileName>{upload.fileName}</FileName>
                      </UploadFileContainer>
                    ) : (
                      <img src={uploadSrc} alt={upload.fileName!} />
                    )}
                  </UploadContainer>
                )
              })}
            {/* New Chat Settings */}
            <div style={{ display: 'none' }}>
              <h3>Chat Settings</h3>
              {selectedTeamId && (
                <>
                  <Checkbox
                    size={16}
                    style={{ alignItems: 'flex-start' }}
                    labelStyle={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span style={{ fontSize: 16, fontWeight: 700 }}>
                      Include All Members
                    </span>
                    <span style={{ fontSize: 14, color: Colors.SHUTTLE_GRAY }}>
                      All future members will be included in this chat
                    </span>
                  </Checkbox>
                  <br />
                  <br />
                </>
              )}
              {selectedTeamId && (
                <Checkbox
                  size={16}
                  style={{ alignItems: 'flex-start' }}
                  labelStyle={{ display: 'flex', flexDirection: 'column' }}
                >
                  <span style={{ fontSize: 16, fontWeight: 700 }}>
                    Disable Replies
                  </span>
                  <span style={{ fontSize: 14, color: Colors.SHUTTLE_GRAY }}>
                    Only you can create messages
                  </span>
                </Checkbox>
              )}
            </div>
          </ChatCreateFormContainer>

          <ListAndRecipientContainer>
            <ListContainer>
              <ColumnHeader>Add/Remove Recipients</ColumnHeader>
              <SelectUserListContainer>
                {props.showTeamPicker ? (
                  <TeamList>
                    {teamsAndGroupsUserCanChat?.map((team) => {
                      const avatarImage =
                        getProfileImage.getProfileAvatarImageUrl(
                          team as Profile
                        )
                      const isChecked = selectedTeamId === team.id
                      return (
                        <TeamListItem
                          key={team.id}
                          onClick={() => onClickTeam(team)}
                        >
                          <TeamListItemRow>
                            <TeamListItemRowName>
                              <Avatar
                                diameter={24}
                                name={team.name!}
                                uri={avatarImage}
                                style={{ marginRight: 5 }}
                              />
                              {team.name}
                            </TeamListItemRowName>
                            <TeamListItemRadio checked={isChecked} hideLabel />
                          </TeamListItemRow>
                          {isChecked ? (
                            users?.length ? (
                              <SelectUserListSectioned
                                addHoverStyles
                                canSelectAll
                                clickableHeader
                                collapsible
                                listStyle={{
                                  borderBottom: `1px solid ${Colors.ALTO}`,
                                  marginBottom: 10,
                                  marginTop: 8,
                                }}
                                onChange={setSelectedUsers}
                                rowSize='small'
                                searchbarInputContainerStyle={{ minWidth: 1 }}
                                rightAlignCheckbox
                                selectedUsers={selectedUsers}
                                sections={userSectionsByType}
                                useCheckboxForSelectAll
                              />
                            ) : (
                              <TeamListEmpty>
                                {isLoading ? (
                                  <StyledSpinner size={20} />
                                ) : (
                                  <>
                                    Your team doesn't have any other members to
                                    start a chat with.
                                    <br />
                                    <br />
                                    You can add more members to your team by
                                    going to the {team.name} page and clicking
                                    the "Add Members" button.
                                  </>
                                )}
                              </TeamListEmpty>
                            )
                          ) : null}
                        </TeamListItem>
                      )
                    })}
                  </TeamList>
                ) : (
                  <SelectUserListSectioned
                    addHoverStyles
                    canSelectAll={
                      !!selectedTeamId && !selectedTeam?.isLargeTeam
                    }
                    clickableHeader
                    collapsible={selectedTeam?.type === 'team'}
                    listStyle={{ height: '100%', overflowY: 'auto' }}
                    onChange={setSelectedUsers}
                    rowSize='small'
                    rightAlignCheckbox
                    searchable
                    searchbarContainerStyle={{
                      marginBottom: 10,
                      padding: 0,
                      width: '100%',
                    }}
                    searchbarInputContainerStyle={{ minWidth: 1 }}
                    selectedUsers={selectedUsers}
                    sections={userSectionsByType}
                    useCheckboxForSelectAll
                  />
                )}
              </SelectUserListContainer>
            </ListContainer>
            <RecipientContainer>
              <ColumnHeader>
                Recipients: <b>{uniqueSelectedUsers?.length ?? 0} </b>
              </ColumnHeader>
              <Recipients>
                {uniqueSelectedUsers?.map((u, index) => (
                  <RecipientRow key={index}>
                    <Recipient key={u.id}>
                      {u.firstName} {u.lastName}
                    </Recipient>
                    <RemoveButton
                      onClick={() => handleOnClickRemoveRecipient(u)}
                    >
                      <Icon name='X' width={14} />
                    </RemoveButton>
                  </RecipientRow>
                ))}
              </Recipients>
            </RecipientContainer>
          </ListAndRecipientContainer>
        </ModalBodyContainer>
      </Modal.Body>
      <Modal.Footer
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {selectedTeamId && (
            <StyledCheckbox
              checked={includeAllMembers}
              labelStyle={{ fontSize: 14 }}
              onChange={toggleIncludeAllMembers}
              size={16}
            >
              Include All Members
            </StyledCheckbox>
          )}

          {selectedTeamId && (
            <StyledCheckbox
              checked={disableReplies}
              onChange={toggleDisableReplies}
              labelStyle={{ fontSize: 14 }}
              size={16}
            >
              Disable Replies
            </StyledCheckbox>
          )}
        </div>

        <Button disabled={!canStartChat} onClick={onCreate} variant='primary'>
          Start Chat
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const ChatCreateFormContainer = styled.div`
  flex: 0 0 300px;
  margin-right: 5;
  min-width: 1;
`

const HeaderContainer = styled.div`
  display: flex;
`

const SetCustomNameButton = styled.div`
  cursor: pointer;
  padding-left: 4px;

  & svg {
    margin-left: 5px;
  }
`
const ListAndRecipientContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-left: 5px;
  min-width: 1px;
  // overflow-y: auto;
`
const RecipientContainer = styled.div`
  flex: 1 1 30%;
  margin-left: 10px;
  min-width: 200px;
`
const ColumnHeader = styled.header`
  font-size: 12px;
  padding-bottom: 4px;
  text-transform: uppercase;
`
const Recipients = styled.div`
  border-top: 1px solid ${Colors.ALTO};
  overflow-y: auto;
`
const RecipientRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`
const Recipient = styled.p`
  margin: 0;
`

const SelectUserListContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  // margin: 0 -10px;
`
const StyledCheckbox = styled(Checkbox)`
  border-radius: ${BORDER_RADIUS};
  padding: 4px;
  transition: background-color 120ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
  & + & {
    margin-left: 6px;
  }
`

const TeamList = styled.div`
  border-top: 1px solid ${Colors.ALTO};
  height: 400px;
  overflow-y: scroll;
  padding-top: 10px;
`
const TeamListItem = styled.div`
  align-items: center;
  font-size: 14px;
`
const TeamListItemRow = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  padding-right: 10px;
  width: 100%;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const TeamListItemRadio = styled(Radio)`
  padding-right: 2px;
`
const TeamListItemRowName = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`
const TeamListEmpty = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  padding: 10px;
  line-height: 1;
  max-width: 250px;
`
const StyledSpinner = styled(Spinner)`
  margin-left: auto;
  margin-right: auto;
`
const UploadContainer = styled.div`
  align-items: center;
  background-color: ${Colors.ALTO};
  border-radius: 8px;
  color: ${Colors.DUSTY_GRAY};
  display: flex;
  height: 150px;
  justify-content: center;
  margin: 5px 5px 0 0;
  overflow: hidden;
  position: relative;
  width: 300px;

  .remove {
    align-items: center;
    background-color: ${Colors.BLACK};
    border-radius: 50%;
    color: ${Colors.WHITE};
    cursor: pointer;
    display: none;
    height: 20px;
    justify-content: center;
    position: absolute;
    padding: 2px;
    right: 0;
    top: 0;
    width: 20px;
  }

  &:hover {
    .remove {
      display: flex;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const UploadFileContainer = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  // flex-wrap: wrap;
`
const FileName = styled.span`
  padding-top: 10px;
  text-align: center;
`
const ModalBodyContainer = styled.div`
  display: flex;
  height: 420px;
`

const RemoveButton = styled.button`
  background: none;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`

export default ChatCreateModal
