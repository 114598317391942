import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ChatMessageLike, Profile, User } from '@sportsyou/api'
import { Colors, getProfileImage } from '@sportsyou/core'
import { Avatar, Modal } from '@sportsyou/react-dom-ui'

import { shortCodeToEmoji } from '../../../utils/EmojiUtils'

interface ReactionsModalProps {
  onClose: () => void
  items?: ChatMessageLike[]
}

export default function ReactionsModal(props: ReactionsModalProps) {
  const navigate = useNavigate()

  const onClose = useCallback(() => {
    props.onClose()
  }, [props])

  const onClickUser = useCallback(
    (user?: User) => {
      navigate(`/profile/${user?.id}`)
      onClose()
    },
    [navigate, onClose]
  )

  const renderItem = (item: ChatMessageLike) => {
    const user = item?.createdBy
    if (!user) {
      return null
    }
    const avatarImage = getProfileImage.getProfileAvatarImageUrl(
      user as Profile
    )
    const fullName = user.fullName || `${user.firstName} ${user.lastName}`
    return (
      <Row key={user.id} onClick={() => onClickUser(user)}>
        <UserContainer>
          <Avatar name={fullName} uri={avatarImage} diameter={40} />
          <UserName>{fullName}</UserName>
        </UserContainer>
        <ReactionImage>{shortCodeToEmoji(item.likeType)}</ReactionImage>
      </Row>
    )
  }

  return (
    <Modal onClose={onClose} visible={!!props.items}>
      <Modal.Header>
        <b>Reactions {props.items?.length}</b>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <MainContainer>{props.items?.map(renderItem)}</MainContainer>
      </Modal.Body>
    </Modal>
  )
}

const MainContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  border-bottom-color: ${Colors.MYSTIC};
  padding: 10px;

  border-bottom-width: 1px;
  width: 100%;

  &:hover {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`

const UserContainer = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
`

const UserName = styled.div`
  color: ${Colors.MINE_SHAFT};
  margin-left: 10px;
  margin-right: 10px;
`

const ReactionImage = styled.div`
  font-size: 30px;
  margin-right: 10px;
`
