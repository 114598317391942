import { useContext } from 'react'
import { BuildTeamContext } from './BuildTeam'

export const useCreateTeam = () => {
  const {
    createTeam,
    isCreateTeamVisible,
    setIsCreateTeamVisible,
    shouldNavigateToTeam,
    type,
  } = useContext(BuildTeamContext)
  return {
    createTeam,
    isVisible: isCreateTeamVisible,
    setIsVisible: setIsCreateTeamVisible,
    shouldNavigateToTeam,
    type,
  }
}

export default useCreateTeam
