import React from 'react'

interface Props {}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type FriendListItemProps = Props & NativeAttributes

export const FriendListItem: React.FC<FriendListItemProps> = ({
  ...props
}: FriendListItemProps) => {
  return <div {...props}></div>
}

export default FriendListItem
