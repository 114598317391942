export interface names {
  total: number
  formattedData: AvatarDataTypes[]
  useRawString?: boolean
  titles: Array<string>
  formatted: Array<string>
}

export interface AvatarDataTypes {
  display: string
  title: string
  useRawString: boolean
  uri?: string
}

export interface AvatarGroupItemProps {
  name: string
  uri?: string
}

export const formatAvatarItems = (
  items: Array<AvatarGroupItemProps>
): Array<AvatarDataTypes> => {
  if (items.length > 4) {
    const MAX_ITEMS_TO_DISPLAY = 4
    const REMAINING_ITEMS = items.length - 3

    const itemData: AvatarDataTypes[] = []

    // build first 3 Items
    for (let i = 0; i < MAX_ITEMS_TO_DISPLAY - 1; i++) {
      itemData.push({
        display: items[i].name,
        uri: items[i].uri,
        useRawString: i > 3,
        title: items[i].name,
      })
    }

    // build title for remaining names
    const REMAINING_ITEMS_NAMES = items
      .map((item) => item.name)
      .splice(3, items.length)

    for (let i = 0; i < REMAINING_ITEMS_NAMES.length; i++) {
      if (i > 0) {
        if (i + 1 === REMAINING_ITEMS_NAMES.length) {
          REMAINING_ITEMS_NAMES[i] = ` and ${REMAINING_ITEMS_NAMES[i]}`
        } else {
          REMAINING_ITEMS_NAMES[i] = `, ${REMAINING_ITEMS_NAMES[i]}`
        }
      }
    }

    const title = REMAINING_ITEMS_NAMES.join('')

    itemData.push({
      display: `+${REMAINING_ITEMS}`,
      title,
      useRawString: true,
    })
    return itemData
  }
  return items.map((item) => ({
    display: item.name,
    title: item.name,
    uri: item.uri,
    useRawString: false,
  }))
}
export const formatAvatarNames = (items: Array<AvatarGroupItemProps>) => {
  const names = items.map((item) => item.name)
  if (names.length > 4) {
    const MAX_AVATARS_TO_DISPLAY = 4
    const REMAINING_AVATARS = names.length - 3

    const _data: AvatarDataTypes[] = []

    // first 3 avatars
    for (let i = 0; i < MAX_AVATARS_TO_DISPLAY - 1; i++) {
      _data.push({
        display: names[i],
        title: names[i],
        useRawString: i > 3,
      })
    }

    const REMAINING_NAMES = names.map((n) => n).splice(3, names.length)

    for (let i = 0; i < REMAINING_NAMES.length; i++) {
      if (i > 0) {
        if (i + 1 === REMAINING_NAMES.length) {
          REMAINING_NAMES[i] = ` and ${REMAINING_NAMES[i]}`
        } else {
          REMAINING_NAMES[i] = `, ${REMAINING_NAMES[i]}`
        }
      }
    }

    const title = REMAINING_NAMES.join('')

    _data.push({
      display: `+${REMAINING_AVATARS}`,
      title,
      useRawString: true,
    })
    return _data
  } else {
    const _names = names.map((name, index) => {
      return {
        display: name,
        title: name,
        useRawString: false,
      }
    })
    return _names
  }
}
