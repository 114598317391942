import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import {
  mutationUserPreferences,
  MutationUserPreferencesRequest,
  User,
} from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { IconNames } from '@sportsyou/react-icons'
import { BORDER_RADIUS, Button, CloseButton } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import { updateUserPreference } from 'web/store/slices/UserSlice'
import { publicImagesPath } from 'web/constants'
import { useCreateTeam } from 'web/hooks'

interface Props {
  user: User
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type WelcomePostProps = Props & NativeAttributes

type WelcomePostCallout = {
  image?: {
    alt?: string
    src?: string
    title?: string
    href?: string
  }
  buttonText?: string
  buttonOnClick?: () => void
  description?: string
}

type MainCallout = {
  icon?: IconNames
  header?: string
  description?: string
  onClick?: () => void
  iconFill?: Colors
}

export interface WelcomeCard {
  callouts?: Array<WelcomePostCallout>
  main?: MainCallout
}

export const WelcomePost: React.FC<WelcomePostProps> = ({
  user,
}: WelcomePostProps) => {
  const { createTeam } = useCreateTeam()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { fetch: closeWelcomeCard } = useFetchApi(mutationUserPreferences)

  const callouts: Array<WelcomePostCallout> = [
    {
      buttonText: 'Create Team',
      description:
        'Post updates and send real-time messages to keep everyone together.',
      image: {
        alt: 'Create a Team',
        href: 'https://www.youtube.com/watch?v=kxkzzhMibQk',
        src: `${publicImagesPath}/how-to/set-up-team-web.png`,
        title: 'Video: How to Create a Team on sportsYou',
      },
      buttonOnClick: () => {
        createTeam({ isVisible: true, type: 'team' })
      },
    },
    {
      image: {
        alt: 'Export Calendar',
        href: 'https://www.youtube.com/watch?v=hTMgwNeGIvA',
        src: `${publicImagesPath}/how-to/cal-event-web.png`,
        title: 'Video: How to Create an Event on sportsYou Calendar',
      },
      buttonText: 'Explore Calendar',
      description:
        'Manage your entire schedule with ease using the sportsYou calendar.',
      buttonOnClick: () => {
        navigate('/calendar')
      },
    },
  ]

  const handleOnClickAction = (fn?: () => void) => {
    fn?.()
  }

  const onClose = async () => {
    const { error, ok } = await closeWelcomeCard({
      showWelcomeCard: false,
    } as MutationUserPreferencesRequest)
    if (ok) {
      dispatch(updateUserPreference({ showWelcomeCard: false }))
    }
    if (error) console.log({ error })
  }

  const renderDocIcon = useMemo(
    () => (
      <svg width='100px' height='100px' viewBox='0 0 100 100'>
        <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
          <g id='Group-32' fill-rule='nonzero'>
            <g id='Group_30' fill='#E0E7EE'>
              <circle id='Ellipse_4' cx='50' cy='50' r='50'></circle>
            </g>
            <g id='Group_31' transform='translate(30.357143, 22.457143)'>
              <g id='Group_4_1_'>
                <path
                  d='M37.2785714,12.0964286 C37.3305668,11.7476103 37.2419297,11.3924196 37.0321429,11.1089286 L31.7232143,5.80178571 L26.2928571,0.123214286 C26.1696429,0.123214286 26.0464286,0.123214286 26.0464286,0 L4.9375,0 C4.81428571,0.123214286 4.69107143,0.123214286 4.69107143,0.246428571 L0.369642857,4.69107143 C0.335520563,4.69047679 0.302723146,4.70426866 0.279280444,4.72907036 C0.255837743,4.75387206 0.243914017,4.78739386 0.246428571,4.82142857 C0.24691495,4.85425372 0.234089728,4.88587715 0.210876294,4.90909058 C0.18766286,4.93230401 0.156039435,4.94512924 0.123214286,4.94464286 L0.123214286,5.06785714 C0.123214286,5.17857143 0.123214286,5.17857143 0,5.17857143 L0,51.9642857 C0,52.0875 0.123214286,52.0875 0.123214286,52.2107143 L0.246428571,52.3339286 L0.369642857,52.3339286 C0.492857143,52.4571429 0.492857143,52.4571429 0.616071429,52.4571429 C0.739285714,52.4571429 0.739285714,52.4571429 0.739285714,52.5803571 L31.7214286,52.5803571 C32.0162994,52.5813099 32.2993678,52.4645945 32.507874,52.2560883 C32.7163802,52.0475821 32.8330956,51.7645136 32.8321429,51.4696429 L32.8321429,48.2553571 L36.1642857,48.2553571 C36.4591565,48.2563099 36.7422249,48.1395945 36.9507311,47.9310883 C37.1592374,47.7225821 37.2759527,47.4395136 37.275,47.1446429 L37.275,12.3446429 C37.4017857,12.2196429 37.4017857,12.2196429 37.2785714,12.0964286 Z'
                  id='Path_1_1_'
                  fill='#D94B21'
                ></path>
                <path
                  d='M23.3303571,32.5875 L11.3571429,32.5875 C10.7851471,32.6365542 10.3514117,33.1244085 10.3696429,33.6982143 C10.4096063,34.2261573 10.8291998,34.6457508 11.3571429,34.6857143 L23.3303571,34.6857143 C23.5934319,34.6896051 23.8468734,34.5868151 24.0329157,34.4007728 C24.218958,34.2147305 24.321748,33.9612891 24.3178571,33.6982143 C24.3463925,33.417173 24.2557998,33.1370407 24.0681051,32.9259265 C23.8804103,32.7148124 23.6128067,32.5920548 23.3303571,32.5875 Z'
                  id='Path_2_1_'
                  fill='#2B2B2B'
                ></path>
                <path
                  d='M29.8732143,28.6375 L11.8517857,28.6375 C10.9875,28.6375 10.3696429,29.1321429 10.3696429,29.7482143 C10.3696429,30.3642857 11.1107143,30.8589286 11.8517857,30.8589286 L29.8732143,30.8589286 C30.7375,30.8589286 31.3553571,30.3642857 31.3553571,29.7482143 C31.3553571,29.1321429 30.6142857,28.6375 29.8732143,28.6375 Z'
                  id='Path_3_1_'
                  fill='#2B2B2B'
                ></path>
                <path
                  d='M10.3696429,20.8607143 C10.3696429,21.4785714 11.1107143,21.9714286 11.8517857,21.9714286 L29.8732143,21.9714286 C30.7375,21.9714286 31.3553571,21.4767857 31.3553571,20.8607143 C31.3553571,20.2446429 30.6142857,19.75 29.8732143,19.75 L11.8517857,19.75 C10.9875,19.75 10.3696429,20.2446429 10.3696429,20.8607143 Z'
                  id='Path_4_1_'
                  fill='#2B2B2B'
                ></path>
                <path
                  d='M29.8732143,24.1946429 L11.8517857,24.1946429 C10.9875,24.1946429 10.3696429,24.6892857 10.3696429,25.3053571 C10.3696429,25.9214286 11.1107143,26.4160714 11.8517857,26.4160714 L29.8732143,26.4160714 C30.7375,26.4160714 31.3553571,25.9214286 31.3553571,25.3053571 C31.3553571,24.6892857 30.6142857,24.1946429 29.8732143,24.1946429 Z'
                  id='Path_5_1_'
                  fill='#2B2B2B'
                ></path>
              </g>
              <polygon
                id='Path_49'
                fill='#2B2B2B'
                points='26.4160714 3.70357143 30.1196429 7.40714286 33.4517857 10.8625 26.4160714 10.8625'
              ></polygon>
              <polygon
                id='Path_50'
                fill='#2B2B2B'
                points='2.22142857 5.925 4.31964286 3.70357143 4.31964286 46.6607143 2.09821429 48.8821429 2.09821429 5.925'
              ></polygon>
              <polygon
                id='Path_51'
                fill='#2B2B2B'
                points='30.7357143 50.3625 3.70357143 50.3625 6.04821429 48.1410714 30.7357143 48.1410714'
              ></polygon>
            </g>
          </g>
        </g>
      </svg>
    ),
    []
  )

  const renderArrowSvg = useMemo(
    () => (
      <svg
        viewBox='0 0 39 32'
        xmlns='http://www.w3.org/2000/svg'
        fill={Colors.WHITE}
      >
        <path
          d='M29.744504 13l-8.800588-8.724583c-.980535-.972068-.9874-2.554965-.015333-3.535501.972068-.980535 2.554965-.9874 3.535501-.015333l13.2 13.086c.982599.974113.987121 2.561078.010089 3.540775l-13.2 13.236c-.974981.977639-2.557892.979795-3.535531.004815-.977639-.974981-.979795-2.557892-.004815-3.535531L29.965837 18H2.5C1.119288 18 0 16.880712 0 15.5S1.119288 13 2.5 13h27.244504z'
          fill-rule='nonzero'
        />
      </svg>
    ),
    []
  )

  if (!user.preferences?.showWelcomeCard) return null

  return (
    <Container>
      <Header>
        <h3>Welcome to sportsYou, {user.firstName}!</h3>
        <CloseButton onClick={onClose} />
      </Header>
      <Body>
        {callouts.map((callout, index) => (
          <CalloutContainer key={index}>
            <CalloutImageLink
              aria-label={callout.image?.title}
              href={callout.image?.href}
              rel='nofollow'
              target='_blank'
              title={callout.image?.title}
            >
              <CalloutImage alt={callout.image?.alt} src={callout.image?.src} />
            </CalloutImageLink>
            <CalloutActionContainer>
              <Button
                onClick={() => handleOnClickAction(callout.buttonOnClick)}
              >
                {callout.buttonText}
              </Button>
              <p>{callout.description}</p>
            </CalloutActionContainer>
          </CalloutContainer>
        ))}
      </Body>
      <Footer
        aria-label='sportsYou Help Center'
        href='https://help.sportsyou.com/en/collections/642961-help-center'
        rel='nofollow'
        target='_blank'
        title='sportsYou Help Center'
      >
        <DocContainer>{renderDocIcon}</DocContainer>

        <FooterText>
          <h4>sportsYou Feature Guide</h4>
          <p>
            Check out articles and vides to learn more about the sportsYou
            platform.
          </p>
        </FooterText>

        <ArrowContainer>{renderArrowSvg}</ArrowContainer>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  margin-top: 10px;
`
const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
  & > h3 {
    margin: 0;
    font-size: 18px;
    color: ${Colors.PUNCH};
    font-weight: 500;
  }
`
const Body = styled.div`
  border-bottom: 1px solid ${Colors.ALTO};
`
const CalloutContainer = styled.div`
  display: flex;
  padding: 20px 10px;
  @media all and (max-width: 767px) {
    flex-direction: column;
  }
  & + & {
    border-top: 1px solid ${Colors.ALTO};
  }
`
const CalloutImageLink = styled.a`
  flex: 0 0 150px;
`
const CalloutImage = styled.img`
  background-color: ${Colors.HAVELOCK_BLUE};
  display: block;
  height: auto;
  width: 100%;
`
const CalloutActionContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  & > p {
    font-size: 14px;
    margin-bottom: 0;
  }
  @media all and (max-width: 767px) {
    margin-top: 15px;
  }
  @media all and (min-width: 768px) {
    margin-left: 30px;
    padding-right: 0px;
  }
  @media all and (min-width: 900px) {
    margin-left: 30px;
    padding-right: 120px;
  }
`
const Footer = styled.a`
  align-items: center;
  background-color: ${Colors.HAVELOCK_BLUE};
  border-radius: ${BORDER_RADIUS};
  color: ${Colors.WHITE};
  display: flex;
  margin: 10px;
  padding: 16px 20px;
  text-decoration: none;
  transition: 120ms ease-in-out;
  &:hover,
  &:active {
    box-shadow: 0 0 12px -2px rgb(0 0 0 / 20%);
  }
`
const IconContainerStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
`
const DocContainer = styled.div`
  ${IconContainerStyles};
  height: 44px;
  width: 44px;
  margin-right: 10px;

  @media all and (max-width: 767px) {
    display: none;
  }
`
const FooterText = styled.div`
  color: ${Colors.WHITE};
  & > h4 {
    margin: 0;
    font-family: 'Cocogoose Pro';
    font-size: 20px;
  }
  & > p {
    font-weight: 500;
    margin: 0;
    margin-top: 4px;
  }
`
const ArrowContainer = styled.div`
  ${IconContainerStyles};
  height: 52px;
  margin-left: 10px;
  width: 52px;
  @media all and (min-width: 768px) {
    margin-left: 40px;
    margin-top: 15px;
  }
`

export default WelcomePost
