import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  getContentType,
  getTranscodeUrl,
  isImage,
  isVideo,
} from '@sportsyou/core'
import { Image, QueryUploadUrlRequest, queryUploadUrl } from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

import { LightboxItem as LightboxItemProps } from '../../../store/slices/LightboxSlice'

import VideoPlayer from './VideoPlayer'
import Player from 'video.js/dist/types/player'

function canDisplayOnBrowser(item: LightboxItemProps): boolean {
  const displayableImageTypes = ['jpeg', 'png', 'gif', 'webp']
  const imageType = item.contentType?.split('/').pop() ?? ''

  return displayableImageTypes.includes(imageType)
}

export default function LightboxItem(item: LightboxItemProps) {
  const { fetch: getUploadUrl } = useFetchApi(queryUploadUrl)

  const onLoadImage = useCallback((e: any) => {
    e.target.style.opacity = '1'
  }, [])

  const [signedUrl, setSignedUrl] = useState<string | undefined>(undefined)
  const [type] = useState<string>(() => {
    const contentType = getContentType(item)
    if (isImage(contentType)) {
      return 'image'
    } else if (isVideo(contentType)) {
      return 'video'
    }
    return 'file'
  })

  const getSignedUrl = useCallback(async () => {
    const response = await getUploadUrl({
      uploadId: item.id,
      requestSignedURL: true,
    } as QueryUploadUrlRequest)
    setSignedUrl(response.data?.signedUrl as string)
  }, [item.id, getUploadUrl])

  useEffect(() => {
    if (item.active && !signedUrl && type === 'file') {
      getSignedUrl()
    }
  }, [getSignedUrl, item.active, signedUrl, type])

  if (!item.viewUrl) {
    return null
  }

  if (type === 'image') {
    const viewUrl = canDisplayOnBrowser(item)
      ? item.viewUrl
      : getTranscodeUrl({
          upload: item.transcodes as Image[],
          transcodeTypes: ['feedLarge', 'feed', 'media'],
        })
    return (
      <div className='swiper-zoom-container'>
        <img
          alt={item.fileName as string}
          className='swiper-lazy'
          data-src={viewUrl}
          onLoad={onLoadImage}
        />
        <div className='swiper-lazy-preloader swiper-lazy-preloader-white'></div>
      </div>
    )
  }
  if (type === 'video') {
    const transcodes = item.transcodes as Image[]
    const transcodeTypes = ['HLS', 'VIDEO']
    const src = getTranscodeUrl({ upload: transcodes, transcodeTypes })
    const srcType = transcodes
      .filter((tr) => transcodeTypes.includes(tr.transcodeType as string))
      .shift()?.contentType
    return (
      <VideoPlayer
        canPlay={!!item.active}
        options={{
          autoPlay: item.autoPlay,
          controls: true,
          fill: true,
          responsive: true,
          sources: [{ src: src ?? item.viewUrl, type: srcType ?? 'video/mp4' }],
        }}
        onReady={(player: Player) => {
          if (item.autoPlay) {
            /**
             * Using autoplay attribute is not reliable, using .play() instead
             * https://videojs.com/blog/autoplay-best-practices-with-video-js/
             */
            // wait for player to be ready
            player.ready(() => {
              const promise = player.play()
              if (promise !== undefined) {
                promise
                  .then(() => {
                    // Autoplay started!
                    // console.log('auto play')
                  })
                  .catch((error) => {
                    // Autoplay was prevented.
                    console.log(
                      '==> Lightbox VideoPlayer: autoplay prevented',
                      { error }
                    )
                  })
              }
            })
          }
        }}
        item={item}
      />
    )
  }
  if (signedUrl) {
    return (
      <FileViewer
        src={
          'https://docs.google.com/viewer?embedded=true&url=' +
          encodeURIComponent(signedUrl)
        }
        title={item.fileName as string}
      />
    )
  }
  return null
}

const FileViewer = styled.iframe`
  margin-top: 65px;
  width: calc(100vw - 150px);
  height: calc(100vh - 80px);
  margin-right: 70px;
  margin-left: 70px;
  border-radius: 8px;
`
