import moment from 'moment'

import { ChatParticipant } from '@sportsyou/api'
import { ChatListChat } from 'web/store/slices/types'

export function preProcessSingleChat(chat: ChatListChat): ChatListChat {
  if (!chat) {
    return chat
  }
  let _chat = structuredClone({ ...chat })
  let chatUsers = _chat.participants as ChatParticipant[]
  chatUsers = chatUsers?.filter(
    (user: ChatParticipant) => user.isExisting && !user.deletedAt
  )
  const myInfo: ChatParticipant | undefined = chatUsers
    ?.filter((user: ChatParticipant) => user.isCurrentUser)
    .shift()

  // set fullName if missing
  // _chat.participants = chatUsers.map((user: ChatParticipant) => {
  //   const _user = {
  //     ...user,
  //   }

  //   if (!_user.fullName) {
  //     _user.fullName = `${_user.firstName} ${_user.lastName}`
  //   }

  //   return _user
  // })

  // set chatName (actual chatTitle to be displayed)
  if (_chat.chatType === 'team') {
    // team/group chat
    if (_chat.participants?.length === 2) {
      _chat.chatName = (_chat.participants as ChatParticipant[])
        ?.filter((user: ChatParticipant) => !user.isCurrentUser)
        .shift()?.fullName!
      _chat.customChatTitle = null
    } else {
      _chat.chatName = _chat.customChatTitle ?? _chat.team?.teamName!
    }
  } else if (_chat.chatType === 'direct') {
    // one-on-one chat
    _chat.chatName = chatUsers
      ?.filter((user: ChatParticipant) => !user.isCurrentUser)
      .shift()?.fullName!
  } else {
    // friends-group chat
    _chat.chatName =
      _chat.customChatTitle ??
      chatUsers
        ?.filter(
          (user: ChatParticipant) => !user.isCurrentUser && !user.deletedAt
        )
        .map((user) => user.firstName)
        .join(', ') // skipping chat.chatTitle to circumvent middleware bug
  }

  // set hasNewMessage
  _chat.hasNewMessage = moment(
    _chat.modifiedAt || _chat.createdAt || ''
  ).isAfter(myInfo?.viewedAt || myInfo?.createdAt || '')
  return _chat
}

export default function preProcessChat(chats: ChatListChat[]): ChatListChat[] {
  let _chats: ChatListChat[] = [
    ...chats?.filter(
      (chat) =>
        !chat.participants?.filter((user) => user?.isCurrentUser).shift()
          ?.deletedAt
    ),
  ]
  return _chats.map(preProcessSingleChat)
}
