import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { capitalize } from '@sportsyou/core'
import { Button, Modal, TextInput } from '@sportsyou/react-dom-ui'
import { Sport, mutationTeamCreate, queryTeam } from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

import { SportsPicker } from 'web/components'
import { useAddMembers, useAnalytics, useCreateTeam } from 'web/hooks'
import { fetchTeams } from 'web/store/slices/TeamsSlice'

interface Props {
  isVisible: boolean
  shouldNavigateToTeam?: boolean
  type?: 'team' | 'group'
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type CreateTeamModalProps = Props & NativeAttributes

export const CreateTeamModal: React.FC<CreateTeamModalProps> = ({
  ...props
}: CreateTeamModalProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { logEvent } = useAnalytics()

  const { addMembers } = useAddMembers()
  const { isVisible, setIsVisible, type = 'team' } = useCreateTeam()

  const { fetch: createTeam } = useFetchApi(mutationTeamCreate)
  const { fetch: getTeam } = useFetchApi(queryTeam)

  const [selectedSport, setSelectedSport] = useState<Sport>()
  const [teamName, setTeamName] = useState<string>('')

  const isDisabled = useMemo(
    () =>
      type === 'group'
        ? teamName.length === 0
        : teamName.length === 0 || !selectedSport,
    [selectedSport, teamName.length, type]
  )

  const handleOnChangeTeamName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value)
  }

  const handleOnClickCancel = () => {
    // close modal and clear state
    setTeamName('')
    setIsVisible(false)
    setSelectedSport(undefined)
  }

  const handleOnClickCreate = async () => {
    const {
      data: _team,
      error,
      ok,
    } = await createTeam({
      team: {
        name: teamName,
        sportId: selectedSport?.id,
        type,
      },
    })

    if (ok) {
      const { data: teamData } = await getTeam({
        id: _team?.id!,
        includeLargeTeamMembers: false,
      })
      if (teamData) {
        logEvent('teamCreate', {
          sportId: selectedSport?.id,
          sport: selectedSport?.name,
          type,
          isTeamCreateMultiple: false,
        })

        // show add members modal
        addMembers({
          isVisible: true,
          shouldNavigateToTeam: true,
          team: teamData,
        })
      }
      setTeamName('')
      // hide create team modal
      setIsVisible(false)
      setSelectedSport(undefined)

      dispatch(fetchTeams(true))
    }

    if (error) {
      console.log({ error })
    }
  }

  const onChangeSport = useCallback((sport: Sport) => {
    setSelectedSport(sport)
  }, [])

  return (
    <>
      <Modal
        backdropIgnoresClicks
        visible={isVisible}
        onClose={handleOnClickCancel}
        {...props}
      >
        <Modal.Body
          closeButtonStyle={{ height: 30, padding: 8, width: 30 }}
          includeCloseButton
          style={{ paddingTop: 0, overflow: 'visible' }}
        >
          <Container>
            <Heading>Create {capitalize(type)}</Heading>
            {type === 'team' ? (
              <Text>
                Name your team, choose a sport, and add members. You can also
                create several teams at once by clicking this{' '}
                <Link to='/teams/create/multiple'>create multiple teams</Link>{' '}
                link.
              </Text>
            ) : (
              <Text>
                Name your group to get started sharing media, communicating and
                creating events. You can also create several groups at once by
                clicking this{' '}
                <Link to='/groups/create/multiple'>create multiple groups</Link>{' '}
                link.
              </Text>
            )}

            <StyledInput
              autoFocus
              containerStyle={{ minWidth: 300, minHeight: 0 }}
              maxLength={100}
              onChange={handleOnChangeTeamName}
              placeholder={`${capitalize(type)} Name`}
              value={teamName}
            />

            <SportsPicker
              onChange={onChangeSport}
              selectedSport={selectedSport}
              style={{ minHeight: 40, height: 40, marginTop: 10 }}
              testId={'create-team-sport-picker'}
            />

            <ButtonContainer>
              <CreateButton
                data-testId='create-team-modal-create-button'
                disabled={isDisabled}
                onClick={handleOnClickCreate}
              >
                Create
              </CreateButton>
              <CancelButton
                appearance='minimal'
                onClick={handleOnClickCancel}
                variant='alternate'
              >
                Cancel
              </CancelButton>
            </ButtonContainer>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const Heading = styled.h2`
  margin-bottom: 0px;
  margin-top: 40px;
  text-align: center;
`
const Text = styled.p`
  margin-top: 10px;
  max-width: 300px;
  text-align: center;
`
const StyledInput = styled(TextInput)`
  margin-top: 10px;
  // margin-left: auto;
  // margin-right: auto;
`
const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 300px;
`
const CreateButton = styled(Button)`
  align-self: stretch;
`
const CancelButton = styled(Button)`
  margin-top: 10px;
`
export default CreateTeamModal
