import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment-timezone'

import { Notification as NotificationProps } from '@sportsyou/api'

export interface AlertsSliceProps {
  alerts: NotificationProps[]
}

const initialState: AlertsSliceProps = {
  alerts: [],
}

const AlertsSlice = createSlice({
  name: 'alerts',
  initialState: initialState,
  reducers: {
    storeAlerts(
      state: AlertsSliceProps,
      action: PayloadAction<NotificationProps[]>
    ) {
      state.alerts = action.payload
    },
    markAlertAsRead(state, action: PayloadAction<NotificationProps>) {
      const newState = state.alerts.map((alert) => {
        if (alert.id === action.payload.id) {
          return {
            ...action.payload,
            viewedAt: moment().toISOString(),
          }
        }
        return alert
      })
      state.alerts = newState
    },
    markAllAlertsAsRead(state) {
      const newState = state.alerts.map((alert) => ({
        ...alert,
        viewedAt: moment().toISOString(),
      }))

      state.alerts = newState
    },
    addAlert(state, action: PayloadAction<NotificationProps>) {
      const ids = state.alerts.map((r) => r.id)
      if (!ids.includes(action.payload.id)) {
        state.alerts = [action.payload, ...state.alerts]
      }
    },
    clearAlerts(state) {
      state.alerts = initialState.alerts
    },
  },
})

export const {
  addAlert,
  clearAlerts,
  markAlertAsRead,
  markAllAlertsAsRead,
  storeAlerts,
} = AlertsSlice.actions
export default AlertsSlice.reducer
