import { useCallback, useEffect, useRef, useState } from 'react'

import {
  MutationTeamUploadRequest,
  mutationAlbumCreate,
  mutationTeamUpload,
} from '@sportsyou/api'
import { Button, FileInput, Modal, TextInput } from '@sportsyou/react-dom-ui'
import { ExtendedUpload, useFetchApi, useUploads } from '@sportsyou/react-hooks'

export interface NewAlbumModalProps {
  isOpen: boolean
  onClose: () => void
  onCreate: (albumId: string) => void
  teamId?: string
}

export const NewAlbumModal: React.FC<NewAlbumModalProps> = (
  props: NewAlbumModalProps
) => {
  const uploadsHook = useUploads()

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [newAlbumTitle, setNewAlbumTitle] = useState<string>('')

  const { fetch: addAlbum } = useFetchApi(mutationAlbumCreate)
  const { fetch: setMediaTeamMedia } = useFetchApi(mutationTeamUpload)

  const newAlbumCreated = useRef(false)

  useEffect(() => {
    console.log({ newUploads: uploadsHook.newUploads })
  }, [uploadsHook.newUploads])

  useEffect(() => {
    console.log({ deletedUploads: uploadsHook.deletedUploads })
  }, [uploadsHook.deletedUploads])

  useEffect(() => {
    console.log({ allUploads: uploadsHook.allUploads })
  }, [uploadsHook.allUploads])

  useEffect(() => {
    console.log({ existingUploads: uploadsHook.existingUploads })
  }, [uploadsHook.existingUploads])

  const onUploaderUploadStart = useCallback(
    (newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      console.log('onUploaderUploadStart', { newUploads, currentUpload })
      setIsUploading(true)
    },
    []
  )

  const onUploaderUploadProgress = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      console.log('onUploaderUploadProgress', { currentUpload })
    },
    []
  )

  const onUploaderUploadDone = useCallback(
    (newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      console.log('onUploaderUploadDone', { newUploads, currentUpload })
      uploadsHook.addNewUploads([currentUpload])
      currentUpload.id && props.teamId && setMediaAsTeamMedia(currentUpload.id)
    },
    [uploadsHook.addNewUploads]
  )

  const onUploaderUploadComplete = useCallback(
    (newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      console.log('onUploaderUploadDone', { newUploads, currentUpload })
      setIsUploading(false)
    },
    []
  )

  const onUploaderCancel = useCallback(
    (cancelledUpload: ExtendedUpload | undefined): void => {
      console.log('onUploaderCancel', { cancelledUpload })
      cancelledUpload?.id && uploadsHook.removeUpload(cancelledUpload?.id)
    },
    [uploadsHook.removeUpload]
  )

  const onUploaderError = useCallback(
    (newUploads: ExtendedUpload[], cancelledUpload: ExtendedUpload): void => {
      console.log('onUploaderError', { newUploads, cancelledUpload })
    },
    []
  )

  const onAlbumCreate = useCallback(async () => {
    const { data: albumId } = await addAlbum({
      albumName: newAlbumTitle,
      albumType: props.teamId ? 'team' : 'user',
      teamId: props.teamId,
      uploads: uploadsHook.newUploads.map((up) => up.id),
    })
    if (albumId) {
      newAlbumCreated.current = true
      props.onCreate(albumId)
      props.onClose?.()
    }
  }, [addAlbum, newAlbumTitle, props, uploadsHook.newUploads])

  async function onModalClose() {
    if (!newAlbumCreated.current) {
      await uploadsHook.removeUploads(
        uploadsHook.newUploads.map((up) => up.id!)
      )
      uploadsHook.resetUploads()
    }
    props.onClose?.()
  }

  async function setMediaAsTeamMedia(uploadId: string) {
    if (props.teamId) {
      try {
        const res = await setMediaTeamMedia({
          id: props.teamId,
          uploads: [uploadId],
        } as MutationTeamUploadRequest)
        if (!res.ok) {
          console.error(
            'Fail to set upload as team upload',
            res.errors ?? res.error
          )
        }
      } catch (error) {
        console.error('Fail to set upload as team upload', error)
      }
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setIsUploading(false)
      setNewAlbumTitle('')
      uploadsHook.resetUploads()
    }
  }, [props.isOpen])

  return (
    <Modal backdropIgnoresClicks onClose={onModalClose} visible={props.isOpen}>
      <Modal.Header>
        <b>New Album</b>
      </Modal.Header>
      <Modal.Body>
        <TextInput
          autoFocus
          containerStyle={{ marginBottom: 10, width: '100%' }}
          label='Album Title'
          onChange={(e) => setNewAlbumTitle(e.target.value)}
          placeholder='New Album'
          value={newAlbumTitle}
        />
        {props.isOpen && (
          <FileInput
            hideSelectFileButton
            label='Add media files'
            maxFiles={1000}
            onUploaderCancel={onUploaderCancel}
            onUploaderError={onUploaderError}
            onUploaderUploadComplete={onUploaderUploadComplete}
            onUploaderUploadDone={onUploaderUploadDone}
            onUploaderUploadProgress={onUploaderUploadProgress}
            onUploaderUploadStart={onUploaderUploadStart}
            uploads={uploadsHook.allUploads}
          />
        )}
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!newAlbumTitle?.length || isUploading}
          onClick={onAlbumCreate}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewAlbumModal
