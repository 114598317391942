import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Friend as FriendProps, mutationFriendCancel } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'
import {
  Avatar,
  Button,
  Dropdown,
  Icon,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'

import { selectCurrentUser } from 'web/store/slices/UserSlice'
import { CHAT_MESSAGE_FILTER, useChat } from 'web/hooks'

interface Props {
  friends?: Array<FriendProps>
  id?: string
  onRemoveFriend: () => void
  searchable?: boolean
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type FriendListProps = Props & NativeAttributes

export const FriendList: React.FC<FriendListProps> = ({
  friends = [],
  onRemoveFriend,
  searchable,
}: FriendListProps) => {
  const { sendBanner } = useDialog()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')

  const filteredFriends = useMemo(
    () =>
      searchValue !== ''
        ? friends.filter((friend) => {
            const name = (
              friend.fullName ?? `${friend.firstName} ${friend.lastName}`
            ).toLowerCase()
            return name.includes(searchValue)
          })
        : friends,
    [friends, searchValue]
  )

  useEffect(() => {
    console.log({ filteredFriends })
  }, [filteredFriends])

  const chatClient = useChat({
    establishConnection: false,
    filter: CHAT_MESSAGE_FILTER.LISTEN_FOR_MESSAGES_ONLY,
  })

  const { fetch: removeFriend } = useFetchApi(mutationFriendCancel)

  const currentUser = useSelector(selectCurrentUser)

  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value.toLowerCase())
  }

  const onClickFriend = (friend: FriendProps) => {
    navigate(`/profile/${friend.id}`, {
      replace: false,
      state: { id: friend.id },
    })
  }

  const onClickChat = async (friend: FriendProps) => {
    const { id } = friend
    if (!id) return
    chatClient.startChatWithUser({ showUI: true, userId: id })
  }

  const onClickRemove = async (friend: FriendProps) => {
    const { fullName, id } = friend
    if (!id) return
    const { data, error, ok } = await removeFriend({
      targetId: id,
    })

    if (data && ok) {
      onRemoveFriend()
      sendBanner({
        autoDismiss: true,
        dismissTime: 6000,
        status: 'success',
        message: `${fullName} has been removed successfully.`,
      })
    }

    if (error) {
      sendBanner({
        autoDismiss: true,
        dismissTime: 6000,
        status: 'danger',
        message: `There was an error trying to remove ${fullName}. Please try again later or contact sportsYou Customer Service`,
      })
      console.log({ error })
    }
  }

  const renderMenuButton = useMemo(
    () => (
      <MenuButton appearance='minimal' collapse variant='alternate'>
        <Icon fill={Colors.SHUTTLE_GRAY} name={'Dots'} height={18} width={18} />
      </MenuButton>
    ),
    []
  )

  return (
    <Container>
      {searchable && (
        <div
          style={{
            padding: 10,
          }}
        >
          <TextInput
            containerStyle={{ minHeight: 1 }}
            onChange={handleOnChangeSearch}
            placeholder='Search for Contacts'
            showSearchIcon
            value={searchValue}
          />
        </div>
      )}

      {friends &&
        filteredFriends.map((friend: FriendProps, index: number) => {
          const canRemove = friend.id !== currentUser.id
          return (
            <Row key={index}>
              <UserName onClick={() => onClickFriend(friend)}>
                <Avatar
                  diameter={36}
                  name={friend.fullName ?? 'SY'}
                  uri={friend?.profileImage?.[0]?.viewUrl ?? ''}
                />
                <span
                  style={{
                    marginLeft: 6,
                  }}
                >
                  {friend.fullName}
                </span>
              </UserName>
              <ChatButton
                appearance='minimal'
                collapse
                onClick={() => onClickChat(friend)}
                variant='alternate'
              >
                <Icon
                  fill={Colors.SHUTTLE_GRAY}
                  name={'Bubble'}
                  height={18}
                  width={18}
                />
              </ChatButton>

              {canRemove && (
                <Dropdown
                  hideChevron
                  placement='bottomEnd'
                  title={renderMenuButton}
                  triggerStyle={{
                    marginLeft: 10,
                    minWidth: 1,
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}
                >
                  <Dropdown.Item onClick={() => onClickRemove(friend)}>
                    Remove
                  </Dropdown.Item>
                </Dropdown>
              )}
            </Row>
          )
        })}
    </Container>
  )
}

const Container = styled.div`
  background-color: ${Colors.WHITE};
  border-radius: 8px;
`
const Row = styled.div`
  align-items: center;
  display: flex;
  height: 58px;
  padding-left: 10px;
  padding-right: 10px;
  & + & {
    border-top: 1px solid ${Colors.MYSTIC};
  }
`
const UserName = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: auto;
  &:hover > span {
    text-decoration: underline;
  }
`
const ChatButton = styled(Button)`
  && {
    border-radius: 50%;
  }

  margin-right: 10px;
`
const MenuButton = styled(Button)`
  && {
    border-radius: 50%;
  }
`

export default FriendList
