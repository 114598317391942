import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  mutationTeamJoinByCode,
  MutationTeamJoinByCodeRequest,
  mutationTeamMemberSetAlias,
  MutationTeamMemberSetAliasRequest,
  queryTeamBySignupCode,
  QueryTeamBySignupCodeRequest,
} from '@sportsyou/api'
import { capitalize, Colors } from '@sportsyou/core'
import {
  Button,
  Modal,
  Radio,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import { addTeam } from '../../../../store/slices/TeamsSlice'
import { getFakeUser } from '../../../../constants'

export interface JoinTeamProps {
  isVisible?: boolean
  onClose?: () => void
  signupCode?: string
}

type Role = 'coach' | 'player' | 'parent'
type TeamType = 'group' | 'team'

export const JoinTeam: React.FC<JoinTeamProps> = ({
  isVisible: initialVisible = false,
  onClose,
  signupCode,
}: JoinTeamProps) => {
  const { sendBanner } = useDialog()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { fetch: getTeam } = useFetchApi(queryTeamBySignupCode)
  const { fetch: joinTeam } = useFetchApi(mutationTeamJoinByCode)
  const { fetch: setAlias } = useFetchApi(mutationTeamMemberSetAlias)

  const [aliases, setAliases] = useState<Array<string>>(['', ''])
  const [code, setCode] = useState<string>('')
  const [displayCode, setDisplayCode] = useState<string>('')
  const [isCodeValid, setIsCodeValid] = useState<boolean>()
  const [isPlayerAliasVisible, setIsPlayerAliasVisible] =
    useState<boolean>(false)
  const [isSecondAliasVisible, setIsSecondAliasVisible] =
    useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(initialVisible)
  const [roleSelected, setRoleSelected] = useState<Role>()
  const [teamId, setTeamId] = useState<string>()
  const [teamType, setTeamType] = useState<TeamType>()
  const [validationMessage, setValidationMessage] = useState<string>()

  useEffect(() => {
    signupCode && setCode(signupCode)
  }, [signupCode])

  // isVisible is changing from parent, let's update
  useEffect(() => {
    setIsVisible(initialVisible)
  }, [initialVisible])

  useEffect(() => {
    // Format signup code on any changes to `code`
    setDisplayCode(formatSignupCode(code))

    // Fetching the team by signup code
    if (code.length === 8) {
      const fetchTeam = async () => {
        const { data, error, ok } = await getTeam({
          signupCode: code,
        } as QueryTeamBySignupCodeRequest)
        if (data && ok) {
          setIsCodeValid(true)
          setValidationMessage(data.name as string)
          setTeamType(data.type as TeamType)
          setTeamId(data.id as string)
        }
        if (error) {
          console.log({ error })
          setIsCodeValid(false)
          setTeamType(undefined)
          setValidationMessage(error as string)
        }
      }
      fetchTeam()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  const formatSignupCode = (codeToFormat: string) => {
    // const _code = codeToFormat.match(/([A-Z0-9])/g)?.join('') ?? ''
    const _code = codeToFormat.substring(0, 9).replace(/-/g, '')

    let formattedCode: string
    if (_code.length >= 4) {
      formattedCode = _code.replace(/(^.{4})(.{0,4})/, '$1-$2')
    } else {
      formattedCode = _code
    }
    return formattedCode
  }

  const isJoinButtonDisabled = useMemo(
    () =>
      !isCodeValid ||
      (teamType === 'team' && typeof roleSelected === 'undefined'),
    [isCodeValid, roleSelected, teamType]
  )

  // Disable button until user enters first player alias
  const isAddPlayerDisabled = useMemo(() => aliases[0] === '', [aliases])

  const joinButtonString = useMemo(() => {
    let string = 'Join Team/Group'
    if (teamType) {
      string = `Join ${capitalize(teamType)}`
      if (teamType === 'team' && roleSelected === 'parent') {
        string = 'Next'
      }
      if (isPlayerAliasVisible) {
        string = `Join ${capitalize(teamType)}`
      }
    }
    return string
  }, [isPlayerAliasVisible, roleSelected, teamType])

  const resetState = () => {
    // Team/Group state
    setCode('')
    setTeamType(undefined)
    setIsCodeValid(undefined)
    setDisplayCode('')

    setValidationMessage(undefined)

    // Player alias
    setIsPlayerAliasVisible(false)
    setIsSecondAliasVisible(false)
    setAliases(['', ''])
  }

  const handleOnClickBack = () => {
    setAliases(['', ''])
    setIsPlayerAliasVisible(false)
    setIsSecondAliasVisible(false)
  }

  const handleOnChangeAlias = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target

    // if (index === 0) {
    //     setAliases(prevState =>[value])
    //   } else if (index === 1) {
    //     setAliases(prevState => [...prevState, value])
    //   }
    // } else if (aliases.length === 2) {
    setAliases((prevState) =>
      prevState.map((a, i) => (i === index ? value : a))
    )
    // }

    // reset state (so we can disable the add player button)
  }

  const handleJoinTeam = async () => {
    // Do we need to display the player alias section?
    if (
      teamType === 'team' &&
      roleSelected === 'parent' &&
      !isPlayerAliasVisible
    ) {
      setIsPlayerAliasVisible(true)
    } else {
      const { data, error, ok } = await joinTeam({
        signupCode: code,
        role: teamType === 'team' ? roleSelected : 'other',
      } as MutationTeamJoinByCodeRequest)

      if (data && ok) {
        dispatch(addTeam(data))
        // check for player alias
        const hasAlias = !!aliases.filter((a) => a).length
        if (roleSelected === 'parent' && hasAlias) {
          const { error } = await setAlias({
            aliases: aliases.filter((a) => a),
            teamId,
          } as MutationTeamMemberSetAliasRequest)
          if (error) {
            console.log({ error })
          }
        }
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: `Joined ${teamType} successfully`,
        })
        navigate(`/${teamType}s/${teamId}`)
        handleOnClose()
      }

      if (error) {
        console.log({ error })
      }
    }
  }

  const handleOnChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    // If role is selected, let's reset it
    if (typeof roleSelected !== 'undefined') {
      setRoleSelected(undefined)
    }

    const { value } = event.target
    // Remove hypens from value, they will be set with `displayCode` later.
    const _value = value.toUpperCase().replace('-', '')
    // If value is under 8 characters, let's reset some stored state.
    if (_value.length < 8) {
      setTeamType(undefined)
      setIsCodeValid(undefined)
      setValidationMessage(undefined)
    }
    // Set the new code
    setCode(_value)
  }

  const handleOnPasteCode = (event: React.ClipboardEvent<HTMLInputElement>) => {
    // Reset states for code and role, this should help prevent stale state
    // when pasting a sign up code
    setRoleSelected(undefined)
    setCode('')
    setDisplayCode('')

    const { value } = event.target as HTMLInputElement
    setCode(value)
  }

  const handleOnChangeRole = (role: Role) => {
    setRoleSelected(role)
  }

  const handleOnClose = () => {
    // setTimeout(() =>
    // , 1000)
    onClose && onClose()
    resetState()
  }

  const handleOnClickAddAlias = () => {
    setIsSecondAliasVisible(true)
  }

  const renderCodeSection = () => (
    <>
      <Header>
        <H2>Join {teamType ? capitalize(teamType) : 'Team/Group'}</H2>
      </Header>
      <TextInput
        label='Access Code'
        maxLength={9}
        onChange={handleOnChangeCode}
        onPaste={handleOnPasteCode}
        placeholder='Enter Access Code'
        status={
          typeof isCodeValid === 'undefined'
            ? undefined
            : isCodeValid
            ? 'success'
            : 'error'
        }
        showValidationMessage={!!validationMessage}
        validationMessage={validationMessage}
        value={displayCode}
      />
      {teamType === 'team' && isCodeValid && (
        <RoleContainer>
          <Radio.Group
            horizontal
            label='Role'
            onChange={(role) => handleOnChangeRole(role as Role)}
            radioContainerStyle={{ padding: '2px 4px' }}
            value={roleSelected}
          >
            <StyledRadio
              // checked={roleSelected === 'coach'}
              value={'coach'}
            >
              Coach
            </StyledRadio>
            <StyledRadio
              // checked={roleSelected === 'player'}
              value={'player'}
            >
              Player
            </StyledRadio>
            <StyledRadio
              // checked={roleSelected === 'parent'}
              value={'parent'}
            >
              Family
            </StyledRadio>
          </Radio.Group>
        </RoleContainer>
      )}
    </>
  )

  const renderPlayerAlias = () => (
    <>
      <Header>
        <H2>Do you want to add a player's name to your profile?</H2>
        <p>
          This tells everyone which player you're connected with (this is
          optional and can be added later).
        </p>
      </Header>
      <PlayerAliasInput
        label='Player Name'
        onChange={(val) => handleOnChangeAlias(val, 0)}
        placeholder={`${getFakeUser('fullName')}`}
        value={aliases[0]}
      />
      {isSecondAliasVisible && (
        <PlayerAliasInput
          label='Player Name'
          onChange={(val) => handleOnChangeAlias(val, 1)}
          placeholder={`${getFakeUser('fullName')}`}
          value={aliases[1]}
        />
      )}
      {!isSecondAliasVisible && (
        <AddPlayerButton
          appearance='minimal'
          disabled={isAddPlayerDisabled}
          onClick={handleOnClickAddAlias}
        >
          Add another player
        </AddPlayerButton>
      )}
    </>
  )

  return (
    <Modal backdropIgnoresClicks onClose={handleOnClose} visible={isVisible}>
      <Modal.Header
        style={{ border: 0, paddingBottom: 0 }}
        onClick={handleOnClose}
      />
      <Modal.Body style={{ paddingTop: 0 }}>
        {isPlayerAliasVisible && (
          <BackButton
            appearance='minimal'
            onClick={handleOnClickBack}
            variant='alternate'
          >
            Back
          </BackButton>
        )}
        <Container>
          {!isPlayerAliasVisible && renderCodeSection()}
          {isPlayerAliasVisible && renderPlayerAlias()}
          <Footer>
            <JoinButton
              disabled={isJoinButtonDisabled}
              onClick={handleJoinTeam}
            >
              {joinButtonString}
            </JoinButton>
            <CancelButton appearance='minimal' onClick={handleOnClose}>
              Cancel
            </CancelButton>
          </Footer>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

const BackButton = styled(Button)`
  position: absolute;
  left: 0;
  top: 0;
  margin: 10px;
  && {
    min-width: 1px;
  }
`
const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 300px;
`
const H2 = styled.h2`
  line-height: 1.2;
`
const RoleContainer = styled.div`
  margin-top: 20px;
`
const PlayerAliasInput = styled(TextInput)`
  & + & {
    margin-top: 10px;
  }
`
const AddPlayerButton = styled(Button)`
  margin-top: 10px;
`
const JoinButton = styled(Button)`
  align-self: stretch;
  margin-top: 20px;
`
const CancelButton = styled(Button)`
  margin-top: 10px;
`
const Header = styled.header`
  text-align: center;
`
const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
`
const StyledRadio = styled(Radio)`
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 120ms ease-in-out;
  && {
    padding: 4px 6px;
  }
  & + & {
    margin-left: 4px;
  }
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`

export default JoinTeam
