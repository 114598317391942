import { useCallback, useEffect, useRef, useState } from 'react'

import { environment } from '../../environments/environment'

const POLLING_INTERVAL = 300000 // 5 minutes

export default function useSystemStatus() {
  const [status, setStatus] = useState<any>({})

  const pollingInterval = useRef<ReturnType<typeof setTimeout>>()

  const getStatus = useCallback(async () => {
    try {
      const response = await fetch(environment.urls.systemStatusUrl as string, {
        headers: {
          pragma: 'no-cache',
          'cache-control': 'no-cache',
        },
      })
      const _status = await response.json()
      setStatus(_status)
      return _status
    } catch (error) {
      console.warn('System Status: error fetching status', error)
      return {}
    }
  }, [])

  const startPollingStatus = useCallback(() => {
    getStatus()
    pollingInterval.current !== undefined &&
      clearTimeout(pollingInterval.current)
    pollingInterval.current = setTimeout(startPollingStatus, POLLING_INTERVAL)
  }, [getStatus])

  const stopPollingStatus = useCallback(() => {
    pollingInterval.current !== undefined &&
      clearTimeout(pollingInterval.current)
  }, [])

  useEffect(() => {
    startPollingStatus()
  }, [startPollingStatus])

  return {
    getStatus,
    isPolling: pollingInterval.current !== undefined,
    ok: status?.OK !== false,
    startPollingStatus,
    status,
    stopPollingStatus,
  }
}
