import { t } from 'i18next'
import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'

import {
  Event,
  MutationEventDeleteRequest,
  mutationEventDelete,
  Team as TeamProps,
} from '@sportsyou/api'
import {
  BORDER_RADIUS,
  Button,
  Checkbox,
  Modal,
  TRANSITION_EASING,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { Colors } from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'
import {
  removeCalendarEvents,
  selectCalendarEvents,
} from 'web/store/slices/CalendarSlice'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

export interface DeleteEventModalProps {
  isVisible: boolean
  event: Event
  team?: TeamProps
  id?: string
  onClose?: (deleted: boolean) => void
}

type RepeatUpdateType = 'instance' | 'following' | 'all'

export const DeleteEvent: React.FC<DeleteEventModalProps> = ({
  event,
  isVisible,
  onClose,
  ...props
}: DeleteEventModalProps) => {
  const { fetch: deleteEvent } = useFetchApi(mutationEventDelete)

  const dispatch = useDispatch()
  const events = useSelector(selectCalendarEvents)

  const { sendBanner } = useDialog()

  const [isNotifyAllAttendees, setIsNotifyAllAttendees] =
    useState<boolean>(true)
  const [repeatUpdateType, setRepeatUpdateType] =
    useState<RepeatUpdateType>('instance')

  function onChangeRepeastType(event: ChangeEvent<HTMLInputElement>) {
    setRepeatUpdateType(event.target.value as RepeatUpdateType)
  }

  function closeModal(deleted: boolean) {
    onClose?.(deleted)
  }

  const handleOnClickDismiss = () => {
    closeModal(false)
  }

  const handleOnClickCancel = () => {
    closeModal(false)
  }

  const handleOnClickDelete = async () => {
    const { error, ok } = await deleteEvent({
      id: event.id,
      notify: isNotifyAllAttendees,
      repeatUpdateType,
    } as MutationEventDeleteRequest)
    if (ok) {
      // dismiss delete event modal
      closeModal(true)

      // remove deleted events from redux
      if (repeatUpdateType) {
        let eventsToDelete: Event[] = [event]

        // All events with same repeat id
        const repeatEvents = events.filter(
          (ev) => ev.repeat?.id === event.repeat?.id
        )
        if (repeatUpdateType === 'following') {
          eventsToDelete = [
            event,
            ...repeatEvents.filter((ev) =>
              moment(ev.startDate).isAfter(moment(event.startDate))
            ),
          ]
        } else if (repeatUpdateType === 'all') {
          eventsToDelete = [event, ...repeatEvents]
        }
        dispatch(removeCalendarEvents(eventsToDelete))
      }
      sendBanner({
        autoDismiss: true,
        message: `${
          event.title ?? event.type ?? 'Event'
        } has been deleted successfully.`,
        status: 'success',
      })
    }

    if (error) {
      console.log({ error })
      sendBanner({
        autoDismiss: true,
        status: 'danger',
        message:
          'There was an error deleting the event, please try again or contact support.',
      })
    }
  }

  return (
    <Modal
      backdropIgnoresClicks
      onClose={handleOnClickDismiss}
      visible={isVisible}
    >
      <Modal.Header>Delete Event</Modal.Header>
      <Modal.Body>
        <Container>
          <LargeText>Are you sure you want to delete this event?</LargeText>

          {!!event.repeat?.id && (
            <RadioContainer>
              <RadioLabel>
                <input
                  defaultChecked
                  name='event-select'
                  onChange={onChangeRepeastType}
                  type='radio'
                  value={'instance' as RepeatUpdateType}
                />
                <RadioTextContainer>
                  <Text>
                    <>{t('event-repeat-update-instance-label')}</>
                  </Text>
                  <SubText>
                    <>{t('event-repeat-update-instance-sublabel')}</>
                  </SubText>
                </RadioTextContainer>
              </RadioLabel>
              <RadioLabel>
                <input
                  name='event-select'
                  onChange={onChangeRepeastType}
                  type='radio'
                  value={'following' as RepeatUpdateType}
                />
                <RadioTextContainer>
                  <Text>
                    <>{t('event-repeat-update-following-label')}</>
                  </Text>
                  <SubText>
                    <>{t('event-repeat-delete-following-sublabel')}</>
                  </SubText>
                </RadioTextContainer>
              </RadioLabel>
              <RadioLabel>
                <input
                  name='event-select'
                  onChange={onChangeRepeastType}
                  type='radio'
                  value={'all' as RepeatUpdateType}
                />
                <RadioTextContainer>
                  <Text>
                    <>{t('event-repeat-update-all-label')}</>
                  </Text>
                  <SubText>
                    <>{t('event-repeat-delete-all-sublabel')}</>
                  </SubText>
                </RadioTextContainer>
              </RadioLabel>
            </RadioContainer>
          )}
          <StyledCheckbox
            checked={isNotifyAllAttendees}
            onChange={() => {
              setIsNotifyAllAttendees(!isNotifyAllAttendees)
            }}
          >
            <>{t('event-update-notify-option-label')}</>
          </StyledCheckbox>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <FooterButtonContainer>
          <FooterButton
            appearance='minimal'
            onClick={handleOnClickCancel}
            variant='alternate'
          >
            Cancel
          </FooterButton>
          <FooterButton
            data-testid='delete-event-confirm-button'
            onClick={handleOnClickDelete}
            variant='danger'
          >
            Delete
          </FooterButton>
        </FooterButtonContainer>
      </Modal.Footer>
    </Modal>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const RadioContainer = styled.div`
  margin-bottom: 20px;
`
const RadioLabel = styled.label`
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  border-radius: ${BORDER_RADIUS};
  padding: 6px 10px;
  cursor: pointer;
  & + & {
    margin-top: 6px;
  }
  & > input[type='radio'] {
    margin-top: 4px;
  }
  &:hover,
  &:active {
    background: ${Colors.CATSKILL_WHITE};
  }
`
const RadioTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`
const Text = styled.p`
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
`
const LargeText = styled.p`
  font-size: 20px;
  margin-top: 10px;
  max-width: 300px;
  text-align: center;
`
const SubText = styled.p`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.2;
`
const StyledCheckbox = styled(Checkbox)`
  border-radius: ${BORDER_RADIUS};
  margin-bottom: 20px;
  padding: 6px 8px;
  transition: background-color ${TRANSITION_EASING};
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const FooterButton = styled(Button)`
  & + & {
    margin-left: 10px;
  }
`

export default DeleteEvent
