import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { mutationUserRequestResetPassword } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { Button, useDialog } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

interface Props {
  className?: string
  email: string
}

type NativeAttributes = Omit<HTMLAttributes<any>, keyof Props>
export type ForgotPasswordProps = Props & NativeAttributes

// Timer count down for resending verification email
const DEFAULT_TIMER = 15

export const ForgotPasswordEmail: FC<ForgotPasswordProps> = ({
  email,
}: ForgotPasswordProps) => {
  const { sendBanner } = useDialog()

  const timerRef = useRef<number | undefined>()

  const [canResend, setCanResend] = useState(true)
  const [timerDisplay, setTimerDisplay] = useState(DEFAULT_TIMER)

  const resendRequest = useFetchApi(async () =>
    mutationUserRequestResetPassword({
      email,
    })
  )

  useEffect(() => {
    return () => {
      // make sure interval is deleted on unmount to prevent any memory leaks
      timerRef.current && clearInterval(timerRef.current)
    }
  }, [])

  useEffect(() => {
    // When timer ends, unblock resend button and reset timer state
    if (timerDisplay === 0) {
      timerRef.current && clearInterval(timerRef.current)
      setCanResend(true)
      setTimerDisplay(DEFAULT_TIMER)
    }
  }, [timerDisplay])

  const handleOnClickResend = async () => {
    // block resend button to prevent spam clicking
    setCanResend(false)

    await resendRequest.fetch().then((response) => {
      console.log({ response })
      if (response.ok) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 5000,
          message: 'Verification email resent',
          status: 'success',
        })
      } else {
        console.error('Error resending verification email for password reset')
      }
    })

    timerRef.current = window.setInterval(() => {
      setTimerDisplay((num) => num - 1)
    }, 1000)
  }

  return (
    <>
      <Header>Check Your Email</Header>
      <SubText>An email with a link has been sent to:</SubText>

      <SubTextAlternate>{email}</SubTextAlternate>

      <SubText>
        If you haven't received an email, check your junk/spam folder or click
        the button below to send a new email.
      </SubText>

      <StyledButton disabled={!canResend} onClick={handleOnClickResend}>
        {canResend
          ? 'Resend Reset Password Email'
          : `Resend in ${timerDisplay}`}
      </StyledButton>
    </>
  )
}

const Header = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`
const SubText = styled.p`
  text-align: center;
`
const SubTextAlternate = styled(SubText)`
  background-color: ${Colors.CATSKILL_WHITE};
  border-radius: 6px;
  color: ${Colors.MINE_SHAFT};
  margin-top: 0;
  padding-bottom: 8px;
  padding-top: 8px;
  font-weight: 500;
`
const StyledButton = styled(Button)`
  margin-top: 20px;
`

export default ForgotPasswordEmail
