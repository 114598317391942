import SocketIOClient from 'socket.io-client'

import {
  Chat,
  ChatParticipant,
  ChatTeamInfo,
  VideoPlaylistSummary,
  queryChats,
  queryUser,
} from '@sportsyou/api'
import { Observable } from '../../utils/Observable'
import { environment } from '../../environments/environment'
// import { ServerSession } from '../../Lib/Utils'
// import { UserInterface, refreshChatList, updateChatList } from '../../Lib/Redux'

const CHAT_URL = environment.urls.wsChats ?? ''

export class ChatProps {
  chatList?: Chat[]
  onConnect?: () => void
  sessionState?: any
  user?: any
}

export enum ChatIncomingMessageType {
  CHAT_ADD_NEW_MEMBER = 'chat_add_new_member',
  CHAT_LEFT = 'chat_left',
  CHAT_TYPING = 'chat_typing',
  CHAT_VIEWED = 'chat_viewed',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  MESSAGE_TO_CLIENT = 'message_to_client',
  REACTION = 'chat_message_likes_changed',
  TRANSPORT_CLOSED = 'transport close',
}

export enum ChatOutgoingMessageType {
  CHAT_ADD_NEW_MEMBER = 'chat_add_new_member',
  CHAT_LEFT = 'chat_left',
  CHAT_TYPING = 'chat_typing',
  CHAT_VIEWED = 'chat_viewed',
  MESSAGE_TO_SERVER = 'message_to_server',
  REACTION = 'chat_message_likes_changed',
  SETUP_NEW_CHAT = 'setup_new_chat',
}

export interface ChatIncomingMessage {
  chatAttachment?: ChatAttachment
  chatMessageId?: string
  chatTitle: string
  from?: string
  fromName?: string
  id?: string
  isGroupChat?: boolean
  message?: string
  messageType?: string
  playlist?: VideoPlaylistSummary
  timestamp?: Date
  token?: string
  viewedAt?: Date
  wsMessageType: ChatIncomingMessageType
}

export interface ChatOutgoingMessage {
  chatAttachment?: ChatAttachment | null
  chatId?: string | null
  chatType?: 'team' | 'group' | 'direct' | string | null
  clientId?: string | null
  from?: string | null
  fromName?: string | null
  id?: string | null
  message?: string | null
  messageType?: string | null
  name?: string | null
  participants?: (ChatParticipant | null)[]
  partnerId?: (string | null | undefined)[]
  team?: ChatTeamInfo | null
  timestamp?: string | null
  token?: string | null
  userId?: string | null
  userName?: string | null
  viewedAt?: string | null
}

export interface ChatAttachment {
  contentType?: string
  fileName?: string
  height?: number
  isAnimated?: boolean
  localSource?: string
  src?: string
  uploadId?: string
  viewUrl?: string
  width?: number
}

interface ActiveChatDictionary {
  [index: string]: boolean
}

let chatClient: ChatClient

export class ChatClient {
  private _websocket: any
  private _sessionState?: any
  private _currentUser?: any
  private _incomingMessages: Observable<ChatIncomingMessage> =
    new Observable<ChatIncomingMessage>()
  private _activeChats: ActiveChatDictionary = {}

  static getInstance(): ChatClient {
    if (!chatClient) {
      chatClient = new ChatClient()
    }
    return chatClient
  }

  isConnected(): boolean {
    return this._websocket?.connected
  }

  disconnect(): void {
    if (this._websocket) {
      const events = Object.values(ChatIncomingMessageType)
      events.forEach((messageType) => {
        this._websocket.off(messageType)
      })
      this._websocket.disconnect()
    }
  }

  addActiveChat(token: string) {
    this._activeChats[token] = true
  }

  removeActiveChat(token: string) {
    if (this._activeChats[token]) {
      delete this._activeChats[token]
    }
  }

  isActiveChat(token: string) {
    return this._activeChats[token] ?? false
  }

  async setupUser(props?: ChatProps): Promise<Observable<ChatIncomingMessage>> {
    const isConnected = this.isConnected()
    if (props?.user === this._currentUser && isConnected) {
      return Promise.resolve(this._incomingMessages)
    } else if (isConnected) {
      this.disconnect()
    }
    const chatTokenResponse = await queryUser({
      requestOptions: { fieldList: 'chatUserToken' },
    })
    if (chatTokenResponse.ok) {
      let chats: Chat[] = props?.chatList as Chat[]
      if (!chats) {
        const chatListResponse = await queryChats()
        if (chatListResponse.ok) {
          chats = chatListResponse.data as Chat[]
          // store.dispatch(updateChatList(chats))
          // store.dispatch(refreshChatList(chats))
        }
      }
      if (!chats) {
        chats = []
      }
      let tokens: (string | undefined | null)[] = []
      if (chats.length > 0) {
        tokens = chats.map((chat) => chat.token)
      }

      const options: any = {}
      // let options: SocketIOClient.ConnectOpts = {}
      // if (props?.sessionState) {
      //   options.transportOptions = {
      //     polling: {
      //       extraHeaders: {
      //         HEADER_SET_COOKIE: props.sessionState.cookieString(),
      //       },
      //     },
      //   }
      // }
      options.transports = ['websocket']
      options.query = { userId: chatTokenResponse.data?.chatUserToken }
      this._websocket = SocketIOClient(CHAT_URL, options)
      return new Promise<Observable<ChatIncomingMessage>>(
        // eslint-disable-next-line no-async-promise-executor
        async (resolve, reject) => {
          this._websocket.on('connect', () => {
            if (props?.onConnect) {
              props.onConnect()
            }
            tokens.forEach((token) => {
              const msg: ChatOutgoingMessage = {}
              msg.token = token
              msg.partnerId = [chatTokenResponse.data?.chatUserToken]
              this.chatSetupNew(msg)
            })

            const events = Object.values(ChatIncomingMessageType)
            events.forEach((messageType) => {
              if (messageType !== ChatIncomingMessageType.CONNECT) {
                this._websocket.off(messageType)
              }

              this._websocket.on(messageType, (data: any) => {
                this.eventCallback(messageType, data)
              })
            })

            resolve(this._incomingMessages)
          })

          this._websocket.on('connect_error', (connectError: any) => {
            reject(connectError)
          })
          this._websocket.on('disconnect', () => {
            console.log('==> Chat', 'disconnect')
            this.setupUser(props)
          })
        }
      )
    } else {
      return Promise.resolve(this._incomingMessages)
    }
  }

  eventCallback(wsType: string, response: any) {
    const retVal = (response as any) || {}

    try {
      retVal.wsMessageType = wsType
    } catch (error) {
      if (wsType === 'transport close') {
        retVal.wsMessageType = ChatIncomingMessageType.TRANSPORT_CLOSED
      }
    }
    // if (retVal.timestamp && typeof(retVal.timestamp) === 'string') {
    //   retVal.timestamp = new Date(retVal.timestamp);
    // }
    // if (retVal.viewedAt && typeof(retVal.viewedAt) === 'string') {
    //   retVal.viewedAt = new Date(retVal.viewedAt);
    // }
    if (retVal.name && !retVal.fromName) {
      retVal.fromName = retVal.name
    }
    if (retVal.userName && !retVal.fromName) {
      retVal.fromName = retVal.userName
    }
    if (retVal.userId && !retVal.from) {
      retVal.from = retVal.userId
    }

    this._incomingMessages.set(retVal)
  }

  async connect(
    chatProps?: ChatProps
  ): Promise<Observable<ChatIncomingMessage>> {
    return this.setupUser(chatProps)
  }

  chatSetupNew(data: ChatOutgoingMessage, callback?: any) {
    if (this.isConnected()) {
      this._websocket.emit(
        ChatOutgoingMessageType.SETUP_NEW_CHAT,
        data,
        callback
      )
    }
  }

  chatLeft(data: ChatOutgoingMessage, callback?: any) {
    if (this.isConnected()) {
      this._websocket.emit(ChatOutgoingMessageType.CHAT_LEFT, data, callback)
    }
  }

  chatAddNewMember(data: ChatOutgoingMessage, callback?: any) {
    if (this.isConnected()) {
      this._websocket.emit(
        ChatOutgoingMessageType.CHAT_ADD_NEW_MEMBER,
        data,
        callback
      )
    }
  }

  chatSend(data: ChatOutgoingMessage, callback?: any) {
    if (this.isConnected()) {
      this._websocket.emit(
        ChatOutgoingMessageType.MESSAGE_TO_SERVER,
        data,
        callback
      )
    }
  }

  chatTyped(data: ChatOutgoingMessage, callback?: any) {
    if (this.isConnected()) {
      this._websocket.emit(ChatOutgoingMessageType.CHAT_TYPING, data, callback)
    }
  }

  chatViewed(data: ChatOutgoingMessage, callback?: any) {
    if (this.isConnected()) {
      this._websocket.emit(ChatOutgoingMessageType.CHAT_VIEWED, data, callback)
    }
  }

  chatMessageLikesChanged(data: ChatOutgoingMessage, callback?: any) {
    if (this.isConnected()) {
      this._websocket.emit(ChatOutgoingMessageType.REACTION, data, callback)
    }
  }

  getIncomingMessages() {
    return this._incomingMessages
  }
}

chatClient = ChatClient.getInstance()

export default chatClient
