import { useContext } from 'react'
import { BuildTeamContext } from './BuildTeam'

export const useAddMembers = () => {
  const {
    addMembers,
    id,
    isAddMembersVisible,
    setIsAddMembersVisible,
    shouldNavigateToTeam,
    team,
  } = useContext(BuildTeamContext)
  return {
    addMembers,
    id,
    isVisible: isAddMembersVisible,
    setIsVisible: setIsAddMembersVisible,
    shouldNavigateToTeam,
    team,
  }
}

export default useAddMembers
