import { ChatParticipant, Profile } from '@sportsyou/api'
import { getProfileImage } from '@sportsyou/core'

import { CHAT_TYPE_DIRECT, CHAT_TYPE_GROUP, CHAT_TYPE_TEAM } from '../constants'

// import { ChatListChat } from 'store/slices/ChatListSlice'
type ChatListChat = any

export interface AvatarEntryProps {
  id: string
  type: 'user' | 'team'
  name?: string
  url?: string
  isDeleted?: boolean
}

export default function buildChatListAvatarProps(
  chat: ChatListChat
): AvatarEntryProps[] {
  const allChatUsers: ChatParticipant[] =
    (chat.participants as ChatParticipant[]) ?? []
  const chatUsers = allChatUsers.filter(
    (user: ChatParticipant) =>
      !user.isCurrentUser && !user.deletedAt && user.isActive
  )
  const avatarProps: AvatarEntryProps[] = []

  switch (chat.chatType) {
    case CHAT_TYPE_DIRECT: // friend one-on-one chat
    case CHAT_TYPE_GROUP: // friends group chat
      avatarProps.push(
        ...chatUsers.map((user): AvatarEntryProps => {
          return {
            isDeleted: !!user.deletedAt,
            id: user.id!,
            type: 'user',
            name: user.fullName!,
            url: getProfileImage.getProfileAvatarImageUrl(user as Profile),
          }
        })
      )
      break
    case CHAT_TYPE_TEAM: // Team/Group chat
      const team = chat.team!
      avatarProps.push({
        id: team?.teamId!,
        type: 'team',
        name: team?.teamName!,
        url: getProfileImage.getProfileAvatarImageUrl(team as Profile),
      })
      break
    default:
  }

  return avatarProps
}
