import { createSlice } from '@reduxjs/toolkit'
import { Upload } from '@sportsyou/api'

export interface ChatCreateModalInterface {
  isOpen: boolean
  message?: string
  showTeamPicker?: boolean
  teamId?: string
  title?: string
  uploads?: Upload[]
}

const initialState: ChatCreateModalInterface = {
  isOpen: false,
  message: undefined,
  showTeamPicker: true,
  teamId: undefined,
  title: undefined,
  uploads: undefined,
}

const ChatCreateModalSlice = createSlice({
  name: 'ChatCreateModal',
  initialState,
  reducers: {
    showChatCreateModal(state, action) {
      state.isOpen = true
      state.message = action.payload?.message
      state.showTeamPicker = action.payload?.showTeamPicker
      state.teamId = action.payload?.teamId
      state.title = action.payload?.title
      state.uploads = action.payload?.uploads
    },
    hideChatCreateModal(state) {
      state.isOpen = initialState.isOpen
      state.message = initialState.message
      state.showTeamPicker = initialState.showTeamPicker
      state.teamId = initialState.teamId
      state.title = initialState.title
      state.uploads = initialState.uploads
    },
  },
})

export const { hideChatCreateModal, showChatCreateModal } =
  ChatCreateModalSlice.actions
export default ChatCreateModalSlice.reducer
