// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

type Urls = { [key: string]: string }

export const environment = {
  production: false,
  env: process.env.NX_ENV,
  urls: {
    graphQL: process.env.NX_GRAPHQL_API_URL,
    metascraper: process.env.NX_METASCRAPER_API_URL,
    staticApi: process.env.NX_STATIC_API_URL,
    wsChats: process.env.NX_WEBSOCKET_CHATS_URL,
    wsNotify: process.env.NX_WEBSOCKET_NOTIFY_URL,
    wsSubscriptions: process.env.NX_WEBSOCKET_SUBSCRIPTIONS_URL,
    web: process.env.NX_WEB_URL,
    webV1: process.env.NX_WEB_V1_URL,
    privacyPolicy: process.env.NX_PRIVACY_POLICY,
    termsOfUse: process.env.NX_TERMS_OF_USE,
    systemStatusUrl: process.env.NX_SYSTEM_STATUS_URL,
    syVideo: process.env.NX_SY_VIDEO_URL,
  } as Urls,
  videoJsUseCredentials: process.env.NX_VIDEOJS_USE_CREDENTIALS,
  useIntercom: true,
  intercomKey: process.env.NX_INTERCOM_KEY,
  googleMapsKey: process.env.NX_GOOGLE_MAPS_API_KEY,
  googleOAuthClientId: process.env.NX_GOOGLE_OAUTH_CLIENT_ID,
  googleTrackingId: process.env.GOOGLE_TRACKING_ID,
  googleGA4Id: process.env.NX_GA4_MEASUREMENT_ID,
  googleTagAuth: process.env.NX_GOOGLE_TAG_MANAGER_AUTH,
  googleTagId: process.env.NX_GOOGLE_TAG_MANAGER_ID,
  googleTagPreview: process.env.NX_GOOGLE_TAG_MANAGER_PREVIEW,
  hotJarId: process.env.NX_HOTJAR_ID,
  hotJarSv: process.env.NX_HOTJAR_SV,
  playbookFilename: 'sY_ProductPlaybook_Manual_V2_101823.pdf',
}
