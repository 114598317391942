import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../rootReducer'
import { UnreadChat, UnreadChats } from '@sportsyou/api'

export interface UnreadChatInterface {
  totalUnreadChatRooms: number
  totalUnreadMessages: number
  unreadChats: UnreadChat[]
}

const initialState: UnreadChatInterface = {
  totalUnreadChatRooms: 0,
  totalUnreadMessages: 0,
  unreadChats: [],
}

const unreadChatSlice = createSlice({
  name: 'chatUnreadCount',
  initialState,
  reducers: {
    setUnreadChats(state, action: PayloadAction<UnreadChats>) {
      state.unreadChats = (action.payload.chats as UnreadChat[]) ?? []
      state.totalUnreadChatRooms = action.payload.unreadChatRooms ?? 0
      state.totalUnreadMessages = action.payload.unreadMessages ?? 0
    },
  },
})

export const { setUnreadChats } = unreadChatSlice.actions
export default unreadChatSlice.reducer

export const selectTotalUnreadChatRooms = (state: RootState) =>
  state.chatUnreadCount.totalUnreadChatRooms
export const selectTotalUnreadMessages = (state: RootState) =>
  state.chatUnreadCount.totalUnreadMessages
export const selectUnreadChats = (state: RootState) =>
  state.chatUnreadCount.unreadChats
