import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { PollChoice } from '@sportsyou/api'
import { Colors, pluralize } from '@sportsyou/core'
import { Avatar, Button, Modal } from '@sportsyou/react-dom-ui'

export interface PollChoiceData {
  choices: Array<PollChoice>
  index: number
}

interface Props {
  isVisible: boolean
  onClose: () => void
  data: PollChoiceData
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type PollVotesModalProps = Props & NativeAttributes

export const PollVotesModal: React.FC<PollVotesModalProps> = ({
  data,
  isVisible,
  onClose,
}: PollVotesModalProps) => {
  const navigate = useNavigate()

  const initialIndex = useMemo(() => data.index, [data])
  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  useEffect(() => {
    setCurrentIndex(data.index)
  }, [data])

  const handleOnClose = () => {
    onClose && onClose()
  }

  const handleOnClickPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }
  const handleOnClickNext = () => {
    if (currentIndex < data.choices.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const navigateToProfile = (id?: string) => {
    if (!id) return
    navigate(`/profile/${id}`, {
      state: { id },
    })
  }

  const voters = useMemo(() => {
    if (currentIndex === -1) return []
    return data.choices[currentIndex].votes ?? []
  }, [currentIndex, data.choices])

  if (!data || currentIndex === -1) return null

  return (
    <Modal onClose={handleOnClose} visible={isVisible}>
      <Modal.Header>
        <b>{data.choices[currentIndex].desc}</b>
        <NumberOfVotes>
          {data.choices[currentIndex].votes!.length}{' '}
          {pluralize(data.choices[currentIndex].votes!.length, 'Vote')}
        </NumberOfVotes>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Body>
          {voters.length > 0 &&
            voters.map((user) => (
              <Voter
                key={user?.id}
                onClick={() => navigateToProfile(user?.id!)}
              >
                <Avatar
                  diameter={34}
                  name={user?.fullName ?? undefined}
                  uri={user?.profileImage?.[0]?.viewUrl ?? undefined}
                />
                <VoterName>{user?.fullName}</VoterName>
              </Voter>
            ))}
        </Body>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          appearance='minimal'
          disabled={currentIndex === 0}
          onClick={handleOnClickPrev}
        >
          Previous
        </Button>

        <Button
          appearance='minimal'
          disabled={currentIndex === data.choices.length - 1}
          onClick={handleOnClickNext}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const Body = styled.div`
  height: 400px;
  max-height: 100%;
  overflow-y: auto;
`

const Voter = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 6px 10px;
  transition: background-color 120ms ease-in-out;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const VoterName = styled.span`
  margin-left: 10px;
`
const NumberOfVotes = styled.span`
  margin-left: 10px;
  color: ${Colors.HAVELOCK_BLUE};
`

export default PollVotesModal
