import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment-timezone'

import { Notification } from '@sportsyou/api'

export interface RequestsSliceProps {
  requests: Notification[]
}

const initialState: RequestsSliceProps = {
  requests: [],
}

const RequestsSlice = createSlice({
  name: 'requests',
  initialState: initialState,
  reducers: {
    storeRequests(
      state: RequestsSliceProps,
      action: PayloadAction<Notification[]>
    ) {
      state.requests = action.payload
    },
    updateRequests(state, action: PayloadAction<Notification>) {
      const newState = state.requests.map((req) => {
        if (req.id === action.payload.id) {
          return action.payload
        }
        return req
      })
      state.requests = newState
    },
    markRequestAsRead(state, action: PayloadAction<Notification>) {
      const newState = state.requests.map((request) => {
        if (request.id === action.payload.id) {
          return {
            ...action.payload,
            viewedAt: moment().toISOString(),
          }
        }
        return request
      })
      state.requests = newState
    },
    approveRequest(state, action: PayloadAction<Notification>) {
      const newState = state.requests.map((request) => {
        if (request.id === action.payload.id) {
          // Check if it's team
          if (!!request.teamConfirmToken) {
            return {
              ...action.payload,
              joinedAt: moment().toISOString(),
              viewedAt: moment().toISOString(),
            }
          } else if (!!request.friendRequestToken) {
            return {
              ...action.payload,
              friendRequestStatus: 'accepted',
              viewedAt: moment().toISOString(),
            }
          }
        }
        return request
      })
      state.requests = newState
    },
    rejectRequest(state, action: PayloadAction<Notification>) {
      // let newState = state.requests
      let newState = state.requests
      if (action.payload.teamConfirmToken) {
        newState = state.requests.filter(
          (request) => request.id !== action.payload.id
        )
      } else if (action.payload.friendRequestToken) {
        newState = state.requests.map((request) => {
          if (request.id === action.payload.id) {
            return {
              ...action.payload,
              friendRequestStatus: 'rejected',
              viewedAt: moment().toISOString(),
            }
          }
          return request
        })
      }
      state.requests = newState
    },
    addRequest(state, action: PayloadAction<Notification>) {
      const ids = state.requests.map((r) => r.id)
      if (!ids.includes(action.payload.id)) {
        state.requests = [action.payload, ...state.requests]
      }
    },
    clearRequests(state) {
      state.requests = initialState.requests
    },
  },
})

export const {
  addRequest,
  approveRequest,
  clearRequests,
  markRequestAsRead,
  rejectRequest,
  storeRequests,
  updateRequests,
} = RequestsSlice.actions
export default RequestsSlice.reducer
