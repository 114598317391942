import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { EventRepeatInput } from '@sportsyou/api'
import { capitalize, Colors, pluralize } from '@sportsyou/core'
import {
  Button,
  Checkbox,
  CheckboxEvent,
  DatePicker,
  FONT_SIZE_SM,
  Modal,
  Radio,
  Select,
  TextInput,
} from '@sportsyou/react-dom-ui'
import RepeatingEventSelectionResultsText from './RepeatingEventSelectionResultsText'

export interface RepeatEventProps {
  data?: EventRepeatInput
  isVisible: boolean
  onCancel: () => void
  onClose: (repeatData: EventRepeatInput) => void
  startOn: Date
  type?: string
}

export interface RepeatSettings {
  repeatByDayOfMonth: number
  repeatEndOn: Date
  repeatInterval: string
  repeatIntervalEvery: number
  repeatOnDayOfWeek: Array<RepeatOnDaysOfWeek>
  repeatStartOn: Date
  repeatTimes: number
}

type DaysOfWeek =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

type RepeatOnDaysOfWeek = {
  checked: boolean
  shortName: string
  value: DaysOfWeek
}

type RepeatOption = 'daily' | 'weekly' | 'monthly' | 'yearly'
type RepeatEndType = 'on' | 'after'
type RepeatMonthlyByType = 'month' | 'week'

export const RepeatEvent: React.FC<RepeatEventProps> = ({
  isVisible = false,
  data,
  onCancel,
  onClose,
  startOn,
  type = 'event',
}: RepeatEventProps) => {
  /**
   * Helper to determine what day of the week is today
   */
  const isToday = (day: DaysOfWeek) =>
    moment().format('dddd').toLowerCase() === day.toLowerCase()

  const initialRepeatDaysOfWeek: Array<RepeatOnDaysOfWeek> = useMemo(
    () => [
      {
        checked: isToday('sunday'),
        shortName: 'SU',
        value: 'sunday',
      },
      {
        checked: isToday('monday'),
        shortName: 'MO',
        value: 'monday',
      },
      {
        checked: isToday('tuesday'),
        shortName: 'TU',
        value: 'tuesday',
      },
      {
        checked: isToday('wednesday'),
        shortName: 'WE',
        value: 'wednesday',
      },
      {
        checked: isToday('monday'),
        shortName: 'TH',
        value: 'thursday',
      },
      {
        checked: isToday('friday'),
        shortName: 'FR',
        value: 'friday',
      },
      {
        checked: isToday('saturday'),
        shortName: 'SA',
        value: 'saturday',
      },
    ],
    []
  )

  const [repeatEnds, setRepeatEnds] = useState<RepeatEndType>(
    !data?.repeatEndOn ? 'after' : 'on'
  )
  const [repeatEndsOnDate, setRepeatEndsOnDate] = useState<Date | undefined>(
    data?.repeatEndOn ? moment(data?.repeatEndOn).toDate() : undefined
  )
  const [repeatEveryValue, setRepeatEveryValue] = useState<string>(
    `${data?.repeatIntervalEvery ?? 1}`
  )
  const [repeatOccurance, setRepeatOccurance] = useState<string>(
    `${data?.repeatTimes ?? 1}`
  )
  const [repeatOption, setRepeatOption] = useState<RepeatOption>(
    (data?.repeatInterval as RepeatOption) ?? 'weekly'
  )
  const [repeatDaysOfWeek, setRepeatDaysOfWeek] = useState<
    Array<RepeatOnDaysOfWeek>
  >(initialRepeatDaysOfWeek)
  const [monthlyRepeatBy, setMonthlyRepeatBy] = useState<boolean>(
    data?.repeatByDayOfMonth ?? true
  )

  const resetForm = useCallback(() => {
    setRepeatDaysOfWeek(initialRepeatDaysOfWeek)
    setRepeatEnds('after')
    setRepeatEndsOnDate(undefined)
    setRepeatEveryValue('1')
    setRepeatOccurance('1')
    setRepeatOption('weekly')
    setMonthlyRepeatBy(false)
  }, [initialRepeatDaysOfWeek])

  useEffect(() => {
    // weekly day settings
    const repeatDays = initialRepeatDaysOfWeek.map((d, i) => ({
      ...d,
      checked: (data?.repeatOnDayOfWeek ?? [])[i] ?? d.checked,
    }))
    setRepeatDaysOfWeek(repeatDays)
  }, [
    data?.repeatByDayOfMonth,
    data?.repeatOnDayOfWeek,
    initialRepeatDaysOfWeek,
  ])

  /**
   * Populate/update the form when modal becomes visible
   */
  useEffect(() => {
    if (isVisible) {
      if (data) {
        setMonthlyRepeatBy(data?.repeatByDayOfMonth ?? true)
        setRepeatOption((data.repeatInterval as RepeatOption) ?? 'weekly')
        setRepeatOccurance(`${data.repeatTimes ?? 1}`)
        setRepeatEnds(!data?.repeatEndOn ? 'after' : 'on')
        setRepeatEndsOnDate(
          data?.repeatEndOn ? moment(data?.repeatEndOn).toDate() : undefined
        )
        setRepeatEveryValue(`${data?.repeatIntervalEvery ?? 1}`)

        // setRepeatDaysOfWeek(initialRepeatDaysOfWeek)
      } else {
        resetForm()
      }
    }
  }, [data, isVisible, resetForm])

  const repeatEveryString = useMemo(() => {
    if (repeatOption === 'daily') {
      return 'day'
    }
    let string = repeatOption?.substring(0, repeatOption.length - 2)
    return `${string}`
  }, [repeatOption])

  // event handlers
  const handleOnChangeRepeatInterval = (value: RepeatOption) => {
    setRepeatOption(value)
    if (value === 'monthly') {
      setMonthlyRepeatBy(true)
    }
  }

  const handleOnChangeRepeatEnds = (value: RepeatEndType) => {
    setRepeatEnds(value)
    if (value === 'on') {
      if (!repeatEndsOnDate) {
        setRepeatEndsOnDate(moment(repeatEndsOnDate).toDate())
      }
    } else {
      setRepeatEndsOnDate(undefined)
    }
  }

  const handleOnChangeDayCheckbox = (event: CheckboxEvent) => {
    const { checked, value } = event.target

    // get index of incoming value
    const indexOfDay = repeatDaysOfWeek
      .map((d) => d.value as string)
      .indexOf(value)

    setRepeatDaysOfWeek((current) =>
      current.map((item, index) => {
        if (index === indexOfDay) {
          // update checked status
          current[indexOfDay].checked = checked
        }
        return item
      })
    )
  }

  const handleOnChangeRepeatMonthlyBy = (value: RepeatMonthlyByType) => {
    setMonthlyRepeatBy(value === 'month')
  }

  const handleOnCancel = () => {
    onCancel && onCancel()
    if (!data) {
      resetForm()
    }
  }

  const handleOnClose = (isSaveButton?: boolean) => {
    const repeat: EventRepeatInput = {
      id: data?.id ?? undefined,
      repeatByDayOfMonth: monthlyRepeatBy,
      repeatEndOn: !!repeatEndsOnDate
        ? moment(repeatEndsOnDate).format('MM/DD/YYYY')
        : undefined,
      repeatInterval: repeatOption,
      repeatIntervalEvery: Number(repeatEveryValue),
      repeatOnDayOfWeek: repeatDaysOfWeek.map((d) => d.checked),
      repeatStartOn: moment(startOn).format('MM/DD/YYYY'),
      repeatTimes: !!repeatEndsOnDate ? undefined : Number(repeatOccurance),
      repeatUpdateType: '',
    }
    if (repeatEndsOnDate) {
      setRepeatOccurance('0')
    }
    onClose?.(repeat)
  }

  const renderRepeatWeeklyRow = () => (
    <Row>
      <FauxInputContainer>
        <FauxLabel>Repeat On</FauxLabel>
        <FauxInput>
          {repeatDaysOfWeek.map((d, i) => (
            <DayCheckbox
              checked={d.checked}
              key={i}
              onChange={handleOnChangeDayCheckbox}
              value={d.value}
            >
              {d.shortName}
            </DayCheckbox>
          ))}
        </FauxInput>
      </FauxInputContainer>
    </Row>
  )

  const renderRepeatMonthlyRow = () => (
    <Row>
      <Radio.Group
        horizontal
        label='Repeat By'
        onChange={(val) =>
          handleOnChangeRepeatMonthlyBy(val as RepeatMonthlyByType)
        }
        value={monthlyRepeatBy ? 'month' : 'week'}
      >
        <Radio value='month'>Day of the month</Radio>
        <Radio value='week'>Day of the week</Radio>
      </Radio.Group>
      {/* <FauxInputContainer>
        <FauxLabel>Repeat By</FauxLabel>
        <FauxInput>
          <label>
            <input type="radio" name="repeat-by" /> Day of the month
          </label>
          <label>
            <input type="radio" name="repeat-by" /> Day of the week
          </label>
        </FauxInput>
      </FauxInputContainer> */}
    </Row>
  )

  const renderRepeatEnds = () => (
    <Radio.Group
      label='Ends'
      onChange={(val) => handleOnChangeRepeatEnds(val as RepeatEndType)}
      value={!!repeatEndsOnDate ? 'on' : 'after'}
    >
      <Radio value='after'>
        After
        <TextInput
          containerStyle={{
            marginLeft: 6,
            marginRight: 6,
            minHeight: 1,
          }}
          inputContainerStyle={{ minWidth: 1, width: 60 }}
          inputStyle={{ textAlign: 'center' }}
          maxLength={4}
          min={0}
          onChange={(e) =>
            setRepeatOccurance(
              Math.max(0, parseInt(e.target.value, 10)).toString()
            )
          }
          placeholder='0'
          type='number'
          value={repeatOccurance.toString()}
        />
        <span>{pluralize(Number(repeatOccurance), 'occurrence')}</span>
      </Radio>
      <Radio value='on'>
        <RadioInputContainer>
          <span style={{ marginRight: 6 }}>On</span>
          <DatePickerContainer>
            <DatePicker
              onChange={(d) => setRepeatEndsOnDate(d)}
              startDate={new Date()}
              selected={moment(repeatEndsOnDate).toDate()}
            />
          </DatePickerContainer>
        </RadioInputContainer>
      </Radio>
    </Radio.Group>
  )

  return (
    <Modal backdropIgnoresClicks visible={isVisible} onClose={handleOnClose}>
      <Modal.Header>
        <div>
          <b>Repeat {capitalize(type)}</b>
          <RepeatingEventSelectionResultsText
            eventType={type}
            repeatSettings={{
              origEventStartDate: startOn?.toString(),
              repeatEndOn: moment(repeatEndsOnDate).format('MM/DD/YYYY'),
              repeatEnds,
              repeatInterval: repeatOption,
              repeatIntervalEvery: Number(repeatEveryValue),
              repeatOnDayOfWeek: repeatDaysOfWeek.map((d) => d.checked),
              repeatStartOn: startOn.toString(),
              repeatTimes: !!repeatEndsOnDate
                ? undefined
                : Number(repeatOccurance),
              repeatByDayOfMonth: monthlyRepeatBy,
            }}
            startDate={startOn}
          />
        </div>
      </Modal.Header>

      <Modal.Body>
        <Container>
          {/* Repeat Interval Type */}
          <Row>
            <Select
              initialValue={capitalize(repeatOption)}
              label='Repeats'
              menuStyle={{ maxHeight: 'none' }}
            >
              <Select.Option
                isSelected={repeatOption === 'daily'}
                onClick={() => handleOnChangeRepeatInterval('daily')}
                value='Daily'
              >
                Daily
              </Select.Option>
              <Select.Option
                isSelected={repeatOption === 'weekly'}
                onClick={() => handleOnChangeRepeatInterval('weekly')}
                value='Weekly'
              >
                Weekly
              </Select.Option>
              <Select.Option
                isSelected={repeatOption === 'monthly'}
                onClick={() => handleOnChangeRepeatInterval('monthly')}
                value='Monthly'
              >
                Monthly
              </Select.Option>
              <Select.Option
                isSelected={repeatOption === 'yearly'}
                onClick={() => handleOnChangeRepeatInterval('yearly')}
                value='Yearly'
              >
                Yearly
              </Select.Option>
            </Select>
          </Row>

          {/* Repeat Interval */}
          <Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextInput
                containerStyle={{ flex: '0 0 120px' }}
                inputContainerStyle={{ minWidth: 1 }}
                label='Repeat Every'
                onChange={(e) =>
                  setRepeatEveryValue(
                    Math.max(0, parseInt(e.target.value, 10)).toString()
                  )
                }
                placeholder='0'
                type='number'
                value={repeatEveryValue}
                min={0}
              />
              <span style={{ marginLeft: 10, marginTop: 27 }}>
                {capitalize(
                  pluralize(Number(repeatEveryValue), repeatEveryString)
                )}
              </span>
            </div>
          </Row>

          {repeatOption === 'weekly' && renderRepeatWeeklyRow()}

          {repeatOption === 'monthly' && renderRepeatMonthlyRow()}

          {/* Repeat Starts On */}
          <Row>
            <DatePicker
              label='Start On'
              // onChange={d => setStartOn(d)}
              selected={moment(startOn).toDate()}
              startDate={moment().toDate()}
            />
          </Row>

          {/* Repeat Ends */}
          <Row>{renderRepeatEnds()}</Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <ModalFooterButtons>
          <CancelButton appearance='minimal' onClick={handleOnCancel}>
            Cancel
          </CancelButton>
          <Button onClick={() => handleOnClose(true)}>Save</Button>
        </ModalFooterButtons>
      </Modal.Footer>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Row = styled.div`
  margin-top: 10px;
`
const FauxInputContainer = styled.div`
  border: 1px solid ${Colors.ALTO};
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 60px;
  min-width: 300px;
`
const FauxLabel = styled.span`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  margin: 6px 10px 0;
  text-transform: uppercase;
`
const FauxInput = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 6px 10px;
`
const RadioInputContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 2px;
`
const DatePickerContainer = styled.div`
  border: 1px solid ${Colors.ALTO};
  border-radius: 6px;
`
const DayCheckbox = styled(Checkbox)`
  & + & {
    margin-left: 10px;
  }
`
const ModalFooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`
const CancelButton = styled(Button)`
  margin-right: 10px;
`

export default RepeatEvent
