import { IntercomProps, useIntercom } from 'react-use-intercom'
import { uniqBy } from 'lodash'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import TagManager from 'react-gtm-module'
import useHotjar from 'react-use-hotjar'

import { useFetchApi } from '@sportsyou/react-hooks'
import {
  QueryUserTrackingRequest,
  Team,
  TeamMember,
  UserTracking,
  queryUserTracking,
} from '@sportsyou/api'

import { selectCurrentUser } from 'web/store/slices/UserSlice'
import { environment } from '../../environments/environment'

export interface ExtendedUserTracking extends UserTracking {
  lastExperiment?: string
}

export interface EventProperties {
  [key: string]: any
  isRepeatingEvent?: boolean
  isTeamEvent?: boolean
  method?: string
  pageName?: string
  timestamp?: string
  url?: string
}

export function useAnalytics() {
  const currentUser = useSelector(selectCurrentUser)
  const {
    boot: initIntercom,
    shutdown: shutdownIntercom,
    trackEvent: trackIntercomEvent,
    update: updateIntercom,
  } = useIntercom()
  const { identifyHotjar } = useHotjar()
  const { fetch: getTrackingInfo } = useFetchApi(queryUserTracking)

  function getMemberRole(team: Team, memberId: string) {
    return team.members
      ?.filter((m) => m?.id === memberId)
      .map((m) => m?.role)
      .shift()
  }

  function buildTeamInfo(teamData?: Team) {
    let teamInfo = {}
    if (teamData && Object.keys(teamData).length) {
      const teamId = teamData.id
      const teamSport = teamData.sport?.name ?? null
      const numberOfMembers = uniqBy(
        teamData.members as TeamMember[],
        'userId'
      ).length
      const numberOfCoaches = teamData.coaches?.length ?? 0
      const numberOfPlayers = teamData.players?.length ?? 0
      const numberOfFamilyMembers = teamData.parents?.length ?? 0
      const numberOfOtherMembers = teamData.others?.length ?? 0

      teamInfo = {
        teamId,
        teamSport,
        numberOfMembers,
        numberOfCoaches,
        numberOfPlayers,
        numberOfFamilyMembers,
        numberOfOtherMembers,
      }
    }
    return teamInfo
  }

  async function fetchUserTracking(): Promise<
    ExtendedUserTracking | undefined
  > {
    let trackingInfo: ExtendedUserTracking | undefined
    if (!currentUser.id) {
      // TODO: need for any kind of error message?
      return
    }

    const currentIntercomHash = window.localStorage.getItem('intercomUserHash')

    try {
      const res = await getTrackingInfo({
        generateIntercomHash: !currentIntercomHash,
      } as QueryUserTrackingRequest)
      if (res.ok) {
        trackingInfo = res.data
        if (
          currentIntercomHash &&
          trackingInfo &&
          !trackingInfo?.intercomHash
        ) {
          trackingInfo.intercomHash = currentIntercomHash
        } else if (!currentIntercomHash && trackingInfo?.intercomHash) {
          window.localStorage.setItem(
            'intercomUserHash',
            trackingInfo.intercomHash
          )
        }
      }
    } catch (_error) {
      //
    }

    return trackingInfo
  }

  async function setupIntercom(trackingInfo: ExtendedUserTracking | undefined) {
    // if  (!currentUser.id) {
    //   // TODO: need for any kind of error message?
    //   return
    // }

    if (environment.useIntercom) {
      let userProps: IntercomProps

      if (trackingInfo && environment.intercomKey) {
        userProps = {
          hideDefaultLauncher: true,
          name: trackingInfo?.fullName ?? undefined,
          email: trackingInfo?.email ?? undefined,
          phone: trackingInfo?.phoneNumber ?? undefined,
          customAttributes: {
            app_id: environment.intercomKey,
            user_id: trackingInfo?.id ?? undefined,
            isAdmin: trackingInfo?.isAdmin ?? undefined,
            isPhotographer: trackingInfo?.isPhotographer ?? undefined,
            isCoach: trackingInfo?.isCoach ?? undefined,
            isPlayer: trackingInfo?.isPlayer ?? undefined,
            isParent: trackingInfo?.isParent ?? undefined,
            isOther: trackingInfo?.isOther ?? undefined,
            adminTeamsCount: trackingInfo?.adminTeamsCount ?? undefined,
            adminMembersCount: trackingInfo?.adminMembersCount ?? undefined,
            createdGroup: trackingInfo?.createdGroup ?? undefined,
            createdTeam: trackingInfo?.createdTeam ?? undefined,
            sports: trackingInfo?.sports ?? undefined,
            teamIds: trackingInfo?.teamIds ?? undefined,
            user_hash: trackingInfo?.intercomHash ?? undefined,
            hasVideo: trackingInfo?.hasVideo ?? undefined,
          },
        }
        if (trackingInfo.lastExperiment && userProps?.customAttributes) {
          userProps.customAttributes.lastExperiment =
            trackingInfo.lastExperiment
        }
      } else {
        userProps = {
          hideDefaultLauncher: true,
        }
      }

      try {
        initIntercom(userProps)
      } catch (_error) {
        //
      }
    }
  }

  async function setupGoogleAnalytics(
    trackingInfo?: ExtendedUserTracking,
    team?: Team
  ) {
    // if  (!currentUser.id) {
    //   // TODO: need for any kind of error message?
    //   return
    // }

    if (environment.googleTrackingId) {
      ReactGA.initialize(environment.googleTrackingId)

      // ReactGA.set({ dimension14: 'Sports' })

      if (trackingInfo) {
        ReactGA.ga('create', environment.googleTrackingId, {
          userId: trackingInfo.id,
        })
        if (team) {
          const role = getMemberRole(team, trackingInfo.id!)
          ReactGA.ga('set', 'dimension1', team.id)
          ReactGA.ga('set', 'dimension2', team.name)
          ReactGA.ga('set', 'dimension3', team.type)
          ReactGA.ga('set', 'dimension4', role)
          ReactGA.ga('set', 'dimension5', (!!team.canEdit).toString())
        }
        ReactGA.ga('set', 'dimension6', (!!trackingInfo.isAdmin).toString())
        ReactGA.ga('set', 'dimension7', (!!trackingInfo.isCoach).toString())
        ReactGA.ga('set', 'dimension8', (!!trackingInfo.isPlayer).toString())
        ReactGA.ga('set', 'dimension9', (!!trackingInfo.isParent).toString())
        ReactGA.ga('set', 'dimension10', (!!trackingInfo.isOther).toString())
        ReactGA.ga(
          'set',
          'dimension11',
          (!!trackingInfo.createdTeam).toString()
        )
        ReactGA.ga(
          'set',
          'dimension12',
          (!!trackingInfo.createdGroup).toString()
        )
        ReactGA.ga(
          'set',
          'dimension13',
          (!!trackingInfo.isPhotographer).toString()
        )
        ReactGA.ga(
          'set',
          'dimension14',
          trackingInfo.adminTeamsCount?.toString() ?? ''
        )
        ReactGA.ga(
          'set',
          'dimension15',
          trackingInfo.adminMembersCount?.toString() ?? ''
        )
        ReactGA.ga('set', 'dimension16', trackingInfo.sports)
        ReactGA.ga('set', 'dimension17', trackingInfo.teamIds)
        ReactGA.ga('set', 'dimension18', (!!trackingInfo.hasVideo).toString())
      } else {
        ReactGA.ga('create', environment.googleGA4Id, {})
      }
      ReactGA.ga('set', 'anonymizeIp', true)
      ReactGA.ga('send', 'pageview')
    }

    if (environment.googleGA4Id) {
      ReactGA4.initialize(environment.googleGA4Id)

      // ReactGA4.initialize([
      //   {
      //     trackingId: environment.googleGA4Id,
      //     gaOptions: {}, // optional
      //     gtagOptions: {}, // optional
      //   },
      //   {
      //     trackingId: "your second GA measurement id",
      //   },
      // ])

      // ReactGA4.set(fieldsObject)

      if (trackingInfo) {
        // ReactGA4.gtag(...args)
        // ReactGA4.ga(...args)

        ReactGA4.ga('create', environment.googleTrackingId, {
          userId: trackingInfo.id,
        })
        if (team) {
          const role = getMemberRole(team, trackingInfo.id!)
          ReactGA4.ga('set', 'dimension1', team.id)
          ReactGA4.ga('set', 'dimension2', team.name)
          ReactGA4.ga('set', 'dimension3', team.type)
          ReactGA4.ga('set', 'dimension4', role)
          ReactGA4.ga('set', 'dimension5', (!!team.canEdit).toString())
        }
        ReactGA4.ga('set', 'dimension6', (!!trackingInfo.isAdmin).toString())
        ReactGA4.ga('set', 'dimension7', (!!trackingInfo.isCoach).toString())
        ReactGA4.ga('set', 'dimension8', (!!trackingInfo.isPlayer).toString())
        ReactGA4.ga('set', 'dimension9', (!!trackingInfo.isParent).toString())
        ReactGA4.ga('set', 'dimension10', (!!trackingInfo.isOther).toString())
        ReactGA4.ga(
          'set',
          'dimension11',
          (!!trackingInfo.createdTeam).toString()
        )
        ReactGA4.ga(
          'set',
          'dimension12',
          (!!trackingInfo.createdGroup).toString()
        )
        ReactGA4.ga(
          'set',
          'dimension13',
          (!!trackingInfo.isPhotographer).toString()
        )
        ReactGA4.ga(
          'set',
          'dimension14',
          trackingInfo.adminTeamsCount?.toString() ?? ''
        )
        ReactGA4.ga(
          'set',
          'dimension15',
          trackingInfo.adminMembersCount?.toString() ?? ''
        )
        ReactGA4.ga('set', 'dimension16', trackingInfo.sports)
        ReactGA4.ga('set', 'dimension17', trackingInfo.teamIds)
        ReactGA4.ga('set', 'dimension18', (!!trackingInfo.hasVideo).toString())
      } else {
        ReactGA4.ga('create', environment.googleGA4Id, {})
      }
      ReactGA4.ga('set', 'anonymizeIp', true)
      ReactGA4.ga('send', 'pageview')
    }

    if (environment.googleTagId) {
      const tagManagerArgs = {
        gtmId: environment.googleTagId,
        auth: environment.googleTagAuth,
        preview: environment.googleTagPreview,
      }
      TagManager.initialize(tagManagerArgs)
    }
  }

  async function setupHotjar(trackingInfo: ExtendedUserTracking | undefined) {
    if (environment.hotJarId && environment.hotJarSv && trackingInfo) {
      const userProps: any = {
        isAdmin: trackingInfo?.isAdmin ?? undefined,
        isPhotographer: trackingInfo?.isPhotographer ?? undefined,
        isCoach: trackingInfo?.isCoach ?? undefined,
        isPlayer: trackingInfo?.isPlayer ?? undefined,
        isParent: trackingInfo?.isParent ?? undefined,
        isOther: trackingInfo?.isOther ?? undefined,
        adminTeamsCount: trackingInfo?.adminTeamsCount ?? undefined,
        adminMembersCount: trackingInfo?.adminMembersCount ?? undefined,
        createdGroup: trackingInfo?.createdGroup ?? undefined,
        createdTeam: trackingInfo?.createdTeam ?? undefined,
        sports: trackingInfo?.sports ?? undefined,
        teamIds: trackingInfo?.teamIds ?? undefined,
        hasVideo: trackingInfo?.hasVideo ?? undefined,
      }
      if (trackingInfo.lastExperiment) {
        userProps.lastExperiment = trackingInfo.lastExperiment
      }

      try {
        trackingInfo.id && identifyHotjar(trackingInfo.id, userProps)
      } catch (_error) {
        //
      }
    }
  }

  async function initialize(
    userTrackingInfo?: ExtendedUserTracking,
    shouldFetchTrackingInfo?: boolean,
    team?: Team
  ) {
    // if  (!currentUser.id) {
    //   // TODO: need for any kind of error message?
    //   return
    // }

    const _userTrackingInfo: ExtendedUserTracking | undefined = userTrackingInfo
      ? {
          ...userTrackingInfo,
        }
      : await fetchUserTracking()

    await setupIntercom(_userTrackingInfo)
    await setupGoogleAnalytics(_userTrackingInfo, team)
    await setupHotjar(_userTrackingInfo)
  }

  async function setUserProperties(
    trackingInfo?: ExtendedUserTracking,
    team?: Team
  ) {
    const _trackingInfo: ExtendedUserTracking | undefined = trackingInfo
      ? {
          ...trackingInfo,
        }
      : await fetchUserTracking()
    // console.log({ _trackingInfo })
    if (!_trackingInfo) {
      // TODO: need for any kind of error message?
      return
    }

    if (environment.useIntercom && environment.intercomKey && _trackingInfo) {
      const userProps: IntercomProps = {
        hideDefaultLauncher: true,
        name: _trackingInfo?.fullName ?? undefined,
        email: _trackingInfo?.email ?? undefined,
        phone: _trackingInfo?.phoneNumber ?? undefined,
        customAttributes: {
          app_id: environment.intercomKey,
          user_id: _trackingInfo?.id ?? undefined,
          isAdmin: _trackingInfo?.isAdmin ?? undefined,
          isPhotographer: _trackingInfo?.isPhotographer ?? undefined,
          isCoach: _trackingInfo?.isCoach ?? undefined,
          isPlayer: _trackingInfo?.isPlayer ?? undefined,
          isParent: _trackingInfo?.isParent ?? undefined,
          isOther: _trackingInfo?.isOther ?? undefined,
          adminTeamsCount: _trackingInfo?.adminTeamsCount ?? undefined,
          adminMembersCount: _trackingInfo?.adminMembersCount ?? undefined,
          createdGroup: _trackingInfo?.createdGroup ?? undefined,
          createdTeam: _trackingInfo?.createdTeam ?? undefined,
          sports: _trackingInfo?.sports ?? undefined,
          teamIds: _trackingInfo?.teamIds ?? undefined,
          user_hash: _trackingInfo?.intercomHash ?? undefined,
          hasVideo: _trackingInfo?.hasVideo ?? undefined,
        },
      }
      if (_trackingInfo.lastExperiment && userProps.customAttributes) {
        userProps.customAttributes.lastExperiment = _trackingInfo.lastExperiment
      }

      try {
        updateIntercom(userProps)
      } catch (_error) {
        //
      }
    }

    if (_trackingInfo && environment.googleTrackingId) {
      if (team) {
        const role = getMemberRole(team, _trackingInfo.id!)
        ReactGA.ga('set', 'dimension1', team.id)
        ReactGA.ga('set', 'dimension2', team.name)
        ReactGA.ga('set', 'dimension3', team.type)
        ReactGA.ga('set', 'dimension4', role)
        ReactGA.ga('set', 'dimension5', (!!team.canEdit).toString())
      }
      ReactGA.ga('set', 'dimension6', (!!_trackingInfo.isAdmin).toString())
      ReactGA.ga('set', 'dimension7', (!!_trackingInfo.isCoach).toString())
      ReactGA.ga('set', 'dimension8', (!!_trackingInfo.isPlayer).toString())
      ReactGA.ga('set', 'dimension9', (!!_trackingInfo.isParent).toString())
      ReactGA.ga('set', 'dimension10', (!!_trackingInfo.isOther).toString())
      ReactGA.ga('set', 'dimension11', (!!_trackingInfo.createdTeam).toString())
      ReactGA.ga(
        'set',
        'dimension12',
        (!!_trackingInfo.createdGroup).toString()
      )
      ReactGA.ga(
        'set',
        'dimension13',
        (!!_trackingInfo.isPhotographer).toString()
      )
      ReactGA.ga(
        'set',
        'dimension14',
        _trackingInfo.adminTeamsCount?.toString() ?? ''
      )
      ReactGA.ga(
        'set',
        'dimension15',
        _trackingInfo.adminMembersCount?.toString() ?? ''
      )
      ReactGA.ga('set', 'dimension16', _trackingInfo.sports)
      ReactGA.ga('set', 'dimension17', _trackingInfo.teamIds)
      ReactGA.ga('set', 'dimension18', (!!_trackingInfo.hasVideo).toString())
      ReactGA.ga('set', 'anonymizeIp', true)
    }

    if (_trackingInfo && environment.googleGA4Id) {
      if (team) {
        const role = getMemberRole(team, _trackingInfo.id!)
        ReactGA4.ga('set', 'dimension1', team.id)
        ReactGA4.ga('set', 'dimension2', team.name)
        ReactGA4.ga('set', 'dimension3', team.type)
        ReactGA4.ga('set', 'dimension4', role)
        ReactGA4.ga('set', 'dimension5', (!!team.canEdit).toString())
      }
      ReactGA4.ga('set', 'dimension6', (!!_trackingInfo.isAdmin).toString())
      ReactGA4.ga('set', 'dimension7', (!!_trackingInfo.isCoach).toString())
      ReactGA4.ga('set', 'dimension8', (!!_trackingInfo.isPlayer).toString())
      ReactGA4.ga('set', 'dimension9', (!!_trackingInfo.isParent).toString())
      ReactGA4.ga('set', 'dimension10', (!!_trackingInfo.isOther).toString())
      ReactGA4.ga(
        'set',
        'dimension11',
        (!!_trackingInfo.createdTeam).toString()
      )
      ReactGA4.ga(
        'set',
        'dimension12',
        (!!_trackingInfo.createdGroup).toString()
      )
      ReactGA4.ga(
        'set',
        'dimension13',
        (!!_trackingInfo.isPhotographer).toString()
      )
      ReactGA4.ga(
        'set',
        'dimension14',
        _trackingInfo.adminTeamsCount?.toString() ?? ''
      )
      ReactGA4.ga(
        'set',
        'dimension15',
        _trackingInfo.adminMembersCount?.toString() ?? ''
      )
      ReactGA4.ga('set', 'dimension16', _trackingInfo.sports)
      ReactGA4.ga('set', 'dimension17', _trackingInfo.teamIds)
      ReactGA4.ga('set', 'dimension18', (!!_trackingInfo.hasVideo).toString())
      ReactGA4.ga('set', 'anonymizeIp', true)
    }

    // if (environment.googleTagId) {
    //   // const tagManagerArgs = {
    //   //   gtmId: 'GTM-000000',
    //   //   events: {
    //   //       sendUserInfo: 'userInfo',
    //   //   },
    //   // }
    //   // TagManager.initialize(tagManagerArgs)
    // }
  }

  async function logEvent(
    eventType: string,
    eventProperties: EventProperties,
    team?: Team
  ) {
    const teamInfo = buildTeamInfo(team)
    let _eventProperties: EventProperties

    switch (eventType) {
      case 'signupMethod':
        _eventProperties = {
          ...eventProperties,
        }
        break
      case 'teamJoin':
        _eventProperties = {
          ...eventProperties,
          timestamp: new Date().toISOString(),
        }
        break
      default:
        _eventProperties = {
          ...eventProperties,
          ...teamInfo,
          timestamp: new Date().toISOString(),
        }
    }

    if (environment.useIntercom && environment.intercomKey) {
      try {
        switch (eventType) {
          case 'signupMethod':
            if (_eventProperties.method) {
              updateIntercom({
                customAttributes: {
                  signupMethod: _eventProperties.method,
                },
              } as Partial<IntercomProps>)
            }
            break
          default:
            trackIntercomEvent(eventType, _eventProperties)
        }
      } catch (err) {
        // console.warn(err)
      }
    }

    if (environment.googleTrackingId) {
      // ReactGA.pageview('/about/contact-us')

      // ReactGA.modalview('/about/contact-us')

      // ReactGA.event({
      //   category: 'User',
      //   action: 'Created an Account'
      // })

      // ReactGA.event({
      //   category: 'Social',
      //   action: 'Rated an App',
      //   value: 3
      // })

      // ReactGA.event({
      //   category: 'Editing',
      //   action: 'Deleted Component',
      //   label: 'Game Widget'
      // })

      // ReactGA.event({
      //   category: 'Promotion',
      //   action: 'Displayed Promotional Widget',
      //   label: 'Homepage Thing',
      //   nonInteraction: true
      // })

      // args.category	String. Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
      // args.action	String. Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
      // args.label	String. Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
      // args.value	Int. Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.
      // args.nonInteraction	Boolean. Optional. If an event is not triggered by a user interaction, but instead by our code (e.g. on page load), it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
      // args.transport	String. Optional. This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.

      try {
        switch (eventType) {
          case 'pageVisited':
            // ReactGA.ga('send', 'pageview')
            if (_eventProperties.pageName) {
              ReactGA.pageview(_eventProperties.pageName)
            }
            break
          default:
        }
      } catch (err) {
        // console.warn(err)
      }
    }

    if (environment.googleGA4Id) {
      // ReactGA4.pageview(path, _, title)
      // Deprecated Use .send('pageview') instead

      // ReactGA4.send({ hitType: 'pageview', page: '/my-path' })

      // ReactGA4.event({
      //   category: 'your category',
      //   action: 'your action',
      //   label: 'your label', // optional
      //   value: 99, // optional, must be a number
      //   nonInteraction: true, // optional, true/false
      //   transport: 'xhr', // optional, beacon/xhr/image
      // })

      // ReactGA4.event(name, params)

      // ReactGA4.event(options)
      // options.action	string Required
      // options.category	string Required
      // options.label	string Optional
      // options.value	number Optional
      // options.nonInteraction	boolean Optional
      // options.transport	'beacon'|'xhr'|'image' Optional
      // options.dimension{1...200}	any Optional
      // options.metric{1...200}	any Optional

      try {
        switch (eventType) {
          case 'pageVisited':
            // ReactGA4.send('pageview')
            if (_eventProperties.pageName) {
              ReactGA4.send({
                hitType: 'pageview',
                page: _eventProperties.pageName,
              })
            }
            break
          default:
        }
      } catch (err) {
        // console.warn(err)
      }
    }

    // if (environment.googleTagId) {
    // }
  }

  function logout() {
    if (environment.useIntercom && environment.intercomKey) {
      shutdownIntercom()
    }

    // TODO: logout/shutdown for GA?
    // if (environment.googleTrackingId) {
    // }
    // if (environment.googleGA4Id) {
    // }
    // if (environment.googleTagId) {
    // }
  }

  return {
    initialize,
    logEvent,
    logout,
    setUserProperties,
  }
}

export default useAnalytics
