import { Colors } from '@sportsyou/core'
import { Checkmark } from '@sportsyou/react-icons'
import styled from 'styled-components'

interface CheckboxSimpleProps {
  boxSize?: number
  checked: boolean
  className?: string
  onClick?: (e: React.MouseEvent) => void
  title?: string
}

export default function CheckboxSimple(props: CheckboxSimpleProps) {
  const { boxSize = 18, checked, className, onClick } = props
  return (
    <CheckmarkContainer
      $size={boxSize}
      checked={checked}
      className={className}
      onClick={onClick}
      title={props.title}
    >
      {checked ? <Checkmark fill={Colors.WHITE} /> : null}
    </CheckmarkContainer>
  )
}

const CheckmarkContainer = styled.div<{ checked: boolean; $size: number }>`
  align-items: center;
  background-color: ${({ checked }) =>
    checked ? Colors.HAVELOCK_BLUE : Colors.WHITE};
  border-radius: 4px;
  border: 2px solid
    ${({ checked }) => (checked ? Colors.HAVELOCK_BLUE : Colors.SHUTTLE_GRAY)};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 2px;
  transition: background-color 120ms ease-in-out, border-color 120ms ease-in-out;
  ${({ $size }) => $size && `height: ${$size}px; width: ${$size}px;`}
`
