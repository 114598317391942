import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import styled from 'styled-components'

import {
  Activity,
  Avatar,
  FadeInContainer,
  EmptyState,
  Modal,
  PollChoiceReturnData,
  Post,
  ReportCommentParams,
  useDialog,
} from '@sportsyou/react-dom-ui'
import {
  FetchPostCriteria,
  TimelineUpdateInfo,
  useFetchApi,
  useTimeline,
} from '@sportsyou/react-hooks'
import {
  Comment,
  Post as PostProps,
  PostFeedbackReason,
  queryLikeUsers,
  QueryLikeUsersRequest,
  queryPollVotes,
  QueryPollVotesRequest,
  queryPostCommentsUsers,
  QueryPostCommentsUsersRequest,
  queryPostFeedbackReasons,
  QueryPostFeedbackReasonsRequest,
  queryTeam,
  ScheduledPost,
  TeamMember as TeamMemberProps,
  User as UserProps,
  VideoPlaylistSummary,
  Team,
} from '@sportsyou/api'
import { Colors, isFile } from '@sportsyou/core'

import {
  EditPostModal,
  HideAdModal,
  PollChoiceData,
  PollVotesModal,
  PostCommentsModal,
  PostLikeModal,
  PostViewedModal,
  ReportUserModal,
} from '../../components'
import { RootState } from 'web/store/rootReducer'
import { initializeFeed, setFeed } from 'web/store/slices/FeedSlice'
import { removePage } from 'web/store/slices/CommunitiesSlice'
import { TEAM_MEMBER_FIELD_LIST } from '../../../constants'
import {
  setPostComposer,
  showPostComposer,
} from 'web/store/slices/PostComposerSlice'
import usePages from '../../pages/Pages/UsePages'
import {
  CHAT_MESSAGE_FILTER,
  useAppFeatures,
  useChat,
  useFolders,
} from 'web/hooks'
import { environment } from 'apps/web/src/environments/environment'
import useLightbox from '../Lightbox/useLightbox'

export interface PostListProps {
  className?: string
  commentId?: string
  communityId?: string
  currentUser: UserProps
  id?: string
  isCommunity?: boolean
  onDeletePost?: (postOrId: string | PostProps) => void
  onRefetch?: () => void
  onScheduledPostUpdate?: Function
  onSinglePostUpdate?: Function
  onTeamFilesChanged?: Function
  overscan?: number
  posts?: PostProps[]
  refetch?: boolean
  renderFooter?: Function
  renderHeader?: Function
  showComments?: boolean
  style?: React.CSSProperties
}

interface ViewedByProps {
  viewed: Array<TeamMemberProps>
  notViewed: Array<TeamMemberProps>
}

interface PostsWithMultiPosts extends PostProps {
  posts?: Array<PostProps>
}

const COMMENTS_PER_PAGE = 5

type ProfileType = 'group' | 'profile' | 'team'

export const PostList: React.FC<PostListProps> = (props: PostListProps) => {
  const {
    className,
    commentId,
    currentUser,
    id: feedId,
    isCommunity,
    onDeletePost,
    onRefetch,
    onScheduledPostUpdate,
    overscan,
    refetch: refetchPosts,
    renderFooter,
    renderHeader,
    showComments = false,
    style,
  } = props

  const { downloadItem, createFolderItem } = useFolders({})
  const { follow: followCommunity, unfollow: unfollowCommunity } = usePages()
  const { sendBanner, sendConfirm } = useDialog()
  const { showWithItems: showLightboxWithItems } = useLightbox()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { posts: reduxPosts } = useSelector((state: RootState) => state.feed)
  const { allTeamsAndGroups: teams } = useSelector(
    (state: RootState) => state.teams,
    isEqual
  )

  const chatClient = useChat({
    establishConnection: false,
    filter: CHAT_MESSAGE_FILTER.LISTEN_FOR_MESSAGES_ONLY,
  })

  const { fetch: getPostFeedbackReasons } = useFetchApi(
    queryPostFeedbackReasons
  )
  const { fetch: getCommentUsers } = useFetchApi(queryPostCommentsUsers)
  const { fetch: getPollVotes } = useFetchApi(queryPollVotes)
  const { fetch: getPostLikes } = useFetchApi(queryLikeUsers)
  const { fetch: getTeam } = useFetchApi(queryTeam)

  const [currentPost, setCurrentPost] = useState<PostsWithMultiPosts>({})
  const [currentCommentPage, setCurrentCommentPage] = useState<number>(1)
  const [currentPollData, setCurrentPollData] = useState<PollChoiceData>({
    index: -1,
    choices: [],
  })
  const [isEditPostVisible, setIsEditPostVisible] = useState<boolean>(false)
  const [isCommentCreationSuccessful, setIsCommentCreationSuccessful] =
    useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingComments, setIsLoadingComments] = useState(false)

  const [isSchedulePostTeamsVisible, setIsSchedulePostTeamsVisible] =
    useState(false)

  const [scheduledPostTeams, setSetscheduledPostTeams] = useState<Team[]>()
  const [hideAdReasons, setHideAdReasons] =
    useState<Array<PostFeedbackReason>>()
  const [postLikes, setPostLikes] = useState<Array<UserProps>>([])
  const [postViewed, setPostViewed] = useState<ViewedByProps>({
    viewed: [],
    notViewed: [],
  })
  const [isCommentModalVisible, setIsCommentModalVisible] =
    useState<boolean>(false)
  const [isFetchingCount, setIsFetchingCount] = useState<boolean>(false)
  const [isLikeModalVisible, setIsLikeModalVisible] = useState(false)
  const [isPollModalVisible, setIsPollModalVisible] = useState(false)
  const [isHideAdModalVisible, setIsHideAdModalVisible] = useState(false)
  const [isReportModalVisible, setIsReportModalVisible] = useState(false)
  const [
    isInitialSinglePostCommentsLoaded,
    setIsInitialSinglePostCommentsLoaded,
  ] = useState(false)
  const [isViewedByModalVisible, setIsViewedByModalVisible] = useState(false)
  const [namesWhoCommented, setNamesWhoCommented] = useState<Array<string>>([])
  const [_posts, set_posts] = useState<PostsWithMultiPosts[]>(getPosts)
  const [reportCommentParams, setReportCommentParams] =
    useState<ReportCommentParams>()
  const [currentPostId, setCurrentPostId] = useState<string>()
  const [currentPostComments, setCurrentPostComments] = useState<Comment[]>([])

  const shouldLoadMore = useRef(true)

  const timelineHook = useTimeline({
    onSinglePostUpdate: (post) => props.onSinglePostUpdate?.(post),
    onTimelineUpdate,
    teamMemberFieldList: TEAM_MEMBER_FIELD_LIST,
    teams: teams ?? [],
  })

  const { pages } = useAppFeatures().features

  function onTimelineUpdate({ post, timeline }: TimelineUpdateInfo) {
    if (
      timeline?.operationType === 'update' &&
      timeline.postId &&
      timeline.postId === post?.id
    ) {
      let index1: number = -1
      let index2: number = -1

      index1 = _posts.findIndex((_post) => {
        if (_post.posts?.length) {
          return (
            _post.posts.findIndex((__post) => __post.id === timeline.postId) !==
            -1
          )
        } else {
          return _post.id === timeline.postId
        }
      })

      if (index1 !== -1 && _posts[index1].posts) {
        index2 =
          _posts[index1].posts?.findIndex(
            (_post) => _post.id === timeline.postId
          ) ?? -1
      }

      const __posts = [..._posts]
      if (index2 !== -1) {
        const _post = {
          posts: [...(__posts[index1].posts ?? [])],
        }
        _post.posts.splice(index2, 1, post)
        __posts.splice(index1, 1, _post)
        console.log('timeline updated')
        if (feedId) {
          // check for team posts with files to update file list
          if (post?.uploads?.length) {
            if (post?.uploads?.some((upload) => isFile(upload?.contentType!))) {
              props.onTeamFilesChanged?.()
            }
          }
          dispatch(setFeed({ id: feedId, posts: __posts }))
        } else {
          dispatch(initializeFeed(__posts))
        }
        set_posts(__posts)
      }
    }
  }

  // function onSinglePostUpdate(post: PostProps) {
  //   set_posts([post])
  // }

  const fetchNewPosts = useCallback(() => {
    if (props.posts) {
      set_posts(props.posts)
      return
    }
    const startPostId = getFirstPostId(_posts)
    const fetchCriteria: FetchPostCriteria = {
      startPostId,
      direction: 'newer',
    }
    if (feedId?.startsWith('te-')) {
      fetchCriteria.teamId = feedId
    } else if (feedId?.startsWith('us-')) {
      fetchCriteria.userId = feedId
    }
    timelineHook.fetch(fetchCriteria)
  }, [props.posts, _posts, feedId, timelineHook])

  useEffect(() => {
    function initialFetch() {
      const fetchCriteria: FetchPostCriteria = {}
      const posts = getPosts()
      const startPostId = getLastPostId(posts)
      // const shouldKeepExistingPosts = true
      const shouldKeepExistingPosts = false
      set_posts(posts)
      if (shouldKeepExistingPosts && posts.length && startPostId) {
        fetchCriteria.startPostId = startPostId
        fetchCriteria.direction = 'newer'
        if (feedId?.startsWith('te-')) {
          fetchCriteria.teamId = feedId
        } else if (feedId?.startsWith('us-')) {
          fetchCriteria.userId = feedId
        }
      } else {
        fetchCriteria.page = 1
        if (feedId?.startsWith('te-')) {
          fetchCriteria.teamId = feedId
        } else if (feedId?.startsWith('us-')) {
          fetchCriteria.userId = feedId
        }
      }
      timelineHook.fetch(fetchCriteria)
    }

    initialFetch()
  }, [feedId])

  useEffect(() => {
    function dispatchTimelinePosts() {
      if (props.posts) {
        if (
          !feedId &&
          timelineHook.operationType === 'delete' &&
          props.posts.length === 1 &&
          timelineHook.operationPostId === props.posts.at(0)?.id
        ) {
          // single post view post delete msg
          props.onSinglePostUpdate?.(timelineHook.operationPostId)
          set_posts([])
          return
        } else if (
          timelineHook.posts?.length &&
          timelineHook.operationPostId === props.posts.at(0)?.id
        ) {
          const operationPost = timelineHook.posts.find(
            (post) => post.post?.id === timelineHook.operationPostId
          )?.post
          if (operationPost?.id) {
            operationPost.canPin = false
            set_posts([operationPost])
            return
          }
        }
        set_posts(props.posts)
        return
      }
      if (timelineHook.posts?.length) {
        const posts: PostsWithMultiPosts[] = timelineHook.posts.map(
          (postEl) => {
            if (postEl.multiPost) {
              return {
                posts: [...postEl.multiPost],
              }
            }
            return postEl.post ?? {}
          }
        )
        if (feedId) {
          const pinnedIndex = posts.findIndex((post) => post.isPinned)
          if (pinnedIndex > 0) {
            posts.unshift(posts.splice(pinnedIndex, 1).shift() ?? {})
          }
          dispatch(setFeed({ id: feedId, posts }))
        } else {
          dispatch(initializeFeed(posts))
        }
        set_posts(posts)
      } else if (timelineHook.posts && timelineHook.posts.length === 0) {
        if (feedId) {
          dispatch(setFeed({ id: feedId, posts: [] }))
        } else {
          dispatch(initializeFeed([]))
        }
        set_posts([])
      }
      setIsLoading(false)
    }
    dispatchTimelinePosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, feedId, props.posts, timelineHook.posts])

  useEffect(() => {
    if (!props.posts && timelineHook.isNewPostAvailable) {
      fetchNewPosts()
    }
  }, [fetchNewPosts, props.posts, timelineHook.isNewPostAvailable])

  useEffect(() => {
    if (!timelineHook.lastFetchResult?.criteria.multiPostId) {
      // if (
      //   timelineHook.lastFetchResult?.criteria.direction === 'older' &&
      //   (timelineHook.lastFetchResult.posts?.length ?? 0) === 0
      // ) {
      //   shouldLoadMore && setShouldLoadMore(false)
      // } else {
      //   !shouldLoadMore && setShouldLoadMore(true)
      // }

      // shouldLoadMore.current = !(
      //   timelineHook.lastFetchResult?.criteria.direction !== 'newer' &&
      //   (timelineHook.lastFetchResult?.posts?.length ?? 0) === 0
      // )

      if (timelineHook.lastFetchResult?.criteria.direction === 'newer') {
        // shouldLoadMore should not be updated
      } else {
        if ((timelineHook.lastFetchResult?.posts?.length ?? 0) < 10) {
          shouldLoadMore.current = false
        } else {
          shouldLoadMore.current = true
        }
      }
    }
    setIsLoading(false)
  }, [timelineHook.lastFetchResult])

  useEffect(() => {
    if (isInitialSinglePostCommentsLoaded) return
    if (!commentId) return
    // make sure only 1 post is passed to props
    if (props.posts?.length === 1) {
      const postId = props.posts[0].id

      if (!postId) return

      // Fetch single post with timelineHook
      const fetchPost = async () => {
        await timelineHook.fetch({ postId })
      }
      fetchPost()

      if (!timelineHook.posts || timelineHook.posts.length > 1) return

      const post = timelineHook.posts[0].post as PostsWithMultiPosts

      // set post so we can interact with timelinehook properly
      set_posts([post])

      // Check if comment is curretly visible on single post view, we will use this
      // later to fetch all comments if needed
      const isTargetCommentVisible = post?.comments
        ?.map((c) => c?.id)
        .includes(commentId)

      // fetch all comments if the comment is not visible at first
      if (!isTargetCommentVisible) {
        timelineHook.loadAllComments(postId)
      } else {
        timelineHook.loadComments(postId, 1)
      }
      setIsInitialSinglePostCommentsLoaded(true)
    }
  }, [
    isInitialSinglePostCommentsLoaded,
    commentId,
    timelineHook.posts,
    props.posts,
  ])

  useEffect(() => {
    if (refetchPosts) {
      const fetch = () => {
        fetchNewPosts()
      }
      fetch()
      onRefetch?.()
    }
  }, [onRefetch, refetchPosts])

  const namesWhoLiked = useMemo(() => {
    const unique: Array<UserProps> = []
    postLikes.forEach((user) => {
      const currIds = unique.map((u) => u.id)
      if (!currIds.includes(user.id)) {
        unique.push(user)
      }
    })
    return unique.map(
      (item: UserProps) =>
        item.fullName ?? `${item.firstName ?? ''} ${item.lastName ?? ''}`
    )
  }, [postLikes])

  const fetchNamesWhoLiked = async (
    post: PostsWithMultiPosts,
    postsIndex?: number
  ) => {
    if (namesWhoLiked.length === 0) setIsFetchingCount(true)
    // let _numberLikes = post.numberLikes ?? 0
    let postId = post.id
    if (typeof postsIndex !== 'undefined' && post.posts) {
      postId = post.posts[postsIndex].id
      // _numberLikes = post.posts[postsIndex].numberLikes ?? 0
    }
    // console.log({ _numberLikes, namesWhoLiked })
    // if (_numberLikes > 0 && namesWhoLiked.length === 0) {
    const { data } = await getPostLikes({ postId } as QueryLikeUsersRequest)
    if (data) {
      setPostLikes(data)
    }
    setIsFetchingCount(false)
    // }
  }

  const fetchNamesWhoCommented = async (
    post: PostsWithMultiPosts,
    postsIndex?: number
  ) => {
    if (namesWhoCommented.length === 0) setIsFetchingCount(true)
    // let postComments = post.numberComments ?? 0
    let postId = post.id
    if (typeof postsIndex !== 'undefined' && post.posts) {
      postId = post.posts[postsIndex].id
      // postComments = post.posts[postsIndex].numberComments ?? 0
    }
    // if (postComments > 0 && namesWhoCommented?.length === 0) {
    const { data } = await getCommentUsers({
      postId,
    } as QueryPostCommentsUsersRequest)

    if (data) {
      const userNames: Array<string> = data.map(
        (user) => user.fullName ?? `${user.firstName} ${user.lastName}`
      )
      setNamesWhoCommented(userNames)
    }
    setIsFetchingCount(false)
    // }
  }

  const sortPostByCreatedAtAsc = (a: PostProps, b: PostProps) =>
    new Date(a.createdAt ?? '').getTime() -
    new Date(b.createdAt ?? '').getTime()
  const sortPostByCreatedAtDesc = (a: PostProps, b: PostProps) =>
    new Date(b.createdAt ?? '').getTime() -
    new Date(a.createdAt ?? '').getTime()

  function fetchFirstPage(
    removePinnedPost?: boolean,
    postIdToRemove?: string,
    forceFetch?: boolean
  ) {
    const fetchCriteria: FetchPostCriteria = {}
    if (postIdToRemove || typeof removePinnedPost === 'boolean') {
      let posts = getPosts()
      if (postIdToRemove) {
        posts = posts.filter((post) => post.id !== postIdToRemove)
      }
      if (removePinnedPost) {
        posts = posts.filter((post) => !post.isPinned)
      }
      if (feedId) {
        dispatch(setFeed({ id: feedId, posts }))
      } else {
        dispatch(initializeFeed(posts))
      }
      set_posts(posts)
    }
    fetchCriteria.page = 1
    if (feedId?.startsWith('te-')) {
      fetchCriteria.teamId = feedId
    } else if (feedId?.startsWith('us-')) {
      fetchCriteria.userId = feedId
    }

    if (forceFetch) {
      fetchCriteria.force = true
    }

    timelineHook.fetch(fetchCriteria)
  }

  function getPosts() {
    if (props.posts) {
      return props.posts
    }
    if (feedId) {
      return reduxPosts[feedId] ?? []
    } else {
      return reduxPosts.main ?? []
    }
  }

  function getFirstPostId(posts: PostsWithMultiPosts[]) {
    let firstPostId: string | null | undefined
    const firstPost = [...posts]
      .filter((post) => !(post.sponsoredPostInfo || post.isPinned))
      .shift()
    if (firstPost?.posts?.length) {
      firstPostId = [...firstPost.posts]
        .sort(sortPostByCreatedAtAsc)
        .shift()?.id
    } else if (firstPost?.id) {
      firstPostId = firstPost?.id
    }
    return firstPostId
  }

  function getLastPostId(posts: PostsWithMultiPosts[]) {
    let lastPostId: string | null | undefined
    const lastPost = [...posts].filter((post) => !post.sponsoredPostInfo).pop()
    if (lastPost?.posts?.length) {
      lastPostId = [...lastPost.posts].sort(sortPostByCreatedAtDesc).pop()?.id
    } else if (lastPost?.id) {
      lastPostId = lastPost.id
    }
    return lastPostId
  }

  const loadMorePosts = useCallback(() => {
    if (props.posts) {
      return
    }
    if (shouldLoadMore.current) {
      setIsLoading(true)
      const startPostId = getLastPostId(_posts)
      const fetchCriteria: FetchPostCriteria = {
        startPostId,
        direction: 'older',
      }
      if (feedId?.startsWith('te-')) {
        fetchCriteria.teamId = feedId
      } else if (feedId?.startsWith('us-')) {
        fetchCriteria.userId = feedId
      }
      timelineHook.fetch(fetchCriteria)
    }
  }, [_posts, feedId, props.posts, timelineHook.fetch])

  const openPollVotesModal = async (choiceId: string, postId: string) => {
    /**
     * Query current votes on poll
     */
    const { data } = await getPollVotes({
      postId,
    } as QueryPollVotesRequest)
    /**
     * Set data so we can display it properly in a modal
     */
    if (data) {
      const index = data.findIndex((choice) => choice.id === choiceId)
      setCurrentPollData({ choices: data!, index })
    }
    setIsPollModalVisible(true)
  }

  const handleOnClickPostComments = async (
    post: PostsWithMultiPosts,
    postsIndex?: number
  ) => {
    let comments: Comment[] = (post.comments as Comment[]) ?? []
    if (typeof postsIndex !== 'undefined' && post.posts) {
      comments = (post.posts[postsIndex].comments as Comment[]) ?? []
    }
    if (comments && comments.length > 0) {
      setCurrentPostComments(comments)
      fetchNamesWhoCommented(post, postsIndex)
      setIsCommentModalVisible(true)
    }
  }

  const handleOnClickPostLikes = async (
    post: PostsWithMultiPosts,
    postsIndex?: number
  ) => {
    let postId = post.id
    if (typeof postsIndex !== 'undefined' && post.posts) {
      postId = post.posts[postsIndex].id
    }
    const { data } = await getPostLikes({
      postId,
    } as QueryLikeUsersRequest)
    if (data) {
      setPostLikes(data)
      setIsLikeModalVisible(true)
    } else {
      sendBanner({
        autoDismiss: true,
        status: 'danger',
        message:
          'There was a problem retriving likes for this post, please try again later.',
      })
    }
  }

  const handleOnClickViewedBy = async (
    post: PostsWithMultiPosts,
    viewed: TeamMemberProps[],
    postsIndex?: number
  ) => {
    let id = postsIndex ? post.posts?.[postsIndex].team?.id : post.team?.id
    let _post = postsIndex ? post.posts?.[postsIndex] : post

    if (!id || !_post) return

    const { data: team } = await getTeam({ id })
    if (team?.members) {
      // remove creator of post
      const _members = team.members.filter(
        (member) => member?.userId !== post.createdBy?.id
      )

      const viewedBy = [
        ...new Map(
          _members
            .filter((teamMember) =>
              _post?.viewed?.find(
                (member) => member?.userId === teamMember?.userId
              )
            )
            .map((member) => [member?.userId, member])
        ).values(),
      ]
      const notViewedBy = [
        ...new Map(
          _members
            .filter(
              (teamMember) =>
                !viewedBy.find(
                  (member) => member?.userId === teamMember?.userId
                )
            )
            .map((member) => [member?.userId, member])
        ).values(),
      ]

      setIsViewedByModalVisible(true)
      setPostViewed({
        viewed: viewedBy as TeamMemberProps[],
        notViewed: notViewedBy as TeamMemberProps[],
      })
    }
  }

  const handleOnChatUser = (user: TeamMemberProps) => {
    const { userId } = user
    if (!userId) return
    chatClient.startChatWithUser({ showUI: true, userId })
    setIsViewedByModalVisible(false)
  }

  const handleOnClickUser = (user: TeamMemberProps) => {
    const { userId: id } = user
    if (id) navigateToProfile(id, 'profile')
  }

  const handleOnCloseModal = () => {
    setIsLikeModalVisible(false)
    setIsViewedByModalVisible(false)
    setTimeout(() => {
      setPostLikes([])
    }, 300)
  }

  /**
   * Navigating to profiles
   */
  const handleOnClickProfileName = (post: PostProps) => {
    if (post.community && !post.sponsoredPostInfo) {
      if (!pages?.isOn) return
      navigate(`/pages/${post.community.communityUrl}`, {
        state: {
          communityId: post.community.id,
          community: post.community,
        },
      })
    } else {
      const id = post.createdBy?.id
      if (id) navigateToProfile(id, 'profile')
    }
  }
  const handleOnClickTeamName = (post: PostProps) => {
    if (!post.team) return
    const { id, type } = post.team
    if (id) navigateToProfile(id, (type as ProfileType) ?? 'team')
  }
  const handleOnClickHelmetProfile = (
    id?: string,
    profileType?: ProfileType,
    teams?: Team[]
  ) => {
    if (teams) {
      setSetscheduledPostTeams(teams)
      setIsSchedulePostTeamsVisible(true)
    } else {
      if (id && profileType) {
        navigateToProfile(id, profileType)
      }
    }
  }
  const navigateToProfile = (id: string, type: ProfileType) => {
    const _type = type === 'team' || type === 'group' ? `${type}s` : type
    navigate(`/${_type}/${id}`, {
      state: { id },
    })
  }

  const onClickVideoPlaylistCard = useCallback(
    (playlist: VideoPlaylistSummary) => {
      window.location.assign(
        `${environment.urls.syVideo}/playlists/${playlist.id}`
      )
    },
    []
  )

  async function onClickLikeButton(postId: string, isLiked?: boolean) {
    const newLikeState = !isLiked
    const postIndex = _posts.findIndex((p) => p.id === postId)
    if (postIndex > -1) {
      let newNumberOfLikes: number = _posts[postIndex].numberLikes || 0
      if (newNumberOfLikes >= 0) {
        newNumberOfLikes = newLikeState
          ? newNumberOfLikes + 1
          : newNumberOfLikes - 1
      }
      const newPosts = _posts.map((p: PostProps, i: number) =>
        i === postIndex
          ? {
              ...p,
              isLiked: newLikeState,
              numberLikes: newNumberOfLikes,
            }
          : p
      )
      set_posts(newPosts)
    }
    props.onSinglePostUpdate?.(_posts[postIndex] as PostProps)

    timelineHook.postLiked(postId, newLikeState)
  }

  const handleOnClickRemoveVote = async (postId: string) => {
    await timelineHook.pollVote(postId, '')
  }

  const handleOnClickPollChoice = async (
    pollData: PollChoiceReturnData,
    postId: string
  ) => {
    if (!postId) return
    const {
      hasCurrentVote,
      id: choiceId,
      isActive,
      isCreator,
      // votes = [],
    } = pollData

    // Check if the poll is still active or the user is the poll creator
    if (!isActive || isCreator) {
      // Open modal
      choiceId && openPollVotesModal(choiceId, postId)
    } else {
      // if user has not voted, set the vote
      if (!hasCurrentVote) {
        choiceId && (await timelineHook.pollVote(postId, choiceId))
      }
    }
  }

  const handleOnClickMoreComments = async (
    post: PostsWithMultiPosts,
    postsIndex?: number
  ) => {
    // retrieve proper counts for specific post
    const currentComments =
      (typeof postsIndex !== 'undefined' && post.posts
        ? post.posts[postsIndex].comments?.length
        : post.comments?.length) ?? 0
    const totalComments =
      (typeof postsIndex !== 'undefined' && post.posts
        ? post.posts[postsIndex].numberComments
        : post.numberComments) ?? 0

    let page = currentCommentPage

    if (currentComments <= 5 && totalComments <= 5) {
      page = 1
    } else {
      page = Math.ceil(currentComments / COMMENTS_PER_PAGE)
      if (currentComments % 5 === 0 && currentComments < totalComments) {
        page += 1
      }
    }

    // All comments have been loaded
    if (currentComments === totalComments) return

    let postId = post.id

    if (typeof postsIndex !== 'undefined' && post.posts) {
      postId = post.posts?.[postsIndex].id
    }

    if (postId) {
      setIsLoadingComments(true)
      await timelineHook.loadComments(postId, page)
    }
    setIsLoadingComments(false)

    // const thPosts =
    //   ([...(timelineHook.posts ?? [])].map(p =>
    //     p.multiPost
    //       ? {
    //           post: { posts: p.multiPost as PostProps[] },
    //         }
    //       : (p.post as PostProps),
    //   ) as Array<PostsWithMultiPosts>) ?? []

    // console.log({ thPosts })

    // console.log(post.comments)

    // console.log({ page })
    setCurrentCommentPage(page)
    // const { data, error, ok } = await getComments({
    //   postId,
    //   page,
    //   perPage: COMMENTS_PER_PAGE,
    // } as QueryPostCommentsRequest)

    // if (data && ok) {
    //   console.log({ data, page })
    //   // setCurrentPostComments(prevState => [...prevState, ...data])
    //   //   if (data.length === 0) {
    //   //     // still more comments to load, but paging messed up, reset
    //   //     if ((post.numberComments ?? 0) > currentPostComments.length) {
    //   //       setCurrentCommentPage(0)
    //   //     }
    //   // }
    // }
    // if (error) {
    //   console.log({ error })
    // }
  }

  async function handleOnClickToggleComments(post: PostProps) {
    const { id: postId, allowComments } = post
    postId && timelineHook.allowComments(postId, !allowComments)
  }

  // TODO: fix timelinehook not updating state
  const handleOnClickPinPost = async (post: PostProps) => {
    const { id: postId, isPinned = false } = post
    if (!postId) return
    if (!feedId?.startsWith('te-')) return

    sendConfirm({
      title: `${isPinned ? 'Unpin' : 'Pin'} to page`,
      message: `This post will${
        isPinned ? ' no longer' : ''
      } appear at the top for your page.`,
      confirmText: `${isPinned ? 'Unpin' : 'Pin'} Post`,
      onConfirm: async () => {
        const { error, ok } = await timelineHook.pinPost(postId, !isPinned)

        if (ok) {
          sendBanner({
            autoDismiss: true,
            message: `Post ${isPinned ? 'upinned' : 'pinned'} successfully`,
            status: 'success',
          })
          fetchFirstPage(true, undefined, true)
          if (!isPinned) {
            window.scrollTo(0, 0)
          }
        } else if (error) {
          console.log({ error })

          sendBanner({
            autoDismiss: true,
            message: `There was an error pinning this post, please try again later or contact our support team.`,
            status: 'danger',
          })
        }
      },
    })
  }

  async function handleOnClickDeletePost(postId: string, post?: PostProps) {
    if (!postId) return

    sendConfirm({
      confirmText: 'Delete',
      isDestructive: true,
      message: 'Are you sure you want to delete this post?',
      onConfirm: async () => {
        await timelineHook.postDelete(postId)
        onDeletePost?.(post ?? postId)
        // const __posts = [..._posts]
        //   .map(post => {
        //     // check if multi post
        //     if (post.posts) {
        //       // get index of post
        //       const postIndex = post.posts.findIndex(p => p.id === postId)

        //       // filter out posts that match index to delete
        //       const posts = post.posts.filter(p => p.id !== postId)

        //       // if only 1 post in multipost, return as single post
        //       if (posts.length === 1) {
        //         return posts[0]
        //       }
        //       // return updated post
        //       return postIndex !== -1 ? { ...post, posts } : post
        //     }
        //     return post
        //   })
        //   // filter any single posts that match criteria
        //   .filter(post => post.id !== postId)
        // set_posts(__posts)
        removePostFromList(postId)
        sendBanner({
          autoDismiss: true,
          message: 'Post deleted',
          status: 'success',
        })

        // check for team posts with files to update file list
        if (post?.uploads?.some((upload) => isFile(upload?.contentType!))) {
          setTimeout(() => {
            props.onTeamFilesChanged?.()
          }, 5000)
        }

        props.onSinglePostUpdate?.(postId)
      },
    })
  }

  async function handleOnClickDeleteScheduledPost(post: PostsWithMultiPosts) {
    const postsToDelete = post.posts ?? [post]
    const postIds = postsToDelete.map((post) => post.id as string)
    // const { id: postId } = postToDelete
    if (!postIds) return
    sendConfirm({
      confirmText: 'Delete',
      isDestructive: true,
      message: 'Are you sure you want to delete this scheduled post?',
      onConfirm: async () => {
        for (const id of postIds) {
          await timelineHook.postDelete(id)
        }
        onScheduledPostUpdate?.(post)
      },
    })
  }

  async function onClickCreateComment(
    post: PostsWithMultiPosts,
    postId: string,
    message: string
  ) {
    postId && (await timelineHook.addComment(message, postId))
    // postId && (await timelineHook.loadAllComments(postId))

    // const { data, error, ok } = await createComment({ message, postId })
    // if (data && ok) {
    //   setIsCommentCreationSuccessful(true)
    //   setTimeout(() => {
    //     setIsCommentCreationSuccessful(undefined)
    //   }, 5000)

    //   let numOfComments =
    //     (post.posts
    //       ? post.posts.filter(p => p.id === postId)[0].numberComments
    //       : post.numberComments) ?? 0

    //   postId && (await timelineHook.loadComments(postId, 1, numOfComments + 1))

    // if (currentPostComments.length >= 5 && currentCommentPage)
    // console.log({ numOfComments })

    // const { data: newComments } = await getComments({
    //   postId,
    //   page: 1,
    //   perPage: numOfComments + 1,
    // } as QueryPostCommentsRequest)
    // let newPosts: Array<PostsWithMultiPosts> = []

    // let postsIndex = -1
    // let numberComments = (post.numberComments ?? 0) + 1
    // if (post.posts && post.posts.length > 0) {
    //   postsIndex = post.posts.findIndex(p => p.id === postId)
    //   numberComments = (post.posts[postsIndex].numberComments ?? 0) + 1
    // }

    // console.log({ numberComments })

    // _posts.forEach((p, i) => {
    //   if (p.posts) {
    //     newPosts[i] = {
    //       ...p,
    //       posts: p.posts.map(_post => {
    //         if (_post.id === postId) {
    //           return {
    //             ..._post,
    //             numberComments: numberComments,
    //             comments: newComments,
    //           }
    //         }
    //         return _post
    //       }),
    //     }
    //   } else {
    //     newPosts[i] = {
    //       ...p,
    //       comments: newComments,
    //       numberComments,
    //     }
    //   }
    //   // if (!!newPosts[i].posts) {
    //   //   newPosts[i].posts[postsIndex].numberComments = numberComments
    //   // }

    //   // if (p.posts) {
    //   //   const _mp = p.posts.map(p => {
    //   //     if (p.id === postId) {
    //   //       p.numberComments = numOfComments + 1
    //   //     }
    //   //     return p
    //   //   })
    //   //   const postIndex = p.posts.findIndex(p => p.id === postId)
    //   //   const x = (p.posts[postIndex].numberComments = numOfComments + 1)
    //   //   newPosts[i] = {
    //   //     ...p,
    //   //     posts: _mp ?? undefined,
    //   //     numberComments: !post.posts ? numOfComments + 1 : undefined,
    //   //   }
    //   //   // if p.posts
    //   // }
    //   //   newPosts[i] = {
    //   //     ...p,
    //   //     comments: postId === p.id ? newComments : p.comments,
    //   //     numberComments:
    //   //       postId === p.id ? (p.numberComments ?? 0) + 1 : p.numberComments,
    //   //   }
    // })
    // set_posts(newPosts)
    // console.log({ newComments })
    // if (newComments) {
    //   setCurrentPostComments((newComments as Array<Comment>) ?? [])
    // }
    // }
    // if (error) {
    //   console.log({ error })
    //   // setIsCommentCreationSuccessful(false)
    //   sendBanner({
    //     autoDismiss: true,
    //     text: error.toString(),
    //     status: 'danger',
    //   })
    // }
  }

  const handleOnClickEditPost = (post: PostProps) => {
    setCurrentPost(post)
    setIsEditPostVisible(true)
  }

  const onClickEditScheduledPost = (post: PostProps) => {
    setCurrentPost(post)
    dispatch(
      setPostComposer({
        post: {
          ...post,
          //@ts-ignore
          id: post.posts[0].id,
        },
        initialView: post.uploads?.length ? 'attachment' : 'message',
        uploads: post.uploads,
      })
    )
    dispatch(showPostComposer())
  }

  const handleOnCancelEditPost = () => {
    setIsEditPostVisible(false)
    setTimeout(() => {
      setCurrentPost({})
    }, 300)
  }

  const handleOnClickFollow = async (
    postId: string,
    post: PostsWithMultiPosts
  ) => {
    if (post.community?.isAdmin) return
    const postIndex = _posts.findIndex((p) => p.id === postId)
    if (postIndex > -1) {
      const post = _posts[postIndex]
      const newFollowState = !post.community?.isFollowing
      const newPosts = _posts.map((p: PostProps, i: number) =>
        i === postIndex
          ? {
              ...p,
              community: {
                ...p.community,
                isFollowing: newFollowState,
              },
            }
          : p
      )
      set_posts(newPosts)

      if (newFollowState) {
        followCommunity({
          communityId: post.community?.id!,
          userId: currentUser.id,
        })
        // sendBanner({
        //   autoDismiss: true,
        //   text: 'You are now following this community',
        //   status: 'success',
        // })
      } else {
        // TODO: Should this be wrapped in confirm dialog?
        // sendConfirm({
        //   confirmButtonText: 'Unfollow',
        //   onConfirm: async () => {
        if (!post.community?.id) return
        await unfollowCommunity({
          communityId: post.community?.id!,
          userId: currentUser.id,
        })
        dispatch(removePage(post.community))
        // dispatch(removeFeed(post.community.id))
        //   },
        //   text: `Are you sure you want to unfollow **${post.community?.name}**?`,
        //   title: `Unfollow ${post.community?.name}`,
        //   useMarkdown: true,
        // })
        // sendBanner({
        //   autoDismiss: true,
        //   text: 'You have unfollowed this community',
        //   status: 'success',
        // })
      }
    }
  }

  const handleOnSaveEditPost = async (message: string) => {
    if ((currentPost as ScheduledPost).scheduleId) {
      onScheduledPostUpdate?.(currentPost)
    }

    let id = currentPost.id
    if (!id && currentPost.posts?.length) {
      id = currentPost.posts[0].id
    }
    if (!id) return

    const { data, error, ok } = await timelineHook.postUpdated(id, message)
    if (error) {
      console.log({ error })
    }
    if (data && ok) {
      let postIndex = _posts.findIndex((p) => p.id === currentPost.id)

      const newPosts = _posts.map((p: PostProps, i: number) =>
        i === postIndex
          ? {
              ...p,
              message,
              isEdited: true,
            }
          : p
      )

      set_posts(newPosts)
      setCurrentPost({})
    }

    setIsEditPostVisible(false)

    // const onPostEdit = useCallback(
    //   async (post: PostProps, newMessage: string) => {
    //     let newPosts = [...(posts ?? [])]
    //     let res = await timelineHook.postUpdated(post.id!, newMessage)
    //     if (res.ok) {
    //       toast(t(I18N.POST_EDIT_TOAST))
    //       const index = post.index ?? 0
    //       let p = findPost(newPosts[index], post.id!)
    //       p.message = newMessage
    //       p.isEdited = true
    //       setPosts(newPosts)
    //     }
    //     return res.ok
    //   },
    //   [timelineHook.postUpdated],
    // )
  }

  const onClickViewUpload = useCallback(
    (upload?: any) => {
      const items = upload.allUploads ?? [upload]
      const index = upload.currentIndex ?? 0
      showLightboxWithItems({ items, index })
    },
    [showLightboxWithItems]
  )

  const onClickSaveUpload = useCallback(
    async (upload?: any) => {
      const folderId = await createFolderItem({
        folderName: upload.fileName,
        uploadId: upload.id,
      })
      if (folderId) {
        sendBanner({
          autoDismiss: true,
          message: 'Saved to Folders',
          status: 'success',
        })
      }
    },
    [createFolderItem, sendBanner]
  )

  const onClickDownloadUpload = useCallback(
    (upload?: any) => {
      if (upload) {
        downloadItem(upload)
      }
    },
    [downloadItem]
  )

  const onDeleteComment = async (postId: string, id?: string) => {
    // todo make comment id explicit
    const status = await timelineHook.removeComment(postId, id ?? '')
    if (status === 'ok') {
      sendBanner({
        autoDismiss: true,
        status: 'success',
        message: 'Comment has been removed',
      })
    } else if (status === 'error') {
      sendBanner({
        autoDismiss: true,
        status: 'danger',
        message: 'Error removing comment',
      })
    }

    props.onSinglePostUpdate?.(postId)

    return status
  }

  const findPostInList = useCallback(
    (postId: string) => {
      let postIndex = _posts.findIndex((p) => p.id === postId)
      let post = _posts[postIndex]
      let multipostIndex = -1
      if (!post) {
        // or, look inside multiposts to find a matching post
        for (const [i, p] of _posts.entries()) {
          if (p.posts) {
            postIndex = p.posts.findIndex((p) => p.id === postId)
            if (postIndex > -1) {
              post = p.posts[postIndex]
              multipostIndex = i
              break
            }
          }
        }
      }
      return { post, postIndex, multipostIndex }
    },
    [_posts]
  )

  const setValueOnPostInList = useCallback(
    (postId: string, key: string, value: any) => {
      const { post, postIndex, multipostIndex } = findPostInList(postId)
      if (!post) return
      let newPosts = [...(_posts ?? [])]
      if (multipostIndex > -1) {
        newPosts[multipostIndex] = {
          ...newPosts[multipostIndex],
          posts: [
            ...(newPosts[multipostIndex].posts ?? []).filter(
              (p) => p.id !== postId
            ),
            { ...post, [key]: value },
          ],
        }
      } else {
        newPosts[postIndex] = { ...post, [key]: value }
      }
      set_posts(newPosts)
    },
    [_posts, findPostInList]
  )

  const removePostFromList = useCallback(
    (postId: string) => {
      const { post, postIndex, multipostIndex } = findPostInList(postId)
      if (!post) return
      let newPosts = [..._posts]
      if (multipostIndex > -1) {
        newPosts[multipostIndex] = {
          ...newPosts[multipostIndex],
          posts: (newPosts[multipostIndex].posts ?? []).filter(
            (p) => p.id !== postId
          ),
        }
      } else {
        newPosts.splice(postIndex, 1)
      }
      set_posts(newPosts)
    },
    [_posts, findPostInList]
  )

  const handleOnApprovePost = async (postId: string) => {
    const { post } = findPostInList(postId)
    if (!post) return
    await timelineHook.postApprove(post.id!, post.team?.id ?? undefined)
    setValueOnPostInList(postId, 'isPending', false)
    sendBanner({
      autoDismiss: true,
      status: 'success',
      message: 'Post approved',
    })
    // check for team posts with files to update file list
    if (post?.uploads?.length) {
      if (post?.uploads?.some((upload) => isFile(upload?.contentType!))) {
        props.onTeamFilesChanged?.()
      }
    }

    // props.onSinglePostUpdate?.(postId)
  }

  const handleOnRejectPost = async (postId: string) => {
    const { post } = findPostInList(postId)
    if (!post) return
    await timelineHook.postDisapprove(post.id!)
    removePostFromList(postId)
    sendBanner({
      autoDismiss: true,
      status: 'success',
      message: 'Post rejected',
    })

    props.onSinglePostUpdate?.(postId)
  }

  const handleInitReport = async (
    post: PostProps,
    isComment: boolean,
    params?: ReportCommentParams
  ) => {
    setCurrentPostId(post.id ?? undefined)
    if (isComment && params) {
      setReportCommentParams(params)
    }
    setIsReportModalVisible(true)
    // const onPostReport = useCallback(
    //   async (
    //     targetId: string,
    //     post: PostProps,
    //     commentId: string,
    //     reason: string,
    //   ) => {
    //     let res = await timelineHook.createComplaint(
    //       targetId,
    //       post.id!,
    //       undefined,
    //       reason,
    //     )
    //     if (res.ok) {
    //       toast(t(I18N.POST_REPORT_TOAST))
    //     }
    //     return res.ok
    //   },
    //   [],
    // )
  }

  // const handleInitReportComment = (
  //   post: PostProps,
  //   params: ReportCommentParams,
  // ) => {
  //   setIsReportModalVisible(true)
  //   setReportCommentParams(params)
  //   setCurrentPostId(post.id ?? undefined)
  // }

  const handleOnReportComment = async (message: string) => {
    // if (!reportCommentParams || !currentPostId) return
    if (!currentPostId) return

    const post = _posts.filter((p) => p.id === currentPostId)[0]

    const targetId = reportCommentParams?.targetId ?? post.createdBy?.id
    const commentId = reportCommentParams?.commentId ?? undefined

    // TODO: Error handler
    if (!targetId) return

    const response = await timelineHook.createComplaint(
      targetId,
      currentPostId,
      commentId,
      message
    )

    if (response.ok) {
      sendBanner({
        autoDismiss: true,
        status: 'success',
        message: 'Report has been submitted to the administrator. Thank you!',
      })
    }
    return response.ok

    //   async function onReportPost(
    //     targetId: string,
    //     post: PostProps,
    //     commentId: string,
    //     reason: string,
    //   ) {
    //     if (post.id) {
    //       let res = await timelineHook.createComplaint(
    //         targetId,
    //         post.id,
    //         commentId,
    //         reason,
    //       )
    //       if (res.ok) {
    //         toast(t(I18N.POST_REPORT_TOAST))
    //       }
    //       return res.ok
    //     }
    //   }

    //   function onReportComment(id: string, reason: string) {
    //     if (id === '') {
    //       return
    //     }
    //     for (let i = 0; i < comments.length; i++) {
    //       if (comments[i].id === id && post) {
    //         return onReportPost(
    //           comments[i].createdBy?.id ?? '',
    //           post,
    //           id,
    //           reason,
    //         )
    //       }
    //     }
    //   }
  }

  const handleOnCloseReportModal = () => {
    setIsReportModalVisible(false)
    setCurrentPostId(undefined)
    setReportCommentParams(undefined)
  }

  const handleOnClosePollModal = () => {
    setIsPollModalVisible(false)
    // setCurrentPollData({
    //   index: -1,
    //   choices: [],
    // })
  }

  const handlePostViewed = (postId?: string) => {
    if (postId) timelineHook.postViewed(postId)
  }

  async function handleHidePost(postId: string) {
    if (!postId) return

    sendConfirm({
      message: 'Are you sure you want to hide this post?',
      confirmText: 'Hide Post',
      onConfirm: async () => {
        const { error, ok } = await timelineHook.postHide(postId)
        if (ok) {
          // const __posts = [..._posts].filter(post => post.id !== postId)
          // set_posts(__posts)
          removePostFromList(postId)
          sendBanner({
            autoDismiss: true,
            message: 'Post hidden successfully',
            status: 'success',
          })
        } else if (error) {
          console.warn(error)
          sendBanner({
            autoDismiss: true,
            message: `There was an error hiding this post, please try again later or contact our support team.`,
            status: 'danger',
          })
        }

        props.onSinglePostUpdate?.(postId)
      },
    })
  }

  // const handleOnClickAdFeedback = () => {
  //   window.open(URLS.AD_FEEDBACK, '_blank', 'noreferrer')
  // }

  const handleOnClickHideAd = async (id: string) => {
    const { data: reasons, error } = await getPostFeedbackReasons(
      {} as QueryPostFeedbackReasonsRequest
    )

    if (reasons) {
      setCurrentPostId(id)
      setHideAdReasons(reasons)
      setIsHideAdModalVisible(true)
    }

    if (error) {
      console.log({ error })
    }
  }

  // update feeed
  const handleOnClickHideAdSubmit = (id: string) => {
    const newPosts = _posts.filter((p: PostProps) => p.id !== id)
    set_posts(newPosts)
    if (feedId) {
      dispatch(setFeed({ id: feedId, posts: newPosts }))
    } else {
      dispatch(initializeFeed(newPosts))
    }
  }

  // const getPostFromStore = (postId: string) =>
  //   feedId
  //     ? reduxPosts[feedId].filter(p => p.id === postId)[0]
  //     : reduxPosts.main.filter(p => p.id === postId)[0]

  const renderRow = (index: number, item: PostsWithMultiPosts) => {
    let key = item.id ?? (item as ScheduledPost).scheduleId ?? index
    if (item.posts) {
      // make key for multipost
      key = item.posts?.map((p) => p.id).join(',')
    }
    // let comments = item.comments
    // if (item.id) {
    //   const storePost = getPostFromStore(item.id)
    //   if ((storePost.comments ?? []).length > (comments ?? []).length) {
    //     comments = storePost.comments
    //   }
    // }
    return (
      <StyledPost
        {...item}
        activeCommentId={commentId}
        autoFocusCommentInput={false}
        canHidePost={!feedId?.startsWith('te-')}
        className='ListItem'
        // comments={comments}
        // comments={currentPostComments}
        hideMenu={!item.sponsoredPostInfo && isCommunity}
        hideFollowButton={isCommunity}
        isFetchingCount={isFetchingCount}
        isFetchingMoreComments={isLoadingComments}
        onHoverLikeCount={(index) => fetchNamesWhoLiked(item, index)}
        onHoverCommentCount={(index) => fetchNamesWhoCommented(item, index)}
        namesWhoCommented={namesWhoCommented}
        namesWhoLiked={namesWhoLiked}
        currentUserId={currentUser.id!}
        disableLikesModal={!!item.sponsoredPostInfo}
        key={key}
        newCommentSuccessful={isCommentCreationSuccessful}
        // onClickAdFeedback={handleOnClickAdFeedback}
        onClickApprove={handleOnApprovePost}
        onClickCommentAuthor={(id) => navigateToProfile(id, 'profile')}
        onClickCommentCount={(postsIndex) =>
          handleOnClickPostComments(item, postsIndex)
        }
        onClickCreateComment={(postId, message) =>
          onClickCreateComment(item, postId, message)
        }
        onClickDelete={handleOnClickDeletePost}
        onClickDeleteScheduledPost={() =>
          handleOnClickDeleteScheduledPost(item)
        }
        onClickDownloadUpload={onClickDownloadUpload}
        onClickEdit={handleOnClickEditPost}
        onClickEditScheduledPost={() => onClickEditScheduledPost(item)}
        onClickFollow={(id) => handleOnClickFollow(id, item)}
        onClickHelmetProfile={({ id, profileType, teams }) =>
          handleOnClickHelmetProfile(id, profileType, teams)
        }
        onClickHide={handleHidePost}
        onClickHideAd={handleOnClickHideAd}
        onClickLikeButton={onClickLikeButton}
        onClickLikeCount={(postsIndex) =>
          handleOnClickPostLikes(item, postsIndex)
        }
        onClickMoreComments={(postsIndex) =>
          handleOnClickMoreComments(item, postsIndex)
        }
        onClickPollChoice={(data, id) => handleOnClickPollChoice(data, id)}
        onClickProfileName={() => handleOnClickProfileName(item)}
        onClickReject={handleOnRejectPost}
        onClickRemoveVote={handleOnClickRemoveVote}
        onClickReport={() => handleInitReport(item, false, undefined)}
        onClickSaveUpload={onClickSaveUpload}
        onClickTeamProfile={() => handleOnClickTeamName(item)}
        onClickToggleComments={handleOnClickToggleComments}
        onClickTogglePin={() => handleOnClickPinPost(item)}
        onClickUpload={onClickViewUpload}
        onClickVideoPlaylistCard={onClickVideoPlaylistCard}
        onClickViewedBy={(viewed, postsIndex) =>
          handleOnClickViewedBy(item, viewed, postsIndex)
        }
        onClickViewUpload={onClickViewUpload}
        onDeleteComment={onDeleteComment}
        onReportComment={(params) => handleInitReport(item, true, params)}
        onViewed={handlePostViewed}
        onExpand={handlePostViewed}
        showCommentListComponent={showComments}
        sortComments='ascending'
      />
    )
  }

  const renderHeaderElement = useCallback(() => {
    return renderHeader?.() ?? null
  }, [renderHeader])

  // const renderPostListFooter = useCallback(() => {

  //   if (posts.length < POST_LIST_PAGE_SIZE) {
  //     return null
  //   }
  //   if (posts.length / POST_LIST_PAGE_SIZE > 1) {
  //     return <>That's all!</>
  //   }
  //   return null
  // }, [posts, isLoading])

  const renderFooterElement = useCallback(() => {
    if (isLoading) {
      return <Activity position='fixed' />
    }
    return renderFooter ? renderFooter() : null
  }, [isLoading, renderFooter])

  const renderEmptyState = useCallback(() => {
    if (isLoading) return null
    if (_posts.length === 0) {
      // main feed
      if (props.id === 'main' && !currentUser.preferences?.showWelcomeCard) {
        return (
          <EmptyState
            header='See and Share Posts'
            icon='HomeES'
            iconContainerStyle={{ alignSelf: 'flex-start' }}
            listItems={[
              'Click **Share a post** to create post.',
              'In left column, click **Join Team/Group** button to enter access code.',
              'In the left column, click **Create** to add a team or a group.',
            ]}
            style={{ width: '100%' }}
            useMarkdown
          />
        )
      }
      if (props.id === currentUser.id) {
        return (
          <EmptyState
            header='Manage Your Profile'
            icon='UserSolid'
            iconContainerStyle={{ alignSelf: 'flex-start', marginRight: 15 }}
            iconSize={36}
            listItems={[
              'In far right, click **gear icon** to edit your account information',
              'Above your name, hover on photo space and click to add cover photo.',
              'Next to your name, hover on photo circle and click to add profile photo.',
            ]}
            style={{ padding: 15, width: '100%' }}
            useMarkdown
          />
        )
      }
    }
    return null
  }, [
    _posts.length,
    currentUser.id,
    currentUser.preferences?.showWelcomeCard,
    isLoading,
    props.id,
  ])

  return (
    <FadeInContainer className={className} style={style}>
      {renderEmptyState()}
      <Virtuoso
        components={{
          Header: renderHeaderElement,
          Footer: renderFooterElement,
        }}
        data={_posts}
        endReached={loadMorePosts}
        itemContent={renderRow}
        overscan={overscan ?? 100}
        useWindowScroll
      />
      <EditPostModal
        isVisible={isEditPostVisible}
        onCancel={handleOnCancelEditPost}
        onSave={(message) => handleOnSaveEditPost(message)}
        post={currentPost}
        user={currentUser}
      />
      <PostCommentsModal
        comments={currentPostComments}
        isVisible={isCommentModalVisible}
        onClose={() => setIsCommentModalVisible(false)}
      />
      <PostLikeModal
        isVisible={isLikeModalVisible}
        likes={postLikes}
        onClose={handleOnCloseModal}
      />
      <PostViewedModal
        isVisible={isViewedByModalVisible}
        notViewed={postViewed.notViewed}
        onClickChat={(user) => handleOnChatUser(user)}
        onClickUser={(user) => handleOnClickUser(user)}
        onClose={handleOnCloseModal}
        viewed={postViewed.viewed}
      />
      <ReportUserModal
        isVisible={isReportModalVisible}
        onClose={handleOnCloseReportModal}
        {...reportCommentParams}
        onReport={handleOnReportComment}
      />
      <PollVotesModal
        data={currentPollData}
        isVisible={isPollModalVisible}
        onClose={handleOnClosePollModal}
      />
      <HideAdModal
        isVisible={isHideAdModalVisible}
        onClose={() => {
          setIsHideAdModalVisible(!isHideAdModalVisible)
        }}
        onSubmit={handleOnClickHideAdSubmit}
        postId={currentPostId}
        reasons={hideAdReasons}
      />

      {/* TODO convert to component */}
      <Modal
        onClose={() => setIsSchedulePostTeamsVisible(false)}
        visible={isSchedulePostTeamsVisible}
      >
        <Modal.Header>
          <b>Teams/Groups</b>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          {scheduledPostTeams?.map((team: Team) => (
            <TeamRow
              key={team.id}
              onClick={() => {
                setIsSchedulePostTeamsVisible(false)
                navigate(`/${team.type}s/${team.id}`)
              }}
            >
              <Avatar
                name={team.name ?? `sportsYou ${team.type}`}
                diameter={44}
                uri={team.profileImage?.[0]?.viewUrl ?? undefined}
              />

              <TeamName>{team.name}</TeamName>
            </TeamRow>
          ))}
        </Modal.Body>
      </Modal>
    </FadeInContainer>
  )
}

// React-virtuoso wraps each item into a seperate div so the post component
// will not render margins properly. Adding margin-bottom should make spacing
// consistent throughout the list.
const StyledPost = styled(Post)`
  margin-bottom: 10px;
`

const TeamRow = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px;
  transition: 120ms ease-in-out background-color;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const TeamName = styled.p`
  margin-left: 10px;
`

export default PostList
