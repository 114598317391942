import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ChatBoxProps {
  chatId: string
  minimized?: boolean
}

export interface ChatBoxSliceProps {
  boxes: ChatBoxProps[]
  chatsScreenActiveChatId?: string
  focusedChatBoxId?: string
  leftChatIds?: string[]
  pressEnterToSend?: boolean
}

const initialState: ChatBoxSliceProps = {
  boxes: [],
  chatsScreenActiveChatId: undefined,
  focusedChatBoxId: undefined,
  leftChatIds: [],
  pressEnterToSend: true,
}

const ChatBoxSlice = createSlice({
  name: 'chatBoxes',
  initialState: initialState,
  reducers: {
    addChatBox(state: ChatBoxSliceProps, action: PayloadAction<ChatBoxProps>) {
      if (state.boxes.find((box) => box.chatId === action.payload.chatId)) {
        return
      }
      if (state.leftChatIds?.includes(action.payload.chatId)) {
        // ignore adding chat box if the user has left the chat
        return
      }
      state.boxes.push(action.payload)
    },
    removeChatBox(state, action: PayloadAction<ChatBoxProps>) {
      state.boxes = state.boxes.filter(
        (box) => box.chatId !== action.payload.chatId
      )
    },
    updateChatBox(state, action: PayloadAction<ChatBoxProps>) {
      console.log('updateChatBox', action.payload)
      const index = state.boxes.findIndex(
        (box) => box.chatId === action.payload.chatId
      )
      if (index !== -1) {
        state.boxes[index] = action.payload
      }
    },
    setFocusedChatBox(state, action: PayloadAction<ChatBoxProps>) {
      state.focusedChatBoxId = action.payload.chatId
    },
    setPressEnterToSend(state, action: PayloadAction<boolean>) {
      state.pressEnterToSend = action.payload
    },
    setChatsScreenActiveChatId: (state, action) => {
      state.chatsScreenActiveChatId = action.payload
    },
    logChatLeft: (state, action) => {
      if (!state.leftChatIds) {
        state.leftChatIds = []
      }
      state.leftChatIds.push(action.payload)
    },
    clearChatBoxes(state) {
      state.boxes = initialState.boxes
      state.chatsScreenActiveChatId = initialState.chatsScreenActiveChatId
      state.focusedChatBoxId = initialState.focusedChatBoxId
      state.leftChatIds = initialState.leftChatIds
      // state.pressEnterToSend = initialState.pressEnterToSend // TODO: should we reset this on logout?
    },
  },
})

export const {
  addChatBox,
  clearChatBoxes,
  logChatLeft,
  removeChatBox,
  setChatsScreenActiveChatId,
  setFocusedChatBox,
  setPressEnterToSend,
  updateChatBox,
} = ChatBoxSlice.actions
export default ChatBoxSlice.reducer
