import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import { Chat, ChatParticipant, queryChats } from '@sportsyou/api'

import { CHAT_TYPE_TEAM } from '../../constants'
import { ChatListChat, ChatListStateProps } from './types'
import { preProcessSingleChat } from '../../utils/PreProcessChat'
import { RootState } from '../rootReducer'
import { setChatsScreenActiveChatId } from './ChatBoxSlice'
import buildChatListAvatarProps from '../../utils/BuildChatListAvatarProps'

const initialState: ChatListStateProps = {
  chatList: [],
  chatListScreenList: [],
  recentChatUsers: [],
}

function sortChatByDateDesc(chatA: ChatListChat, chatB: ChatListChat): number {
  const chatAModifiedAt = moment(
    chatA.modifiedAt || chatA.createdAt || 0
  ).valueOf()
  const chatBModifiedAt = moment(
    chatB.modifiedAt || chatB.createdAt || 0
  ).valueOf()
  return chatBModifiedAt - chatAModifiedAt
}

function getRecentChatUsers(chatList: ChatListChat[]): ChatParticipant[] {
  const recentChats = [...chatList]
  let recentChatUsers: ChatParticipant[] = []
  for (const chat of recentChats) {
    if (chat.chatType !== CHAT_TYPE_TEAM) {
      const recentChatUserIds = recentChatUsers.map((user) => user.id)
      const chatUsers: ChatParticipant[] =
        (chat?.participants?.filter(
          (user) =>
            !user?.isCurrentUser &&
            !recentChatUserIds.includes(user?.id) &&
            !!user?.isActive &&
            !!user.isExisting
        ) as ChatParticipant[]) ?? []
      recentChatUsers = [...recentChatUsers, ...chatUsers]
    }
  }

  return recentChatUsers
}

const chatList = createSlice({
  name: 'chatList',
  initialState,
  reducers: {
    initializeChatList(state) {
      state.chatList = []
      state.chatListScreenList = []
      state.recentChatUsers = []
    },
    setChatList(state, action: PayloadAction<ChatListChat[]>) {
      state.chatList = action.payload
    },
    updateChatList(state, action: PayloadAction<ChatListChat | Chat>) {
      const chat = action.payload
      const chatList = [...state.chatList]
      const chatIndex = chatList.findIndex(
        (chatItem) => chatItem?.id === chat?.id
      )
      if (chatIndex > -1) {
        chatList[chatIndex] = chat
      } else {
        chatList.unshift(chat)
      }
      state.chatList = chatList
    },
    refreshChatListItem(state, action: { payload: Chat }) {
      const { payload: chat } = action
      const chatListScreenList = [...state.chatListScreenList]
      const chatIndex = chatListScreenList.findIndex((c) => c.id === chat.id)
      if (chatIndex > -1) {
        const processedChat = preProcessSingleChat(chat)
        const chatListScreenListItem = {
          avatars: buildChatListAvatarProps(processedChat),
          createdAt: processedChat.createdAt as string,
          hasNewMessage: processedChat.hasNewMessage as boolean,
          id: processedChat.id as string,
          isMuted: processedChat.isMuted as boolean,
          lastMessage: processedChat.lastMessage as string,
          modifiedAt: processedChat.modifiedAt as string,
          title: processedChat.chatName as string,
          token: processedChat.token as string,
        }
        chatListScreenList[chatIndex] = chatListScreenListItem
        chatListScreenList.sort(sortChatByDateDesc)
        state.chatListScreenList = chatListScreenList
      }
    },
    refreshChatList(state, action: { payload: Chat[] }) {
      const newItems: any[] | undefined = action.payload?.map((item) => {
        const _item = { ...item }
        const processedItem = preProcessSingleChat(_item)
        return {
          avatars: buildChatListAvatarProps(processedItem),
          createdAt: processedItem.createdAt as string,
          hasNewMessage: processedItem.hasNewMessage as boolean,
          id: processedItem.id as string,
          isMuted: processedItem.isMuted as boolean,
          lastMessage: processedItem.lastMessage as string,
          modifiedAt: processedItem.modifiedAt as string,
          title: processedItem.chatName as string,
          token: processedItem.token as string,
        }
      })
      if (!isEqual(state.chatListScreenList, newItems)) {
        state.chatListScreenList = newItems
      }
    },
    removeChatFromList(state, action: PayloadAction<{ chatToken: string }>) {
      const { payload } = action
      if (payload.chatToken) {
        const chatId = state.chatList.find(
          (chat: ChatListChat) => chat?.token === payload.chatToken
        )?.id
        if (chatId) {
          const newChatList = state.chatList.filter(
            (chat: ChatListChat) => chat?.id && chat.id !== chatId
          )
          state.chatList = newChatList

          const newChatListScreenList = state.chatListScreenList.filter(
            (chat: ChatListChat) => chat?.id && chat.id !== chatId
          )
          state.chatListScreenList = newChatListScreenList

          try {
            const recentChatUsers = getRecentChatUsers(newChatList)
            state.recentChatUsers = recentChatUsers
          } catch (err) {
            console.log({ err })
          }
        }
      }
    },
  },
})

export const {
  initializeChatList,
  refreshChatList,
  refreshChatListItem,
  removeChatFromList,
  setChatList,
  updateChatList,
} = chatList.actions
export default chatList.reducer

export const selectChatList = (state: RootState) => state.chatList.chatList

export const selectChatListScreenList = (state: RootState) => {
  return state.chatList.chatListScreenList
}

export const fetchChats =
  (resetActiveChatId = true) =>
  async (dispatch: Dispatch) => {
    const { data } = await queryChats()
    if (data) {
      dispatch(setChatList(data as ChatListChat[]))
      dispatch(refreshChatList(data as Chat[]))
      if (resetActiveChatId) {
        dispatch(setChatsScreenActiveChatId(data?.[0]?.id ?? undefined))
      }
    }
    return data
  }
