import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'

import { IntercomProvider } from 'react-use-intercom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'

// import { GoogleOAuthProvider } from '@react-oauth/google'

import App from './app'

// Import custom fonts
// import './fonts.css'

import { DialogProvider, ToastProvider } from '@sportsyou/react-dom-ui'

import { AppFeaturesProvider } from './app/hooks/useAppFeatures'
import { GlobalStyle } from './styles/global-styles'

import 'sanitize.css'

// Initialize languages
import './locales/i18n'

// Polyfills
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './wdyr'
import { Colors } from '@sportsyou/core'
import { configureAppStore } from './store/configureStore'
import { environment } from './environments/environment'

const INTERCOM_APP_ID = environment.intercomKey ?? ''

const store = configureAppStore()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const APP_FEATURES = [
  {
    appKey: 'pages',
    key: 'pages_enabled',
  },
]

const INTERCOM_AUTOBOOT_PROPS = {
  backgroundColor: Colors.PUNCH,
}

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <StrictMode>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
          autoBootProps={INTERCOM_AUTOBOOT_PROPS}
        >
          {/* <GoogleOAuthProvider clientId={process.env.NX_GOOGLE_OAUTH_CLIENT_ID}> */}
          <AppFeaturesProvider features={APP_FEATURES}>
            <DialogProvider>
              <ToastProvider placement='bottomStart'>
                <App />
              </ToastProvider>
              <GlobalStyle />
            </DialogProvider>
          </AppFeaturesProvider>
          {/* </GoogleOAuthProvider> */}
        </IntercomProvider>
      </StrictMode>
    </HelmetProvider>
  </Provider>
)
