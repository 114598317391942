import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import {
  CalendarImport as CalImport,
  mutationCalendarImport,
  mutationCalendarImportDelete,
  MutationCalendarImportDeleteRequest,
  MutationCalendarImportRequest,
  mutationCalendarImportResync,
  MutationCalendarImportResyncRequest,
  mutationCalendarImportUpdate,
  MutationCalendarImportUpdateRequest,
  queryCalendarImports,
  QueryCalendarImportsRequest,
  queryCalendarImportStatus,
  Team,
  Upload,
} from '@sportsyou/api'
import { Colors, isUrlValid, pluralize } from '@sportsyou/core'
import {
  BannerProps,
  BORDER_RADIUS,
  Button,
  Checkbox,
  FileInput,
  Modal,
  Radio,
  Select,
  Spinner,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'

interface CalendarImportProps {
  isOpen: boolean
  onClose: () => void
  onRefreshCalendars?: () => void
  team?: Team
}

export default function CalendarImport(props: CalendarImportProps) {
  const { isOpen, team } = props
  const { sendBanner, sendConfirm } = useDialog()

  const [currentImports, setCurrentImports] = useState<Array<CalImport>>([])
  const [eventType, setEventType] = useState('events')
  const [includeReminders, setIncludeReminders] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [upload, setUpload] = useState<Upload>()
  const [url, setUrl] = useState('')

  const timers = useRef<{ [key: string]: NodeJS.Timeout }>({})

  const { fetch: calendarImportUpdate } = useFetchApi(
    mutationCalendarImportUpdate
  )
  const { fetch: deleteImport } = useFetchApi(mutationCalendarImportDelete)
  const { fetch: getCalendarImports } = useFetchApi(queryCalendarImports)
  const { fetch: getImportStatus } = useFetchApi(queryCalendarImportStatus)
  const { fetch: importCalendar } = useFetchApi(mutationCalendarImport)
  const { fetch: resyncImport } = useFetchApi(mutationCalendarImportResync)

  const onClose = useCallback(() => {
    props.onClose()
    setCurrentImports([])
    setEventType('events')
    setIncludeReminders(false)
    setUpload(undefined)
    setUrl('')
  }, [props])

  const fetchImports = useCallback(async () => {
    const { data, error, ok } = await getCalendarImports({
      teamId: team?.id,
    } as QueryCalendarImportsRequest)

    if (data && ok) {
      setCurrentImports(data)
    }
    if (error) {
      console.log({ error })
    }
  }, [getCalendarImports, team?.id])

  const checkImportStatus = useCallback(
    async (importId: string) => {
      const { data, error, ok } = await getImportStatus({
        importId,
      } as MutationCalendarImportUpdateRequest)

      if (data && ok) {
        setCurrentImports((prev) => {
          const index = prev.findIndex((p) => p.id === importId)
          if (index > -1) {
            prev[index] = data
          }
          return [...prev]
        })

        const status = data.status
        if (status === 'Importing' && isOpen) {
          timers.current[importId] = setTimeout(() => {
            checkImportStatus(importId)
          }, 1000)
        } else {
          fetchImports()
        }
        if (status === 'Successful') {
          sendBanner({
            autoDismiss: true,
            dismissTime: 6000,
            status: 'success',
            message: 'Calendar import complete',
          })
          fetchImports()
          props.onRefreshCalendars?.()
        }
        if (status === 'Error') {
          sendBanner({
            autoDismiss: true,
            status: 'danger',
            message: 'Calendar import failed',
          })
          fetchImports()
        }
      }
      if (error) {
        console.log({ error })
      }
    },
    [fetchImports, getImportStatus, isOpen, props, sendBanner]
  )

  const onClickImport = useCallback(async () => {
    const { ok } = await importCalendar({
      eventType,
      importNotifications: includeReminders,
      teamId: team?.id,
      uploadId: upload?.id,
      url,
    } as MutationCalendarImportRequest)

    if (ok) {
      sendBanner({
        autoDismiss: true,
        dismissTime: 6000,
        status: 'success',
        message:
          'Calendar import started your events will appear on your calendar shortly',
      })
      fetchImports()
      setUrl('')
      setUpload(undefined)
    }
  }, [
    eventType,
    fetchImports,
    importCalendar,
    includeReminders,
    sendBanner,
    team?.id,
    upload?.id,
    url,
  ])

  const onUploaderUploadStart = useCallback(
    (_newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      setUpload(currentUpload)
      setIsUploading(true)
    },
    []
  )

  const onUploaderUploadDone = useCallback(
    (_newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      setUpload(currentUpload)
    },
    []
  )

  const onTranscodeComplete = useCallback(
    (completeUpload: ExtendedUpload | undefined): void => {
      setUpload(completeUpload)
      setIsUploading(false)
    },
    []
  )

  const onUploaderCancel = useCallback((): void => {
    setUpload(undefined)
    setIsUploading(false)
  }, [setUpload])

  const onUploaderError = useCallback((): void => {
    setUpload(undefined)
    setIsUploading(false)
  }, [setUpload])

  const isValid = useMemo(() => {
    if (!isUploading && !!upload?.id?.length) {
      return true
    }
    return isUrlValid(url)
  }, [upload, isUploading, url])

  const removeCalendarFromImports = (id: string) => {
    setCurrentImports((prev) => prev.filter((p) => p.id !== id))
  }

  const handleDeleteImport = useCallback(
    (id?: string) => {
      if (!id) return
      sendConfirm({
        confirmText: 'Delete',
        isDestructive: true,
        onConfirm: async () => {
          const { data, error, ok } = await deleteImport({
            importId: id,
          } as MutationCalendarImportDeleteRequest)
          if (data && ok) {
            sendBanner({
              autoDismiss: true,
              message: 'Calendar import deleted successfully.',
              status: 'success',
            })
          }
          if (error) {
            sendBanner({
              autoDismiss: true,
              message: 'Error deleting calendar import',
              status: 'danger',
            })
          }
          removeCalendarFromImports(id)
          props.onRefreshCalendars?.()
        },
        message:
          'This will delete all the events from this import. Are you sure you want to continue?',
      })
    },
    [deleteImport, props, sendBanner, sendConfirm]
  )

  const handleResyncImport = useCallback(
    async (id?: string) => {
      const { ok } = await resyncImport({
        importId: id,
      } as MutationCalendarImportResyncRequest)
      if (ok) {
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: 'Calendar import resynced',
        })

        checkImportStatus(id!)
      }
    },
    [checkImportStatus, resyncImport, sendBanner]
  )

  const handleOnChangeResyncInterval = useCallback(
    async (id: string, val: string) => {
      const { error, ok } = await calendarImportUpdate({
        importId: id,
        resyncInterval: Number(val),
      } as MutationCalendarImportUpdateRequest)
      if (ok) {
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: 'Calendar import updated successfully',
        })
      }
      if (error) {
        console.log({ error })
      }
    },
    [calendarImportUpdate, sendBanner]
  )

  useEffect(() => {
    if (isOpen) {
      fetchImports()
    }
  }, [fetchImports, isOpen])

  useEffect(() => {
    if (isOpen) {
      currentImports.forEach((cal) => {
        if (cal.status === 'Importing') {
          if (!timers.current[cal.id!]) {
            checkImportStatus(cal.id!)
          }
        }
      })
    }
  }, [checkImportStatus, currentImports, isOpen])

  useEffect(() => {
    if (isOpen) {
      if (upload && !isUploading) {
        onClickImport()
      }
    }
  }, [isUploading, onClickImport, upload, isOpen])

  const renderCalendarImports = useMemo(() => {
    return (
      <CalendarImportsList>
        <h3>Calendar Imports</h3>
        {currentImports.map((cal) => (
          <ImportContainer key={cal.id}>
            <CalendarImportStatus>
              <div>
                <b>Import On:</b>{' '}
                {moment(cal.createdAt).format('ddd, MMM DD, YYYY, h:mma')}
              </div>
              {cal.errors && (
                <div>
                  <b>Errors:</b> {cal.errors}
                </div>
              )}
              <div>
                <b>User:</b> {cal.createdByName}
              </div>
              <div>
                <b>Status:</b> {cal.status} - Added {cal.eventsImported}{' '}
                {pluralize(cal.eventsImported ?? 0, 'event', 'events')}
                {cal.status === 'Importing' && (
                  <Spinner
                    size={16}
                    style={{ marginLeft: 10, display: 'inline-block' }}
                  />
                )}
              </div>
            </CalendarImportStatus>
            <CalendarImportActions>
              <Select
                initialValue={`${cal.resyncInterval ?? '-1'}`}
                label='Resync Calendar'
                triggerStyle={{ minWidth: 180 }}
                selectedTextStyle={{ fontSize: 14 }}
              >
                <Select.Option
                  isSelected={false}
                  onClick={(val) => handleOnChangeResyncInterval(cal.id!, val)}
                  value='-1'
                >
                  Never
                </Select.Option>
                <Select.Option
                  isSelected={false}
                  onClick={(val) => handleOnChangeResyncInterval(cal.id!, val)}
                  value='60'
                >
                  Once a Hour
                </Select.Option>
                <Select.Option
                  isSelected={false}
                  onClick={(val) => handleOnChangeResyncInterval(cal.id!, val)}
                  value='1440'
                >
                  Once a Day
                </Select.Option>
                <Select.Option
                  isSelected={false}
                  onClick={(val) => handleOnChangeResyncInterval(cal.id!, val)}
                  value='10080'
                >
                  Once a Week
                </Select.Option>
              </Select>
              <StatusActionsButtons>
                <ResyncImportButton onClick={() => handleResyncImport(cal.id!)}>
                  Resync Calendar
                </ResyncImportButton>
                <DeleteImportButton
                  onClick={() => handleDeleteImport(cal.id!)}
                  variant='danger'
                >
                  Delete Import
                </DeleteImportButton>
              </StatusActionsButtons>
            </CalendarImportActions>
          </ImportContainer>
        ))}
      </CalendarImportsList>
    )
  }, [
    currentImports,
    handleDeleteImport,
    handleOnChangeResyncInterval,
    handleResyncImport,
  ])

  return (
    <Modal visible={isOpen} onClose={onClose}>
      <Modal.Header>
        <b>Import External Calendar into {team?.name} Calendar</b>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <TextInput
            inputStyle={{ minWidth: 300 }}
            label='iCalendar URL'
            onChange={(event) => setUrl(event.target.value)}
            placeholder='Enter URL of the calendar to import'
            type='url'
            value={url}
          />

          <Radio.Group
            label='Import Events As:'
            onChange={(val) => setEventType(val as string)}
            style={{ marginTop: 10 }}
            value='events'
          >
            <Radio value='event'>Events</Radio>
            <Radio value='game'>Games</Radio>
          </Radio.Group>

          <Checkbox
            checked={includeReminders}
            onChange={(e) => setIncludeReminders(e.target.checked)}
            style={{ marginTop: 10 }}
          >
            Include Reminders in Import
          </Checkbox>

          <Actions>
            <ImportButton disabled={!isValid} onClick={onClickImport}>
              Import Calendar URL
            </ImportButton>
            <span style={{ marginLeft: 20, marginRight: 20 }}>or</span>
            <FileInput
              onlySelectFileButton
              uploadType={'calendar'}
              onTranscodeComplete={onTranscodeComplete}
              onUploaderCancel={onUploaderCancel}
              onUploaderError={onUploaderError}
              onUploaderUploadDone={onUploaderUploadDone}
              onUploaderUploadStart={onUploaderUploadStart}
              selectFileButtonLabel={'Upload ICS File'}
              selectFileButtonVariant={'primary'}
              uploads={upload ? ([upload] as ExtendedUpload[]) : []}
            />
            {upload && isUploading && (
              <UploadPreview>
                <Spinner size={20} /> Uploading... {upload?.fileName}
              </UploadPreview>
            )}
          </Actions>

          {currentImports.length > 0 && renderCalendarImports}
        </Container>
      </Modal.Body>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-start;
`
const UploadPreview = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`
const ImportButton = styled(Button)`
  // width: 100%;
`
const ImportContainer = styled.div`
  // align-items: center;
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  // display: flex;
  padding: 10px;
  & + & {
    margin-top: 10px;
  }
`
const CalendarImportStatus = styled.div`
  color: ${Colors.DUSTY_GRAY};
  flex: 1 1 auto;
  font-size: 14px;
`
const CalendarImportActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media all and (max-width: 767px) {
    flex-direction: column;
  }
  @media all and (min-width: 768px) {
    align-items: center;
  }
`
const Actions = styled.div`
  padding: 10px 0;
`
const StatusActionsButtons = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-top: 10px;
  @media all and (max-width: 767px) {
    flex-direction: column;
  }
`
const DeleteImportButton = styled(Button)`
  @media all and (min-width: 768px) {
    margin-left: 10px;
  }
`
const ResyncImportButton = styled(Button)`
  @media all and (max-width: 767px) {
    margin-top: 10px;
  }
  @media all and (min-width: 768px) {
    margin-left: 10px;
  }
`
const CalendarImportsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-top: 1px solid ${Colors.ALTO};
  margin-bottom: 120px;
  width: 100%;
`
