import moment from 'moment'

import { Event } from '@sportsyou/api'

export interface ExtdCalendarEvent extends Event {
  agendaDate?: string
  customTime?: string
}

interface AgendaData {
  date: string
  day: string
  events: ExtdCalendarEvent[]
  title: string
}

function widgetEventSort(a: ExtdCalendarEvent, b: ExtdCalendarEvent) {
  if (moment(a.agendaDate).isAfter(b.agendaDate)) {
    return 1
  } else if (moment(a.agendaDate).isBefore(b.agendaDate)) {
    return -1
  } else if (a.customTime && !b.customTime && a.customTime === 'All Day') {
    return -1
  } else if (!a.customTime && b.customTime && b.customTime === 'All Day') {
    return 1
  } else if (a.allDay && !b.allDay && b.customTime !== 'All Day') {
    return -1
  } else if (!a.allDay && b.allDay && a.customTime !== 'All Day') {
    return 1
  } else if (moment(a.startDate).isAfter(b.startDate)) {
    return 1
  } else if (moment(a.startDate).isBefore(b.startDate)) {
    return -1
  } else if (moment(a.endDate).isAfter(b.endDate)) {
    // a.startDate === b.startDate
    return 1
  } else if (moment(a.endDate).isBefore(b.endDate)) {
    // a.startDate === b.startDate
    return -1
  } else {
    // a.startDate === b.startDate && a.endDate === b.endDate
    return 0
  }
}

export function preProcessEvents(
  events: ExtdCalendarEvent[],
  numberOfDays = 7
): ExtdCalendarEvent[] {
  const eventsClone: ExtdCalendarEvent[] = [
    ...(structuredClone(events) as ExtdCalendarEvent[]),
  ]
  const agendaData: AgendaData[] = []
  const today = moment()

  for (let day = 0; day < numberOfDays; day++) {
    const dayOfWeek = moment(today).add(day, 'days')
    const title = dayOfWeek.format('MMM D ddd')
    agendaData.push({
      date: title.slice(0, -4),
      day: title.substring(title.length - 3),
      events: [],
      title,
    })

    for (const event of eventsClone) {
      const eventClone = structuredClone(event)
      const eventStart = moment(eventClone.startDate)
      const startOfEventStart = moment(eventClone.startDate).startOf('day')
      const eventEnd = moment(eventClone.endDate)
      const startOfToday = moment(dayOfWeek).startOf('day')
      const endOfToday = moment(dayOfWeek).endOf('day')

      let customTime: string | undefined

      if (eventClone.allDay) {
        customTime = 'All Day'
      } else if (!(eventClone.allDay || eventClone.noEndTime)) {
        // Check if this is a multi day event that is all day today...
        if (eventStart.isBefore(startOfToday) && eventEnd.isAfter(endOfToday)) {
          customTime = 'All Day'
        }
        // Check if this is a multi day event that ends today
        else if (
          eventStart.isBefore(startOfToday) &&
          eventEnd.isBetween(startOfToday, endOfToday)
        ) {
          const startTime = startOfToday.format('h:mma')
          const endTime = eventEnd.format('h:mma')
          // using – (en dash) character directly rather than &ndash; to avoid using dangerouslySetInnerHTML
          customTime = `${startTime} – ${endTime}`
        }
        // Check if this is a multi day event that started today
        else if (
          eventStart.isBetween(startOfToday, endOfToday) &&
          eventEnd.isAfter(endOfToday)
        ) {
          const startTime = eventStart.format('h:mma')
          const endTime = endOfToday.format('h:mma')
          // using – (en dash) character directly rather than &ndash; to avoid using dangerouslySetInnerHTML
          customTime = `${startTime} – ${endTime}`
        } else {
          customTime = undefined
        }
      } else if (eventClone.noEndTime) {
        const startTime = eventStart.format('h:mma')
        customTime = `${startTime}`
      } else {
        customTime = undefined
      }

      eventClone.agendaDate = startOfToday.format('YYYY-MM-DD')
      if (customTime) {
        eventClone.customTime = customTime
      }

      if (
        eventEnd.isSameOrAfter(startOfToday) &&
        eventStart.isSameOrBefore(endOfToday)
      ) {
        agendaData[day].events.push(eventClone)
      }
    }
  }

  const sortedEvents = agendaData
    .map((data) => data.events)
    .flat()
    .sort(widgetEventSort)

  console.log({
    agendaData,
    flat: agendaData.map((data) => data.events).flat(),
    sortedEvents,
  })
  return sortedEvents
}

export default {
  preProcessEvents,
}
