import { useState, useEffect, useCallback } from 'react'

import { useToast } from '@sportsyou/react-dom-ui'
import useWindowFocus from './useWindowFocus'

const versionCheckInterval = 1000 * 60 * 60 // 1 hour

const useVersions = (initialVersion?: string) => {
  const { sendToast } = useToast()

  const [isShowingNewVersionMessage, setIsShowingNewVersionMessage] =
    useState(false)
  const [currentVersion, setCurrentVersion] = useState<string | undefined>(
    initialVersion
  )

  const fetchLatestVersion = useCallback(async () => {
    const response = await fetch('/version', {
      headers: { 'cache-control': 'no-cache', pragma: 'no-cache' },
    })
    return (await response.text()).trim()
  }, [])

  const refresh = useCallback(() => {
    window.location.reload()
  }, [])

  const showRefreshMessage = useCallback(() => {
    sendToast({
      action: {
        label: 'Refresh',
        onClick: refresh,
      },
      autoDismiss: false,
      header: 'A new version of sportsYou is available! 🎉',
      hideDismissButton: true,
      message: 'Please refresh your page to get the latest version.',
      onDismiss: () => setIsShowingNewVersionMessage(false),
      transition: 'slide',
    })
  }, [sendToast, refresh])

  const checkForVersionChange = useCallback(async () => {
    const latestVersion = await fetchLatestVersion()
    console.log('SY WEB VERSION', { latestVersion, currentVersion })
    if (currentVersion && latestVersion && currentVersion !== latestVersion) {
      if (!isShowingNewVersionMessage) {
        setIsShowingNewVersionMessage(true)
        showRefreshMessage()
      }
    }
  }, [
    currentVersion,
    fetchLatestVersion,
    isShowingNewVersionMessage,
    showRefreshMessage,
  ])

  useEffect(() => {
    if (!currentVersion) {
      fetchLatestVersion().then(setCurrentVersion)
    }
    checkForVersionChange()
    const intervalId = setInterval(checkForVersionChange, versionCheckInterval)
    return () => {
      clearInterval(intervalId)
    }
  }, [checkForVersionChange, currentVersion, fetchLatestVersion])

  useWindowFocus({ onWindowFocus: checkForVersionChange })

  return {
    checkForVersionChange,
    currentVersion,
    refresh,
    setCurrentVersion,
    showRefreshMessage,
  }
}

export default useVersions
