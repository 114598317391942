import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { v4 as uuidV4 } from 'uuid'
import { cloneDeep } from 'lodash'

import {
  MutationChatAddMembersRequest,
  MutationChatCreateRequest,
  MutationChatMessageCreateArgs,
  MutationChatRenameRequest,
  UnreadChats,
  mutationBlockUser,
  mutationChatAddMembers,
  mutationChatCreate,
  mutationChatDelete,
  mutationChatMessageCreate,
  mutationChatRename,
  mutationChatViewed,
  queryChat,
  queryChatStatus,
  queryUnreadChatMessages,
} from '@sportsyou/api'
import { sleep } from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'

import { useAnalytics } from 'web/hooks'
import ChatClient, {
  ChatAttachment,
  ChatIncomingMessage,
  ChatIncomingMessageType,
  ChatOutgoingMessage,
} from 'web/services/Chat/ChatBase'
import { chatEvent } from 'web/store/slices/ChatEventSlice'
import {
  fetchChats,
  refreshChatListItem,
  removeChatFromList,
  updateChatList,
} from 'web/store/slices/ChatListSlice'
import { addChatBox } from 'web/store/slices/ChatBoxSlice'
import { preProcessSingleChat } from '../../utils/PreProcessChat'
import { selectCurrentUser } from 'web/store/slices/UserSlice'
import { setUnreadChats } from 'web/store/slices/ChatUnreadCountSlice'
import { ChatListChat } from 'web/store/slices/types'

const MISSING_TOKEN_ERROR = 'Missing token'

export enum CHAT_MESSAGE_FILTER {
  LISTEN_FOR_ALL = 'ALL',
  LISTEN_FOR_MESSAGES_ONLY = 'MESSAGES_ONLY',
  LISTEN_FOR_NONE = 'NONE',
}

export interface UseChatOptions {
  deps?: DependencyList
  establishConnection?: boolean
  filter?: CHAT_MESSAGE_FILTER
  logEvents?: boolean
  onIncoming?: (message: ChatIncomingMessage) => void
  token?: string
  updateChatList?: boolean
  updateEventState?: boolean
}

export interface ChatTypedProps {
  name: string
  token: string
  userId: string
}

export interface ChatViewedProps {
  token: string
  userId: string
  viewedAt?: string
}

export interface ChatLeftProps {
  token: string
  userId: string
  userName?: string
}

export interface ChatMessageProps {
  chatAttachment?: ChatAttachment
  clientId?: string
  from?: string
  fromName?: string
  message?: string
  timestamp?: string
  token?: string
  uploadFromShared?: boolean
  uploadId?: string
}

export interface ChatCreateProps {
  announcers?: string[]
  chatDirection: 'unilateral' | 'bilateral'
  chatTitle?: string
  chatType: 'team' | 'group' | 'direct'
  fullTeam?: boolean
  participantIds: string[]
  teamId?: string
}

export interface ChatDeleteProps {
  deleteTeamChat: boolean
  token?: string
}

export interface ChatRenameProps {
  chatId?: string
  chatTitle: string
  chatToken?: string
}

export interface ChatBlockProps {
  targetId: string
  token?: string
  userId?: string
  userName?: string
}

export interface ChatAddMemberProps {
  chatId?: string
  chatToken?: string
  partnerIds: string[]
}

export interface ChatOutgoingMessageResult {
  data?: any
  error?: string | object
  id?: string
  ok: boolean
  token?: string
}

export interface UseChatResult {
  chatAddMembers: (
    props: ChatAddMemberProps
  ) => Promise<ChatOutgoingMessageResult>
  chatBlock: (props: ChatBlockProps) => Promise<ChatOutgoingMessageResult>
  chatCreate: (props: ChatCreateProps) => Promise<ChatOutgoingMessageResult>
  chatDelete: (props: ChatDeleteProps) => Promise<ChatOutgoingMessageResult>
  chatLeaveRoom: (props?: ChatLeftProps) => Promise<ChatOutgoingMessageResult>
  chatMessageLikesChanged: (props: { id: string }) => void
  chatRename: (props: ChatRenameProps) => Promise<ChatOutgoingMessageResult>
  chatSend: (props?: ChatMessageProps) => Promise<ChatOutgoingMessageResult>
  chatTyped: (props?: ChatTypedProps) => void
  chatViewed: (props?: ChatViewedProps) => Promise<ChatOutgoingMessageResult>
  incomingMessages?: ChatIncomingMessage
  setActive: (isActive: boolean) => void
  start: (token?: string) => void
  startChatWithTeam: (props: {
    fullTeam?: boolean
    memberIds: Array<string>
    teamId?: string
    showUI?: boolean
  }) => void
  startChatWithUser: (props: { userId: string; showUI?: boolean }) => void
  stop: (shouldDisconnect?: boolean) => void
  updateUnreadCount: () => void
}

export const useChat = (options?: UseChatOptions): UseChatResult => {
  const filter = options?.filter ?? CHAT_MESSAGE_FILTER.LISTEN_FOR_NONE
  const updateEventState = options?.updateEventState ?? true
  const updateChatListState = options?.updateChatList ?? true
  const establishConnection = options?.establishConnection ?? true

  const dispatch = useDispatch()
  const incomingMessages = ChatClient.getIncomingMessages()
  const { logEvent } = useAnalytics()

  const started = useRef(false)
  const stopListening = useRef(() => {})
  const clientId = useRef('')
  const currentToken = useRef<string>('')

  const currentUser = useSelector(selectCurrentUser)

  const [allEvents, setAllEvent] = useState<ChatIncomingMessage>()

  const { fetch: getUnread } = useFetchApi(queryUnreadChatMessages, {
    noStateUpdates: true,
  })
  const fetchUnread = useDebouncedCallback(getUnread, 500, {
    leading: true,
    trailing: true,
  })

  const { fetch: getChat } = useFetchApi(queryChat, { noStateUpdates: true })
  const fetchChat = useDebouncedCallback(getChat, 500, {
    leading: true,
    trailing: true,
  })

  //call the api to get unread chat message counts
  const updateUnreadCount = useDebouncedCallback(
    async () => {
      let unread = await fetchUnread()
      if (unread?.ok) {
        dispatch(setUnreadChats(unread.data as UnreadChats))
      }
    },
    300,
    { leading: true, trailing: true }
  )

  function checkConnectedAndStart() {
    return new Promise(async (resolve) => {
      let connected = !!ChatClient.isConnected()

      while (!connected) {
        console.log('is NOT connected', ChatClient.isConnected(), options)
        if (establishConnection) {
          console.log(
            'should establish connection…',
            establishConnection,
            options
          )
          const msgs = await ChatClient.connect()
          console.log(
            'connection success',
            ChatClient.isConnected(),
            options,
            msgs
          )
          connected = ChatClient.isConnected()
        } else {
          console.log('retrying connection check…', options)
          await sleep(2_000)
          connected = ChatClient.isConnected()
        }
      }

      console.log('is connected', ChatClient.isConnected(), options)
      resolve(null)
    })
  }

  const start = useCallback(
    async (token?: string) => {
      let tokArg = token || options?.token || ''

      currentToken.current = tokArg
      if (tokArg) {
        ChatClient.addActiveChat(tokArg)
      }

      started.current = true

      console.log('==> Chat Hook Start:', options, tokArg, currentToken.current)

      const data = localStorage.getItem('clientId')
      if (!data) {
        clientId.current = uuidV4()
        localStorage.setItem('clientId', clientId.current)
      } else {
        clientId.current = data
      }

      await checkConnectedAndStart()
      console.log({ useChat_isConnected: ChatClient.isConnected(), options })

      stopListening.current = incomingMessages.addListener(async (message) => {
        let setRedux = true
        // if (currentToken.current !== '') {
        //   setRedux = false
        //   let doNotify = false
        //   if (message.token && message.token === currentToken.current) {
        //     doNotify = true
        //   }
        //   if (!doNotify) {
        //     return
        //   }
        // }

        // Ignore messages from yourself
        if (message?.from === currentUser.id) {
          if (message.wsMessageType === ChatIncomingMessageType.CHAT_TYPING) {
            setRedux = false
          }
        }

        if (filter === CHAT_MESSAGE_FILTER.LISTEN_FOR_NONE) {
          return
        }
        if (
          filter === CHAT_MESSAGE_FILTER.LISTEN_FOR_MESSAGES_ONLY &&
          message.wsMessageType !== ChatIncomingMessageType.MESSAGE_TO_CLIENT
        ) {
          return
        }

        if (setRedux) {
          dispatch(chatEvent(message))
        }

        if (options?.onIncoming) {
          options.onIncoming(message)
        }

        if (updateEventState) {
          setAllEvent(message)
        }

        if (options?.logEvents) {
          if (message.wsMessageType === ChatIncomingMessageType.CONNECT) {
            console.log('==> Chat Connected')
          } else if (
            message.wsMessageType === ChatIncomingMessageType.DISCONNECT ||
            message.wsMessageType === ChatIncomingMessageType.TRANSPORT_CLOSED
          ) {
            console.log('==> Chat Disconnected')
          } else if (
            message.wsMessageType === ChatIncomingMessageType.MESSAGE_TO_CLIENT
          ) {
            console.log('==> Chat Message To Client', message)
          } else if (
            message.wsMessageType === ChatIncomingMessageType.CHAT_TYPING
          ) {
            console.log('==> Chat Typing', message)
          } else if (
            message.wsMessageType === ChatIncomingMessageType.CHAT_VIEWED
          ) {
            console.log('==> Chat Viewed', message)
          } else if (
            message.wsMessageType === ChatIncomingMessageType.CHAT_LEFT
          ) {
            console.log('==> Chat Left', message)
          } else if (
            message.wsMessageType === ChatIncomingMessageType.REACTION
          ) {
            console.log('==> Chat Reaction', message)
          } else if (
            message.wsMessageType ===
            ChatIncomingMessageType.CHAT_ADD_NEW_MEMBER
          ) {
            console.log('==> Chat New Member', message)
          }
        }

        if (!currentToken.current) {
          if (
            message.wsMessageType === ChatIncomingMessageType.MESSAGE_TO_CLIENT
          ) {
            if (message.token && !ChatClient.isActiveChat(message.token)) {
              updateUnreadCount()
            }
          } else if (
            message.wsMessageType === ChatIncomingMessageType.CHAT_VIEWED &&
            message.from === currentUser.id
          ) {
            updateUnreadCount()
          }
          // load updated chat to update chat list
          if (message.token) {
            switch (message.wsMessageType) {
              case ChatIncomingMessageType.CHAT_ADD_NEW_MEMBER:
              case ChatIncomingMessageType.CHAT_VIEWED:
              case ChatIncomingMessageType.CONNECT:
              case ChatIncomingMessageType.MESSAGE_TO_CLIENT:
                if (message.messageType === 'system') return
                if (updateChatListState) {
                  const res = await fetchChat({ token: message.token })
                  await sleep(250)
                  if (res?.ok && res.data && !res.data.deletedAt) {
                    const chat = cloneDeep(res.data)
                    dispatch(
                      updateChatList(
                        preProcessSingleChat(res.data as ChatListChat)
                      )
                    )
                    dispatch(refreshChatListItem(chat))

                    // Show a chat box if a new message comes in
                    if (
                      message.token &&
                      message.from !== currentUser.id &&
                      !ChatClient.isActiveChat(message.token)
                    ) {
                      dispatch(
                        addChatBox({
                          chatId: chat.id as string,
                          minimized: false,
                        })
                      )
                      dispatch(fetchChats(false)) // fetch chats without resetting active chat
                    }
                  }
                }
                break
              case ChatIncomingMessageType.CHAT_LEFT:
                if (message.from === currentUser.id) {
                  dispatch(removeChatFromList({ chatToken: message.token }))
                  updateUnreadCount()
                } else {
                  if (updateChatListState) {
                    const res = await fetchChat({ token: message.token })
                    await sleep(250)
                    if (res?.ok && res.data && !res.data.deletedAt) {
                      const chat = cloneDeep(res.data)
                      dispatch(
                        updateChatList(
                          preProcessSingleChat(res.data as ChatListChat)
                        )
                      )
                      dispatch(refreshChatListItem(chat))
                    }
                    updateUnreadCount()
                  }
                }
                break
              default:
            }
          }
        }
      })
    },
    [
      currentUser.id,
      dispatch,
      // establishConnection,
      // fetchChat,
      // filter,
      // incomingMessages,
      // options,
      // updateChatListState,
      // updateEventState,
      updateUnreadCount,
    ]
  )

  const setActive = (isActive: boolean) => {
    if (currentToken.current && currentToken.current !== '') {
      if (isActive) {
        chatViewed()
        ChatClient.addActiveChat(currentToken.current)
      } else {
        if (currentToken.current && currentToken.current !== '') {
          ChatClient.removeActiveChat(currentToken.current)
        }
      }
    }
  }

  const stop = (shouldDisconnect?: boolean) => {
    started.current = false
    if (currentToken.current && currentToken.current !== '') {
      ChatClient.removeActiveChat(currentToken.current)
      currentToken.current = ''
    }
    stopListening.current()
    shouldDisconnect && ChatClient.disconnect()
  }

  // Unsubscribe on unmount
  useEffect(() => {
    return stop
  }, [])

  //automatically kick off the chat on mount if deps are specified, otherwise it must be started with the start function
  // useEffect(() => {
  //   if (options?.deps) {
  //     setTimeout(start, 10)
  //   }
  // }, options?.deps ?? [])

  // send a chat typing message to the server
  const chatTyped = useDebouncedCallback(
    async (props?: ChatTypedProps): Promise<ChatOutgoingMessageResult> => {
      let sendProps = props ?? {
        name: currentUser.fullName || '',
        token: currentToken.current,
        userId: currentUser.id || '',
      }
      if (!sendProps.token || sendProps.token === '') {
        return { ok: false, error: MISSING_TOKEN_ERROR }
      }
      ChatClient.chatTyped(sendProps)
      return { ok: true, token: sendProps.token }
    },
    3000,
    { leading: true, trailing: true }
  )

  // send a chat viewed message to the server
  const chatViewed = async (props?: ChatViewedProps) => {
    let sendProps = props
    let viewedAt = new Date().toISOString()

    if (!sendProps) {
      sendProps = {
        token: currentToken.current,
        userId: currentUser.id || '',
        viewedAt,
      }
    } else {
      if (!sendProps.viewedAt) {
        sendProps.viewedAt = viewedAt
      }
    }

    if (!sendProps.token || sendProps.token === '') {
      return { ok: false, error: MISSING_TOKEN_ERROR }
    }

    await mutationChatViewed({ token: sendProps.token })
    ChatClient.chatViewed(sendProps)

    return { ok: true, token: sendProps.token }
  }

  // send a message to the server
  const chatSend = async (props?: ChatMessageProps) => {
    let token = props?.token || currentToken.current
    if (!token) {
      return { ok: false, error: MISSING_TOKEN_ERROR }
    }
    let createArgs: MutationChatMessageCreateArgs = { token }
    let sendArgs: ChatOutgoingMessage = { token }

    if (props?.message) {
      createArgs.message = props.message
      sendArgs.message = props.message
    }
    if (props?.uploadId) {
      createArgs.uploadIds = [props.uploadId]
    }
    if (props?.uploadFromShared) {
      createArgs.uploadFromShared = props.uploadFromShared
    }
    let res = await mutationChatMessageCreate(createArgs)
    if (!res.ok || !res.data || res.data.length === 0) {
      return res
    }

    let message = res.data[0]
    sendArgs.id = message.id
    sendArgs.token = token
    sendArgs.from = props?.from || currentUser?.id
    sendArgs.fromName = props?.fromName || currentUser?.fullName
    sendArgs.timestamp = props?.timestamp || new Date().toISOString()
    sendArgs.clientId = clientId.current
    sendArgs.chatAttachment = props?.chatAttachment
    ChatClient.chatSend(sendArgs)

    return { ok: true, token: token }
  }

  // leave the chat room
  const chatLeaveRoom = async (props?: ChatLeftProps) => {
    let sendArgs = props
    if (!sendArgs) {
      sendArgs = {
        token: currentToken.current,
        userName: currentUser.fullName || '',
        userId: currentUser.id || '',
      }
    }

    if (!sendArgs.token || sendArgs.token === '') {
      return { ok: false, error: MISSING_TOKEN_ERROR }
    }

    let leaveRoom = await mutationChatDelete({
      token: sendArgs.token,
      requestOptions: {
        fieldList: 'lastMessages {id, message, createdAt, messageType}',
      },
    })
    if (leaveRoom.ok) {
      let lastMessages = leaveRoom.data?.lastMessages
      if (lastMessages) {
        for (let i = 0; i < lastMessages.length; i++) {
          let msg = lastMessages[i]
          const messageArgs: ChatOutgoingMessage = {
            id: msg?.id,
            from: sendArgs.userId,
            fromName: sendArgs.userName,
            message: msg?.message,
            messageType: msg?.messageType,
            timestamp: msg?.createdAt,
            token: sendArgs.token,
            clientId: clientId.current,
          }
          console.log({ messageArgs })
          ChatClient.chatSend(messageArgs)
        }
      }

      ChatClient.chatLeft(sendArgs)
    }
    stop()
    return leaveRoom
  }

  //create a new chat
  const chatCreate = useCallback(
    async (props: ChatCreateProps): Promise<ChatOutgoingMessageResult> => {
      let mutProp: MutationChatCreateRequest = { ...props }
      // mutProp.requestOptions = {
      //   fieldList: 'id token participants { chatUserToken isCurrentUser }',
      // }
      if (currentToken.current && currentToken.current !== '') {
        ChatClient.removeActiveChat(currentToken.current)
      }
      let newChat = await mutationChatCreate(mutProp)
      if (newChat.ok) {
        const _chatId = newChat.data?.id ?? ''
        const _chatToken = newChat.data?.token ?? ''
        const participants = newChat.data?.participants
        const participantTokens: string[] = []
        let userToken = ''
        dispatch(updateChatList(newChat.data as ChatListChat))
        await sleep(250)
        if (participants) {
          for (let i = 0; i < participants.length; i++) {
            if (participants[i]?.isCurrentUser) {
              userToken = participants[i]?.chatUserToken || ''
            } else {
              participantTokens.push(participants[i]?.chatUserToken || '')
            }
          }
        }
        ChatClient.chatSetupNew({
          token: newChat.data?.token,
          userId: userToken,
          partnerId: participantTokens,
        })

        currentToken.current = _chatToken
        ChatClient.addActiveChat(_chatToken)
        return { ok: true, data: newChat.data, id: _chatId, token: _chatToken }
      } else {
        currentToken.current = ''
        return { ok: false, error: newChat.error }
      }
    },
    [dispatch]
  )

  //delete the chat
  const chatDelete = async (props: ChatDeleteProps) => {
    let sendProps = structuredClone(props)
    if (!sendProps.token) {
      sendProps.token = currentToken.current
    }

    if (!sendProps.token || sendProps.token === '') {
      return { ok: false, error: MISSING_TOKEN_ERROR }
    }

    let res = await mutationChatDelete({
      token: sendProps.token,
      deleteTeamChat: sendProps.deleteTeamChat,
      requestOptions: { fieldList: 'id token participants {id, fullName}' },
    })
    if (res.data && res.data.participants) {
      let participants = res.data.participants
      for (let i = 0; i < participants.length; i++) {
        const userName =
          participants[i]?.fullName ??
          `${participants[i]?.firstName ?? ''} ${
            participants[i]?.lastName ?? ''
          }`
        let deleteProps: ChatOutgoingMessage = {
          token: res.data.token,
          chatId: res.data.id,
          userId: participants[i]?.id,
          userName,
        }
        ChatClient.chatLeft(deleteProps)
      }
    }
    currentToken.current = ''
    stop()
    return { ok: res.ok, error: res.error }
  }

  //rename the chat
  const chatRename = async (props: ChatRenameProps) => {
    let sendProps: MutationChatRenameRequest = {
      chatId: props.chatId || '',
      chatTitle: props.chatTitle,
    }

    if (sendProps.chatId === '') {
      let checkToken = props.chatToken || currentToken.current
      if (!checkToken || checkToken === '') {
        return { ok: false, error: MISSING_TOKEN_ERROR }
      }

      let chat = await queryChatStatus({ token: checkToken })
      if (chat) {
        if (chat.data?.id) {
          sendProps.chatId = chat.data?.id
        } else {
          return { ok: false, error: MISSING_TOKEN_ERROR }
        }
      }
    }
    return await mutationChatRename(sendProps)
  }

  //block another user from chatting
  const chatBlock = async (props: ChatBlockProps) => {
    let sendArgs = props

    if (!sendArgs.token) {
      if (!currentToken.current) {
        return { ok: false, error: 'Missing token' }
      } else {
        sendArgs.token = currentToken.current
      }
    }

    let blockUser = await mutationBlockUser({
      targetId: sendArgs.targetId,
      requestOptions: {
        fieldList: `
          id
          lastMessage
          lastMessages {
            chatId
            createdAt
            id
            message
            messageType
          }
          participants {
            chatUserToken
            id
            firstName
            fullName
            isCurrentUser
            lastName
          }
          token
        `,
      },
    })
    if (blockUser.ok) {
      ChatClient.chatLeft(sendArgs)
    }

    stop()
    return { ok: true, token: sendArgs.token, data: blockUser?.data }
  }

  //add members to a chat
  const chatAddMembers = async (props: ChatAddMemberProps) => {
    let sendProps: MutationChatAddMembersRequest = {
      id: props.chatId || '',
      participantIds: props.partnerIds,
    }

    if (sendProps.id === '') {
      let checkToken = props.chatToken || currentToken.current
      if (!checkToken || checkToken === '') {
        return { ok: false, error: MISSING_TOKEN_ERROR }
      }

      let chat = await queryChatStatus({ token: checkToken })
      if (chat) {
        if (chat.data?.id) {
          sendProps.id = chat.data?.id
        } else {
          return { ok: false, error: chat.error }
        }
      }
    }
    sendProps.requestOptions = {
      fieldList:
        ' token, chatType, participants {chatUserToken,isCurrentUser, firstName, lastName, deletedAt, viewedAt, isActive, isExisting} lastMessages {id, message, messageType, createdAt} team {teamId, teamName}',
    }

    let res = await mutationChatAddMembers(sendProps)

    if (res.ok && res.data) {
      let userToken = ''
      let participantTokens: string[] = []
      let participants = res.data?.participants
      if (participants) {
        for (let i = 0; i < participants.length; i++) {
          if (participants[i]?.isCurrentUser) {
            userToken = participants[i]?.chatUserToken || ''
          } else {
            participantTokens.push(participants[i]?.chatUserToken || '')
          }
        }
      }
      await ChatClient.chatSetupNew({
        token: res.data?.token,
        userId: userToken,
        partnerId: participantTokens,
      })

      await ChatClient.chatAddNewMember({
        id: res.data?.id,
        token: res.data?.token,
        chatType: res.data?.chatType,
        participants: res.data?.participants || [],
        team: res.data?.team,
      })

      const lastMessages = res.data.lastMessages || []

      lastMessages.forEach((msg) => {
        const chatAddNewMemberWSMsg = {
          id: msg?.id,
          from: currentUser?.id,
          fromName: currentUser?.fullName,
          message: msg?.message,
          messageType: msg?.messageType,
          timestamp: msg?.createdAt,
          token: res.data?.token,
          clientId: clientId.current,
        }

        ChatClient.chatSend(chatAddNewMemberWSMsg)
      })

      return { ok: true }
    } else {
      return { ok: res.ok, error: res.error }
    }
  }

  const chatMessageLikesChanged = useCallback(
    (props: { id: string }) => {
      ChatClient.chatMessageLikesChanged({
        id: props.id,
        token: currentToken.current,
        userId: currentUser.id,
      })
    },
    [currentUser.id]
  )

  const startChatWithTeam = useCallback(
    async (props: {
      memberIds: Array<string>
      teamId?: string
      showUI?: boolean
      fullTeam?: boolean
    }) => {
      const response = await chatCreate({
        chatDirection: 'bilateral',
        chatType: 'team',
        fullTeam: props.fullTeam,
        teamId: props.teamId,
        participantIds: props.memberIds,
      })
      if (response.ok) {
        if (props.showUI) {
          dispatch(
            addChatBox({ chatId: response.id as string, minimized: false })
          )
        }
      }
      return response
    },
    [chatCreate, dispatch]
  )

  const startChatWithUser = useCallback(
    async (props: { userId: string; showUI?: boolean }) => {
      const response = await chatCreate({
        chatDirection: 'bilateral',
        chatType: 'direct',
        participantIds: [props.userId],
      })
      if (response.ok) {
        logEvent('chatCreate', {
          createdFromNewChatDialog: false,
          createdFromChatIcon: true,
          isDirectChat: true,
          isFriendsGroupChat: false,
          isTeamChat: false,
          isBilateralChat: true,
          numberOfParticipants: 2,
        })
        if (props.showUI) {
          dispatch(
            addChatBox({ chatId: response.id as string, minimized: false })
          )
        }
      }
      return response
    },
    [chatCreate, dispatch]
  )

  return {
    chatAddMembers, //add members to an existing chat
    chatBlock, //block another user from chatting the current user
    chatCreate, //create a new chat
    chatDelete, //delete a chat
    chatLeaveRoom, //user left the chat
    chatMessageLikesChanged, // function to update chat message likes
    chatRename, //rename a chat
    chatSend, //send a new chat message
    chatTyped, //send a chat typing message to server
    chatViewed, //send a chat view message to server
    incomingMessages: allEvents, //events as a state variable
    setActive, //set this chat as active or not indicating it's able to send and receive messages on a visible screen
    start, //function to start listening for chat messages
    startChatWithTeam, // easy way to start a chat with team
    startChatWithUser, // easy way to start a chat with another user
    stop, //function to stop listening for chat messages
    updateUnreadCount, //function to update unread message count
  }
}

// useChat.whyDidYouRender = true

export default useChat
