import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styled from 'styled-components'

import { EventRepeat } from '@sportsyou/api'
import { pluralize } from '@sportsyou/core'

import {
  REPEAT_INTERVAL_DAILY,
  REPEAT_INTERVAL_MONTHLY,
  REPEAT_INTERVAL_NONE,
  REPEAT_INTERVAL_WEEKLY,
  REPEAT_INTERVAL_YEARLY,
} from '../../../../constants'

export interface RepeatingEventSelectionResultsTextProps {
  eventType?: string
  repeatSettings: RepeatSettingsProps
  startDate?: Date
  style?: object
}

export interface RepeatSettingsProps extends Omit<EventRepeat, '__typename'> {
  repeatEnds: string
}

export default function RepeatingEventSelectionResultsText(
  props: RepeatingEventSelectionResultsTextProps
) {
  const { t } = useTranslation()

  const weekdaysIndex = [0, 1, 2, 3, 4, 5, 6]
  const weekdays = [
    t('sunday'),
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
  ]
  const prefixes = [t('first'), t('second'), t('third'), t('fourth'), t('last')]
  const defaultWeekday = moment(
    props.startDate ?? props.repeatSettings.repeatStartOn!
  )

  const intervals = {
    [REPEAT_INTERVAL_NONE]: {
      interval: t('repeat-interval-none'),
      unit: t('repeat-interval-none-unit'),
      unitPlural: t('repeat-interval-none-unit_plural'),
    },
    [REPEAT_INTERVAL_DAILY]: {
      interval: t('repeat-interval-daily'),
      unit: t('repeat-interval-daily-unit'),
      unitPlural: t('repeat-interval-daily-unit_plural'),
    },
    [REPEAT_INTERVAL_WEEKLY]: {
      interval: t('repeat-interval-weekly'),
      unit: t('repeat-interval-weekly-unit'),
      unitPlural: t('repeat-interval-weekly-unit_plural'),
    },
    [REPEAT_INTERVAL_MONTHLY]: {
      interval: t('repeat-interval-monthly'),
      unit: t('repeat-interval-monthly-unit'),
      unitPlural: t('repeat-interval-monthly-unit_plural'),
    },
    [REPEAT_INTERVAL_YEARLY]: {
      interval: t('repeat-interval-yearly'),
      unit: t('repeat-interval-yearly-unit'),
      unitPlural: t('repeat-interval-yearly-unit_plural'),
    },
  }

  function repeatWeekdaysValue(weekdayIndex: number) {
    let days = props.repeatSettings.repeatOnDayOfWeek || [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]
    const repeatOnDayOfWeek = days[weekdayIndex]
    let selected = false
    if (weekdayIndex === defaultWeekday.day()) {
      selected = !days.some((day) => day === true)
    }
    return selected || !!repeatOnDayOfWeek
  }

  function renderSelectionResultsText() {
    if (!props.repeatSettings.repeatIntervalEvery) {
      return ''
    }
    if (
      !props.repeatSettings.repeatTimes &&
      !props.repeatSettings.repeatEndOn
    ) {
      return ''
    }
    let settingsText = t('repeat-interval-text-none')
    let interval = props.repeatSettings.repeatInterval
    let intervalEvery = props.repeatSettings.repeatIntervalEvery

    if (interval === REPEAT_INTERVAL_DAILY) {
      if (intervalEvery === 1) {
        settingsText = intervals[REPEAT_INTERVAL_DAILY].interval
      } else {
        settingsText = t('repeat-interval-text-daily', {
          count: intervalEvery,
          days: pluralize(
            intervalEvery,
            intervals[REPEAT_INTERVAL_DAILY].unit,
            intervals[REPEAT_INTERVAL_DAILY].unitPlural
          ),
        })
      }
    } else if (interval === REPEAT_INTERVAL_WEEKLY) {
      if (intervalEvery === 1) {
        settingsText = intervals[REPEAT_INTERVAL_WEEKLY].interval
      } else {
        settingsText = t('repeat-interval-text-weekly', {
          count: intervalEvery,
          weeks: pluralize(
            intervalEvery,
            intervals[REPEAT_INTERVAL_WEEKLY].unit,
            intervals[REPEAT_INTERVAL_WEEKLY].unitPlural
          ),
        })
      }
      let onText = ''
      let onDays = weekdaysIndex
        .filter((weekday) => {
          return !!repeatWeekdaysValue(weekday)
        })
        .map((index) => weekdays[index])
      if (onDays.length > 1) {
        onDays[onDays.length - 1] = t('repeat-interval-text-and-last-day', {
          day: onDays[onDays.length - 1],
        })
      }
      if (onDays.length > 2) {
        onText += onDays.join(', ')
      } else {
        onText += onDays.join(' ')
      }
      if (onText === '') {
        let day = defaultWeekday.day()
        onText = weekdays[day]
      }
      settingsText += t('repeat-interval-text-on-day', { day: onText })
    } else if (interval === REPEAT_INTERVAL_MONTHLY) {
      if (intervalEvery === 1) {
        settingsText = intervals[REPEAT_INTERVAL_MONTHLY].interval
      } else {
        settingsText = t('repeat-interval-text-monthly', {
          count: intervalEvery,
          months: pluralize(
            intervalEvery,
            intervals[REPEAT_INTERVAL_MONTHLY].unit,
            intervals[REPEAT_INTERVAL_MONTHLY].unitPlural
          ),
        })
      }
      if (props.repeatSettings.repeatByDayOfMonth) {
        settingsText += t('repeat-interval-text-month-on-date', {
          date: defaultWeekday.date(),
        })
      } else {
        settingsText += t('repeat-interval-text-month-on-the-nth-day', {
          prefix: prefixes[parseInt(`${defaultWeekday.date() / 7}`)],
          day: weekdays[defaultWeekday.day()],
        })
      }
    } else if (interval === REPEAT_INTERVAL_YEARLY) {
      if (intervalEvery === 1) {
        settingsText = intervals[REPEAT_INTERVAL_YEARLY].interval
      } else {
        settingsText = t('repeat-interval-text-yearly', {
          count: intervalEvery,
          years: pluralize(
            intervalEvery,
            intervals[REPEAT_INTERVAL_YEARLY].unit,
            intervals[REPEAT_INTERVAL_YEARLY].unitPlural
          ),
        })
      }
    }
    if (interval !== REPEAT_INTERVAL_NONE) {
      if (props.repeatSettings.repeatEnds === 'on') {
        settingsText += t('repeat-interval-text-ends-on', {
          date: moment(props.repeatSettings.repeatEndOn).format('MM/DD/YYYY'),
        })
      } else {
        const times = props.repeatSettings.repeatTimes ?? 1
        settingsText += t('repeat-interval-text-ends-after', {
          count: times,
          occurrence: pluralize(times, t('occurrence'), t('occurrence_plural')),
        })
      }
    }
    return settingsText
  }

  return (
    <SelectionResultsText style={props.style}>
      {renderSelectionResultsText() ||
        (props.eventType === 'event' ? (
          <>{t('repeat-interval-option-label-none')}</>
        ) : (
          <>{t('repeat-interval-option-label-none-game')}</>
        ))}
    </SelectionResultsText>
  )
}

const SelectionResultsText = styled.div`
  font-size: 16;
  font-weight: 400;
`
