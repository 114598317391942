import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Avatar,
  Button,
  FilesWidget,
  FormattedText,
  PostCreator,
  SingleTagProps,
  TagGroup,
  Widget,
  getBoundingBox,
  useDialog,
} from '@sportsyou/react-dom-ui'
import {
  Colors,
  capitalize,
  getProfileImage,
  isFile,
  pluralize,
} from '@sportsyou/core'
import {
  Event as CalendarEvent,
  File,
  MutationPostCreateArgs,
  MutationPostCreateRequest,
  MutationPostUpdateArgs,
  MutationTeamFileAddRequest,
  Post,
  Profile,
  QueryEventsRequest,
  QueryTeamFilesRequest,
  Team,
  TeamMember,
  User,
  mutationPostCreate,
  mutationTeamFileAdd,
  mutationTeamFileDelete,
  queryEvents,
  queryPost,
  queryTeamFiles,
} from '@sportsyou/api'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'

import { PostList } from '../../../components/PostList/PostList'
import {
  selectAgendaEvents,
  setAgendaEvents,
} from '../../../../store/slices/CalendarSlice'
import { preProcessEvents } from '../../../../utils/pre-process-agenda-events'
import { selectAllTeamsAndGroups } from '../../../../store/slices/TeamsSlice'
import { selectCurrentUser } from '../../../../store/slices/UserSlice'
import { useAnalytics } from '../../../hooks/useAnalytics'
import useFolders from '../../../hooks/useFolders'
import useLightbox from '../../../components/Lightbox/useLightbox'

interface ProfileFeedTabProps {
  isSmallScreen?: boolean
  isLoading?: boolean
  id?: string
  onClickEditIntro: () => void
  team?: Team
  user?: User | Profile
}

export const ProfileFeedTab: React.FC<ProfileFeedTabProps> = (
  props: ProfileFeedTabProps
) => {
  const { id, isSmallScreen, team, user } = props

  const { downloadItem } = useFolders({})
  const { logEvent } = useAnalytics()
  const { sendBanner, sendConfirm } = useDialog()
  const { showWithItems: showLightboxWithItems } = useLightbox()
  const navigate = useNavigate()

  const currentUserAgendaEvents = useSelector(selectAgendaEvents)
  const currentUser = useSelector(selectCurrentUser)
  const teams = useSelector(selectAllTeamsAndGroups)

  const postCreatorRef = useRef<HTMLDivElement | null>(null)

  const [files, setFiles] = useState<File[]>([])
  const [filesPage, setFilesPage] = useState(1)
  const [hasMoreFiles, setHasMoreFiles] = useState(true)
  const [isLoadingFiles, setIsLoadingFiles] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [shouldRefetchPosts, setShouldRefetchPosts] = useState<boolean>(false)

  const isLargeTeam = useMemo(() => !!team?.isLargeTeam, [team?.isLargeTeam])
  const isTeam = useMemo(() => !!team, [team])
  const isCurrentUser = useMemo(
    () => !!(id === currentUser?.id),
    [currentUser?.id, id]
  )
  const teamIds = useMemo(() => teams.map((team) => team.id), [teams])
  const teamsAndGroupsTheUserCanPostTo = useMemo(
    () => teams.filter((teamOrGroup) => !!teamOrGroup.canPost),
    [teams]
  )

  const { fetch: addTeamFile } = useFetchApi(mutationTeamFileAdd)
  const { fetch: createPost } = useFetchApi(mutationPostCreate)
  const { fetch: deleteTeamFile } = useFetchApi(mutationTeamFileDelete)
  const { fetch: getEvents } = useFetchApi(queryEvents)
  const { fetch: getFiles } = useFetchApi(queryTeamFiles)
  const { fetch: getPost } = useFetchApi(queryPost)

  const fetchFiles = useCallback(
    async (page = 1) => {
      if (isTeam) {
        setIsLoadingFiles(true)
        const { data = [] } = await getFiles({
          page,
          perPage: 12,
          teamId: team?.id,
        } as QueryTeamFilesRequest)
        data?.sort((a, b) => {
          const aDate = a?.createdAt ? new Date(a.createdAt) : new Date()
          const bDate = b?.createdAt ? new Date(b.createdAt) : new Date()
          if (aDate > bDate) return -1
          if (aDate < bDate) return 1
          return 0
        })

        if (data.length > 0) {
          setFiles((prev) => (page === 1 ? data : [...prev, ...data]))
          setFilesPage(page)
        }

        // no more files to load
        if (data.length < 12) {
          setHasMoreFiles(false)
        }

        setIsLoadingFiles(false)
      }
    },
    [filesPage, files, id, isTeam]
  )

  const onUploaderUploadComplete = useCallback(
    async (uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      setIsUploading(false)
      const { error, ok } = await addTeamFile({
        teamId: team?.id,
        uploads: uploads.map((u) => u.id),
      } as MutationTeamFileAddRequest)

      if (ok) {
        const _files = [...uploads, ...files]
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: `Successfully added ${uploads.length} ${pluralize(
            uploads.length,
            'file',
            'files'
          )} to ${team?.name}`,
        })
        setFiles(_files)
        fetchFiles()
        // reset files params to enable infinite scrolling again
        setFilesPage(1)
        setHasMoreFiles(true)
      }
      if (error) {
        console.log({ error })
      }
    },
    [addTeamFile, fetchFiles, files, sendBanner, team?.id, team?.name]
  )

  // Filter out specific team events from users calendar and build the agenda
  const currentAgendaEvents = useMemo(() => {
    const _events = isCurrentUser
      ? currentUserAgendaEvents
      : currentUserAgendaEvents.filter((event) => event.teamId === id) //.sort()

    return preProcessEvents(_events as CalendarEvent[], 7)
  }, [id, isCurrentUser, currentUserAgendaEvents])

  useEffect(() => {
    isTeam && team?.id && fetchFiles()
  }, [])

  useEffect(() => {
    // fetch agenda events incase there are any new events
    const fetchEvents = async () => {
      const { data = [] } = await getEvents({
        startDate: moment().startOf('day').toISOString(),
        endDate: moment().add(1, 'week').endOf('day').toISOString(),
        summaryOnly: true,
        teamIds: teamIds.concat(['-1']),
      } as QueryEventsRequest)
      const sortedEvents = data.sort((a, b) =>
        a.startDate && b.startDate
          ? moment(a.startDate).diff(moment(b.startDate))
          : 0
      )
      if (!isEqual(sortedEvents, currentAgendaEvents)) {
        setAgendaEvents(sortedEvents)
      }
    }
    fetchEvents()
  }, [id, isCurrentUser])

  const isAdmin = useMemo(() => {
    if (team) {
      const _admins = team.admins ?? []
      return _admins.findIndex((a) => a?.userId === currentUser.id) > -1
    }
    return false
  }, [team, currentUser.id])

  const canPost = useMemo(() => {
    // Check if team member can post on a team
    if (team) {
      // Only admins can post, let's check if user is an admin
      if (!team.canPost) {
        return isAdmin
      }
      return true
    }

    // Check if the user is on his own profile.
    // If props.id is undefined or is equal to user.id from redux store user can edit
    if (!id || id === currentUser.id) {
      return true
    }
    return false
  }, [team, id, currentUser.id, isAdmin])

  const onClickEditIntro = () => {
    props.onClickEditIntro?.()
  }

  const handleOnClickViewFile = useCallback(
    (file: File) => {
      showLightboxWithItems({ index: 0, items: [file] })
    },
    [showLightboxWithItems]
  )

  const handleOnClickDownloadFile = useCallback(
    (file: File) => {
      downloadItem(file)
    },
    [downloadItem]
  )

  const handleOnClickDeleteFile = useCallback(
    (file: File) => {
      sendConfirm({
        confirmText: 'Delete',
        isDestructive: true,
        message: 'Are you sure you want to delete this file?',
        title: 'Delete File?',
        onConfirm: async () => {
          const { ok } = await deleteTeamFile({ fileId: file.fileId })
          if (ok) {
            setFiles(files.filter((f) => f.id !== file.id))
            sendBanner({
              autoDismiss: true,
              message: 'File deleted',
              status: 'success',
            })
          }
        },
      })
    },
    [deleteTeamFile, files, sendBanner, sendConfirm]
  )

  const handleOnTeamFilesChanged = useCallback(fetchFiles, [fetchFiles])

  const onUploaderUploadStart = useCallback(
    (newUploads: Array<ExtendedUpload>, currentUpload: ExtendedUpload) => {
      console.log('onUploaderUploadStart', { newUploads, currentUpload })
      setIsUploading(true)
      // setPendingUploads([...pendingUploads, ...newUploads])
    },
    []
  )
  const onUploaderUploadProgress = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      setIsUploading(true)
    },
    []
  )
  const onUploaderCancel = useCallback(
    (cancelledUpload: ExtendedUpload | undefined): void => {
      console.log('onUploaderCancel', { cancelledUpload })
    },
    []
  )
  const onUploaderError = useCallback(
    (newUploads: ExtendedUpload[], cancelledUpload: ExtendedUpload): void => {
      console.log('onUploaderError', { newUploads, cancelledUpload })
    },
    []
  )
  const onUploaderUploadDone = useCallback(
    (newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      console.log('onUploaderUploadDone', { newUploads, currentUpload })
    },
    []
  )

  const handleOnClickAgendaEvent = (event?: CalendarEvent, date?: string) => {
    if (!event?.id) return

    const dateForMonth = moment(date ?? event.startDate).format('YYYY-MM-DD')
    let currentPath = window.location.pathname.replace('/feed', '')
    if (currentPath.includes('profile')) {
      currentPath = ''
    }
    navigate(`${currentPath}/calendar/month/${dateForMonth}/${event.id!}`, {
      state: {
        id,
      },
    })
  }

  const handleOnOpen = () => {
    const appHeight = document.getElementById('root')?.scrollHeight ?? 0
    const bodyHeight = document.body.clientHeight

    if (appHeight > bodyHeight) {
      const headerHeight =
        (document.body.querySelector('header[role="banner"]')?.clientHeight ??
          66) + 10 // 10px offset for margins

      const { top: y } = getBoundingBox(postCreatorRef)

      window.scrollTo({
        top: y - headerHeight,
        behavior: 'smooth',
      })
    }
  }

  const handleOnCreatePost = useCallback(
    async (post: MutationPostCreateArgs) => {
      const { targetIds: ids } = post
      const _post = post
      _post.postTypes = ids.map((id) =>
        id === currentUser.id! ? 'user' : 'team'
      )

      const scheduledTime: any = _post.scheduledTime
      if (scheduledTime) {
        if (scheduledTime instanceof Date) {
          _post.scheduledTime = scheduledTime.toISOString()
        }
      }

      const { data, error, ok } = await createPost({
        ..._post,
      } as MutationPostCreateRequest)

      if (data && ok) {
        setShouldRefetchPosts(true)
        logEvent('postCreate', {
          isUserPost: post.targetIds.some((id) => id?.startsWith('us-')),
          isTeamPost: post.targetIds.some((id) => id?.startsWith('te-')),
          isMultiPost: post.targetIds.length > 1,
          isTextPost: !!post.message && !post.color,
          isColoredTextPost: !!post.message && !!post.color,
          isPollPost: !!post.poll,
          uploadAttached: !!post.uploads?.length,
          isScheduledPost: !!post.scheduledTime,
        })
        if (post.uploads?.length) fetchFiles()
        sendBanner({
          autoDismiss: true,
          dismissTime: 6000,
          status: 'success',
          message: 'Post created',
        })
      }
      if (error) {
        console.log({ error })
        sendBanner({
          autoDismiss: true,
          message:
            'There was an error creating a new post. Please try again later or contact support',
          status: 'danger',
        })
      }
    },
    [createPost, currentUser.id, logEvent, sendBanner]
  )

  // When a post with files is deleted, we need to update the files widget
  const handleOnDeletePost = useCallback(async (postOrId: Post | string) => {
    // Ignore user profiles
    if (!team?.id) return
    let _post: Post | string = postOrId
    if (typeof _post === 'string') {
      const { data = {} } = await getPost({ id: _post })
      _post = data
    }
    // check for files
    const fileIds = _post.uploads
      ?.filter((upload) => isFile(upload?.contentType as string))
      .map((up) => up?.id)
    setFiles((prevFiles) =>
      prevFiles.filter((file) => !fileIds?.includes(file.id))
    )
  }, [])

  const handleOnUpdatePost = useCallback(
    async (post: MutationPostUpdateArgs) => {
      // throw new Error('Function not implemented.')
    },
    []
  )

  const handleEndReached = useCallback(() => {
    if (!hasMoreFiles) return
    const page = filesPage + 1
    fetchFiles(page)
  }, [filesPage, hasMoreFiles])

  type TeamMemberTypes = keyof Pick<
    Team,
    'coaches' | 'members' | 'parents' | 'players'
  >

  const renderTeamMemberWidget = (type: string) => {
    const typeTitle = type === 'parents' ? 'Family' : capitalize(type)

    const members = team?.[type as TeamMemberTypes] as TeamMember[]
    if (!members?.length) {
      return null
    }
    return (
      <StyledWidget bodyStyle={{ padding: 0 }}>
        <Widget.Header style={{ gap: 4 }}>
          <b>{typeTitle}</b> ({members.length})
        </Widget.Header>
        <Widget.Text style={{ padding: 0 }}>
          {members.map(renderTeamMemberWidgetItem)}
        </Widget.Text>
      </StyledWidget>
    )
  }

  const renderTeamMemberWidgetItem = (item: TeamMember) => {
    const avatarImage = getProfileImage.getProfileAvatarImageUrl(
      item as Profile
    )

    const onClick = () => {
      navigate(`/profile/${item.userId}`)
    }

    const tagData: Array<SingleTagProps> = []
    if (item.adminRoles && item.adminRoles.length > 0) {
      tagData.push({
        fill:
          item.adminRoles[0] === 'admin' ? Colors.HAVELOCK_BLUE : Colors.PERANO,
        title: capitalize(item.adminRoles[0] as string),
      })
    }

    if (item.aliases && item.aliases.length > 0) {
      item.aliases.forEach((alias) =>
        tagData.push({
          fill: Colors.MYSTIC,
          title: alias as string,
          style: {
            display: 'block',
            fontSize: 12,
            lineHeight: '1.5em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        })
      )
    }

    return (
      <WidgetItem
        $active={id === item.id}
        aria-label={item.fullName!}
        key={item.id}
        onClick={onClick}
        title={item.fullName!}
      >
        <Avatar diameter={28} name={item.fullName!} uri={avatarImage} />
        <WidgetItemRowTitle>
          <span>{item.fullName!}</span>
          {tagData.length > 0 && <StyledTagGroup items={tagData} />}
        </WidgetItemRowTitle>
      </WidgetItem>
    )
  }

  const renderPostListHeader = () => {
    return canPost ? (
      <PostCreator
        allowScrolling
        canCreatePoll={!team?.isLargeTeam ?? true}
        currentUser={currentUser}
        isCurrentUserProfile={isCurrentUser}
        onCreatePost={handleOnCreatePost}
        onOpen={handleOnOpen}
        onUpdatePost={handleOnUpdatePost}
        ref={postCreatorRef}
        teamId={team?.id ?? undefined}
        teams={teamsAndGroupsTheUserCanPostTo}
      />
    ) : null
  }

  const renderProfileAboutText = () => {
    const about = team?.about ?? user?.about ?? currentUser.about
    if (about) {
      return (
        <Widget.Text style={{ wordBreak: 'break-word' }}>
          <FormattedText>{about}</FormattedText>
        </Widget.Text>
      )
    } else if (isCurrentUser) {
      return (
        <Widget.Text style={{ color: Colors.SHUTTLE_GRAY, fontSize: 14 }}>
          Share a little about yourself
        </Widget.Text>
      )
    }
    return null
  }

  return (
    <FeedContainer>
      <FeedContent>
        {renderPostListHeader()}
        <StyledPostList
          canPost={canPost}
          currentUser={(user as User).id ? (user as User) : currentUser}
          onDeletePost={handleOnDeletePost}
          id={id}
          refetch={shouldRefetchPosts}
          onRefetch={() => {
            setShouldRefetchPosts(false)
          }}
          onTeamFilesChanged={handleOnTeamFilesChanged}
          showComments
        />
      </FeedContent>
      {!isSmallScreen && (
        <FeedAside>
          {Object.keys(user ?? {}).length > 0 && (
            <StyledWidget contentMaxHeight={240}>
              <Widget.Header
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <b>Intro</b>
                {isCurrentUser && (
                  <EditButton appearance='minimal' onClick={onClickEditIntro}>
                    Edit
                  </EditButton>
                )}
              </Widget.Header>

              {renderProfileAboutText()}
            </StyledWidget>
          )}
          {isTeam && team?.about && (
            <StyledWidget contentMaxHeight={240}>
              <Widget.Header
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <b>About</b>
              </Widget.Header>

              {renderProfileAboutText()}
            </StyledWidget>
          )}
          {isTeam || isCurrentUser ? (
            <StyledWidget
              events={currentAgendaEvents}
              onClickEvent={handleOnClickAgendaEvent}
            />
          ) : null}
          {isTeam && (
            <FilesWidget
              emptyState={
                <>
                  <P>No {team?.type ?? 'team'} files</P>
                  <P>Posted {team?.type ?? 'team'} files will appear here.</P>
                </>
              }
              files={files}
              isAdmin={isAdmin}
              isLoading={isLoadingFiles}
              isUploading={isUploading}
              // Uploader handlers
              onUploaderCancel={onUploaderCancel}
              onUploaderError={onUploaderError}
              onUploaderUploadComplete={onUploaderUploadComplete}
              onUploaderUploadDone={onUploaderUploadDone}
              onUploaderUploadProgress={onUploaderUploadProgress}
              onUploaderUploadStart={onUploaderUploadStart}
              // File handlers
              onClickDeleteFile={handleOnClickDeleteFile}
              onClickDownloadFile={handleOnClickDownloadFile}
              onClickViewFile={handleOnClickViewFile}
              onEndReached={handleEndReached}
              style={{ marginBottom: 10, marginTop: 10 }}
            />
          )}
          {!isLargeTeam ? (
            <>
              {team?.type === 'team' && (
                <>
                  {renderTeamMemberWidget('coaches')}
                  {renderTeamMemberWidget('players')}
                  {renderTeamMemberWidget('parents')}
                </>
              )}
              {team?.type === 'group'
                ? renderTeamMemberWidget('members')
                : null}
            </>
          ) : null}
        </FeedAside>
      )}
    </FeedContainer>
  )
}

const FeedContainer = styled.div`
  display: flex;
  margin-bottom: 60px;
  min-height: 600px; // matches min-height of PostComposer component
`
const FeedContent = styled.main`
  flex: 1 1 400px;
`
const StyledPostList = styled(PostList)<{ canPost: boolean }>`
  margin-top: ${({ canPost }) => (canPost ? '10px' : undefined)};
`
const FeedAside = styled.aside`
  flex: 0 1 300px;
  margin-left: 10px;
  min-width: 1px;
`
const StyledWidget = styled(Widget)`
  & + & {
    margin-top: 10px;
  }
`
const EditButton = styled(Button)`
  && {
    min-height: 1px;
    min-width: 1px;
    padding: 2px 10px;
  }
`
const AddFileButton = styled(Button)`
  && {
    min-height: 1px;
    min-width: 1px;
    padding: 2px 10px;
  }
`
const MoreFilesButton = styled(Button)`
  && {
    min-height: 1px;
    min-width: 1px;
    padding: 4px 8px;
  }
`
const WidgetFileContaner = styled.div<{ $height: number }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${({ $height }) => $height}px;
  padding: 10px;
  transition: background-color 120ms ease-in-out;
  &:hover {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const WidgetFileNameContainer = styled.div`
  cursor: pointer;
  flex-wrap: wrap;
  flex: 1 1 auto;
  // font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`
const WidgetFileName = styled.div`
  cursor: pointer;
  flex-wrap: wrap;
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`
const WidgetFileDate = styled.div`
  cursor: pointer;
  flex-wrap: wrap;
  flex: 1 1 auto;
  font-size: 11px;
  line-height: 11px;
`
const WidgetDownloadButton = styled(Button)`
  && {
    border-radius: 50%;
    min-height: 32px;
    min-width: 32px;
    & + & {
      margin-left: 4px;
    }
  }
`
const WidgetItem = styled.div<{ $active: boolean }>`
  align-items: center;
  background-color: ${({ $active }) =>
    $active ? Colors.CATSKILL_WHITE : Colors.WHITE};
  cursor: pointer;
  display: flex;
  padding: 10px;
  position: relative;
  transition: background-color 90ms ease-in-out;
  border-radius: 8px;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
  &::before {
    content: '';
    background-color: blue;
    display: ${({ $active }) => ($active ? 'block' : 'none')};
    width: 2px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
`
const WidgetItemRowTitle = styled.div`
  font-size: 14px;
  margin-left: 6px;
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  & > span {
    font-weight: 500;
    &:first-child {
      white-space: nowrap;
    }
  }
`
const P = styled.p`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  &:last-child {
    margin-bottom: 10px;
  }
`
const StyledTagGroup = styled(TagGroup)`
  display: flex;
  white-space: nowrap;
  min-width: 1px;
`
const FileUploaderProgress = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: ${Colors.SHUTTLE_GRAY};
`

export default ProfileFeedTab
