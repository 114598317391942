import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  Team as TeamProps,
  mutationTeamNewSignupCode,
  queryTeamSignupCode,
  getQRCode,
} from '@sportsyou/api'

import {
  Activity,
  Button,
  FadeInContainer,
  Modal,
  Skeleton,
  Spinner,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { Colors, formatTeamCode } from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'
import { Checkmark } from '@sportsyou/react-icons'

import { useAddMembers } from 'web/hooks'
import AddMembersFromContacts from './AddMembersFromContacts'
import { environment } from 'apps/web/src/environments/environment'

interface Props {
  id?: string
  isVisible: boolean
  shouldNavigateToTeam?: boolean
  team?: TeamProps
}

export const AddMembersModal: React.FC<Props> = ({ ...props }: Props) => {
  const { id, isVisible, setIsVisible, shouldNavigateToTeam, team } =
    useAddMembers()
  const { sendBanner, sendConfirm } = useDialog()
  const navigate = useNavigate()

  const [addByContacts, setAddByContacts] = useState<boolean>(false)
  const [isCopying, setIsCopying] = useState<boolean>()
  const [qrCode, setQrCode] = useState<string>()
  const [teamCode, setTeamCode] = useState<string>('')
  const [teamId, setTeamId] = useState<string>('')

  const { fetch: getCode } = useFetchApi(queryTeamSignupCode)
  const { fetch: createNewCode } = useFetchApi(mutationTeamNewSignupCode)

  const displayTeamCode = useMemo(() => formatTeamCode(teamCode), [teamCode])

  const fetchQRCode = useCallback(async () => {
    const { ok, base64 } = await getQRCode(teamId)
    if (ok) {
      setQrCode(base64)
    }
  }, [teamId])

  const fetchCode = useCallback(async () => {
    if (teamId && teamId !== '') {
      const { data, ok } = await getCode({ teamId })
      if (data && ok) {
        setTeamCode(data)
      }
    }
  }, [getCode, teamId])

  const onClickCopyCode = useCallback(() => {
    teamCode && navigator.clipboard.writeText(teamCode)
    teamCode && setIsCopying(true)
  }, [teamCode])

  const onClickRegenerateCode = useCallback(() => {
    sendConfirm({
      title: `Regenerate signup code?`,
      message:
        'Once you regenerate your code, the old code will no longer be valid. This action cannot be undone.',
      confirmText: 'Regenerate',
      onConfirm: async () => {
        setQrCode(undefined)
        await createNewCode({ teamId })
        fetchQRCode()
        fetchCode()
        sendBanner({
          autoDismiss: true,
          message: 'A new code has been created.',
          status: 'success',
        })
      },
    })
  }, [createNewCode, fetchCode, fetchQRCode, sendBanner, sendConfirm, teamId])

  const onClickDownloadPDF = useCallback(() => {
    const link = document.createElement('a')
    link.href = `${environment.urls.staticApi}?action=getTeamSignupPdf&teamId=${teamId}`
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  }, [teamId])

  const onClickAddMembers = useCallback(() => {
    setAddByContacts(true)
  }, [])

  const onClickDismiss = useCallback(() => {
    setIsVisible(false)
    if (shouldNavigateToTeam && team) {
      navigate(`/teams/${team.id}`, { state: { id: team.id } })
    }
  }, [setIsVisible, team])

  useEffect(() => {
    if (isVisible) {
      setAddByContacts(false)
    }
  }, [isVisible])

  useEffect(() => {
    if (id || team) {
      setTeamId(id ?? team?.id ?? '')
    }
  }, [id, team])

  useEffect(() => {
    if (teamId && teamId !== '') {
      fetchCode()
      fetchQRCode()
    }
  }, [fetchCode, fetchQRCode, teamId])

  useEffect(() => {
    if (isCopying) {
      setTimeout(() => {
        setIsCopying(false)
      }, 3000)
    }
  }, [isCopying])

  if (addByContacts) {
    return (
      <AddMembersFromContacts
        team={team}
        visible={isVisible}
        onClose={onClickDismiss}
        {...props}
      />
    )
  }

  return (
    <Modal
      backdropIgnoresClicks
      testId='add-members-modal'
      onClose={onClickDismiss}
      visible={isVisible}
      {...props}
    >
      <Modal.Header style={ModalHeaderStyle}></Modal.Header>
      <Modal.Body>
        <Container>
          <Heading>Add Members</Heading>

          <Text>
            You can add members below through copy/paste of Access Code,
            selection and print of "Download PDF" or selection of "Add Members
            via Contacts or Email".
          </Text>
        </Container>

        <QRCodeContainer>
          {!!qrCode ? (
            <FadeInContainer>
              <QRCodeImage
                alt={`QR Code for ${team?.name ?? 'team'}`}
                src={qrCode}
              />
            </FadeInContainer>
          ) : (
            <Spinner />
          )}
        </QRCodeContainer>

        <TeamCodeContainer>
          <CopyCodeContainer>
            <CodeContainer>
              {!qrCode ? (
                <>
                  <Activity position='absolute' />
                  <Skeleton barHeight={10} width={80} />
                </>
              ) : displayTeamCode ? (
                displayTeamCode
              ) : (
                <Activity position='absolute' />
              )}
            </CodeContainer>
            <CopyCodeButton
              disabled={!teamCode}
              onClick={onClickCopyCode}
              variant='success'
            >
              {isCopying ? (
                <>
                  <Checkmark fill={Colors.WHITE} /> Copied
                </>
              ) : (
                'Copy Code'
              )}
            </CopyCodeButton>
          </CopyCodeContainer>
          <RegenCodeButton
            appearance='minimal'
            collapse
            disabled={!teamCode}
            onClick={onClickRegenerateCode}
          >
            Regenerate Code
          </RegenCodeButton>
        </TeamCodeContainer>

        <ButtonContainer>
          <StyledButton onClick={onClickDownloadPDF}>Download PDF</StyledButton>
          <StyledButton onClick={onClickAddMembers}>
            Add Members via Contacts or Email
          </StyledButton>

          <StyledButton
            appearance='minimal'
            onClick={onClickDismiss}
            style={{ alignSelf: 'center' }}
            variant='alternate'
          >
            Dismiss
          </StyledButton>
        </ButtonContainer>
      </Modal.Body>
    </Modal>
  )
}

const ModalHeaderStyle = {
  border: 0,
  paddingBottom: 0,
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const Heading = styled.h2`
  margin-bottom: 0px;
  text-align: center;
`
const Text = styled.p`
  margin-bottom: 0px;
  margin-top: 10px;
  max-width: 300px;
  text-align: center;
`
const QRCodeContainer = styled.div`
  align-items: center;
  display: flex;
  height: 205px;
  justify-content: center;
`
const QRCodeImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 205px;
  width: 205px;
`
const TeamCodeContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 300px;
`
const CopyCodeContainer = styled.div`
  align-items: center;
  justify-content: stretch;
  display: flex;
  height: 40px;
  // overflow: hidden;
`
const RegenCodeButton = styled(Button)`
  align-self: flex-start;
  margin-top: 4px;
  padding-bottom: 2px;
  padding-top: 2px;
  && {
    min-height: 1px;
  }
`
const CodeContainer = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid ${Colors.ALTO};
  border-bottom-left-radius: 6px;
  border-right: none;
  border-top-left-radius: 6px;
  display: flex;
  flex: 1 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  overflow: hidden;
`
const CopyCodeButton = styled(Button)`
  align-self: stretch;
  width: 100px;
  && {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto 20px;
  max-width: 300px;
`
const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-top: 20px;
  }
`

export default AddMembersModal
