import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import styled from 'styled-components'

import { Select } from '@sportsyou/react-dom-ui'
import { Sport } from '@sportsyou/api'

import { fetchSports, selectSports } from '../../../store/slices/SportsSlice'

export interface SportsPickerProps {
  className?: string
  label?: string
  menuStyle?: React.CSSProperties
  onChange: (sport: Sport) => void
  placeholder?: string
  selectedSport?: Sport
  style?: React.CSSProperties
  testId?: string
  triggerStyle?: React.CSSProperties
}

export const SportsPicker: React.FC<SportsPickerProps> = ({
  className,
  label,
  menuStyle = { minHeight: 218 },
  onChange,
  placeholder = 'Choose Sport',
  selectedSport,
  style,
  testId,
  triggerStyle,
}: SportsPickerProps) => {
  const dispatch = useDispatch()

  const sports = useSelector(selectSports)

  useEffect(() => {
    dispatch(fetchSports())
  }, [dispatch])

  const onClick = (sport: Sport) => {
    onChange(sport)
  }

  return (
    <StyledSelect
      className={className}
      displayValue={selectedSport?.name ?? undefined}
      filterable
      initialValue={selectedSport?.name ?? undefined}
      label={label}
      menuStyle={menuStyle}
      placeholder={placeholder}
      style={style}
      testId={testId}
      triggerStyle={triggerStyle}
    >
      {sports?.map((sport: Sport, index: number) => (
        <Select.Option
          isSelected={selectedSport?.id === sport.id}
          key={index}
          onClick={() => onClick(sport)}
          value={`${sport.id}`}
        >
          {sport.name}
        </Select.Option>
      ))}
    </StyledSelect>
  )
}

const StyledSelect = styled(Select)`
  /* margin-top: 10px; */
  max-height: 218px;
`

export default SportsPicker
