import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { User as UserProps } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { Avatar, Modal } from '@sportsyou/react-dom-ui'
import { getUsername } from 'web/utils/user'

interface Props {
  className?: string
  isVisible: boolean
  likes: Array<UserProps>
  onClose: () => void
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type PostLikeModalProps = Props & NativeAttributes

export const PostLikeModal: React.FC<PostLikeModalProps> = ({
  className,
  isVisible,
  likes,
  onClose,
}: PostLikeModalProps) => {
  const navigate = useNavigate()

  const handleOnClose = () => {
    onClose && onClose()
  }

  const handleOnClickUser = useCallback(
    (user: UserProps) => {
      const { id } = user
      navigate(`/profile/${id}`, { state: { id } })
    },
    [navigate]
  )

  const renderBody = useMemo(
    () =>
      likes.map((user: UserProps, index: number) => {
        const name = getUsername(user)
        const avatarImageUrl = user.profileImage?.[0]?.viewUrl ?? undefined
        return (
          <LikeRow
            aria-label={`Profile for ${name}`}
            key={index}
            onClick={() => handleOnClickUser(user)}
            title={`Profile for ${name}`}
          >
            <Avatar diameter={40} name={name} uri={avatarImageUrl} />
            <Name>{name}</Name>
          </LikeRow>
        )
      }),
    [handleOnClickUser, likes]
  )

  return (
    <Modal className={className} onClose={handleOnClose} visible={isVisible}>
      <Modal.Header>
        <b>Likes</b>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 1, padding: 0 }}>{renderBody}</Modal.Body>
    </Modal>
  )
}

const LikeRow = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px 12px;
  transition: background-color 120ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.MYSTIC};
  }
`
const Name = styled.span`
  margin-left: 10px;
`

export default PostLikeModal
