import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'

import { AVATAR_AND_CALENDAR_COLORS, Colors } from '@sportsyou/core'

import {
  Calendar,
  CalendarMode,
  CalendarViewDate,
} from '@sportsyou/react-dom-ui'

import { useFetchApi } from '@sportsyou/react-hooks'
import {
  mutationEventAttending,
  MutationEventAttendingRequest,
  Event,
  QueryEventsRequest,
  queryEvents,
  Team,
  MutationEventUpdateRequest,
  mutationEventUpdate,
  Event as EventProps,
} from '@sportsyou/api'

import { RootState } from 'web/store/rootReducer'

import { BORDER_RADIUS, FadeInContainer } from '@sportsyou/react-dom-ui'
import {
  removeCalendarEvent,
  selectAllTeamsAndGroups,
  selectCalendarEventsByTeamId,
  selectCurrentUser,
  selectDefaultTimezone,
  setCalendarEvents,
  updateCalendarEvent,
} from 'web/store/slices'
import convertEventToEventInput from 'web/utils/ConvertEventToEventInput'
import DeleteEvent from '../../../components/Modals/DeleteEvent/DeleteEvent'
import { SMALL_SCREEN_BREAKPOINT } from 'web/constants'

export interface ProfileCalendarTabRef {
  fetchEvents: () => void
}

export interface ProfileCalendarTabProps {
  team?: Team
}

interface ProfilePageParams {
  albumId?: string
  date?: string
  eventId?: string
  mediaType?: string
  mode?: CalendarMode
  tab?: string
}

export const ProfileCalendarTab: React.ForwardRefExoticComponent<
  ProfileCalendarTabProps & React.RefAttributes<ProfileCalendarTabRef>
> = React.forwardRef<ProfileCalendarTabRef>(
  (props: ProfileCalendarTabProps & { children?: React.ReactNode }, ref) => {
    const { team } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { albumId, eventId, mediaType, tab } = params as ProfilePageParams
    let { date: modeDate, mode } = params as ProfilePageParams
    if (tab === 'calendar' && !mode && mediaType) {
      mode = mediaType as CalendarMode
    }
    if (tab === 'calendar' && !modeDate && albumId) {
      modeDate = albumId
    }

    const currentUser = useSelector(selectCurrentUser)
    const teamEvents: Event[] = useSelector((state: RootState) =>
      selectCalendarEventsByTeamId(state, team?.id ?? '')
    )
    const teams = useSelector(selectAllTeamsAndGroups)
    const defaultTimezone = useSelector(selectDefaultTimezone)

    const { fetch: updateEvent } = useFetchApi(mutationEventUpdate)
    const { fetch: getEvents } = useFetchApi(queryEvents)
    const { fetch: setAttendance } = useFetchApi(mutationEventAttending)

    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteEventModalVisible, setIsDeleteEventModalVisible] =
      useState(false)

    const [eventToDelete, setEventToDelete] = useState<EventProps>()

    const isCurrentUser = useMemo(() => !team, [team])
    const teamIds = useMemo(() => teams.map((team) => team.id), [teams])

    const canEdit = useMemo(() => {
      if (isCurrentUser) return true

      if (team && team.id) {
        return !!team.isAdmin
      }
      return false
    }, [isCurrentUser, team])

    const teamEventsToDisplay = useMemo(
      () =>
        teamEvents.map((event) => ({
          ...event,
          color: AVATAR_AND_CALENDAR_COLORS[0],
        })),
      [teamEvents]
    )

    const fetchEvents = async (
      start: Date = new Date(),
      mode: CalendarMode = 'month'
    ) => {
      setIsLoading(true)
      const startDate =
        mode === 'year'
          ? moment(start).startOf(mode).format('YYYY-MM-DD')
          : moment(start).subtract(1, mode).startOf(mode).format('YYYY-MM-DD')
      const endDate =
        mode === 'year'
          ? moment(start).endOf(mode).format('YYYY-MM-DD')
          : moment(start).add(1, mode).endOf(mode).format('YYYY-MM-DD')

      const { data = [], error } = await getEvents({
        startDate,
        endDate,
        summaryOnly: true,
        teamIds: team?.id ? [team.id] : teamIds.concat(['-1']),
      } as QueryEventsRequest)

      if (data) {
        const _data = data.sort((a, b) =>
          a.startDate && b.startDate
            ? moment(a.startDate).diff(moment(b.startDate))
            : 0
        )
        dispatch(setCalendarEvents(_data))
      }
      setIsLoading(false)

      // TODO: Gracefully handle errors
      if (error) {
        console.log({ error })
      }
    }

    useEffect(() => {
      fetchEvents()
    }, [])

    const handleOnClickViewEvent = (event: Event) => {
      const { id, isEditable, isLargeTeam } = event
      if (isEditable) {
        navigate(`/calendar/${id}/edit`, {
          state: { isLargeTeam },
        })
      } else {
        navigate(`/calendar/${id}`, { state: { isLargeTeam } })
      }
    }

    const handleOnClickDeleteEvent = async (event: Event) => {
      setEventToDelete(event)
      setIsDeleteEventModalVisible(true)
    }

    const handleOnCloseDeleteEventModal = (didDelete: boolean) => {
      setIsDeleteEventModalVisible(false)
      if (didDelete && eventToDelete) {
        dispatch(removeCalendarEvent(eventToDelete))
      }
      setEventToDelete(undefined)
    }

    const handleOnClickAttendance = async (event: Event, attending: string) => {
      const { data, error, ok } = await setAttendance({
        eventId: event.id,
        attending,
      } as MutationEventAttendingRequest)
      if (data && ok) {
        dispatch(updateCalendarEvent({ ...event, attending }))
      }
      if (error) {
        console.log({ error })
      }
    }

    const handleOnChangeCalendar = (
      mode: CalendarMode,
      date?: CalendarViewDate,
      diff?: number
    ) => {
      date?.display && fetchEvents(date?.display, mode)
    }

    const handleCreateNewEvent = (date?: Date) => {
      if (!canEdit) return
      const newDate = moment(date).format('YYYY-MM-DD')
      navigate(`/calendar/new?date=${newDate}`, {
        state: { team },
      })
    }

    const handleOnUpdateEvent = async (
      origEvent: Event,
      newEvent: Event,
      shouldNotify: boolean
    ) => {
      const event = convertEventToEventInput(newEvent)

      const { error, ok } = await updateEvent({
        event,
        notify: shouldNotify,
      } as MutationEventUpdateRequest)
      if (ok) {
        fetchEvents()
      }
      if (error) {
        console.log({ error })
      }
    }

    useImperativeHandle(ref, () => ({
      fetchEvents,
    }))

    return (
      <FadeInContainer>
        <StyledCalendar
          defaultTimezone={defaultTimezone}
          eventId={eventId}
          events={teamEventsToDisplay}
          isLoading={isLoading}
          onChange={handleOnChangeCalendar}
          onClickAttendance={handleOnClickAttendance}
          onClickDay={(date: Date) => handleCreateNewEvent(date)}
          onClickDeleteEvent={handleOnClickDeleteEvent}
          onClickNewEvent={handleCreateNewEvent}
          onClickViewEvent={handleOnClickViewEvent}
          onUpdateEvent={handleOnUpdateEvent}
          showCreateEventInToolbar={canEdit}
          useEventType
          useCustomToolbar
        />

        <DeleteEvent
          isVisible={isDeleteEventModalVisible}
          event={eventToDelete ?? {}}
          onClose={handleOnCloseDeleteEventModal}
        />
      </FadeInContainer>
    )
  }
)

export default ProfileCalendarTab

const StyledCalendar = styled(Calendar)`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  padding: 10px;
  align-self: flex-start;
  flex: 1 1 auto;
  @media all and (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    align-self: stretch;
  }
`
