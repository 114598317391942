/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 * https://github.com/react-ga/react-ga/blob/master/demo/app/withTracker.jsx
 */

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from 'web/hooks'
import { cleanUpPathname, maskId } from 'web/utils/clean-up-pathname'

export function withTracker(WrappedComponent: any, options: any = {}) {
  const HOC: React.FC = (props: any) => {
    const { logEvent } = useAnalytics()
    const location = useLocation()

    useEffect(() => {
      // const pageName = cleanUpPathname(location.pathname)
      const pageName = maskId(location.pathname)
      console.log({ pageName })
      logEvent('pageVisited', { pageName })
    }, [location])

    return <WrappedComponent {...props} />
  }

  return HOC
}

export default withTracker
