import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  CacheOptions,
  ChatMessage,
  ChatParticipant,
  getMetadata,
  GetMetadataRequest,
  GetMetadataResponse,
  PostMetadata,
  Profile,
  Upload,
  VideoPlaylistSummary,
} from '@sportsyou/api'
import {
  Colors,
  darken,
  getProfileImage,
  getThumbnail,
  isGif,
  isNonMedia,
  lighten,
  parseUrls,
} from '@sportsyou/core'
import { useWindowResize } from '@sportsyou/react-hooks'
import {
  Avatar,
  FileTypeIcon,
  FormattedText,
  VideoPlaylistCard,
} from '@sportsyou/react-dom-ui'

import { environment } from 'apps/web/src/environments/environment'
import { LIKE_TYPE } from './ChatRoom'
import useLightbox from '../../components/Lightbox/useLightbox'

interface ChatRoomMessageProps {
  byCurrentUser: boolean
  message: ChatMessage
  onClickShowReactionsModal: (message: ChatMessage) => void
  onClickToggleLike: (message: ChatMessage) => void
  participants: ChatParticipant[]
}

const MESSSAGE_BACKGROUND_COLOR = '#f3f3f3'
const MESSSAGE_CURRENT_USER_BACKGROUND_COLOR = Colors.PERANO
const METADATA_BOX_BACKGROUND_COLOR = '#edf4fd'

export default function ChatRoomMessage(props: ChatRoomMessageProps) {
  const { message } = props
  const { showWithItems: showLightboxWithItems } = useLightbox()
  const { width: windowWidth } = useWindowResize()

  const [isLoading, setIsLoading] = useState(false)
  const [metadata, setMetadata] = useState<PostMetadata[]>([])
  const [urls, setUrls] = useState(parseUrls(props.message.message ?? ''))

  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    async function fetchMetadata() {
      const md: PostMetadata[] = []
      urls.length && setIsLoading(true)
      for (const url of urls) {
        const req: GetMetadataRequest = {
          url,
          requestOptions: {
            useCache: CacheOptions.ALWAYS,
          },
        }
        const resp: GetMetadataResponse = await getMetadata(req)
        if (!resp?.error) {
          resp?.data && md.push(resp.data)
        }
      }
      setMetadata(md)
      setIsLoading(false)
    }
    urls.length && fetchMetadata()
  }, [urls])

  const user = useMemo(() => {
    const participant = props.participants?.find(
      (participant) => participant.id === message.createdById
    )
    return participant ?? {}
  }, [props.participants, message.createdById])

  const avatarUri = useMemo(() => {
    return getProfileImage.getProfileAvatarImageUrl(user as Profile)
  }, [user])

  const likesCount = useMemo(
    () =>
      message?.likes?.find((like) => like?.likeType === LIKE_TYPE)?.count ?? 0,
    [message.likes]
  )

  const onClickToggleLike = useCallback(() => {
    props.onClickToggleLike(props.message)
  }, [props])

  const onClickShowReactionsModal = useCallback(() => {
    props.onClickShowReactionsModal(props.message)
  }, [props])

  const onClickMedia = useCallback(
    async (upload: Upload) => {
      showLightboxWithItems({
        items: [upload],
        index: 0,
      })
    },
    [showLightboxWithItems]
  )

  const onClickVideoPlaylistCard = useCallback(
    (playlist: VideoPlaylistSummary) => {
      window.location.assign(
        `${environment.urls.syVideo}/playlists/${playlist.id}`
      )
    },
    []
  )

  // Set media dimensions to allow virtuoso to scroll properly on load/mount
  const mediaDimensions = useCallback(
    (upload?: Upload) => {
      if (!upload || !contentRef.current) return undefined
      const { height: ogHeight, width: ogWidth } = upload
      if (!ogHeight || !ogWidth) return undefined

      const width = contentRef.current?.offsetWidth * 0.6
      const height = (width * ogHeight) / ogWidth

      return { height, width } as React.CSSProperties
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowWidth]
  )

  function getUrl(upload: Upload): string {
    let url = getThumbnail(upload)

    if (isGif(upload.contentType ?? '')) {
      url = upload.viewUrl ?? url
    }

    return url
  }

  if (message.messageType === 'system') {
    return (
      <SystemMessage>
        <SystemMessageText>{message.message}</SystemMessageText>
        <SystemMessageCreatedAt>
          {moment(message.createdAt).fromNow()}
        </SystemMessageCreatedAt>
      </SystemMessage>
    )
  }

  return (
    <Container byCurrentUser={props.byCurrentUser}>
      {!props.byCurrentUser && (
        <Avatar diameter={30} uri={avatarUri} name={user?.fullName as string} />
      )}
      <Content byCurrentUser={props.byCurrentUser} ref={contentRef}>
        {!props.byCurrentUser && <Name>{user.fullName}</Name>}
        {!!message.message && (
          <Message byCurrentUser={props.byCurrentUser}>
            <FormattedText linkColor={Colors.MINE_SHAFT}>
              {message.message}
            </FormattedText>
          </Message>
        )}
        {!!props.message?.uploads?.length && (
          <>
            {props.message?.uploads?.map((upload, index) => {
              if (isNonMedia(upload?.contentType!)) {
                return (
                  <MediaItemFile onClick={() => onClickMedia(upload!)}>
                    <FileTypeIcon
                      contentType={upload?.contentType!}
                      fileName={upload?.fileName!}
                      nameCase='pascal'
                      size={26}
                    />
                    <MediaItemFileName>{upload?.fileName}</MediaItemFileName>
                  </MediaItemFile>
                )
              } else {
                return (
                  <MediaItem
                    style={mediaDimensions(upload ?? undefined)}
                    key={upload?.id!}
                    onClick={() => onClickMedia(upload!)}
                    src={getUrl(upload!)}
                  />
                )
              }
            })}
          </>
        )}
        {!!props.message.playlists?.length ? (
          <>
            {props.message.playlists.map((playlist, index) => (
              <VideoPlaylistCard
                key={index}
                playlist={playlist as VideoPlaylistSummary}
                onClickVideoPlaylistCard={onClickVideoPlaylistCard}
              />
            ))}
          </>
        ) : null}
        {!isLoading && !!metadata.length && (
          <MetadataContainer byCurrentUser={props.byCurrentUser}>
            {metadata.map((metadata, index) => (
              <MetadataBox
                byCurrentUser={props.byCurrentUser}
                key={`${index}-${metadata.url}`}
              >
                {metadata.image && (
                  <MediaItem
                    onClick={() => window.open(metadata.url, '_blank')}
                    src={metadata.image}
                  />
                )}
                {metadata.title && (
                  <MetadataTitle>{metadata.title}</MetadataTitle>
                )}
                {metadata.description && (
                  <MetadataDescription>
                    {metadata.description}
                  </MetadataDescription>
                )}
                <FormattedText>{metadata.url}</FormattedText>
              </MetadataBox>
            ))}
          </MetadataContainer>
        )}
        <CreatedAt>{moment(message.createdAt).fromNow()}</CreatedAt>
        <Actions byCurrentUser={props.byCurrentUser}>
          <LikeButton onClick={onClickToggleLike} likesCount={likesCount}>
            👍
            {likesCount > 0 && <LikeCount>{likesCount}</LikeCount>}
          </LikeButton>
          {likesCount > 0 && (
            <LikedByButton onClick={onClickShowReactionsModal}>
              Liked By
            </LikedByButton>
          )}
        </Actions>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ byCurrentUser: boolean }>`
  display: flex;
  flex-direction: row;
  // margin-top: 10px;
  padding: 0 10px;
  width: 100%;
`

const Content = styled.div<{ byCurrentUser: boolean }>`
  align-items: ${(props) => (props.byCurrentUser ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-bottom: 20px;
  width: 100%;
`

const Name = styled.div`
  color: ${Colors.DUSTY_GRAY};
  font-size: 12px;
  font-weight: bold;
  overflow: visible;
  white-space: nowrap;
`

const Message = styled.div<{ byCurrentUser: boolean }>`
  align-items: flex-start;
  background: ${(props) =>
    props.byCurrentUser
      ? MESSSAGE_CURRENT_USER_BACKGROUND_COLOR
      : MESSSAGE_BACKGROUND_COLOR};
  border-radius: 20px;
  display: inline-block;
  line-height: 1.43;
  margin-top: 10px;
  max-width: 90%;
  padding: 12px;
  position: relative;
  text-align: left;
  white-space: break-spaces;
  word-wrap: break-word;
  z-index: 1;

  ${({ byCurrentUser }) =>
    byCurrentUser
      ? css`
          &::before {
            border-color: #edf4fd;
            border-right-style: solid;
            border-right-width: 20px;
            border-bottom-left-radius: 16px 14px;
            right: -7px;
          }
          &::before,
          &::after {
            border-bottom-right-radius: 0;
            border-left: none;
            left: auto;
          }

          &::after {
            border-bottom-left-radius: 10px;
            right: -9px;
            width: 9px;
            z-index: 1;
          }
          &::before,
          &::after {
            border-bottom-right-radius: 0;
            border-left: none;
            left: auto;
          }
          &::after {
            content: '';
            background: #ffffff;
            border-bottom-right-radius: 10px;
            left: -9px;
            width: 9px;
            z-index: 1;
          }
          &::before,
          &::after {
            content: '';
            bottom: 0px;
            height: 20px;
            position: absolute;
          }

          &::before {
            border-color: ${MESSSAGE_CURRENT_USER_BACKGROUND_COLOR}; // #edf4fd;
            border-right-style: solid;
            border-right-width: 20px;
            border-bottom-left-radius: 16px 14px;
            right: -7px;
          }

          &::after {
            border-bottom-left-radius: 10px;
            right: -9px;
            width: 9px;
            z-index: 1;
          }

          &::before,
          &::after {
            border-bottom-right-radius: 0;
            border-left: none;
            left: auto;
          }
        `
      : css`
          &::before {
            border-color: ${MESSSAGE_BACKGROUND_COLOR};
            border-left-style: solid;
            border-left-width: 20px;
            border-bottom-right-radius: 16px 14px;
            left: -7px;
            z-index: -1;
          }

          &::after {
            content: '';
            background: ${Colors.WHITE};
            border-bottom-right-radius: 10px;
            left: -9px;
            width: 9px;
            z-index: 1;
          }

          &::before,
          &::after {
            content: '';
            bottom: 0px;
            height: 20px;
            position: absolute;
          }
        `}
`

const CreatedAt = styled.div`
  color: ${Colors.DUSTY_GRAY};
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
`

const Actions = styled.div<{ byCurrentUser: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ byCurrentUser }) =>
    byCurrentUser ? 'row-reverse' : 'row'};
`

const LikeButton = styled.div<{ likesCount: number }>`
  align-items: center;
  background: ${Colors.WHITE};
  border-radius: 20px;
  border: 1px solid
    ${({ likesCount }) =>
      likesCount > 0 ? Colors.HAVELOCK_BLUE : Colors.DUSTY_GRAY};
  cursor: pointer;
  display: flex;
  filter: grayscale(${({ likesCount }) => (likesCount > 0 ? '0' : '1')});
  font-size: 10px;
  padding: 3px 8px;
  transition: all 0.5s ease-in-out;

  &:hover {
    border-color: ${Colors.HAVELOCK_BLUE};
  }
`

const LikeCount = styled.div`
  color: ${Colors.DUSTY_GRAY};
  font-size: 10px;
  font-weight: bold;
  margin-left: 5px;
`

const LikedByButton = styled.span`
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
`

const MediaItem = styled.img`
  border-radius: 20px;
  box-shadow: 0;
  cursor: pointer;
  // margin-top: 10px;
  max-width: 60%;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
  }
`

const MediaItemFile = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  svg {
    margin: 10px;
  }
`

const MediaItemFileName = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 12px;
  font-weight: bold;
  // margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
  white-space: wrap;
`

const SystemMessage = styled.div`
  align-items: center;
  // margin: 20px 0;
`

const SystemMessageText = styled.div`
  color: ${Colors.MINE_SHAFT};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`

const SystemMessageCreatedAt = styled.div`
  color: ${Colors.DUSTY_GRAY};
  font-size: 12px;
  text-align: center;
  padding-bottom: 20px;
`

const MetadataContainer = styled.div<{ byCurrentUser: boolean }>`
  max-width: 90%;
  // padding-left: ${(props) => (props.byCurrentUser ? '35px' : '0')};
  // padding-left: 35px;
`

const MetadataBox = styled.div<{ byCurrentUser: boolean }>`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  margin-bottom: 10px;
  border-radius: 20px;
  // background-color: ${METADATA_BOX_BACKGROUND_COLOR};
  background-color: ${(props) =>
    props.byCurrentUser
      ? lighten(MESSSAGE_CURRENT_USER_BACKGROUND_COLOR, 25)
      : darken(MESSSAGE_BACKGROUND_COLOR, 25)};
  padding: 12px;
  :first-child {
    margin-top: 10px;
  }
  :last-child {
    margin-bottom: 0;
  }
  > * {
    float: right;
  }
  > img {
    width: 100%;
  }
`

const MetadataTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

const MetadataDescription = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
`

// ChatRoomMessage.whyDidYouRender = true
