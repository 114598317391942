import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { mutationLogin, mutationSetUserAuth } from '@sportsyou/api'
import { Colors, isPasswordValid } from '@sportsyou/core'
import {
  BORDER_RADIUS,
  Button,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import { ForgotPasswordModal } from 'web/components'

interface Props {
  email?: string
  onCancel?: () => void
  onSave?: () => void
  onSuccess?: () => void
}

export const ChangePasswordForm: React.FC<Props> = ({
  email,
  onCancel,
  onSave,
  onSuccess,
}: Props) => {
  const { sendBanner } = useDialog()

  const { fetch: updatePassword } = useFetchApi(mutationSetUserAuth)
  const { fetch: verifyPassword } = useFetchApi(mutationLogin)

  const [prevPassword, setPrevPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState<string>('')
  const [showPasswordValidation, setShowPasswordValidation] =
    useState<boolean>(false)

  const [newPasswordValidationMessage, setNewPasswordValidationMessage] =
    useState<string>('')
  const [showNewPasswordValidation, setShowNewPasswordValidation] =
    useState<boolean>(false)

  type PasswordFieldTypes = 'previous' | 'new' | 'confirmation'

  const isButtonDisabled = useMemo(
    () =>
      prevPassword.length === 0 ||
      newPassword.length === 0 ||
      confirmPassword.length === 0,
    [confirmPassword.length, newPassword.length, prevPassword.length]
  )

  const handleOnChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: PasswordFieldTypes
  ) => {
    const v = event.target.value

    if (type === 'previous') {
      setPrevPassword(v)
      setPasswordValidationMessage('')
      setShowPasswordValidation(false)
    } else {
      setNewPasswordValidationMessage('')
      setShowNewPasswordValidation(false)
      if (type === 'new') {
        setNewPassword(v)
      } else if (type === 'confirmation') {
        setConfirmPassword(v)
      }
    }
  }

  const handleOnClickCancel = () => {
    // reset state
    setPrevPassword('')
    setNewPassword('')
    setConfirmPassword('')
    setPasswordValidationMessage('')
    setShowPasswordValidation(false)

    setNewPasswordValidationMessage('')
    setShowNewPasswordValidation(false)

    onCancel && onCancel()
  }

  const handleOnClickSave = async () => {
    // check if new password and cofirmation matches
    if (newPassword !== confirmPassword) {
      setNewPasswordValidationMessage('Passwords Do Not Match')
      setShowNewPasswordValidation(true)
      return
    }

    // check if new password is valid
    if (!isPasswordValid(newPassword)) {
      setNewPasswordValidationMessage('Password must be at least 6 characters')
      setShowNewPasswordValidation(true)
      return
    }

    // verify current password is correct
    const { error, ok } = await verifyPassword({
      userId: email,
      password: prevPassword,
    })

    // error handler for wrong password
    if (error && typeof error === 'string') {
      setPasswordValidationMessage('Incorrect password. Please try again.')
      setShowPasswordValidation(true)
      return
    }

    if (ok) {
      // update user password
      const { ok, error } = await updatePassword({
        password: prevPassword,
        newPassword,
      })

      if (ok) {
        sendBanner({
          autoDismiss: true,
          message: 'Your password has been changed successfully!',
          status: 'success',
        })
        onSuccess && onSuccess()
      }
      if (error) {
        sendBanner({
          autoDismiss: true,
          message:
            'There was an unknown error updating your password, please contact support',
          status: 'danger',
        })
      }
    }

    onSave && onSave()
  }

  return (
    <div>
      <Callout>
        Password must contain at least 6 characters. You will automatically be
        logged out of any devices using your old password.
      </Callout>

      <div
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StyledTextInput
          autoCapitalize='off'
          label='Current Password'
          placeholder='Enter your password'
          showPasswordToggle
          onChange={(e) => handleOnChangePassword(e, 'previous')}
          showValidationMessage={showPasswordValidation}
          status={showPasswordValidation ? 'error' : undefined}
          type='password'
          validationMessage={passwordValidationMessage}
          value={prevPassword}
        />

        <span style={{ marginBottom: 20, marginTop: 10 }}>
          <ForgotPasswordModal style={{ fontSize: 14 }} />
        </span>

        <StyledTextInput
          autoCapitalize='off'
          label='New Password'
          onChange={(e) => handleOnChangePassword(e, 'new')}
          placeholder='Enter new password'
          showPasswordToggle
          showValidationMessage={showNewPasswordValidation}
          status={showNewPasswordValidation ? 'error' : undefined}
          type='password'
          validationMessage={newPasswordValidationMessage}
          validationStyle={{ maxWidth: 260 }}
          value={newPassword}
        />
        <StyledTextInput
          autoCapitalize='off'
          label='Confirm Password'
          onChange={(e) => handleOnChangePassword(e, 'confirmation')}
          placeholder='Re-enter new password'
          showPasswordToggle
          showValidationMessage={showNewPasswordValidation}
          status={showNewPasswordValidation ? 'error' : undefined}
          type='password'
          validationMessage={newPasswordValidationMessage}
          validationStyle={{ maxWidth: 260 }}
          value={confirmPassword}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <StyledButton appearance='ghost' onClick={handleOnClickCancel}>
          Cancel
        </StyledButton>
        <StyledButton disabled={isButtonDisabled} onClick={handleOnClickSave}>
          Save
        </StyledButton>
      </div>
    </div>
  )
}

const Callout = styled.p`
  background-color: ${Colors.PERANO};
  border: 2px solid ${Colors.HAVELOCK_BLUE};
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  margin-top: 20px;
  padding: 10px;
`
// & + & {
const StyledTextInput = styled(TextInput)`
  &:not(:first-child) {
    margin-top: 10px;
  }
`
// & + & {
const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-left: 6px;
  }
`

export default ChangePasswordForm
