import { useCallback, useState } from 'react'
import styled from 'styled-components'

import { Chat, ChatParticipant, mutationComplaintCreate } from '@sportsyou/api'
import { TextArea, Modal, Button, useDialog } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

interface ChatReportUserModalProps {
  chat: Chat
  onClose: () => void
  user?: ChatParticipant
}

export default function ChatReportUserModal(props: ChatReportUserModalProps) {
  const { chat, user } = props

  const { sendBanner } = useDialog()

  const [reason, setReason] = useState<string>('')

  const { fetch: reportChatUser } = useFetchApi(mutationComplaintCreate)

  const onClose = useCallback(() => {
    setReason('')
    props.onClose()
  }, [props])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setReason(e.target.value)
    },
    [setReason]
  )

  const onClickReport = useCallback(async () => {
    const { ok } = await reportChatUser({
      reason,
      targetId: user?.id,
      token: chat.token,
    })
    if (ok) {
      sendBanner({
        autoDismiss: true,
        dismissTime: 6000,
        status: 'success',
        message: 'Complaint report successfully sent',
      })
      onClose()
    }
  }, [reportChatUser, reason, user?.id, chat.token, sendBanner, onClose])

  return (
    <Modal backdropIgnoresClicks onClose={onClose} visible={!!user}>
      <Modal.Header>
        <b>Report User: {user?.fullName}</b>
      </Modal.Header>
      <Modal.Body>
        <div>Alert administrator about this user</div>
        <Reason
          onChange={onChange}
          value={reason}
          placeholder="Please explain why you're reporting this user..."
        ></Reason>
        <Modal.Footer>
          <Button
            onClick={onClickReport}
            variant='primary'
            disabled={!reason?.length}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

const Reason = styled(TextArea)`
  min-height: 100px;
  padding: 10px 0;
  width: 100%;
`
