import { alphaNumericRegex } from '../regex'

export const getFirstLetter = (string: string) => {
  const regexResult = alphaNumericRegex.exec(string)
  let ret = ''
  if (regexResult) {
    ret = regexResult[0].toUpperCase()
  }
  return ret
}
