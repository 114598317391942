import { useCallback, useEffect } from 'react'

interface useWindowFocusProps {
  onWindowFocus?: () => void
  onWindowBlur?: () => void
}

const useWindowFocus = (props: useWindowFocusProps) => {
  const onFocus = useCallback(() => {
    props.onWindowFocus?.()
  }, [props])

  const onBlur = useCallback(() => {
    props.onWindowBlur?.()
  }, [props])

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [onBlur, onFocus])

  return { onFocus, onBlur }
}

export default useWindowFocus
