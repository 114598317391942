import React, { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import styled from 'styled-components'

import { Colors, darken } from '@sportsyou/core'
import { Button, Navbar } from '@sportsyou/react-dom-ui'

import { environment } from '../../environments/environment'

import { Footer } from '../components'

/**
 * Auth Layout
 *
 * This is layout includes our static landing page `Footer` and intercom action
 * button for customer support.
 *
 * The Navbar component displays a 'Schedule Demo' button and Log In/Sign Up
 * buttons, depending on the urls path.
 */
export const AuthLayout: React.FC = () => {
  const { boot, shutdown } = useIntercom()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    boot({ actionColor: Colors.PUNCH })
    return () => {
      shutdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClickAction = useCallback(
    (target: 'login' | 'signup') => {
      navigate(`/${target}`)
    },
    [navigate]
  )

  const logInSignUpPath = useMemo(
    () => (['/signup'].includes(pathname) ? 'login' : 'signup'),
    [pathname]
  )

  const handleOnClickScheduleDemo = () => {
    window.open(
      'https://meetings.hubspot.com/filip-pusnik/sy-website-demo-filip',
      '_blank'
    )
  }

  const handleOnClickBrand = useCallback(
    () => window.open(`${environment.urls.web}`, '_self', 'noopener'),
    []
  )

  return (
    <Container>
      <Navbar contentWidth='900px'>
        <Navbar.Left brand onClickBrand={handleOnClickBrand} />
        <Navbar.Right>
          <StyledButton
            appearance='minimal'
            $isDemoButton
            onClick={handleOnClickScheduleDemo}
            variant='alternate'
          >
            Schedule Demo
          </StyledButton>

          <StyledButton
            appearance='minimal'
            onClick={() => handleOnClickAction(logInSignUpPath)}
            variant='alternate'
          >
            {logInSignUpPath === 'signup' ? 'Sign Up' : 'Log In'}
          </StyledButton>
        </Navbar.Right>
      </Navbar>
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledButton = styled(Button)<{ $isDemoButton?: boolean }>`
  background-color: ${({ $isDemoButton }) =>
    $isDemoButton ? Colors.WHITE : Colors.CATSKILL_WHITE};
  border-radius: 100px;
  &:not(:first-child) {
    margin-left: 12px;
  }
  &:hover,
  &:active {
    background-color: ${({ $isDemoButton }) =>
      $isDemoButton ? '#0000000f' : darken(Colors.CATSKILL_WHITE, 6)};
  }
`
const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`

export default AuthLayout
