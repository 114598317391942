// NOTE: this component has issues with GroupedVirtuoso and SelectUserListSectioned should be used instead of this component

import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Avatar, Checkbox, Chevron, Searchbar } from '@sportsyou/react-dom-ui'
import { capitalize, Colors } from '@sportsyou/core'
import { Friend, Profile, Teammate, TeamMember, User } from '@sportsyou/api'

export type Person = User | Profile | Friend | TeamMember | Teammate

interface SelectUserListProps {
  canSelectAll?: boolean
  collapsible?: boolean // Whether or not segmented lists can be collapsible
  listClassName?: string
  listStyle?: React.CSSProperties
  name?: string
  noResultsText?: string
  onChange: (users: Person[]) => void
  onSearchChange?: (value: string) => void
  onSearchSubmit?: (value: string) => void
  rowSize?: 'small' | 'big'
  searchable?: boolean
  searchbarContainerStyle?: React.CSSProperties
  searchbarInputContainerStyle?: React.CSSProperties
  searchbarPlaceholder?: string
  searchbarStyle?: React.CSSProperties
  searchbarValue?: string
  segmentByTeamRole?: boolean
  selectedUsers?: Person[]
  users?: Person[]
  useWindowScroll?: boolean
}

const ROLES = ['coach', 'player', 'parent']

export const SelectUserList = ({
  canSelectAll,
  collapsible = false,
  listClassName,
  listStyle,
  noResultsText,
  onChange,
  onSearchChange,
  onSearchSubmit,
  rowSize = 'big',
  searchable,
  searchbarContainerStyle,
  searchbarInputContainerStyle,
  searchbarPlaceholder,
  searchbarStyle,
  searchbarValue,
  segmentByTeamRole = false,
  selectedUsers = [],
  users = [],
  useWindowScroll = true,
}: SelectUserListProps) => {
  const [areCoachesVisible, setAreCoachesVisible] = useState<boolean>(false)
  const [arePlayersVisible, setArePlayersVisible] = useState<boolean>(false)
  const [isFamilyVisible, setIsFamilyVisible] = useState<boolean>(false)

  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    if (searchbarValue) {
      setSearchValue(searchbarValue)
    }
  }, [searchbarValue])

  const isUserSelected = useCallback(
    (user: Person): boolean => {
      return !!selectedUsers?.some(
        (selectedUser) => selectedUser?.id === user?.id
      )
    },
    [selectedUsers]
  )

  const areUsersSelected = useCallback(
    (users: Array<Person>): boolean =>
      selectedUsers.some((user) => users.includes(user)),
    [selectedUsers]
  )

  const filteredUsers = useMemo(
    () =>
      searchValue !== ''
        ? users.filter((user) => {
            const name = `${user.firstName ?? ''} ${
              user.lastName ?? ''
            }`.toLowerCase()
            return name.toLowerCase().includes(searchValue.toLowerCase())
          })
        : users,
    [searchValue, users]
  )

  const onClickUser = useCallback(
    (user: Person) => {
      if (isUserSelected(user)) {
        onChange(
          selectedUsers?.filter(
            (selectedUser) => selectedUser.id !== user.id
          ) ?? []
        )
      } else {
        onChange([...(selectedUsers ?? []), user])
      }
    },
    [isUserSelected, onChange, selectedUsers]
  )

  const onChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const trimmedValue = value?.trim() ?? ''
      setSearchValue(trimmedValue)
      onSearchChange?.(trimmedValue)
    },
    [onSearchChange]
  )

  const onKeyPressSearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement
      const trimmedValue = value?.trim() ?? ''
      if (event.key === 'Enter') {
        event.preventDefault()
        onSearchSubmit?.(trimmedValue)
      }
    },
    [onSearchSubmit]
  )

  const handleOnSelectAllByRole = useCallback(
    (usersByRole: Array<Person>) => {
      if (areUsersSelected(usersByRole)) {
        // Deselect users
        const newUsers: Array<Person> = selectedUsers.filter(
          (u) => !usersByRole.map((user) => user.id).includes(u.id)
        )
        onChange(newUsers)
      } else {
        onChange([...(selectedUsers ?? []), ...usersByRole])
      }
    },
    [onChange, selectedUsers, areUsersSelected]
  )

  const handleOnSelectAll = () => {
    if (selectedUsers.length === users.length) {
      onChange([])
    } else {
      onChange(users)
    }
    // users.forEach((user) => {
    // })
    // if (isUserSelected(user)) {
    //   onChange(
    //     selectedUsers?.filter(selectedUser => selectedUser.id !== user.id) ??
    //       [],
    //   )
    // } else {
    //   onChange([...(selectedUsers ?? []), user])
    // }
  }

  const handleOnClickToggleRole = (role: string) => {
    if (role === 'coach') {
      setAreCoachesVisible(!areCoachesVisible)
    } else if (role === 'player') {
      setArePlayersVisible(!arePlayersVisible)
    } else if (role === 'parent') {
      setIsFamilyVisible(!isFamilyVisible)
    }
  }

  // If `segmentByTeamRoles` is true, we need to set up the data properly to
  // so `GroupVirtuoso` can handle and display the user correctly.
  // Virtuoso uses and single array to list elements. In order to set up
  // grouping we need to explicitly tell Virtuoso how many groups there are and
  // how many items are in each group.

  // We will use `teamRoles` to define our groups
  const teamRoles = useMemo(
    () => (segmentByTeamRole ? ROLES : []),
    [segmentByTeamRole]
  )
  // Sort the users in the order provided in `teamRoles` based on their role
  const sortedUsersByRole = useMemo(
    () =>
      teamRoles
        .map((role) =>
          searchValue !== ''
            ? (filteredUsers as Array<TeamMember>).filter(
                (user) => user.role === role
              )
            : (users as Array<TeamMember>).filter((user) => user.role === role)
        )
        .flat(1),
    [teamRoles, filteredUsers, searchValue, users]
  )

  const groupedRoles = useMemo(() => {
    const roles = teamRoles.filter((role) => {
      return searchValue !== ''
        ? (filteredUsers as Array<TeamMember>).some(
            (user) => user?.role === role
          )
        : (users as Array<TeamMember>).some((user) => user?.role === role)
    })
    return roles
  }, [teamRoles, filteredUsers, searchValue, users])

  const groupedRolesWithCounts = useMemo(() => {
    const roles: { [key: string]: number } = {}
    for (const role of groupedRoles) {
      roles[role] =
        searchValue !== ''
          ? (filteredUsers as Array<TeamMember>).filter(
              (user) => user?.role === role
            ).length
          : (users as Array<TeamMember>).filter((user) => user?.role === role)
              .length
    }
    return roles
  }, [groupedRoles, filteredUsers, searchValue, users])

  // Get the "count" of each group/role so Virtuoso knows when to start the
  // next group in the list
  const groupCounts = useMemo(() => {
    const counts = [
      sortedUsersByRole.filter((user) => user.role === 'coach').length ?? 0,
      sortedUsersByRole.filter((user) => user.role === 'player').length ?? 0,
      sortedUsersByRole.filter((user) => user.role === 'parent').length ?? 0,
    ]
    return counts.filter((count) => count > 0)
  }, [sortedUsersByRole])

  const renderRow = (_index?: number, user?: Person) => {
    if (!user) {
      return null
    }

    // type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>

    const fullName =
      (user as Exclude<Person, Teammate>).fullName ??
      `${user.firstName} ${user.lastName}`
    return (
      <Row key={user.id}>
        <StyledCheckbox
          $rowSize={rowSize}
          checked={isUserSelected(user)}
          onChange={() => onClickUser(user)}
          size={rowSize === 'small' ? 14 : undefined}
          title={fullName}
        >
          <UserName>
            <Avatar
              diameter={rowSize === 'small' ? 20 : 36}
              name={fullName}
              uri={user?.profileImage?.[0]?.viewUrl ?? ''}
            />
            <Name>{fullName}</Name>
          </UserName>
        </StyledCheckbox>
      </Row>
    )
  }

  const renderChevron = useCallback(
    (role: string) => {
      if (role === 'coach') {
        return (
          <Chevron
            direction={areCoachesVisible ? 'down' : 'right'}
            fill={Colors.SHUTTLE_GRAY}
            size={18}
          />
        )
      } else if (role === 'player') {
        return (
          <Chevron
            direction={arePlayersVisible ? 'down' : 'right'}
            fill={Colors.SHUTTLE_GRAY}
            size={18}
          />
        )
      } else if (role === 'family' || role === 'parent') {
        return (
          <Chevron
            direction={isFamilyVisible ? 'down' : 'right'}
            fill={Colors.SHUTTLE_GRAY}
            size={18}
          />
        )
      }

      return <Chevron fill={Colors.SHUTTLE_GRAY} />
    },
    [areCoachesVisible, arePlayersVisible, isFamilyVisible]
  )

  const renderRoleSelectAll = useCallback(
    (role: string) => {
      const _users = sortedUsersByRole.filter((user) => user.role === role)
      return (
        <span
          onClick={() => handleOnSelectAllByRole(_users)}
          style={{ color: Colors.PUNCH, cursor: 'pointer' }}
        >
          {areUsersSelected(_users) ? 'Deselect' : 'Select'} All
        </span>
      )
    },
    [areUsersSelected, handleOnSelectAllByRole, sortedUsersByRole]
  )

  const renderSearchBar = useCallback(() => {
    return (
      <Searchbar
        containerStyle={{
          minHeight: 1,
          padding: 10,
          ...searchbarContainerStyle,
        }}
        style={searchbarStyle}
        inputContainerStyle={searchbarInputContainerStyle}
        onChange={onChangeSearch}
        onKeyPress={onKeyPressSearch}
        value={searchValue}
        placeholder={searchbarPlaceholder}
      />
    )
  }, [
    searchbarContainerStyle,
    searchbarStyle,
    searchbarInputContainerStyle,
    onChangeSearch,
    onKeyPressSearch,
    searchValue,
    searchbarPlaceholder,
  ])

  function renderItemContent(itemIndex: number) {
    let _users = [...sortedUsersByRole]

    groupCounts.forEach((roleCount, index) => {
      if (!roleCount) {
        const role = groupedRoles[index]
        _users = _users.filter((user) => (user as TeamMember).role !== role)
      }
    })

    if (_users[itemIndex].role === 'coach' && !areCoachesVisible) {
      return null
    } else if (_users[itemIndex].role === 'player' && !arePlayersVisible) {
      return null
    } else if (
      (_users[itemIndex].role === 'family' ||
        _users[itemIndex].role === 'parent') &&
      !isFamilyVisible
    ) {
      return null
    }
    return renderRow(undefined, _users[itemIndex])
  }

  function renderGroupContent(groupIndex: number) {
    if (groupCounts[groupIndex] === 0) {
      return null
    }
    // Pluralize roles to display headers
    const roleAsPlural =
      groupedRoles[groupIndex] === 'coach'
        ? 'coaches'
        : groupedRoles[groupIndex] === 'parent'
        ? 'family'
        : `${groupedRoles[groupIndex]}s`
    return (
      <ListHeaderContainer style={{ backgroundColor: 'white' }}>
        <ListHeader
          onClick={
            collapsible
              ? () => handleOnClickToggleRole(groupedRoles[groupIndex])
              : undefined
          }
        >
          {collapsible && renderChevron(groupedRoles[groupIndex])}
          {capitalize(roleAsPlural)}
        </ListHeader>
        {canSelectAll && renderRoleSelectAll(groupedRoles[groupIndex])}
      </ListHeaderContainer>
    )
  }

  /**
   * Return a list of selectable users that is segemented by user's team role
   */
  if (segmentByTeamRole) {
    let height = groupCounts.filter((count) => count > 0).length * 45 // height of the headers'
    if (areCoachesVisible) {
      height += groupedRolesWithCounts.coach * 45
    }
    if (arePlayersVisible) {
      height += groupedRolesWithCounts.player * 45
    }
    if (isFamilyVisible) {
      height += groupedRolesWithCounts.parent * 45
    }
    return (
      <>
        {searchable && renderSearchBar()}
        {searchValue !== '' && sortedUsersByRole.length === 0 ? (
          <div style={{ height: 400 }}>
            <NoResults>
              {noResultsText ??
                'No results to show, please check your spelling.'}
            </NoResults>
          </div>
        ) : (
          <GroupedVirtuoso
            groupContent={renderGroupContent}
            groupCounts={groupCounts}
            itemContent={renderItemContent}
            style={{ height, borderBottom: '1px solid #E5E5E5' }}
            useWindowScroll={useWindowScroll}
          />
        )}
      </>
    )
  }

  if (searchable) {
    return (
      <>
        {searchable && renderSearchBar()}
        {canSelectAll && (
          <SelectAllButton onClick={handleOnSelectAll}>
            {selectedUsers.length === users.length ? 'Deselect' : 'Select'} All
          </SelectAllButton>
        )}
        {searchValue !== '' && filteredUsers.length === 0 ? (
          <NoResults>
            {noResultsText ?? 'No results to show, please check your spelling.'}
          </NoResults>
        ) : (
          <Virtuoso
            className={listClassName}
            data={searchValue !== '' ? filteredUsers : users}
            itemContent={renderRow}
            style={listStyle}
            totalCount={users?.length}
            useWindowScroll={useWindowScroll}
          />
        )}
      </>
    )
  }

  return (
    <Virtuoso
      className={listClassName}
      data={users}
      itemContent={renderRow}
      style={listStyle}
      totalCount={users?.length}
      useWindowScroll={useWindowScroll}
    />
  )
}

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  & + & {
    border-top: 1px solid ${Colors.MYSTIC};
  }
`
const UserName = styled.div`
  align-items: center;
  display: flex;
  // margin-right: auto;
  margin-left: 4px;

  &:hover > span {
    text-decoration: underline;
  }
`

const Name = styled.div`
  margin-left: 6px;
`

const StyledCheckbox = styled(Checkbox)<{ $rowSize: 'small' | 'big' }>`
  padding: ${({ $rowSize }) => ($rowSize === 'small' ? '10px 6px' : '10px')};
  width: 100%;

  &:hover,
  &:active {
    background: ${Colors.CATSKILL_WHITE};
  }
`
const NoResults = styled.p`
  color: ${Colors.SHUTTLE_GRAY};
  padding-left: 10px;
  padding-right: 10px;
`
const SelectAllButton = styled.span`
  align-self: flex-end;
  border-radius: 6px;
  color: ${Colors.PUNCH};
  cursor: pointer;
  font-weight: 500;
  padding: 4px;
  margin-bottom: 4px;
  margin-top: 4px;
  transition: background-color 120ms ease-in-out;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const ListHeaderContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  font-size: 12px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: 5px;
  justify-content: space-between;
`
const ListHeader = styled.span`
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  padding: 4px;
  text-transform: uppercase;

  transition: background-color 120ms ease-in-out;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
export default SelectUserList
