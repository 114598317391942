import React from 'react'
import styled from 'styled-components'
import { Colors, darken, lighten } from '@sportsyou/core'

interface Props {}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type LittleFriendProps = Props & NativeAttributes

export const LittleFriend: React.FC<
  LittleFriendProps
> = ({}: LittleFriendProps) => {
  return (
    <Head>
      <HeadBand>
        <Stripe $color={Colors.PUNCH} />
        <Stripe
          $color={Colors.ALTO}
          // style={{ flexGrow: 1 }}
        />
        <Stripe $color={Colors.HAVELOCK_BLUE} />
      </HeadBand>
      <Face>
        <Eyes>
          <Eye />
          <Eye />
        </Eyes>
        <Cheeks>
          <Cheek />
          <Cheek />
        </Cheeks>
        <Mouth />
      </Face>
    </Head>
  )
}

const Head = styled.div`
  align-items: center;
  border: 2px solid ${Colors.BLACK};
  justify-content: center;
  height: 200px;
  border-radius: 12px;
  width: 200px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    background-color: rgb(0 0 0 / 8%);
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    height: 100%;
    position: absolute;
    right: 0;
    width: 12px;
  }
`
const HeadBand = styled.div`
  align-self: stretch;
  border: 2px solid ${Colors.BLACK};
  border-radius: 4px;
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  margin-left: -12px;
  margin-right: -12px;
  overflow: hidden;
  z-index: 1;
`
const Stripe = styled.div<{ $color: string }>`
  // display: none;
  background-color: ${({ $color }) => $color};
  flex: 0 0 8px;
  position: relative;
  min-height: 1px;

  &::before,
  &::after {
    content: '';
    align-self: flex-start;
    height: 8px;
    box-sizing: inherit;

    width: 8px;
    position: absolute;
    background-color: ${({ $color }) => darken($color, 15)};
    z-index: 1;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
`
const Face = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const Eyes = styled.div`
  display: flex;
  justify-content: center;
`
const Eye = styled.div`
  border: 6px solid ${Colors.MINE_SHAFT};
  border-bottom: 0;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  box-sizing: border-box;
  height: 16px;
  margin-left: 12px;
  margin-right: 12px;
  width: 30px;
`
const Cheeks = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 40px;
`
const Cheek = styled.div`
  height: 10px;
  background-color: ${Colors.VIVID_TANGERINE};
  border-radius: 50%;
  opacity: 0.4;
  width: 10px;
`
const Mouth = styled.div`
  background-color: ${Colors.BLACK};
  border-radius: 4px;
  width: 24px;
  height: 4px;
  align-self: center;
`

export default LittleFriend
