export const setColorOpacity = (color: string, amount: number) => {
  let opacity = 1
  if (amount) {
    opacity = amount > 1 ? amount / 100 : amount
  }
  let colorCode: string = color
  if (colorCode[0] === '#') {
    colorCode = color.slice(1)
  }

  if (colorCode.length === 3) {
    colorCode = colorCode
      .split('')
      .map((v) => v + v)
      .join('')
  }
  const rVal = parseInt(colorCode.substring(0, 2), 16)
  const gVal = parseInt(colorCode.substring(2, 4), 16)
  const bVal = parseInt(colorCode.substring(4, 6), 16)

  const rgba = `rgba(${rVal}, ${gVal}, ${bVal}, ${opacity})`
  return rgba
}
