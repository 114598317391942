import moment from 'moment'
import { Event, EventInput } from '@sportsyou/api'

/**
 * Convert Event object to EventInput object to be used for MutationEventUpdate
 */
const convertEventToEventInput = (event: Event): EventInput => {
  const endDate = moment(event.endDate).format('MM/DD/YYYY')
  const endTime = moment(event.endDate).format('h:mma')
  const startDate = moment(event.startDate).format('MM/DD/YYYY')
  const startTime = moment(event.startDate).format('h:mma')

  return {
    allDay: event.allDay,
    allUsersCanViewAttendance: event.allUsersCanViewAttendance,
    endDate,
    endTime,
    formattedLocation: event.formattedLocation,
    id: event.id,
    location: event.location,
    locationLat: event.locationLat,
    locationLng: event.locationLng,
    noEndTime: event.noEndTime,
    notes: event.notes,
    repeat: event.repeat,
    repeatInfo: event.repeatInfo,
    startDate,
    startTime,
    teamId: event.teamId,
    teamRoles: event.teamRoles,
    timezone: event.timezone,
    title: event.title,
    type: event.type,
  }
}

export default convertEventToEventInput
