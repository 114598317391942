import { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  Chat,
  Friend,
  queryFriends,
  queryTeam,
  TeamMember,
} from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { Button, Modal } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import SelectUserList, { Person } from '../../SelectUserList/SelectUserList'

interface AddChatMembersModalProps {
  chat: Chat
  currentUserId?: string
  onAddUsers: (users: Person[]) => void
  onClose: () => void
  visible: boolean
}

export const AddChatMembersModal: React.FC<AddChatMembersModalProps> = (
  props: AddChatMembersModalProps
) => {
  const currentParticipants = useMemo(
    () => props.chat.participants ?? [],
    [props.chat.participants]
  )

  const [selectedUsers, setSelectedUsers] = useState<Person[]>([])
  const [users, setUsers] = useState<Person[]>([])

  const { fetch: getFriends } = useFetchApi(queryFriends)
  const { fetch: getTeam } = useFetchApi(queryTeam)

  const fetchUsers = useCallback(async () => {
    if (props.chat.team) {
      // fetch team to get team members
      const { data: team } = await getTeam({
        id: props.chat.team.teamId,
        includeLargeTeamMembers: true,
      })
      const members = (team?.members as Person[]) ?? []
      if (members.length) {
        // Filter out users that are already in the chat
        // Need to replace `id` prop with userId as TeamMember.id is teamMemberId
        setUsers(
          members
            .filter(
              (member) =>
                !currentParticipants.some(
                  (p) => p?.id === (member as TeamMember).userId
                )
            )
            .map((member) => ({
              ...member,
              id: (member as TeamMember).userId,
            })) ?? []
        )
      }
    } else {
      const { data: friends } = await getFriends()
      // Filter out users that are already in the chat
      setUsers(
        (friends as Friend[]).filter(
          (friend) =>
            !currentParticipants.find((member) => member?.id === friend.id)
        )
      )
    }
  }, [currentParticipants, getFriends, getTeam, props.chat.team])

  const onClickAdd = useCallback(async () => {
    props.onClose()
    props.onAddUsers(selectedUsers)
  }, [props, selectedUsers])

  const onClickSelectAll = useCallback(() => {
    if (users.length === selectedUsers.length) {
      setSelectedUsers([])
    } else {
      setSelectedUsers(users)
    }
  }, [selectedUsers, users])

  useEffect(() => {
    if (props.visible) {
      setSelectedUsers([])
      setUsers([])
      fetchUsers()
    }
  }, [currentParticipants, fetchUsers, props.visible])

  return (
    <Modal visible={props.visible} onClose={props.onClose}>
      <Modal.Header>
        <b>Add People to this Chat</b>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', height: 400, padding: 0 }}>
        <LeftCol>
          <H4>Add/Remove</H4>
          <SelectUserList
            searchable
            users={users}
            selectedUsers={selectedUsers}
            onChange={setSelectedUsers}
          />
        </LeftCol>
        <RightCol>
          <H4>Added: {selectedUsers.length}</H4>
          {selectedUsers.map((user, index) => (
            <SelectedUser key={index}>
              {user.firstName} {user.lastName}
            </SelectedUser>
          ))}
        </RightCol>
      </Modal.Body>
      <Modal.Footer
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          appearance='minimal'
          collapse
          onClick={onClickSelectAll}
          variant='secondary'
        >
          {users.length === selectedUsers.length ? 'Deselect' : 'Select'} All
        </Button>
        <Button
          disabled={!selectedUsers?.length}
          onClick={onClickAdd}
          variant='primary'
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
const ColStyles = css`
  flex: 0 0 50%;
  overflow-y: auto;
`
const LeftCol = styled.div`
  ${ColStyles};
`
const RightCol = styled.div`
  border-left: 1px solid ${Colors.ALTO};

  ${ColStyles};
`
const H4 = styled.h4`
  margin: 10px 10px 0;
`
const SelectedUser = styled.p`
  padding-left: 10px;
  padding-right: 10px;
`

export default AddChatMembersModal
