import 'swiper/css'
import 'swiper/css/lazy'
import 'swiper/css/navigation'
import { Keyboard, Lazy, Navigation, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import type { Swiper as SwiperType } from 'swiper'

import { Colors } from '@sportsyou/core'
import { Button, Dropdown, Icon, useDialog } from '@sportsyou/react-dom-ui'
import { LightboxItem as LightboxItemProps } from '../../../store/slices/LightboxSlice'
import {
  setPostComposer,
  showPostComposer,
} from '../../../store/slices/PostComposerSlice'
import { showChatCreateModal } from '../../../store/slices/ChatCreateModalSlice'
import useFolders from '../../hooks/useFolders'
import LightboxItem from './LightboxItem'
import useLightbox from './useLightbox'

const swiperKeyboardConfig = { enabled: true }
const swiperModules = [Keyboard, Lazy, Navigation, Zoom]
const swiperNavigationConfig = {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
}

export const Lightbox: React.FC = () => {
  const { downloadItem, createFolderItem } = useFolders({})
  const { hide, index, isOpen, items, setIndex } = useLightbox()
  const { sendBanner } = useDialog()
  const dispatch = useDispatch()

  const [canAutoPlay, setCanAutoPlay] = useState(true)
  const [swiper, setSwiper] = useState<SwiperType | null>(null)

  const autoPlayItemId = useMemo(() => items[index]?.id, [index, items])

  const onClose = useCallback(() => {
    setCanAutoPlay(true)
    hide()
  }, [hide])

  const onSlideChange = useCallback(
    (swiper: SwiperType) => {
      setCanAutoPlay(false) // disable autoplay after first slide change
      setIndex(swiper.realIndex)
    },
    [setIndex]
  )

  const onClickDownload = useCallback(() => {
    downloadItem(items[index])
  }, [downloadItem, index, items])

  const onClickShareToChat = useCallback(() => {
    dispatch(
      showChatCreateModal({
        uploads: [items[index]],
      })
    )
    onClose()
  }, [dispatch, index, items, onClose])

  const onClickDelete = useCallback(() => {
    console.log('delete')
  }, [])

  const onClickShareToPost = useCallback(() => {
    dispatch(setPostComposer({ uploads: [items[index]] }))
    dispatch(showPostComposer())
    onClose()
  }, [dispatch, index, items, onClose])

  const onClickSaveToFolders = useCallback(async () => {
    const item = items[index] as LightboxItemProps
    const folderId = await createFolderItem({
      folderName: item.fileName,
      uploadId: item.id,
    })
    if (folderId) {
      sendBanner({
        autoDismiss: true,
        message: 'Saved to Folders',
        status: 'success',
      })
    }
  }, [createFolderItem, index, items, sendBanner])

  // slide to correct item in lightbox when opened
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(index, 0)
    }
  }, [index, swiper])

  // on press escape, close the lightbox
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleKeydown)
    }
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen, onClose])

  if (!isOpen) {
    return null
  }

  return (
    <Container>
      <Swiper
        centeredSlides
        edgeSwipeDetection
        grabCursor
        initialSlide={index}
        keyboard={swiperKeyboardConfig}
        lazy
        modules={swiperModules}
        navigation={swiperNavigationConfig}
        onSlideChange={onSlideChange}
        preloadImages={false}
        onSwiper={setSwiper}
        slidesPerView={1}
        zoom
      >
        {items?.map((item, i) => (
          <SwiperSlide key={item.id}>
            <LightboxItem
              {...item}
              active={i === index}
              autoPlay={canAutoPlay && autoPlayItemId === item.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='swiper-button-prev' />
      <div className='swiper-button-next' />
      <Actions>
        <Dropdown
          title={
            <ActionButton appearance='minimal' collapse>
              <Icon name='Dots' fill={Colors.WHITE} />
            </ActionButton>
          }
          placement='bottomEnd'
          triggerStyle={{ height: 44, width: 44 }}
        >
          <Dropdown.Toggle>
            <Icon name='Dots' fill={Colors.WHITE} />
          </Dropdown.Toggle>
          <Dropdown.Item onClick={onClickDownload}>
            <DropdownIcon
              fill={Colors.SHUTTLE_GRAY}
              height={16}
              width={16}
              name='Download'
            />{' '}
            Download
          </Dropdown.Item>
          <Dropdown.Item onClick={onClickShareToChat}>
            <DropdownIcon
              fill={Colors.SHUTTLE_GRAY}
              height={16}
              width={16}
              name='Share'
            />{' '}
            Share
          </Dropdown.Item>
          <Dropdown.Item onClick={onClickSaveToFolders}>
            <DropdownIcon
              fill={Colors.SHUTTLE_GRAY}
              height={16}
              width={16}
              name='FolderSolid'
            />{' '}
            Save to Folders
          </Dropdown.Item>
          <Dropdown.Item onClick={onClickShareToPost}>
            <DropdownIcon
              fill={Colors.SHUTTLE_GRAY}
              height={16}
              width={16}
              name='Post'
            />{' '}
            Post to Feed
          </Dropdown.Item>
          {items?.[index]?.canDelete && (
            <Dropdown.Item onClick={onClickDelete}>
              <DropdownIcon
                fill={Colors.SHUTTLE_GRAY}
                height={16}
                width={16}
                name='X'
              />{' '}
              Delete
            </Dropdown.Item>
          )}
        </Dropdown>
        <ActionButton appearance='minimal' collapse onClick={onClose}>
          <Icon name='X' fill={Colors.WHITE} />
        </ActionButton>
      </Actions>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  z-index: 999;

  .swiper-wrapper {
    .swiper-slide {
      height: 100vh;
      width: 100vw;

      img {
        height: 100vh;
        object-fit: scale-down;
        width: 100vw;
      }

      img {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: 'transparent';
    border-radius: 50%;
    color: ${Colors.WHITE};
    height: 80px;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    width: 80px;
    z-index: 1;

    &:hover {
      opacity: 1;
      background: #000;
    }

    &.swiper-button-disabled {
      opacity: 0.05;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
`
const Actions = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
`
const ActionButton = styled(Button)`
  && {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    height: 44px;
    cursor: pointer;
    opacity: 0.5;
    margin-left: 6px;
    transition: all 180ms ease-in-out;
    width: 44px;
    &:active,
    &:hover {
      background-color: rgba(255, 255, 255, 0.18);
      opacity: 1;
    }
  }
`

const DropdownIcon = styled(Icon)`
  margin-right: 10px;
`

export default Lightbox
