import styled, { css } from 'styled-components'

import { Colors, AVATAR_AND_CALENDAR_COLORS } from '@sportsyou/core'
import { Checkmark } from '@sportsyou/react-icons'

interface PickerProps {
  color: string
  onChange: (teamId: string, color: string) => void
  teamId: string
}

export const CalendarColorPicker: React.FC<PickerProps> = (
  props: PickerProps
) => {
  return (
    <Container>
      {AVATAR_AND_CALENDAR_COLORS.map((color, index) => (
        <Color
          color={color}
          isSelected={props.color === color}
          key={index}
          onClick={() => props.onChange(props.teamId, color)}
        >
          {props.color === color ? (
            <Checkmark width={10} fill={Colors.WHITE} />
          ) : null}
        </Color>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Color = styled.div<{ color: string; isSelected: boolean }>`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  border: 2px solid transparent;
  flex: 0 0 auto;
  height: 14px;
  margin-left: 10px;
  width: 14px;
  cursor: pointer;
  margin: 4px;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    box-shadow: 0 0 0 1px ${(props) => props.color};
  }

  ${(props) =>
    props.isSelected &&
    css`
      box-shadow: 0 0 0 2px ${Colors.WHITE};
    `}
`

export default CalendarColorPicker
