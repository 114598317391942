import { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Divider, Link } from '@sportsyou/react-dom-ui'

import { FOOTER_NAV_LINKS, SOCIAL_URLS } from '../../../constants'

interface FooterProps {
  className?: string
  minimal?: boolean
}

export const Footer = (props: FooterProps) => {
  type SocialLink = {
    a: ComponentProps<'a'>
    img: ComponentProps<'img'>
  }
  const SOCIAL_LINKS: Array<SocialLink> = [
    {
      a: {
        href: SOCIAL_URLS.FACEBOOK,
        target: '_blank',
        title: 'sportsYou on Facebook',
      },
      img: {
        alt: 'Facebook Logo',
        src: 'https://webassets.sportsyou.com/images/icons/png/facebook-circle-logo-white.png',
      },
    },
    {
      a: {
        href: SOCIAL_URLS.TWITTER,
        target: '_blank',
        title: 'sportsYou on X',
      },
      img: {
        alt: 'X Logo',
        src: 'https://webassets.sportsyou.com/images/icons/png/x-circle-logo-white.png',
      },
    },
    {
      a: {
        href: SOCIAL_URLS.INSTAGRAM,
        target: '_blank',
        title: 'sportsYou on Instagram',
      },
      img: {
        alt: 'Instagram Logo',
        src: 'https://webassets.sportsyou.com/images/icons/png/instagram-circle-logo-white.png',
      },
    },
  ]

  return (
    <Container className={props.className}>
      <Content>
        <Logo
          alt='sportsYou Logo'
          height={80}
          src='https://webassets.sportsyou.com/images/brand/sportsyou-circle-logo-white.png'
          width={80}
        />
        <Divider fill={Colors.CATSKILL_WHITE} gap={20} />
        <AppLinks>
          {FOOTER_NAV_LINKS.map(({ active, href, text, title }, index) =>
            active ? (
              <AppLink
                color={Colors.WHITE}
                href={href}
                key={index}
                title={title}
              >
                {text}
              </AppLink>
            ) : null
          )}
        </AppLinks>
        <Divider fill={Colors.CATSKILL_WHITE} gap={20} />
        <CopyRightAndSocialsContainer>
          <div>
            sportsYou &copy; <span>{new Date().getFullYear()}</span>{' '}
          </div>
          <SocialLinks>
            {SOCIAL_LINKS.map((link, index) => (
              <SocialLink
                // $isSocialLink
                color={Colors.WHITE}
                key={index}
                href={link.a.href}
                target={link.a.target}
                title={link.a.title}
              >
                <SocialImg alt={link.img.alt} src={link.img.src} />
              </SocialLink>
            ))}
          </SocialLinks>
        </CopyRightAndSocialsContainer>
      </Content>
    </Container>
  )
}

export default Footer

const Container = styled.footer`
  background-color: ${Colors.BLACK};
  color: ${Colors.WHITE};
  padding-left: 10px;
  padding-right: 10px;
`
const Content = styled.div<{ minimal?: boolean }>`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-bottom: 60px;
  padding-top: 60px;
  position: relative;
  width: var(--main-site-width);
`

const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  display: block;
  @media all and (min-width: 768px) {
    margin-left: 0;
  }
  text-align: center;
`
const StyledLink = css`
  color: ${Colors.WHITE};
  font-size: 16px;
  transition: background-color 160ms ease-in-out, color 160ms ease-in-out;
  white-space: nowrap;
  @media all and (min-width: 768px) {
    border-radius: 100px;
  }
`
const AppLinks = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  @media all and (min-width: 768px) {
    flex-direction: row;
  }
`
const AppLink = styled(Link)`
  ${StyledLink};
  align-self: stretch;
  border-radius: 100px;
  padding: 10px 20px;

  text-align: center;

  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  display: block;
  &:active,
  &:hover {
    background-color: #fff3;
    text-decoration: underline;
  }
`
const CopyRightAndSocialsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  margin-top: 40px;
  @media all and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
`
const SocialLinks = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
  gap: 30px;
  @media all and (min-width: 768px) {
    // gap: 50px;
  }
`
const SocialLink = styled(Link)`
  ${StyledLink};
  @media all and (min-width: 768px) {
    &:hover,
    &:active {
      background-color: rgb(255 255 255 / 18%);
    }
  }
`

const SocialImg = styled.img`
  height: 44px;
  width: 44px;
  @media all and (min-width: 768px) {
    height: 34px;
    width: 34px;
  }
}
`
