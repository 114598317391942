import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'

import { Team as TeamProps } from '@sportsyou/api'

import { AddMembersModal, CreateTeamModal } from '../components'

export type TeamType = 'group' | 'team'

type CreateParams = {
  isVisible: boolean
  type?: TeamType
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  shouldNavigateToTeam?: boolean
}

type AddParams = {
  id?: string
  team?: TeamProps
  isVisible: boolean
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  shouldNavigateToTeam?: boolean
}

export type BuildTeamContextProps = {
  addMembers: (_: AddParams) => void
  id?: string
  team?: TeamProps
  isAddMembersVisible: boolean
  setIsAddMembersVisible: React.Dispatch<React.SetStateAction<boolean>>
  shouldNavigateToTeam?: boolean
  createTeam: (_: CreateParams) => void
  type?: TeamType
  isCreateTeamVisible: boolean
  setIsCreateTeamVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultContext = {
  addMembers: (_: AddParams) => {},
  createTeam: (_: CreateParams) => {},
  isAddMembersVisible: false,
  isCreateTeamVisible: false,
  setIsAddMembersVisible: () => {},
  setIsCreateTeamVisible: () => {},
  shouldNavigateToTeam: false,
  id: '',
  type: undefined,
  team: {},
}

export const BuildTeamContext =
  React.createContext<BuildTeamContextProps>(defaultContext)

export const BuildTeamProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isAddMembersVisible, setIsAddMembersVisible] = useState<boolean>(false)
  const [isCreateTeamVisible, setIsCreateTeamVisible] = useState<boolean>(false)

  const [id, setId] = useState<string>()
  const [shouldNavigateToTeam, setShouldNavigateToTeam] = useState<boolean>()
  const [type, setType] = useState<TeamType>()
  const [team, setTeam] = useState<TeamProps>()

  const _addMembers = useCallback(
    ({
      id,
      isVisible,
      shouldNavigateToTeam,
      team,
    }: {
      id?: string
      isVisible: boolean
      shouldNavigateToTeam?: boolean
      team?: TeamProps
    }) => {
      setIsAddMembersVisible(isVisible)
      setTeam(team)
      setType(undefined)
      setIsCreateTeamVisible(false)
      setId(id)
      setShouldNavigateToTeam(shouldNavigateToTeam)
    },
    []
  )

  const _createTeam = useCallback(
    ({
      isVisible,
      shouldNavigateToTeam,
      type,
    }: {
      isVisible: boolean
      shouldNavigateToTeam?: boolean
      type?: TeamType
    }) => {
      setIsCreateTeamVisible(isVisible)
      setType(type)
      setTeam({})
      setIsAddMembersVisible(false)
      setShouldNavigateToTeam(shouldNavigateToTeam)
    },
    []
  )

  const initialValue = useMemo(
    () => ({
      addMembers: _addMembers,
      createTeam: _createTeam,
      id,
      isAddMembersVisible,
      isCreateTeamVisible,
      setIsAddMembersVisible,
      setIsCreateTeamVisible,
      shouldNavigateToTeam,
      type,
      team,
    }),
    [
      _addMembers,
      _createTeam,
      id,
      isAddMembersVisible,
      isCreateTeamVisible,
      shouldNavigateToTeam,
      team,
      type,
    ]
  )

  return (
    <BuildTeamContext.Provider value={initialValue}>
      {children}
      <CreateTeamModal isVisible={isCreateTeamVisible} type={type} />
      <AddMembersModal isVisible={isAddMembersVisible} id={id} team={team} />
    </BuildTeamContext.Provider>
  )
}

export default BuildTeamProvider
