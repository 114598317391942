import { CSSProperties, FC, HTMLAttributes, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Profile, TeamMember as TeamMemberProps } from '@sportsyou/api'
import { Avatar, Button, Icon, Modal, Tabs } from '@sportsyou/react-dom-ui'
import { Colors, getProfileImage } from '@sportsyou/core'

import { getUsername } from 'web/utils/user'
import { selectCurrentUser } from 'web/store/slices/UserSlice'

interface Props {
  allowChat?: boolean
  className?: string
  isVisible: boolean
  notViewed?: Array<TeamMemberProps>
  onClickChat?: (user: TeamMemberProps) => void
  onClickUser?: (user: TeamMemberProps) => void
  onClose: () => void
  style?: CSSProperties
  viewed?: Array<TeamMemberProps>
}

type NativeAttributes = Omit<HTMLAttributes<any>, keyof Props>
export type PostViewedModalProps = Props & NativeAttributes

export const PostViewedModal: FC<PostViewedModalProps> = ({
  className,
  isVisible,
  notViewed,
  onClickChat,
  onClickUser,
  onClose,
  viewed,
}: PostViewedModalProps) => {
  const currentUser = useSelector(selectCurrentUser)

  // const tabsHeaderHeight = 44

  // initial min height for modal (1 row)
  const rowHeight = 60

  // Setting the content minHeight will prevent the modal from shrinking when
  // switching tabs which should help creating a better visual experience.
  const contentMinHeight = useMemo(() => {
    const notViewedHeight = (notViewed ?? ['']).length * rowHeight
    const viewedHeight = (viewed ?? ['']).length * rowHeight
    return Math.max(viewedHeight, notViewedHeight)
  }, [notViewed, viewed])

  const handleOnClose = () => {
    onClose && onClose()
  }

  const handleOnClickUser = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, user: TeamMemberProps) => {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      onClickUser && onClickUser(user)
    },
    [onClickUser]
  )

  const handleOnClickChat = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, user: TeamMemberProps) => {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
      onClickChat && onClickChat(user)
    },
    [onClickChat]
  )

  const renderViewed = useMemo(() => {
    if (!viewed || viewed.length === 0) return null
    return viewed.map((member: TeamMemberProps, index: number) => {
      const name = getUsername(member)
      const avatarImage = getProfileImage.getProfileAvatarImageUrl(
        member as Profile
      )
      return (
        <User
          $height={rowHeight}
          aria-label={`Profile for ${name}`}
          key={index}
          onClick={(event) => handleOnClickUser(event, member)}
          title={`Profile for ${name}`}
        >
          <Avatar diameter={40} name={name} uri={avatarImage} />
          <Name>{name}</Name>
          {currentUser.id !== member.userId && (
            <ChatButton
              appearance='minimal'
              collapse
              onClick={(event) => handleOnClickChat(event, member)}
              title='Start a chat'
            >
              <Icon height={16} name='Bubble' width={16} />
            </ChatButton>
          )}
        </User>
      )
    })
  }, [currentUser.id, handleOnClickChat, handleOnClickUser, viewed])

  const renderNotViewed = useMemo(() => {
    if (!notViewed || notViewed.length === 0) return null
    return notViewed.map((member: TeamMemberProps, index: number) => {
      const name = getUsername(member)
      const avatarImage = getProfileImage.getProfileAvatarImageUrl(
        member as Profile
      )
      return (
        <User
          $height={rowHeight}
          aria-label={`Profile for ${name}`}
          key={index}
          onClick={(event) => handleOnClickUser(event, member)}
          title={`Profile for ${name}`}
        >
          <Avatar diameter={40} name={name} uri={avatarImage} />
          <Name>{name}</Name>
          {currentUser.id !== member.userId && (
            <ChatButton
              appearance='minimal'
              collapse
              onClick={(event) => handleOnClickChat(event, member)}
              title='Start a chat'
            >
              <Icon height={16} name='Bubble' width={16} />
            </ChatButton>
          )}
        </User>
      )
    })
  }, [currentUser.id, handleOnClickChat, handleOnClickUser, notViewed])

  return (
    <StyledModal
      className={className}
      onClose={handleOnClose}
      visible={isVisible}
    >
      <Modal.Header>
        <b>Who Viewed This Post?</b>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Tabs
          buttonStyle={{
            borderRadius: 8,
            fontSize: 13,
            margin: 4,
            minHeight: 1,
          }}
          contentStyle={{ padding: 0 }}
        >
          <Tabs.Item title={'Viewed'}>
            <div style={{ minHeight: contentMinHeight }}>{renderViewed}</div>
          </Tabs.Item>
          <Tabs.Item title={'Not Viewed'}>
            <div style={{ minHeight: contentMinHeight }}>{renderNotViewed}</div>
          </Tabs.Item>
        </Tabs>
      </Modal.Body>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  overflow: hidden;
`

const User = styled.a<{ $height?: number }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  height: ${({ $height }) => $height}px;

  &:hover,
  &:active {
    background-color: ${Colors.MYSTIC};
  }
`
const Name = styled.span`
  margin-left: 10px;
`
const ChatButton = styled(Button)`
  && {
    border-radius: 50%;
    height: 36px;
    margin-left: auto;
    min-height: 1px;
    min-width: 1px;
    width: 36px;
  }
`

export default PostViewedModal
