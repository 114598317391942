const idRegex = /^[a-z]{2}-\S{8}-\S{4}-\S{4}-\S{4}-\S{12}/

export function cleanUpPathname(pathname: string): string {
  const paths = pathname.split('/')
  const cleanedPaths = paths.filter(Boolean).filter(path => !idRegex.test(path))

  return `/${cleanedPaths.join('/')}`
}
export function maskId(pathname: string): string {
  const paths = pathname.split('/')
  const cleanedPaths = paths
    .filter(Boolean)
    .map(path => (idRegex.test(path) ? path.replace(idRegex, 'id') : path))

  return `/${cleanedPaths.join('/')}`
}

const CleanUpPathname = {
  cleanUpPathname,
  maskId,
}

export default CleanUpPathname
