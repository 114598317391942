import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  Post,
  Poster as PosterProps,
  SponsoredPostInfoInput,
  User,
} from '@sportsyou/api'
import { Avatar, Button, Modal, TextArea } from '@sportsyou/react-dom-ui'
import { getProfileImage } from '@sportsyou/core'

interface Props {
  color?: string
  post: Post
  onCancel?: () => void
  onSave?: (message: string) => void
  initialMessage?: string
  isVisible?: boolean
  postId?: string
  sponsoredPostInfo?: SponsoredPostInfoInput
  user?: User | PosterProps
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type EditPostModalProps = Props & NativeAttributes

export const EditPostModal: React.FC<EditPostModalProps> = ({
  color,
  initialMessage = '',
  isVisible = false,
  onCancel,

  onSave,
  post,
  postId,
  sponsoredPostInfo,
  user,
}: EditPostModalProps) => {
  const [message, setMessage] = useState<string>(post.message ?? '')

  useEffect(() => {
    if (post) {
      setMessage(post.message ?? '')
    }
  }, [post])

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }
  const handleOnCancel = () => {
    onCancel && onCancel()
  }

  const handleOnSave = () => {
    onSave && onSave(message)
  }
  return (
    <Modal visible={isVisible} onClose={handleOnCancel}>
      <Modal.Header>
        <b>Edit Post</b>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {/* TODO: Add user props */}
          <Avatar
            diameter={44}
            name={post.createdBy?.fullName ?? undefined}
            uri={post.createdBy?.profileImage?.[0]?.viewUrl ?? undefined}
          />
          <Form>
            <StyledTextArea
              initialRows={4}
              maximumRows={8}
              minimumRows={4}
              onChange={handleOnChange}
              placeholder={'Share a post'}
              value={message}
            />
            <div>
              <StyledButton
                appearance='minimal'
                onClick={handleOnCancel}
                style={{ marginRight: 6 }}
                variant='alternate'
              >
                Cancel
              </StyledButton>
              <StyledButton onClick={handleOnSave}>Save</StyledButton>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
`
const StyledButton = styled(Button)`
  margin-top: 10px;
`
const Form = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 10px;
`
const StyledTextArea = styled(TextArea)`
  align-self: stretch;
`

export default EditPostModal
