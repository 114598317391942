import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useMediaQuery } from '@sportsyou/react-hooks'

import ChatBox from './ChatBox'
import { RootState } from 'web/store/rootReducer'
import { SMALL_SCREEN_BREAKPOINT } from 'web/constants'

export default function ChatBoxes() {
  const { boxes, focusedChatBoxId } = useSelector(
    (state: RootState) => state.chatBoxes
  )

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT}`)

  return (
    <Container isSmallScreen={isSmallScreen}>
      {boxes.map((box, index) => (
        <StyledChatBox
          chatId={box.chatId}
          isSmallScreen={isSmallScreen}
          index={index}
          focused={box.chatId === focusedChatBoxId}
          key={box.chatId}
          minimized={box.minimized}
        />
      ))}
    </Container>
  )
}

const Container = styled.div<{ isSmallScreen: boolean }>`
  ${({ isSmallScreen }) =>
    isSmallScreen
      ? {
          height: '100%',
          top: 0,
          pointerEvents: 'none',
          zIndex: 1000,
        }
      : {
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
          height: 1,
          justifyContent: 'flex-end',
          paddingRight: 80, // To avoid the floating action button
          zIndex: 1,
        }};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;

  @media print {
    display: none;
  }
`
const StyledChatBox = styled(ChatBox)<{
  index: number
  isSmallScreen: boolean
}>`
  ${({ index, isSmallScreen }) =>
    isSmallScreen
      ? {
          pointerEvents: 'initial',
          borderRadius: 0,
          height: '100%',
          marginLeft: 0,
          position: 'absolute',
          width: '100%',
          zIndex: index + 1,
        }
      : undefined};
`
