import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import styled from 'styled-components'

/**
 * Blank Layout
 *
 * This is a blank layout that displays content in a flexible container
 */
export const BlankLayout: FC = () => {
  return (
    <Container>
      <Content>
        <Outlet />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const Content = styled.div`
  flex: 1 1 auto;
`

export default BlankLayout
