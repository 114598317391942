import { isEqual } from 'lodash'
import { useState } from 'react'

import { ScheduledPost, queryScheduledPosts } from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

export const useScheduledPosts = () => {
  const [scheduledPosts, setScheduledPosts] = useState<ScheduledPost[]>([])
  const { fetch: fetchScheduledPosts } = useFetchApi(queryScheduledPosts)

  async function getScheduledPosts() {
    const { data } = await fetchScheduledPosts()
    if (!isEqual(scheduledPosts, data)) {
      setScheduledPosts(data as ScheduledPost[])
    }
  }

  return {
    getScheduledPosts,
    scheduledPosts,
  }
}

export default useScheduledPosts
