import { createSelector, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { Event, Timezone } from '@sportsyou/api'

import { RootState } from '../rootReducer'

export interface CalendarSliceProps {
  agendaEvents?: Event[]
  events?: Event[]
  tiemezones?: Timezone[]
  defaultTimezone?: Timezone
}

const initialState: CalendarSliceProps = {
  agendaEvents: [],
  events: [],
  tiemezones: [],
  defaultTimezone: undefined,
}

const CalendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setAgendaEvents(state, action: { payload: Event[] }) {
      state.agendaEvents = action.payload
    },
    setCalendarEvents(state, action: { payload: Event[] }) {
      state.events = action.payload
      state.agendaEvents = action.payload.filter(
        (event) =>
          moment(event.startDate).isSameOrAfter(moment().startOf('day')) &&
          moment(event.startDate).isSameOrBefore(
            moment().add(7, 'days').endOf('day')
          )
      )
    },

    removeAgendaEvent(state, action: { payload: Event }) {
      if (state.agendaEvents) {
        state.agendaEvents = state.agendaEvents.filter(
          (event) => event.id !== action.payload.id
        )
      }
    },
    removeCalendarEvent(state, action: { payload: Event }) {
      if (state.events) {
        state.events = state.events.filter(
          (event: Event) => event.id !== action.payload.id
        )
      }
    },
    removeCalendarEvents(state, action: { payload: Event[] }) {
      if (state.events) {
        const idsToRemove = action.payload.map((ev) => ev.id)
        state.events = state.events.filter(
          (event: Event) => !idsToRemove.includes(event.id)
        )
      }
    },
    updateAgendaEvent(state, action: { payload: Event }) {
      if (state.agendaEvents) {
        state.agendaEvents = state.agendaEvents.map((event: Event) =>
          event.id === action.payload.id ? action.payload : event
        )
      }
    },
    updateCalendarEvent(state, action: { payload: Event }) {
      if (state.events) {
        state.events = state.events.map((event: Event) => {
          if (event.id === action.payload.id) {
            return action.payload
          }
          return event
        })
      }
    },
    clearAgendaEvents(state) {
      state.agendaEvents = initialState.agendaEvents
    },
    clearAllEvents(state) {
      state.agendaEvents = initialState.agendaEvents
      state.events = initialState.events
    },
    clearCalendarEvents(state) {
      state.events = initialState.events
    },
    setTimezones(state, action: { payload: Timezone[] }) {
      const defaultTz = action.payload.find((tz) => tz.isDefault)
      state.tiemezones = action.payload
      if (defaultTz) {
        state.defaultTimezone = defaultTz
      }
    },
  },
})

export const {
  clearAgendaEvents,
  clearAllEvents,
  clearCalendarEvents,
  removeAgendaEvent,
  removeCalendarEvent,
  removeCalendarEvents,
  setAgendaEvents,
  setCalendarEvents,
  setTimezones,
  updateCalendarEvent,
} = CalendarSlice.actions
export default CalendarSlice.reducer

export const selectAgendaEvents = (state: RootState) =>
  state.calendar.agendaEvents ?? []

export const selectCalendarEvents = (state: RootState) =>
  state.calendar.events ?? []

export const selectTiemzones = (state: RootState) =>
  state.calendar.tiemezones ?? []

export const selectDefaultTimezone = (state: RootState) =>
  state.calendar.defaultTimezone

export const selectCalendarEventsByTeamId = createSelector(
  [selectCalendarEvents, (state, teamId?) => teamId],
  (events, teamId?) => events.filter((event) => event.teamId === teamId)
)

export const selectCalendarEventsByMonth = createSelector(
  [selectCalendarEvents, (state, startDate: string) => startDate],
  (events, startDate) => {
    const dateSplit = startDate.split('-')
    const date = dateSplit[0] + '-' + dateSplit[1]
    return events.filter((e) => e.startDate?.startsWith(date))
  }
)
