/**
 * Returns a formatted string to escape certain markdown characters to avoid breaking
 * the styles.
 *
 * @param str String to interpolate
 * @returns String with escaped markdown characters
 */

export default function escapeMarkdownCharacters(
  str: string | null | undefined,
): string {
  if (str == null) return ''

  const charsToEscape = ['*', '_', '~', '`']
  const strArray = str?.split('') ?? ['']
  const escapedString = strArray
    .map(ltr => {
      if (charsToEscape.includes(ltr)) {
        return `\\${ltr}`
      }
      return ltr
    })
    .join('')

  return escapedString
}
