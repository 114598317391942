import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { Comment, Poster } from '@sportsyou/api'
import { Avatar, Modal } from '@sportsyou/react-dom-ui'
import { Colors } from '@sportsyou/core'
import { useNavigate } from 'react-router-dom'

export interface PostCommentsModalProps {
  className?: string
  comments: Array<Comment>
  isVisible: boolean
  onClose: () => void
}

export const PostCommentsModal: React.FC<PostCommentsModalProps> = ({
  className,
  comments,
  isVisible,
  onClose,
}: PostCommentsModalProps) => {
  const navigate = useNavigate()

  // TODO: Add sorting
  const commenters = useMemo(
    () =>
      comments
        .map((c) => c.createdBy)
        .filter(
          (commenter, index, self) =>
            index === self.findIndex((t) => t?.id === commenter?.id)
        ),
    [comments]
  )

  useEffect(() => {
    console.log({ commenters })
  }, [commenters])

  const getUserName = (user?: Poster) => {
    if (!user) {
      return 'sportsYou User'
    }
    const { firstName, fullName, lastName } = user
    if (fullName) {
      return fullName
    } else if (firstName && lastName) {
      return `${firstName} ${lastName}`
    } else if (firstName) {
      return firstName
    } else {
      return 'sportsYou User'
    }
  }

  const handleOnClickUser = (user: Poster) => {
    const { id } = user
    navigate(`/profile/${id}`, {
      state: { id },
    })
  }

  const handleOnClose = () => {
    onClose && onClose()
  }

  return (
    <Modal className={className} visible={isVisible} onClose={handleOnClose}>
      <Modal.Header>
        <b>Comments</b>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 1, padding: 0 }}>
        {commenters.map((user, index) => (
          <Row onClick={() => handleOnClickUser(user as Poster)} key={index}>
            <Avatar
              diameter={30}
              name={getUserName(user as Poster)}
              uri={user?.profileImage?.[0]?.viewUrl ?? undefined}
            />
            <User>{getUserName(user as Poster)}</User>
          </Row>
        ))}
      </Modal.Body>
    </Modal>
  )
}

const Row = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 12px;

  transition: background-color 120ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.MYSTIC};
  }
`
const User = styled.span`
  margin-left: 10px;
`

export default PostCommentsModal
