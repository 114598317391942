import { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'
import 'video.js/dist/video-js.css'

import {
  MutationSetVideoViewedRequest,
  mutationSetVideoViewed,
} from '@sportsyou/api'
import { getThumbnail } from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'

import { LightboxItem as LightboxItemProps } from 'web/store/slices/LightboxSlice'
import { environment } from 'apps/web/src/environments/environment'

interface VideoPlayerProps {
  canPlay: boolean
  item: LightboxItemProps
  onReady: any
  options: any
}

const VIDEOJS_USE_CREDENTIALS = environment.videoJsUseCredentials === 'true'
// const isSafariBrowser =
//   window.navigator.vendor &&
//   window.navigator.vendor.indexOf('Apple') > -1 &&
//   window.navigator.userAgent &&
//   window.navigator.userAgent.indexOf('CriOS') == -1 &&
//   window.navigator.userAgent.indexOf('FxiOS') == -1

export default function VideoPlayer(props: VideoPlayerProps) {
  const { onReady, options } = props

  const { fetch: markVideoViewed } = useFetchApi(mutationSetVideoViewed)

  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<Player | null>(null)

  let duration: number
  let currentTime = 0
  let percentViewed = 0

  const _options = useMemo(
    () => ({
      fluid: true,
      html5: {
        vhs: {
          enableLowInitialPlaylist: true,
          limitRenditionByPlayerDimensions: true,
          allowSeeksWithinUnsafeLiveWindow: true,
          smoothQualityChange: true,
          // overrideNative: !isSafariBrowser,
          withCredentials: VIDEOJS_USE_CREDENTIALS,
        },
      },
      preload: 'auto',
      ...options,
    }),
    [options]
  )

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current

      if (!videoElement) return

      const player = (playerRef.current = videojs(
        videoElement,
        _options,
        () => {
          videojs.log('player is ready')
          onReady?.(player)
        }
      ))
    } else {
      const player = playerRef.current
      player.autoplay(_options.autoPlay)
      // player.src(_options.sources);
      player.on(['play', 'timeupdate'], (e: any) => {
        setCurrentTime(videoRef.current?.currentTime ?? 0)
      })
      player.on(['abort', 'ended', 'pause'], (e: any) => {
        setCurrentTime(videoRef.current?.currentTime ?? 0)
        markVideoAsViewed()
      })
    }
  }, [onReady, options, videoRef])

  useEffect(() => {
    if (!props.canPlay) {
      const player = playerRef.current as any
      player.pause()
    }
  }, [props.canPlay])

  function setCurrentTime(newTime: number) {
    if (duration !== (videoRef.current?.duration ?? 0.01)) {
      duration = videoRef.current?.duration ?? 0.01
    }
    if (newTime > currentTime) {
      currentTime = Math.round(newTime * 100) / 100
      percentViewed = Math.min(Math.round((currentTime / duration) * 100), 100)
    }
  }

  async function markVideoAsViewed() {
    return markVideoViewed({
      currentTime,
      percentViewed,
      duration,
      uploadId: props.item.id,
      postId: props.item.postId,
    } as MutationSetVideoViewedRequest)
  }

  return (
    <VideoPlayerContainer>
      <div data-vjs-player>
        <video
          // Note: "swiper-no-swiping" is a class from Swiper.js that prevents swipes
          // from being registered on the video element while the user interacts with the video controls.
          className='video-js vjs-big-play-centered swiper-no-swiping'
          ref={videoRef}
        ></video>
      </div>
    </VideoPlayerContainer>
  )
}

export function NativeVideoPlayer(props: VideoPlayerProps) {
  const { item } = props

  return (
    <VideoPlayerContainer>
      <StyledVideo controls poster={getThumbnail(item)} src={item.viewUrl!} />
    </VideoPlayerContainer>
  )
}

const VideoPlayerContainer = styled.div`
  height: calc(100vh - 140px);
  padding: 70px 90px;
  width: calc(100vw - 180px);

  && > .video-js {
    height: 100%;
    padding-top: 0;
    width: 100%;
  }

  .vjs-tech {
    // object-fit: cover;
    object-fit: contain;
    position: relative;
  }
`

const StyledVideo = styled.video`
  background: black;
  height: 100%;
  width: 100%;
`
