import { useCallback } from 'react'
import styled from 'styled-components'

import { Team, User } from '@sportsyou/api'
import { Avatar, Select } from '@sportsyou/react-dom-ui'

export type ExtraTeamPickerItem = {
  avatar?: { image?: string; name: string }
  id?: string
  key: string
  value: string
}
export interface TeamPickerProps {
  buttonStyle?: React.CSSProperties
  disabled?: boolean
  extraItems?: Array<ExtraTeamPickerItem>
  id?: string
  info?: string
  initialValue?: string
  label?: string
  name?: string
  onChange?: (id: string) => void
  placeholder?: string
  selectedTeamId?: string
  style?: React.CSSProperties
  teams?: Team[]
  title?: string
  user?: User
  valueStyle?: React.CSSProperties
}

export const TeamPicker: React.FC<TeamPickerProps> = (
  props: TeamPickerProps
) => {
  const onClickRow = useCallback(
    (key: string) => {
      props.onChange?.(key)
    },
    [props]
  )

  return (
    <Select
      disabled={props.disabled}
      filterable
      id='team-picker'
      initialValue={props.initialValue}
      label={props.label}
      menuStyle={{ maxHeight: 300 }}
      placeholder={props.placeholder}
      selectedTextStyle={props.valueStyle}
      style={{ minHeight: 1, ...props.style }}
      triggerStyle={props.buttonStyle}
    >
      {props.extraItems?.map(({ avatar, id, key, value }) => (
        <Select.Option
          className='test'
          isSelected={props.selectedTeamId === id}
          key={key}
          onClick={() => onClickRow(key)}
          style={{ alignItems: 'center', display: 'flex' }}
          testId={`team-picker-option-${key}`}
          value={value}
        >
          {avatar && (
            <Avatar diameter={24} name={avatar.name} uri={avatar.image} />
          )}
          <OptionTitle>{value}</OptionTitle>
        </Select.Option>
      ))}

      {props.user && (
        <Select.Option
          isSelected={props.selectedTeamId === '-1'}
          onClick={() => onClickRow('-1')}
          style={{ alignItems: 'center', display: 'flex' }}
          value={props.user.fullName ?? 'Personal'}
        >
          <Avatar
            diameter={24}
            name={props.user.fullName ?? 'Personal'}
            uri={props.user.profileImage?.[0]?.viewUrl ?? undefined}
          />
          <OptionTitle>{props.user.fullName ?? 'Personal'}</OptionTitle>
        </Select.Option>
      )}

      {props.teams?.length &&
        props.teams.map((team, index) => {
          const { name = 'Team' } = team
          return (
            <Select.Option
              isSelected={props.selectedTeamId === team.id}
              key={index}
              onClick={() => onClickRow(team.id ?? '')}
              style={{ alignItems: 'center', display: 'flex' }}
              value={name as string}
            >
              <Avatar
                diameter={24}
                name={name as string}
                uri={team.profileImage?.[0]?.viewUrl ?? undefined}
              />
              <OptionTitle>{name}</OptionTitle>
            </Select.Option>
          )
        })}
    </Select>
  )
}

const OptionTitle = styled.span`
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export default TeamPicker
