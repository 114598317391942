import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useFetchApi } from '@sportsyou/react-hooks'
import {
  QueryRemoteConfigInfoRequest,
  RemoteConfigInfo,
  queryRemoteConfigInfo,
} from '@sportsyou/api'
import useWindowFocus from './useWindowFocus'

type AppFeature = {
  /**
   * The featureId to query against with `queryRemoteConfigInfo`.
   */
  key: string
  /**
   * The `key` to return with useAppFeatures hook.
   * This will return a RemoteConfigInfo['isOn'] value to determine if the
   * feature is active or not.
   */
  appKey: string
}

type ActiveFeatures = {
  [key: string]: RemoteConfigInfo
  // {
  // isOn?: RemoteConfigInfo['isOn']
  // value?: RemoteConfigInfo['value']
  // }
}

type AppFeaturesContextProps = {
  features: ActiveFeatures
  /**
   * Refetch RemoteConfigInfo and sets features param
   */
  refetch: () => void
}

type AppFeaturesProviderProps = {
  features?: AppFeature[]
}

export const AppFeaturesContext = createContext<AppFeaturesContextProps>({
  features: {},
  refetch: () => {
    //
  },
})

export const AppFeaturesProvider: FC<
  PropsWithChildren<AppFeaturesProviderProps>
> = ({ children, features = [] }) => {
  const appKeys = features.map((f) => f.appKey)

  const [activeFeatures, setActiveFeatures] = useState<ActiveFeatures>({})

  const { fetch: getFeatures } = useFetchApi(queryRemoteConfigInfo)

  const fetchRemoteConfig = useCallback(
    async () =>
      await Promise.allSettled(
        features.map(async (feature) => {
          const { data } = await getFeatures({
            featureId: feature.key,
          } as QueryRemoteConfigInfoRequest)
          if (data) {
            setActiveFeatures((prev) => ({
              ...prev,
              [`${feature.appKey}` as (typeof appKeys)[number]]: data,
            }))
          } else {
            // Reset active features, this could happen if a user is not logged in
            setActiveFeatures({})
          }
        })
      ),
    [features, getFeatures]
  )

  useEffect(() => {
    if (!features?.length) return
    fetchRemoteConfig()
  }, [features, fetchRemoteConfig])

  useEffect(() => {
    console.log({ activeFeatures })
  }, [activeFeatures])

  useWindowFocus({ onWindowFocus: fetchRemoteConfig })

  return (
    <AppFeaturesContext.Provider
      value={{
        // ...activeFeatures,
        features: activeFeatures,
        refetch: fetchRemoteConfig,
      }}
    >
      {children}
    </AppFeaturesContext.Provider>
  )
}

export const useAppFeatures = () => {
  const value = useContext(AppFeaturesContext)
  return { ...value }
}

export default AppFeaturesProvider
