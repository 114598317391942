import { pluralize } from '@sportsyou/core'
import { Accordion, Post, PostProps } from '@sportsyou/react-dom-ui'
import { ScheduledPost } from '@sportsyou/api'

import PostList from './PostList'

export interface ScheduledPostsListProps {
  currentUser: any
  onScheduledPostUpdate: (post: ScheduledPost) => void
  posts: ScheduledPost[]
}

export const ScheduledPostsList: React.FC<ScheduledPostsListProps> = (
  props: ScheduledPostsListProps
) => {
  if (!props.posts?.length) {
    return null
  }

  return (
    <Accordion>
      <Accordion.Header>
        <Accordion.Trigger>
          {props.posts.length} {pluralize(props.posts.length, 'Post', 'Posts')}{' '}
          Scheduled
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content style={{ padding: 0 }}>
        <PostList
          currentUser={props.currentUser}
          onScheduledPostUpdate={props.onScheduledPostUpdate}
          posts={(props.posts as PostProps[]).map((p) => ({
            ...p,
            hideMenu: true,
          }))}
        />
        <hr />
      </Accordion.Content>
    </Accordion>
  )
}

export default ScheduledPostsList
