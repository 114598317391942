import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  Avatar,
  Button,
  DatePicker,
  FileInput,
  Icon,
  Modal,
  Select,
  Spinner,
  TextArea,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import {
  Image,
  mutationUserSetPhoto,
  MutationUserSetPhotoRequest,
  mutationUserUpdate,
  MutationUserUpdateRequest,
  queryProfile,
  QueryProfileRequest,
  queryUserDetail,
  QueryUserDetailRequest,
} from '@sportsyou/api'
import { capitalize, Colors, getImageByTranscodeType } from '@sportsyou/core'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'

import { DEFAULT_BIRTHDAY_INPUT_FORMAT, getFakeUser } from '../../../constants'
import {
  selectCurrentUser,
  setProfile,
  updateUser,
} from '../../../store/slices/UserSlice'

interface Props {
  autoFocusIntro?: boolean
  id?: string
  isVisible?: boolean
  onClose: () => void
  type?: 'user' | 'team'
}

interface UserProfileProps {
  avatarImageUrl?: string
  birthdate?: string
  city?: string
  coverImageUrl?: string
  firstName?: string
  fullName?: string
  gender?: string
  intro?: string
  lastName?: string
  state?: string
  zip?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type EditProfileProps = Props & NativeAttributes

type TextInputType =
  | 'firstName'
  | 'lastName'
  | 'intro'
  | 'city'
  | 'state'
  | 'zip'

export const EditProfile: React.FC<EditProfileProps> = ({
  autoFocusIntro = false,
  id,
  isVisible = true,
  onClose,
  type = 'user',
}: EditProfileProps) => {
  const { sendBanner, sendConfirm } = useDialog()
  const dispatch = useDispatch()

  const currentUser = useSelector(selectCurrentUser)

  const introTextRef = useRef<HTMLTextAreaElement | null>(null)

  const [isCoverImageUploading, setIsCoverImageUploading] =
    useState<boolean>(false)
  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false)
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false)
  const [showValidation, setShowValidation] = useState<boolean>(false)
  const [user, setUser] = useState<UserProfileProps>(() => ({
    // coverImageUrl: getLargeImageUrl(currentUser?.cover),
    avatarImageUrl: getImageByTranscodeType(
      currentUser?.profileImage as Image[],
      'profileImage'
    ),
    birthdate: currentUser?.birthday as string,
    firstName: currentUser?.firstName as string,
    fullName: currentUser?.fullName as string,
    gender: currentUser?.gender as string,
    intro: currentUser?.about ?? '',
    lastName: currentUser?.lastName as string,
    userId: currentUser?.id as string,
  }))

  const { fetch: getProfile } = useFetchApi(queryProfile)
  const { fetch: getUserDetail } = useFetchApi(queryUserDetail)
  const { fetch: setProfileImage } = useFetchApi(mutationUserSetPhoto)
  const { fetch: updateUserProfile } = useFetchApi(mutationUserUpdate)

  const setUserProp = useCallback((key: string, value: string) => {
    setUser((prevState) => {
      if (prevState[key as keyof UserProfileProps] === value) {
        return prevState
      }
      setHasFormChanged(true)
      setShowValidation(false)
      return { ...prevState, [key]: value }
    })
  }, [])

  const onBirthDatePickerChange = useCallback(
    (date: Date) => {
      setUserProp(
        'birthdate',
        moment(date).format(DEFAULT_BIRTHDAY_INPUT_FORMAT)
      )
    },
    [setUserProp]
  )

  const fetchData = useCallback(async () => {
    if (type === 'user') {
      const { data: profile } = await getProfile({
        id,
      } as QueryProfileRequest)
      const { data: user } = await getUserDetail({
        id,
      } as QueryUserDetailRequest)
      if (profile && user) {
        const coverImages = (profile.coverImage ?? []).map(
          (img) => img as Image
        )
        const profileImages = (profile.profileImage ?? []).map(
          (img) => img as Image
        )
        dispatch(updateUser(user))
        setUser({
          avatarImageUrl: getImageByTranscodeType(
            profileImages,
            'profileImage'
          ),
          birthdate: user?.birthday ?? undefined,
          city: user?.location?.city ?? '',
          coverImageUrl: getImageByTranscodeType(coverImages, 'coverImage'),
          firstName: user?.firstName ?? '',
          fullName: user?.fullName ?? '',
          gender: user?.gender ?? '',
          intro: user?.about ?? '',
          lastName: user?.lastName ?? '',
          state: user?.location?.stateProvince ?? '',
          zip: user?.location?.postalCode ?? '',
        })
      }
    }
  }, [dispatch, getProfile, getUserDetail, id, type])

  useEffect(() => {
    if (isVisible && autoFocusIntro) {
      setTimeout(() => {
        introTextRef.current?.focus()
      }, 400)
    }
  }, [autoFocusIntro, isVisible])

  useEffect(() => {
    if (isVisible) {
      fetchData()
    }
  }, [dispatch, getProfile, getUserDetail, id, type, isVisible, fetchData])

  useEffect(() => {
    // console.log({ user })
  }, [user])

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: TextInputType
  ) => {
    const { value } = event.target
    setUserProp(type, value)
    if (type === 'firstName') {
      setUserProp('fullName', `${value} ${user.lastName}`)
    } else if (type === 'lastName') {
      setUserProp('fullName', `${user.firstName} ${value}`)
    }
  }

  const onClickCancel = useCallback(() => {
    if (hasFormChanged) {
      sendConfirm({
        // onCancel: args.onCancel,
        onConfirm: onClose,
        cancelText: 'Cancel',
        confirmText: 'Discard',
        message: `This can't be undone and you'll lose your changes.`,
        title: 'Discard Changes?',
      })
    } else {
      onClose?.()
    }
  }, [hasFormChanged, onClose, sendConfirm])

  const onClickSave = useCallback(async () => {
    // Check validation
    if (user.firstName?.trim() === '' || user.lastName?.trim() === '') {
      return setShowValidation(true)
    }
    onClose?.()
    if (hasFormChanged) {
      const data = {
        about: user.intro,
        birthday: user.birthdate,
        firstName: user.firstName!,
        gender: user.gender,
        lastName: user.lastName!,
        location: {
          city: user.city,
          // country: '',
          postalCode: user.zip,
          stateProvince: user.state,
        },
      }
      // return
      const { ok, error } = await updateUserProfile({
        user: { ...data },
      } as MutationUserUpdateRequest)
      if (ok) {
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: 'Profile updated successfully',
        })
        console.log('Profile updated successfully', user)
        console.log({ data, user })
        dispatch(
          updateUser({
            ...data,
            fullName: `${user.firstName!} ${user.lastName}`,
          })
        )
      } else {
        console.log({ error })
        sendBanner({
          dismissTime: 5000,
          autoDismiss: true,
          status: 'alert',
          message: 'Profile update failed. ' + error,
        })
      }
    }
    setHasFormChanged(false)
    setShowValidation(false)
  }, [dispatch, hasFormChanged, onClose, sendBanner, updateUserProfile, user])

  type ImageType = 'cover-image' | 'avatar'
  const onUploadCancel = (type: ImageType) => {}
  const onUploaderError = (type: ImageType) => {}
  const onUploadStart = (uploads: ExtendedUpload[], type: ImageType) => {
    if (type === 'avatar') {
      setIsAvatarUploading(true)
    }
    if (type === 'cover-image') {
      setIsCoverImageUploading(true)
    }
    console.log({ uploads })
  }
  const onUploadDone = (type: ImageType) => {}
  const onTranscodeComplete = async (
    type: ImageType,
    upload?: ExtendedUpload
  ) => {
    if (!upload) return
    const { data, error, ok } = await setProfileImage({
      imageType: type === 'avatar' ? 1 : 2,
      uploadId: upload.id,
    } as MutationUserSetPhotoRequest)

    if (error) {
      console.log({ error })
    }

    let newState: UserProfileProps = {}
    if (data && ok) {
      if (upload.viewUrl) {
        if (type === 'avatar') {
          newState.avatarImageUrl = upload.viewUrl
          setIsAvatarUploading(false)
        } else if (type === 'cover-image') {
          newState.coverImageUrl = upload.viewUrl
          setIsCoverImageUploading(false)
        }

        // Update store with latest user details
        const { data } = await getUserDetail({ id } as QueryUserDetailRequest)
        const { data: profileData } = await getProfile({
          id,
        } as QueryUserDetailRequest)
        if (data) {
          dispatch(updateUser(data))
        }
        if (profileData) {
          dispatch(setProfile(profileData))
        }
        setUser((prevState) => ({
          ...prevState,
          ...newState,
        }))
      }
    }
  }

  if (!isVisible) {
    return null
  }

  return (
    <Modal backdropIgnoresClicks onClose={onClose} visible={isVisible}>
      <Modal.Header>
        <b>Edit Profile</b>
      </Modal.Header>
      <Modal.Body style={{ padding: '0 0 20px' }}>
        <FileInput
          cropConfig={{
            circular: false,
            height: 500,
            modalTitle: 'Update Your Profile Cover Image',
            modalWidth: 420,
            onClose: () => {},
            onCrop: () => {},
            width: 1600, // width/height updated to match react app
          }}
          onUploaderCancel={() => onUploadCancel('cover-image')}
          onUploaderError={() => onUploaderError('cover-image')}
          onUploaderUploadDone={() => onUploadDone('cover-image')}
          onUploaderUploadStart={(uploads) =>
            onUploadStart(uploads, 'cover-image')
          }
          onTranscodeComplete={(upload) =>
            onTranscodeComplete('cover-image', upload)
          }
          onlySelectFileButton
          selectFileButton={
            <CoverImage>
              {user.coverImageUrl && (
                <img alt='Profile Cover' src={user.coverImageUrl ?? ''} />
              )}
              {isCoverImageUploading ? (
                <SpinnerContainer>
                  <Spinner fill={Colors.WHITE} size={40} />
                </SpinnerContainer>
              ) : (
                <UploadImageButton>
                  <Icon fill={'white'} height={60} width={60} name='Camera' />
                </UploadImageButton>
              )}
            </CoverImage>
          }
          transcodings={['coverImage']} // use-uploader will replace it with proper transcoding set
          uploadType={'coverImage'} // setting either transcodings or uploadType should be good enough
        />

        <FileInput
          cropConfig={{
            circular: true,
            height: 420,
            modalTitle: 'Update Your Profile Avatar Image',
            modalWidth: 420,
            onClose: () => {},
            onCrop: () => {},
            width: 420,
          }}
          onUploaderCancel={() => onUploadCancel('avatar')}
          onUploaderError={() => onUploaderError('avatar')}
          onUploaderUploadStart={(uploads) => onUploadStart(uploads, 'avatar')}
          onUploaderUploadDone={() => onUploadDone('avatar')}
          onTranscodeComplete={(upload) =>
            onTranscodeComplete('avatar', upload)
          }
          onlySelectFileButton
          selectFileButton={
            <AvatarContainer>
              <Avatar
                diameter={100}
                name={user.fullName}
                uri={user.avatarImageUrl ?? ''}
              />

              {isAvatarUploading ? (
                <SpinnerContainer>
                  <Spinner fill={Colors.WHITE} size={40} />
                </SpinnerContainer>
              ) : (
                <UploadImageButton>
                  <Icon fill={'white'} height={40} width={40} name='Camera' />
                </UploadImageButton>
              )}
            </AvatarContainer>
          }
          transcodings={['profileImage']} // use-uploader will replace it with proper transcoding set
          uploadType={'profileImage'} // setting either transcodings or uploadType should be good enough
        />

        <Column>
          <InputRow>
            <StyledTextInput
              autoComplete='given-name'
              containerStyle={{ minWidth: 1 }}
              label='First Name'
              onChange={(e) => onChange(e, 'firstName')}
              placeholder={`${getFakeUser('firstName')}`}
              showValidationMessage={
                showValidation && (user.firstName ?? '').trim() === ''
              }
              status={
                showValidation && (user.firstName ?? '').trim() === ''
                  ? 'error'
                  : undefined
              }
              validationMessage={'Please Enter Your First Name'}
              value={user.firstName ?? ''}
            />
            <StyledTextInput
              autoComplete='family-name'
              containerStyle={{ minWidth: 1 }}
              label='Last Name'
              onChange={(e) => onChange(e, 'lastName')}
              placeholder={`${getFakeUser('lastName')}`}
              showValidationMessage={
                showValidation && (user.lastName ?? '').trim() === ''
              }
              status={
                showValidation && (user.lastName ?? '').trim() === ''
                  ? 'error'
                  : undefined
              }
              validationMessage={'Please Enter Your Last Name'}
              value={user.lastName ?? ''}
            />
          </InputRow>

          <StyledTextArea
            autoGrow
            initialRows={3}
            onFocus={(event) => event.target.select()}
            maximumRows={5}
            minimumRows={3}
            label='Intro'
            onChange={(e) => onChange(e, 'intro')}
            placeholder=''
            ref={introTextRef}
            value={user.intro}
          />

          <InputRow>
            <Select
              displayValue={capitalize(user.gender ?? '')}
              initialValue={user.gender}
              label='Gender'
              placeholder='Select Gender'
              triggerStyle={{ minWidth: 1 }}
            >
              <Select.Option
                isSelected={user.gender === 'female'}
                onClick={() => setUserProp('gender', 'female')}
                value='Female'
              >
                Female
              </Select.Option>
              <Select.Option
                isSelected={user.gender === 'male'}
                onClick={() => setUserProp('gender', 'male')}
                value='Male'
              >
                Male
              </Select.Option>
              <Select.Option
                isSelected={user.gender === 'other'}
                onClick={() => setUserProp('gender', 'other')}
                value='Other'
              >
                Other
              </Select.Option>
            </Select>
            <DatePicker
              label='Birth Date'
              onChange={onBirthDatePickerChange}
              placeholder='Select Birth Date'
              selected={
                user.birthdate?.length
                  ? moment(user.birthdate).toDate()
                  : undefined
              }
              shouldCloseOnSelect
            />
          </InputRow>

          <InputRowThreeColumn>
            <StyledTextInput
              autoComplete='address-level2'
              containerStyle={{ minWidth: 1 }}
              label='City/Town'
              onChange={(e) => onChange(e, 'city')}
              placeholder={getFakeUser('city') as string}
              value={user.city}
            />
            <StyledTextInput
              autoComplete='address-level1'
              containerStyle={{ minWidth: 1 }}
              label='State/Province'
              onChange={(e) => onChange(e, 'state')}
              placeholder='New York'
              value={user.state}
            />
            <StyledTextInput
              autoComplete='postal-code'
              containerStyle={{ minWidth: 1 }}
              label='Zip/Postal Code'
              onChange={(e) => onChange(e, 'zip')}
              placeholder='11765'
              value={user.zip}
            />
          </InputRowThreeColumn>
        </Column>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CancelButton
            appearance='minimal'
            onClick={onClickCancel}
            variant='alternate'
          >
            Cancel
          </CancelButton>
          <Button onClick={onClickSave}>Save</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
const UploadImageButton = styled.button`
  align-items: center;
  background: none;
  background-color: rgb(0 0 0 / 30%);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  transition: 200ms ease-in-out;
  width: 100%;
  // z-index: 10;
  // display: none;
  opacity: 0;
  visibility: hidden;
`
const CoverImage = styled.div`
  background-color: ${Colors.CATSKILL_WHITE};
  height: 180px;
  overflow: hidden;
  position: relative;
  & > img {
    display: block;
    height: 100%;
    left: 50%;
    min-width: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  &:hover > ${UploadImageButton}, &:active > ${UploadImageButton} {
    opacity: 1;
    visibility: visible;
    display: block;
  }
`
const SpinnerContainer = styled.div`
  align-items: center;
  background: none;
  background-color: rgb(0 0 0 / 30%);
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
`
const AvatarContainer = styled.div`
  align-items: center;
  border: 4px solid ${Colors.WHITE};
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-flex;
  height: 100px;
  margin-left: 10px;
  margin-top: -50px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100px;
  // z-index: 100;

  &:hover > ${UploadImageButton}, &:active > ${UploadImageButton} {
    opacity: 1;
    visibility: visible;
    display: block;
  }
`
const StyledTextInput = styled(TextInput)`
  min-width: 1px;

  div {
    min-width: 1px;
  }
`

const Column = styled.div`
  display: 'flex';
  flex-direction: 'column';
  overflow-y: auto;
`
const InputRow = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }

  & .react-datepicker__tab-loop {
    position: fixed;
    z-index: 100;
  }
`
const InputRowThreeColumn = styled(InputRow)`
  grid-template-columns: 1fr 1fr 1fr;
`
const StyledTextArea = styled(TextArea)`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  width: calc(100% - 20px);
`
const CancelButton = styled(Button)`
  margin-right: 6px;
`
export default EditProfile
