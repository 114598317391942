import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearLightboxItems,
  hideLightbox,
  LightboxInterface,
  LightboxItem,
  setLightboxIndex,
  setLightboxItems,
  showLightbox,
  showLightboxWithItems,
} from '../../../store/slices/LightboxSlice'
import { RootState } from '../../../store/rootReducer'

export default function useLightbox() {
  const dispatch = useDispatch()

  const index = useSelector((state: RootState) => state.lightbox.index ?? 0)
  const isOpen = useSelector((state: RootState) => state.lightbox.isOpen)
  const items = useSelector((state: RootState) => state.lightbox.items)
  const playlist = useSelector((state: RootState) => state.lightbox.playlist)

  const count = useMemo(() => items.length, [items])

  const show = useCallback(() => dispatch(showLightbox()), [dispatch])

  const hide = useCallback(() => dispatch(hideLightbox()), [dispatch])

  const showWithItems = useCallback(
    (payload: LightboxInterface) => dispatch(showLightboxWithItems(payload)),
    [dispatch]
  )

  const setIndex = useCallback(
    (payload: number) => dispatch(setLightboxIndex(payload)),
    [dispatch]
  )

  const setItems = useCallback(
    (payload: LightboxItem[]) => dispatch(setLightboxItems(payload)),
    [dispatch]
  )

  const clearItems = useCallback(
    () => dispatch(clearLightboxItems()),
    [dispatch]
  )

  return {
    clearItems,
    count,
    hide,
    index,
    isOpen,
    items,
    playlist,
    setIndex,
    setItems,
    show,
    showWithItems,
  }
}
