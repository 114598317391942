import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import styled, { css } from 'styled-components'

import {
  Chat,
  ChatMessage,
  ChatMessageLike,
  ChatMessageLikeCounts,
  ChatParticipant,
  Image,
  Profile,
  QueryChatMessagesRequest,
  Upload,
  mutationChatMessageLike,
  mutationChatMessageLikeDelete,
  queryChat,
  queryChatMessages,
  queryLikesInChatMessage,
} from '@sportsyou/api'
import {
  Activity,
  Avatar,
  BORDER_RADIUS,
  Badge,
  Button,
  Dropdown,
  Icon,
  RenameInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { Colors, getProfileImage, lighten, sleep } from '@sportsyou/core'
import { useFetchApi, useMediaQuery, usePrevious } from '@sportsyou/react-hooks'

import {
  ChatAttachment,
  ChatIncomingMessageType,
} from '../../../services/Chat/ChatBase'
import { RootState } from '../../../store/rootReducer'
import {
  addChatBox,
  logChatLeft,
  removeChatBox,
  updateChatBox,
} from '../../../store/slices/ChatBoxSlice'
import { getUsername } from '../../../utils/user'
import { Person } from '../../components/SelectUserList/SelectUserList'
import {
  fetchChats,
  refreshChatListItem,
  removeChatFromList,
  updateChatList,
} from '../../../store/slices/ChatListSlice'
import { selectChatEvent } from '../../../store/slices/ChatEventSlice'
import { selectCurrentUser } from '../../../store/slices/UserSlice'
import { AddChatMembersModal } from '../../components/Modals/AddChatMembers/AddChatMembers'
import ChatMessageInput from './ChatMessageInput'
import ChatReportUserModal from './ChatReportUserModal'
import ChatRoomMessage from './ChatRoomMessage'
import ChatViewedByModal from './ChatViewedByModal'
import ReactionsModal from './ChatMessageReactionsModal'
import {
  useChat,
  CHAT_MESSAGE_FILTER,
  ChatDeleteProps,
} from '../../hooks/useChat'
import { CHAT_TYPE_DIRECT, SMALL_SCREEN_BREAKPOINT } from '../../../constants'
import useWindowFocus from '../../hooks/useWindowFocus'

interface ChatRoomProps {
  boxMode?: boolean
  chatId: string
  focused: boolean
  minimized?: boolean
}

export const LIKE_TYPE = ':thumbsup:'

export const convertUploadDataPropsToChatAttachment = (
  newUploads: Upload[]
): ChatAttachment[] => {
  return newUploads.map((upload) => {
    return {
      uploadId: upload.id!,
      contentType: upload.contentType!,
      fileName: upload.fileName!,
      viewUrl: upload.viewUrl!,
      src: upload.viewUrl!,
      // localSource: upload.localSource,
      width: upload.width!,
      height: upload.height!,
      // isAnimated: upload.isAnimated, // this is for gifs; we need to figure out if the gif is animated gif or not on client side first
    }
  })
}

export default function ChatRoom(props: ChatRoomProps) {
  const { boxMode, chatId, focused, minimized } = props
  const { sendBanner, sendConfirm } = useDialog()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT})`)

  const { chatsScreenActiveChatId, pressEnterToSend } = useSelector(
    (s: RootState) => s.chatBoxes
  )
  const chatEvent = useSelector(selectChatEvent)
  const currentUser = useSelector(selectCurrentUser)

  const chatClient = useChat({
    establishConnection: false,
    filter: CHAT_MESSAGE_FILTER.LISTEN_FOR_ALL,
  })

  const [chat, setChat] = useState<Chat>()
  const [isAddChatMembersModalOpen, setIsAddChatMembersModalOpen] =
    useState(false)
  const [isInitialFetch, setIsInitialFetch] = useState<boolean>(true)
  // const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRenaming, setIsRenaming] = useState(false)
  const [isSomeoneTyping, setIsSomeoneTyping] = useState<string | undefined>()
  const [isViewedByModalOpen, setIsViewedByModalOpen] = useState(false)
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [reactionsModalItems, setReactionsModalItems] =
    useState<ChatMessageLike[]>()
  const [userToReport, setUserToReport] = useState<ChatParticipant>()
  const [isScrolling, setIsScrolling] = useState(false)

  const prevChatId = usePrevious(chatId, chatId)
  const prevChatToken = usePrevious(chat?.token, chat?.token)
  const prevIsScrolling = usePrevious(isScrolling, isScrolling)

  const someoneTypingTimer = useRef<number>()
  const numberOfLastPaging = useRef<number>(0)
  const listRef = useRef<VirtuosoHandle>(null)
  const hasUserScrolled = useRef(false)

  const { fetch: addReaction } = useFetchApi(mutationChatMessageLike)
  const { fetch: fetchReactions } = useFetchApi(queryLikesInChatMessage)
  const { fetch: getChat } = useFetchApi(queryChat)
  const { fetch: getMessages } = useFetchApi(queryChatMessages)
  const { fetch: removeReaction } = useFetchApi(mutationChatMessageLikeDelete)

  const activeParticipants = useMemo(
    () =>
      (chat?.participants ?? []).filter(
        (p) =>
          p?.isActive && p.isExisting && !p.deletedAt && p.id !== currentUser.id
      ),
    [chat?.participants, currentUser.id]
  )

  const isCreator = useMemo(
    () => chat?.createdById === currentUser.id,
    [chat?.createdById, currentUser.id]
  )

  // All users in the chat, except the current user
  const participants = useMemo(() => {
    return (
      (chat?.participants?.filter(
        (p) => p?.id !== currentUser.id
      ) as ChatParticipant[]) ?? []
    )
  }, [chat?.participants, currentUser])

  const canDeleteChat = useMemo(() => {
    return chat?.team?.isAdmin
  }, [chat?.team?.isAdmin])

  const canRenameChat = useMemo(() => {
    return isCreator && chat?.chatType !== CHAT_TYPE_DIRECT && !boxMode
  }, [boxMode, isCreator, chat?.chatType])

  const fetchMessages = useDebouncedCallback(
    async (loadPrevious?: boolean) => {
      let firstMessageId: string | undefined = undefined
      if (loadPrevious && messages.length) {
        firstMessageId = messages[0].id ?? undefined
      }
      const params: QueryChatMessagesRequest = {
        chatId,
        firstMessageId,
      }

      const { data: _messages } = await getMessages(params)

      numberOfLastPaging.current = _messages?.length ?? 0
      if (_messages?.length) {
        if (loadPrevious && !isInitialFetch) {
          setMessages((prev) => [..._messages!, ...prev])
        } else {
          setMessages(_messages)
        }
        // setIsLoading(false)
        setIsInitialFetch(false)
      }
    },
    250,
    { leading: true, trailing: true }
  )

  const fetchData = useCallback(
    async (shouldRefreshChatList?: boolean, shouldUpdateChatList?: boolean) => {
      // setIsLoading(true)
      getChat({ id: chatId }).then(({ data: chat, ok }) => {
        if (ok && chat) {
          setChat(chat)
          shouldRefreshChatList && dispatch(refreshChatListItem(chat)) // chat list UI
          shouldUpdateChatList && dispatch(updateChatList(chat)) // chat list redux data
        }
      })
      fetchMessages()
    },
    [chatId]
  )

  const onCloseReactionsModal = useCallback(() => {
    setReactionsModalItems(undefined)
  }, [])

  const onCloseReportUserModal = useCallback(() => {
    setUserToReport(undefined)
  }, [])

  const onCloseAddChatMembersModal = useCallback(() => {
    setIsAddChatMembersModalOpen(false)
  }, [])

  const onCloseViewedByModal = useCallback(() => {
    setIsViewedByModalOpen(false)
  }, [])

  const onClickSendButton = useCallback(
    async ({
      messageText,
      uploads,
    }: {
      messageText: string
      uploads: Upload[]
    }): Promise<boolean> => {
      const send = async ({
        message,
        upload,
      }: {
        message?: string
        upload?: Upload
      }) => {
        const chatAttachment = upload
          ? convertUploadDataPropsToChatAttachment([upload])[0]
          : undefined
        return await chatClient.chatSend({
          chatAttachment,
          message,
          token: chat?.token!,
          uploadId: upload?.id ?? undefined,
        })
      }

      if (messageText.length > 0) {
        const { ok } = await send({
          message: messageText,
        })
        if (!ok) {
          return false
        }
      }
      if (uploads.length > 0) {
        for (const upload of uploads) {
          const { ok } = await send({
            upload,
          })
          if (!ok) {
            return false
          }
        }
      }
      fetchMessages()
      return true
    },
    [chat?.token, fetchMessages]
  )

  const onClickOpenInChatWindow = useCallback(() => {
    dispatch(addChatBox({ chatId, minimized: false }))
  }, [dispatch, chatId])

  const onClickMaximize = useCallback(() => {
    dispatch(updateChatBox({ chatId, minimized: false }))
  }, [chatId, dispatch])

  const onClickMinimize = useCallback(() => {
    dispatch(updateChatBox({ chatId, minimized: true }))
  }, [chatId, dispatch])

  const onClickClose = useCallback(() => {
    dispatch(removeChatBox({ chatId }))
  }, [chatId, dispatch])

  const onClickRename = useCallback(() => {
    if (isCreator) setIsRenaming(true)
  }, [isCreator])

  const onClickAddPeople = useCallback(() => {
    setIsAddChatMembersModalOpen(true)
  }, [])

  const openReportUserModal = useCallback((user?: ChatParticipant) => {
    setUserToReport(user)
  }, [])

  const onClickReportUser = useCallback(() => {
    openReportUserModal(participants[0])
  }, [openReportUserModal, participants])

  const handleBlockUser = useCallback(
    (participant: ChatParticipant) => {
      const onConfirm = async () => {
        const { ok } = await chatClient.chatBlock({
          targetId: participant?.id!,
          token: chat?.token!,
          userId: currentUser?.id!,
          userName: currentUser?.fullName!,
        })
        if (ok) {
          dispatch(removeChatBox({ chatId: chat?.id! }))
          dispatch(removeChatFromList({ chatToken: chat?.token! }))
          await sleep(1_000)
          dispatch(fetchChats())
        }
      }
      if (participant) {
        sendConfirm({
          confirmText: 'Block',
          onConfirm,
          message:
            'Are you sure you want to block this user? The user will no longer be able to send you individual chat messages.',
          title: `Block ${participant.fullName}?`,
        })
      }
    },
    [
      chat?.participants,
      chat?.token,
      currentUser?.fullName,
      currentUser?.id,
      sendConfirm,
    ]
  )

  const onClickBlockUser = useCallback(() => {
    handleBlockUser(participants[0])
  }, [handleBlockUser, participants])

  const onClickLeaveChat = useCallback(() => {
    const onConfirm = async () => {
      const { ok } = await chatClient.chatLeaveRoom({
        token: chat?.token as string,
        userId: currentUser?.id as string,
        userName: currentUser?.fullName as string,
      })
      if (ok && chat) {
        dispatch(logChatLeft(chat.id))
        dispatch(removeChatBox({ chatId: chat.id as string }))
        dispatch(removeChatFromList({ chatToken: chat.token as string }))
        await sleep(1_000)
        dispatch(fetchChats())
      }
    }
    sendConfirm({
      confirmText: 'Leave',
      onConfirm,
      message: 'Are you sure?',
      title: 'Leave Chat',
    })
  }, [
    chat?.id,
    chat?.token,
    currentUser?.fullName,
    currentUser?.id,
    dispatch,
    sendConfirm,
  ])

  const onClickDeleteChat = useCallback(() => {
    sendConfirm({
      confirmText: 'Delete',
      message: `This will delete the chat for all ${
        chat?.team?.teamType ?? 'team'
      } members. Are you sure?`,
      onConfirm: async () => {
        const { ok, error } = await chatClient.chatDelete({
          deleteTeamChat: true,
          token: chat?.token!,
        } as ChatDeleteProps)
        if (ok) {
          dispatch(removeChatBox({ chatId: chat?.id! }))
          dispatch(removeChatFromList({ chatToken: chat?.token! }))
          dispatch(fetchChats())
        }
        if (error) {
          console.log({ error })
        }
      },
    })
  }, [
    chat?.id,
    chat?.team?.teamType,
    chat?.token,
    chatClient,
    dispatch,
    sendConfirm,
  ])

  const onClickToggleLike = useCallback(
    (message: ChatMessage) => {
      const isLiked = message.likes?.some((l) => l?.currentUser)
      const data = { chatMessageId: message.id!, likeType: LIKE_TYPE }

      // Get initial likes for message
      const currentLikes =
        !message.likes || message.likes.length === 0
          ? ([
              {
                count: 0,
                currentUser: false,
                likeType: LIKE_TYPE,
              },
            ] as Array<ChatMessageLikeCounts>)
          : (message.likes as Array<ChatMessageLikeCounts>)

      const likeTypeIndex = currentLikes.findIndex(
        (l) => l?.likeType === LIKE_TYPE
      )
      // Get current count for reaction
      const currentCount = currentLikes[likeTypeIndex].count ?? 0

      // Update params for ui
      const newParams: ChatMessageLikeCounts = {
        // Add/Remove current user from likes
        currentUser: !isLiked,
        // Update count
        count:
          isLiked && currentCount > 0 ? currentCount - 1 : currentCount + 1,
        likeType: LIKE_TYPE,
      }

      // TODO: messages are sometimes not available right away
      const _messages = [...messages]
      const currentMessageIndex = _messages.findIndex(
        (m) => m.id === message.id
      )
      if (
        !_messages[currentMessageIndex].likes ||
        _messages[currentMessageIndex].likes?.length === 0
      ) {
        _messages[currentMessageIndex].likes = [newParams]
      } else {
        _messages[currentMessageIndex].likes![likeTypeIndex] = newParams
      }

      setMessages(_messages)

      isLiked ? removeReaction(data) : addReaction(data)
    },
    [addReaction, messages, removeReaction]
  )

  const onClickShowReactionsModal = useCallback(
    async (message: ChatMessage) => {
      const { data: _reactions } = await fetchReactions({
        chatMessageId: message.id,
      })
      setReactionsModalItems(_reactions ?? [])
    },
    [fetchReactions]
  )

  const onRenameSubmit = useCallback(
    async (title: string) => {
      setTimeout(() => {
        setIsRenaming(false)
      }, 500)
      await chatClient.chatRename({
        chatId,
        chatTitle: title,
      })
      fetchData(true)
    },
    [chatClient, chatId, fetchData]
  )

  const onRenameCancel = useCallback(() => {
    setTimeout(() => {
      setIsRenaming(false)
    }, 100)
  }, [])

  const onClickViewedBy = useCallback(() => {
    setIsViewedByModalOpen(true)
  }, [])

  const onMessageInputKeyPress = useCallback(() => {
    chatClient.chatTyped({
      name: currentUser?.fullName!,
      token: chat?.token!,
      userId: currentUser?.id!,
    })
  }, [chat?.token, currentUser?.fullName, currentUser?.id])

  const onAddUsers = useCallback(
    async (users: Person[]) => {
      const { ok } = await chatClient.chatAddMembers({
        chatId: chat?.id!,
        partnerIds: users.map((user) => user.id!),
      })
      if (ok) {
        const message =
          (users.length === 1
            ? getUsername(users[0]) + ' was'
            : `${users.length} people`) + ' added to the chat'
        sendBanner({
          autoDismiss: true,
          message,
          status: 'success',
        })
        fetchData()
      }
    },
    [chat?.id, fetchData]
  )

  const onWindowFocus = useCallback(
    () => chat?.id && chatClient.setActive(true),
    [chat?.id]
  )

  const onWindowBlur = useCallback(() => {}, [])

  useWindowFocus({ onWindowFocus, onWindowBlur })

  useEffect(() => {
    return () => {
      console.log('unmounting, stop chat client for this instance')
      chatClient.stop()
    }
  }, [])

  useEffect(() => {
    if (chatId !== prevChatId) {
      chatClient.setActive(false)
    }
  }, [chatId])

  useEffect(() => {
    async function start() {
      await chatClient.start(chat?.token!)
      if (!boxMode) {
        chatClient.chatViewed()
        await sleep(250)
        chatClient.updateUnreadCount()
      }
    }

    chat?.token && chat?.token !== prevChatToken && start()
  }, [boxMode, chat?.token])

  useEffect(() => {
    chatClient.setActive(focused)
  }, [focused])

  useEffect(() => {
    function handleChatEvent() {
      const eventType = chatEvent?.wsMessageType

      if (eventType === ChatIncomingMessageType.CHAT_LEFT) {
        if (
          chatEvent?.from === currentUser.id ||
          chatEvent?.id === currentUser.id
        ) {
          dispatch(removeChatBox({ chatId: chat?.id! }))
          dispatch(removeChatFromList({ chatToken: chat?.token! }))
        } else {
          fetchMessages()
        }
        dispatch(fetchChats())
      }

      if (
        (chatEvent?.token && chatEvent.token === chat?.token) ||
        (chatEvent?.id && chatEvent.id === chat?.id)
      ) {
        if (eventType === ChatIncomingMessageType.CHAT_TYPING) {
          setIsSomeoneTyping(chatEvent?.fromName)
        } else if (eventType === ChatIncomingMessageType.MESSAGE_TO_CLIENT) {
          fetchMessages()
          dispatch(updateChatList(chat!))
        } else if (eventType === ChatIncomingMessageType.REACTION) {
          fetchMessages()
        } else if (eventType === ChatIncomingMessageType.CHAT_LEFT) {
          fetchData(true, true)
        } else if (
          eventType === ChatIncomingMessageType.CHAT_VIEWED &&
          chatEvent?.from !== currentUser.id
        ) {
          fetchData(false, true)
        } else if (eventType === ChatIncomingMessageType.CHAT_ADD_NEW_MEMBER) {
          fetchData(true, true)
        }
      }
    }
    chatEvent && handleChatEvent()
  }, [chatEvent, fetchData])

  useEffect(() => {
    clearTimeout(someoneTypingTimer.current)
    if (isSomeoneTyping) {
      someoneTypingTimer.current = window.setTimeout(() => {
        setIsSomeoneTyping(undefined)
      }, 3000)
    } else {
      setIsSomeoneTyping(undefined)
    }
  }, [isSomeoneTyping])

  useEffect(() => {
    if (chatId) {
      fetchData()
      if (prevChatId !== chatId) {
        hasUserScrolled.current = false
        setMessages([])
      }
    }
  }, [chatId, fetchData])

  // Scroll to bottom when chat box is un-minimized
  useEffect(() => {
    if (!minimized) {
      hasUserScrolled.current = false
      listRef.current?.scrollToIndex({ index: messages.length })
    }
  }, [minimized])

  useEffect(() => {
    if (
      numberOfLastPaging.current &&
      messages.length > numberOfLastPaging.current &&
      hasUserScrolled.current
    ) {
      listRef.current?.scrollToIndex(numberOfLastPaging.current)
      numberOfLastPaging.current = 0
    } else if (messages.length && !hasUserScrolled.current) {
      listRef.current?.scrollToIndex(messages.length)
    }
  }, [messages])

  useEffect(() => {
    if (isScrolling) {
      if (messages.length && prevIsScrolling === false) {
        if (!hasUserScrolled.current) {
          hasUserScrolled.current = true
        }
      } else {
        hasUserScrolled.current = false
      }
    } else {
      if (!messages.length) {
        hasUserScrolled.current = false
      }
    }
  }, [isScrolling, messages])

  const onChatInputFocus = useCallback(async () => {
    await chatClient.chatViewed()
    await sleep(250)
    chatClient.updateUnreadCount()
  }, [])

  const renderItem = useCallback(
    (_index: number, message: ChatMessage) => {
      return (
        <ChatRoomMessage
          byCurrentUser={message.createdById === currentUser.id}
          key={message.id}
          message={message}
          onClickShowReactionsModal={onClickShowReactionsModal}
          onClickToggleLike={onClickToggleLike}
          participants={chat?.participants as ChatParticipant[]}
        />
      )
    },
    [
      chat?.participants,
      currentUser.id,
      onClickShowReactionsModal,
      onClickToggleLike,
    ]
  )

  const renderMemberlist = () => (
    <Dropdown
      collapse
      maxHeight={boxMode ? 220 : 380}
      menuWidth='200px'
      placement={'bottomEnd'}
    >
      <Dropdown.Toggle>
        <Button
          appearance='minimal'
          style={{
            height: 32,
            position: 'relative',
            width: 32,
            minWidth: 1,
            minHeight: 1,
            zIndex: 1,
          }}
        >
          <Icon name='UserSolid' width={14} fill={Colors.SHUTTLE_GRAY} />
          {chat?.participants && (
            <ParticipantBadge
              textStyle={{
                color: Colors.WHITE,
                fontSize: 10,
                fontWeight: 500,
              }}
              fill={Colors.SHUTTLE_GRAY}
              count={activeParticipants.length + 1}
              style={
                activeParticipants.length + 1 <= 9 ? { padding: 0 } : undefined
              }
            />
          )}
        </Button>
      </Dropdown.Toggle>
      {participants.map((participant) => {
        if (
          !(
            participant.isActive &&
            participant.isExisting &&
            !participant.deletedAt
          )
        ) {
          return null
        }
        return (
          <Dropdown.Item
            key={participant.id}
            onClick={() => navigate(`/profile/${participant.id}`)}
          >
            <Avatar
              diameter={18}
              name={participant.fullName!}
              uri={getProfileImage.getProfileAvatarImageUrl(
                participant as Profile
              )}
            />
            <MemberlistName>{participant.fullName}</MemberlistName>
          </Dropdown.Item>
        )
      })}
      <Dropdown.Item>
        <Avatar
          diameter={18}
          name={currentUser.fullName!}
          uri={(currentUser.profileImage as Image)?.viewUrl ?? undefined}
        />
        <MemberlistName>{currentUser.fullName} (you)</MemberlistName>
      </Dropdown.Item>
    </Dropdown>
  )

  const renderDropdown = useMemo(() => {
    const canAddPeople = !chat?.team || chat?.team?.isAdmin
    return (
      <Dropdown collapse placement={'bottomEnd'}>
        <Dropdown.Toggle>
          <Button
            appearance='minimal'
            style={{ height: 32, minHeight: 1, minWidth: 1, width: 32 }}
          >
            <Icon fill={Colors.SHUTTLE_GRAY} name='GearSolid' size={17} />
          </Button>
        </Dropdown.Toggle>
        {canAddPeople ? (
          <Dropdown.Item onClick={onClickAddPeople}>Add People</Dropdown.Item>
        ) : null}
        {canRenameChat && (
          <Dropdown.Item onClick={onClickRename}>Rename Chat</Dropdown.Item>
        )}
        {chat?.participants?.length === 2 && (
          <>
            <Dropdown.Item onClick={onClickReportUser}>
              Report User
            </Dropdown.Item>
            <Dropdown.Item onClick={onClickBlockUser}>Block User</Dropdown.Item>
          </>
        )}
        {!boxMode && (
          <Dropdown.Item onClick={onClickOpenInChatWindow}>
            Open in Chat Window
          </Dropdown.Item>
        )}
        <Dropdown.Divider gap={0} />
        <Dropdown.Item onClick={onClickLeaveChat}>Leave Chat</Dropdown.Item>
        {canDeleteChat ? (
          <Dropdown.Item onClick={onClickDeleteChat} isDestructive>
            Delete Chat
          </Dropdown.Item>
        ) : null}
      </Dropdown>
    )
  }, [
    boxMode,
    canRenameChat,
    chat?.participants?.length,
    chat?.team,
    isCreator,
    onClickAddPeople,
    onClickBlockUser,
    onClickDeleteChat,
    onClickLeaveChat,
    onClickOpenInChatWindow,
    onClickRename,
    onClickReportUser,
  ])

  // // Styles for minimze/maximize icons for chat box
  // const lineIconStyles: CSSProperties = useMemo(
  //   () => ({
  //     alignSelf: !!minimized ? 'flex-start' : 'flex-end',
  //     backgroundColor: Colors.WHITE,
  //     height: 1,
  //     margin: 4,
  //     width: '100%',
  //   }),
  //   [minimized],
  // )

  const viewedBy = useMemo(
    () =>
      chat
        ? (chat.chatViewedBy?.filter(
            (user) => !(user?.id === currentUser.id || user?.deletedAt)
          ) as ChatParticipant[]) ?? []
        : [],
    [chat]
  )

  const notViewedBy = useMemo(
    () =>
      chat
        ? ((chat.participants
            // remove users who have already viewed
            ?.filter((p) => !viewedBy.map((v) => v.id).includes(p?.id))
            // remove delete users
            .filter((p) => !p?.deletedAt)
            // remove current user
            .filter((p) => !p?.isCurrentUser) ?? []) as ChatParticipant[])
        : [],
    [chat, viewedBy]
  )

  const renderChatFooter = () => {
    if (chat?.chatDirection === 'unilateral' && !isCreator) return null
    return (
      <ChatRoomFooter>
        <ChatMessageInput
          boxMode={boxMode}
          chatId={chatId}
          initialRows={boxMode ? 1 : 3}
          isSomeoneTyping={isSomeoneTyping}
          maximumRows={boxMode ? 3 : 6}
          minimumRows={boxMode ? 1 : 3}
          onClickSendButton={onClickSendButton}
          onClickViewedBy={onClickViewedBy}
          onFocus={onChatInputFocus}
          onMessageInputKeyPress={onMessageInputKeyPress}
          placeholder={'Send a message...'}
          pressEnterToSend={pressEnterToSend}
          showSendUI
          showViewedBy={chat?.team?.isAdmin as boolean}
          viewedBy={viewedBy}
        />
      </ChatRoomFooter>
    )
  }

  return (
    <Container boxMode={!!boxMode} isSmallScreen={isSmallScreen}>
      {/* Chat Header */}
      <ChatRoomHeader boxMode={!!boxMode}>
        <Header boxMode={!!boxMode}>
          <TitleContainer onClick={boxMode ? undefined : onClickRename}>
            {!boxMode && isRenaming && (
              <RenameInput
                onCancel={onRenameCancel}
                onSubmit={onRenameSubmit}
                value={chat?.customChatTitle ?? chat?.chatTitle ?? ''}
              />
            )}
            {!isRenaming
              ? activeParticipants.length > 0 && (
                  <>
                    <Title boxMode={!!boxMode}>
                      {chat?.customChatTitle ?? chat?.chatTitle}
                    </Title>
                    {!boxMode && canRenameChat && (
                      <Icon
                        name='Pencil'
                        height={14}
                        width={14}
                        style={{ flex: '0 0 auto', marginLeft: 6 }}
                      />
                    )}
                  </>
                )
              : null}
          </TitleContainer>

          {/* Chat Room Options */}
          {!boxMode && renderMemberlist()}
          {!boxMode && renderDropdown}

          {/* Chat Box Options */}
          {boxMode && (
            <BoxModeButtons>
              {!isSmallScreen &&
                (minimized ? (
                  <BoxModeButton
                    appearance='minimal'
                    collapse
                    variant='alternate'
                    onClick={onClickMaximize}
                    textStyle={{
                      alignSelf: 'flex-start',
                      backgroundColor: Colors.WHITE,
                      height: 1,
                      margin: 4,
                      width: '100%',
                    }}
                  >
                    {''}
                    {/* <Icon fill={Colors.WHITE} name="Plus" width={12} /> */}
                  </BoxModeButton>
                ) : (
                  <BoxModeButton
                    appearance='minimal'
                    collapse
                    variant='alternate'
                    onClick={onClickMinimize}
                    textStyle={{
                      alignSelf: 'flex-end',
                      backgroundColor: Colors.WHITE,
                      height: 1,
                      margin: 4,
                      width: '100%',
                    }}
                  >
                    {''}
                    {/* <Icon fill={Colors.WHITE} name="Pin" width={12} /> */}
                  </BoxModeButton>
                ))}
              <BoxModeButton
                appearance='minimal'
                collapse
                variant='alternate'
                onClick={onClickClose}
              >
                <Icon fill={Colors.WHITE} name='X' width={12} />
              </BoxModeButton>
            </BoxModeButtons>
          )}
        </Header>

        {boxMode && !minimized && (
          <SubHeader>
            {renderMemberlist()}
            {renderDropdown}
          </SubHeader>
        )}
        {!isCreator && chat?.chatDirection === 'unilateral' && (
          <DisabledRepliesMessage>
            Sender has disabled replies
          </DisabledRepliesMessage>
        )}
      </ChatRoomHeader>

      {/* Chat Body */}
      {!minimized && (
        <ChatRoomBody style={{ position: 'relative' }}>
          {chat ? (
            <Virtuoso
              alignToBottom
              atTopStateChange={(isReached) =>
                isReached && hasUserScrolled.current && fetchMessages(true)
              }
              atTopThreshold={100}
              data={messages}
              followOutput={(isAtBottom: boolean) => isAtBottom}
              isScrolling={setIsScrolling}
              itemContent={renderItem}
              ref={listRef}
              totalCount={messages.length}
            />
          ) : (
            <Activity position='absolute' />
          )}
        </ChatRoomBody>
      )}

      {/* Chat Footer */}
      {!minimized && renderChatFooter()}

      {/* Chat Modals */}
      {chat && (
        <>
          <AddChatMembersModal
            chat={chat}
            currentUserId={currentUser.id!}
            onAddUsers={onAddUsers}
            onClose={onCloseAddChatMembersModal}
            visible={isAddChatMembersModalOpen}
          />
          <ChatReportUserModal
            chat={chat}
            onClose={onCloseReportUserModal}
            user={userToReport}
          />
          <ChatViewedByModal
            chat={chat}
            isOpen={isViewedByModalOpen}
            notViewedBy={notViewedBy}
            onClose={onCloseViewedByModal}
            viewedBy={viewedBy}
          />
        </>
      )}
      {!!reactionsModalItems?.length && (
        <ReactionsModal
          items={reactionsModalItems}
          onClose={onCloseReactionsModal}
        />
      )}
    </Container>
  )
}

const Container = styled.div<{ boxMode: boolean; isSmallScreen: boolean }>`
  align-items: flex-start;
  align-self: stretch;
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: ${({ boxMode, isSmallScreen }) =>
    isSmallScreen ? undefined : boxMode ? undefined : BORDER_RADIUS};
  border-top-left-radius: ${({ boxMode, isSmallScreen }) =>
    isSmallScreen ? undefined : boxMode ? BORDER_RADIUS : undefined};
  border-top-right-radius: ${({ boxMode, isSmallScreen }) =>
    isSmallScreen ? undefined : boxMode ? BORDER_RADIUS : undefined};
  box-shadow: ${({ boxMode }) =>
    boxMode ? '0 0 12px -4px rgb(0 0 0 / 30%)' : undefined};
  border-bottom: ${({ boxMode }) => (boxMode ? 'none' : undefined)};

  margin-left: ${({ boxMode }) => (!boxMode ? '10px' : undefined)};

  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: ${({ boxMode }) => (!boxMode ? '1 1 calc(100% - 350px)' : '1 0 auto')};

  // height: ${({ boxMode }) => (boxMode ? undefined : 'calc(100vh - 80px)')};
`

const ChatRoomHeader = styled.header<{ boxMode: boolean }>`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  // border-top-left-radius: ${({ boxMode }) => (boxMode ? '8px' : '0')};
  // border-top-right-radius: ${({ boxMode }) => (boxMode ? '8px' : '0')};
  border-bottom: ${({ boxMode }) =>
    !boxMode ? `1px solid ${Colors.ALTO}` : undefined};

  width: 100%;
`
const Header = styled.div<{ boxMode: boolean }>`
  align-items: center;
  background-color: ${({ boxMode }) =>
    boxMode ? Colors.HAVELOCK_BLUE : undefined};
  color: ${({ boxMode }) => (boxMode ? Colors.WHITE : Colors.BLACK)};
  display: flex;
  height: ${({ boxMode }) => (boxMode ? 44 : 60)}px;
  padding: ${({ boxMode }) => (boxMode ? '0 10px' : '15px')};
`
const SubHeader = styled.div`
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid ${Colors.ALTO};
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  height: 44px;
  justify-content: flex-end;
  padding-left: 10px;
  padding-right: 10px;
`
const ButtonStyles = css`
  min-height: 32px;
  min-width: 32px;
  &:hover,
  &:active {
    background-color: ${lighten(Colors.HAVELOCK_BLUE, 20)};
    border-color: ${lighten(Colors.HAVELOCK_BLUE, 20)};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${lighten(Colors.HAVELOCK_BLUE, 40)};
  }
`
const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 700;
  margin-right: 6px;
  min-width: 1px;
  width: 100%;
`
const Title = styled.p<{ boxMode: boolean }>`
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ChatRoomBody = styled.div`
  flex: auto;
  flex: 1 0 auto;
  /* overflow-y: scroll; */
  position: relative;
  width: 100%;
`
const DisabledRepliesMessage = styled.div`
  background-color: #fff6bf;
  position: sticky;
  top: 0;
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding: 6px;
`

// const BoxModeHeader = styled.div<{ focused?: boolean }>`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   border-radius: 8px;
//   align-items: center;
//   width: 100%;
//   background-color: ${({ focused }) =>
//     focused ? Colors.HAVELOCK_BLUE : Colors.WHITE};
// `

const BoxModeButtons = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  // width: 100%;
`

const BoxModeButton = styled(Button)`
  && {
    ${ButtonStyles};
    &:not(:first-child) {
      margin-left: 6px;
    }
  }
`
const ChatRoomFooter = styled.footer`
  border-top: 1px solid ${Colors.ALTO};
  padding: 10px;
  width: 100%;
`

const MemberlistName = styled.span`
  margin-left: 6px;
`
const ParticipantBadge = styled(Badge)`
  && {
    border: 2px solid ${Colors.WHITE};
    height: 18px;
    min-width: 18px;
    // padding: 0;
    position: absolute;
    right: -6px;
    top: -6px;
    // width: 18px;
  }
`

// ChatRoom.whyDidYouRender = true
