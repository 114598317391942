import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { mutationUserDeactivate, mutationLogin } from '@sportsyou/api'
import { Button, TextInput, useDialog } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import { ForgotPasswordModal } from 'web/components'

interface Props {
  email?: string
  phoneNumber?: string
  phoneNumberCountryCode?: string
}

export const DeleteAccountForm: React.FC<Props> = ({
  email,
  phoneNumber,
  phoneNumberCountryCode,
}: Props) => {
  const { sendAlert, sendBanner, sendConfirm } = useDialog()

  const navigate = useNavigate()

  const { fetch: verifyPassword } = useFetchApi(mutationLogin)
  const { fetch: deleteAccount } = useFetchApi(mutationUserDeactivate)

  const [password, setPassword] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [isValidationVisible, setIsValidationVisible] = useState<boolean>(false)

  useEffect(() => {
    setIsButtonDisabled(password === '')
  }, [password])

  const handleOnChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleDeleteAccount = async () => {
    const { error, ok } = await deleteAccount({
      deleteUser: true,
      password,
    })

    if (ok) {
      navigate('/logout')
      sendBanner({
        autoDismiss: true,
        dismissTime: 4000,
        status: 'success',
        message: 'Your account has been deleted successfully.',
      })
      // TODO: logout hook
      // TODO: logout in redux?
    }

    if (error) {
      let _error = error.toString()
      if (_error?.indexOf('Google') !== -1) {
        sendAlert({
          message:
            "Because this account was created with Google, sportsYou can't verify your current password. If you would like to set a password for sportsYou click on 'Forgot Password' instead.",
        })
      }
    }
  }

  const handleOnClickDelete = async () => {
    if (!password || password === '') return

    // verify password
    const { error, ok } = await verifyPassword({
      userId: email,
      phoneNumberCountryCode,
      phoneNumber,
      password,
      verifyOnly: true,
    })

    if (error) {
      setIsValidationVisible(true)
      setIsButtonDisabled(true)
    }

    if (ok) {
      sendConfirm({
        confirmText: 'Delete',
        isDestructive: true,
        message: 'Are you sure you want to delete your sportsYou account?',
        title: 'Delete Account?',
        onConfirm: () => {
          handleDeleteAccount()
        },
      })
    }
  }

  return (
    <>
      <h3>Are you sure you want to delete your account?</h3>

      <p>
        Deleting your account will disable your profile and remove you from
        everything you have shared on sportsYou
      </p>
      <div
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextInput
          autoCapitalize='off'
          label={'Current Password'}
          placeholder={'Enter your password'}
          onChange={handleOnChangePassword}
          type='password'
          status={isValidationVisible ? 'error' : undefined}
          showPasswordToggle
          showValidationMessage={isValidationVisible}
          validationMessage='Invalid Password Entered'
          value={password}
        />
        <div style={{ marginLeft: 10, marginTop: 10 }}>
          <ForgotPasswordModal style={{ fontSize: 14 }} />
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            disabled={isButtonDisabled}
            onClick={handleOnClickDelete}
            title='Delete and Deactive Account'
            variant='danger'
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  )
}

export default DeleteAccountForm
