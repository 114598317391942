import { TeamMember, User } from '@sportsyou/api'
import { Person } from '../app/components/SelectUserList/SelectUserList'

export const getUsername = (user: Person): string => {
  if ((user as User).fullName) {
    return (user as User).fullName as string
  }
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }
  if (user.firstName) {
    return user.firstName
  }
  if (user.lastName) {
    return user.lastName
  }
  return 'Blank Name'
}

export const getAliasesAsString = (user: TeamMember): string => {
  let string = ''
  if (user.aliases) {
    string = user.aliases.join(' ')
  } else if (user.aliasFirstName && user.aliasLastName) {
    string = user.aliasFirstName && user.aliasLastName
  } else if (user.firstName) {
    string = user.firstName
  } else if (user.lastName) {
    string = user.lastName
  }
  return string
}
