import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { capitalize, Colors } from '@sportsyou/core'
import {
  Avatar,
  Button,
  Modal,
  TagGroup,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'
import {
  mutationTeamMemberSetJerseyNumber,
  MutationTeamMemberSetJerseyNumberRequest,
  TeamMember,
} from '@sportsyou/api'

interface Props {
  condensed?: boolean
  isVisible: boolean
  members?: Array<TeamMember>
  onClose: () => void
  onSave?: () => void
  selectedMember?: TeamMember
  teamId?: string
  teamType?: string
}

type JerseyNumberData = {
  [key: string]: string
}

type CondensedTeamMember = {
  roles: Array<string>
  userId: string
  id: Array<string>
  jerseyNumber: string
  firstName: string
  lastName: string
  fullName: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type EditJerseyNumberModalProps = Props & NativeAttributes

export const EditJerseyNumberModal: React.FC<EditJerseyNumberModalProps> = ({
  condensed = false,
  isVisible,
  members = [],
  onClose,
  onSave,
  selectedMember,
  teamId,
  teamType,
}: EditJerseyNumberModalProps) => {
  const { sendBanner } = useDialog()

  const { fetch: saveJerseyNumbers } = useFetchApi(
    mutationTeamMemberSetJerseyNumber
  )

  const [jerseyNumbers, setJerseyNumbers] = useState<JerseyNumberData>({})

  const formattedMembers: Array<CondensedTeamMember> = []
  members.forEach((member) => {
    const index = formattedMembers.findIndex((m) => m.userId === member.userId)
    let params = {
      roles: [member.role as string],
      userId: member.userId as string,
      id: [member.id as string],
      jerseyNumber: member.jerseyNumber as string,
      firstName: member.firstName as string,
      lastName: member.lastName as string,
      fullName: member.fullName as string,
    }
    if (index !== -1) {
      formattedMembers[index].roles.push(member.role as string)
      formattedMembers[index].id.push(member.id as string)
    } else {
      formattedMembers.push(params)
    }
  })

  const coaches = useMemo(
    () => members.filter((member) => member.role === 'coach'),
    [members]
  )
  const players = useMemo(
    () => members.filter((member) => member.role === 'player'),
    [members]
  )
  const family = useMemo(
    () => members.filter((member) => member.role === 'parent'),
    [members]
  )

  useEffect(() => {
    setJerseyNumbers((previousJerseyNumbers) => {
      // remove non-number characters from jersey numbers and limit to 3 characters
      const updatedJerseyNumbers = Object.keys(previousJerseyNumbers).reduce(
        (acc, key) => ({
          ...acc,
          [key]: previousJerseyNumbers[key].replace(/\D/g, '').slice(0, 3),
        }),
        {}
      )
      return updatedJerseyNumbers
    })
  }, [jerseyNumbers])

  if (!teamId) return null

  const handleOnChangeNumber = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { value } = event.target
    if (!id || id === '') return
    setJerseyNumbers((prevData) => ({
      ...prevData,
      [id]: value,
    }))
  }

  const handleOnClose = (resetState?: boolean) => {
    onClose && onClose()
    if (resetState) {
      setJerseyNumbers({})
    }
  }

  const handleOnSave = async () => {
    // Check if there are any updates before calling api
    if (Object.keys(jerseyNumbers).length === 0) {
      handleOnClose()
      return
    }
    const { data, error, ok } = await saveJerseyNumbers({
      jerseyNumber: Object.values(jerseyNumbers),
      teamId,
      userId: Object.keys(jerseyNumbers),
    } as MutationTeamMemberSetJerseyNumberRequest)

    if (data && ok) {
      sendBanner({
        autoDismiss: true,
        status: 'success',
        message: 'Jersey numbers updated successfully',
      })
      handleOnClose(true)
    }
    if (error) {
      console.log({ error })
      sendBanner({
        autoDismiss: true,
        status: 'danger',
        message:
          'There was an error updating jersey numbers, please try again or contact sportsYou Customer Support',
      })
    }
    onSave && onSave()
  }

  const renderMember = (
    member: TeamMember | CondensedTeamMember,
    index: number
  ) => {
    // Combining role as tags for condensed view
    const tagParams = condensed
      ? (member as CondensedTeamMember).roles.map((role) => ({
          fill: Colors.CATSKILL_WHITE,
          title: capitalize(role),
        }))
      : []

    return (
      <Row key={index}>
        <Avatar
          diameter={40}
          name={member.fullName ?? undefined}
          title={member.fullName ?? member.firstName!}
        />
        <NameContainer title={member.fullName ?? member.firstName!}>
          {member.fullName && member.fullName}
          {condensed && <TagGroup items={tagParams} />}
        </NameContainer>
        <TextInput
          autoFocus={member.userId === selectedMember?.userId}
          containerStyle={{ marginLeft: 'auto', minHeight: 1 }}
          inputContainerStyle={{ minWidth: 1, width: 90 }}
          inputMode='numeric'
          inputStyle={{ textAlign: 'center' }}
          max={999}
          maxLength={3}
          min={0}
          onChange={(event) => handleOnChangeNumber(event, member.userId ?? '')}
          placeholder=' '
          value={jerseyNumbers[member.userId!] ?? member.jerseyNumber ?? ''}
          title='Enter Player Jersey Number'
          type='number'
        />
      </Row>
    )
  }

  const renderCondensed = () =>
    formattedMembers && formattedMembers.length > 0 ? (
      <div>
        {formattedMembers.map((member, index) => renderMember(member, index))}
      </div>
    ) : null

  const renderExpanded = () => {
    if (teamType === 'group') {
      return (
        <>
          <H3 title='Members'>Members</H3>
          {members.map((member, index) => renderMember(member, index))}
        </>
      )
    }
    return (
      <>
        {coaches && coaches.length > 0 && (
          <>
            <H3 title='Coaches'>Coaches</H3>
            {coaches.map((member, index) => renderMember(member, index))}
          </>
        )}
        {players && players.length > 0 && (
          <>
            <H3 title='Players'>Players</H3>
            {players.map((member, index) => renderMember(member, index))}
          </>
        )}
        {family && family.length > 0 && (
          <>
            <H3 title='Family'>Family</H3>
            {family.map((member, index) => renderMember(member, index))}
          </>
        )}
      </>
    )
  }

  return (
    <Modal backdropIgnoresClicks visible={isVisible} onClose={handleOnClose}>
      <Modal.Header>
        <b>Edit Jersey Number</b>
      </Modal.Header>
      <Modal.Body>
        <div>{condensed ? renderCondensed() : renderExpanded()}</div>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          appearance='minimal'
          onClick={() => handleOnClose(true)}
          variant='alternate'
          style={{ marginRight: 10 }}
          title='Cancel and Reset'
        >
          Cancel
        </Button>
        <Button onClick={handleOnSave} title='Save Jersey Numbers'>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const H3 = styled.h3`
  color: ${Colors.PUNCH};
  text-transform: uppercase;
  margin-top: 6px;
  margin-bottom: 4px;
`
const Row = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
`

const NameContainer = styled.div`
  font-size: 16px;
  margin-left: 6px;
`

export default EditJerseyNumberModal
