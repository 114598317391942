import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  Button,
  Icon,
  Modal,
  Tabs,
  TextArea,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { mutationTeamAddMembers, Team, TeamMember } from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'
import { Colors, isEmailValid } from '@sportsyou/core'

import {
  fetchFriends,
  fetchTeammates,
  selectCurrentUserFriends,
  selectCurrentUserGroupmates,
  selectCurrentUserTeammates,
} from 'web/store/slices/UserSlice'
import SelectUserListSectioned from '../../SelectUserList/SelectUserListSectioned'
import { EMAIL_REGEX } from 'web/constants'

export interface AddMembersFromContactsProps {
  onClose: () => void
  team?: Team
  visible: boolean
}

export default function AddMembersFromContacts(
  props: AddMembersFromContactsProps
) {
  const { sendBanner } = useDialog()
  const dispatch = useDispatch()

  const friends = useSelector(selectCurrentUserFriends)
  const teammates = useSelector(selectCurrentUserTeammates)
  const groupmates = useSelector(selectCurrentUserGroupmates)

  const [searchInputValue, setSearchInputValue] = useState('')

  const isGroup = useMemo(() => props.team?.type === 'group', [props.team])

  const { fetch: addMembers } = useFetchApi(mutationTeamAddMembers)

  type MemberValues = {
    coaches: Array<TeamMember>
    others: Array<TeamMember>
    parents: Array<TeamMember>
    players: Array<TeamMember>
  }

  type EmailValues = {
    coachEmails: Array<string>
    otherEmails: Array<string>
    parentEmails: Array<string>
    playerEmails: Array<string>
  }

  type InitialValues = MemberValues &
    EmailValues & {
      inviteNote: string
    }

  const formik = useFormik({
    initialValues: {
      coachEmails: [],
      coaches: [],
      inviteNote: '',
      otherEmails: [],
      others: [],
      parentEmails: [],
      parents: [],
      playerEmails: [],
      players: [],
    } as InitialValues,
    onSubmit: async (values) => {
      const addMembersParams = {
        coachEmails: values.coachEmails as string[],
        coachIds: values.coaches.map((u: TeamMember) => u.id) as string[],
        inviteNote: values.inviteNote,
        otherEmails: values.otherEmails as string[],
        otherIds: values.others.map((u: TeamMember) => u.id) as string[],
        parentEmails: values.parentEmails as string[],
        parentIds: values.parents.map((u: TeamMember) => u.id) as string[],
        playerEmails: values.playerEmails as string[],
        playerIds: values.players.map((u: TeamMember) => u.id) as string[],
        teamId: props.team?.id as string,
      }
      const { data } = await addMembers(addMembersParams)
      if (data) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 6000,
          status: 'success',
          message: 'Invites sent',
        })
        props.onClose()
      }
    },
  })

  const selectedMembersCount = useMemo(() => {
    return (
      formik.values.coachEmails.length +
      formik.values.coaches.length +
      formik.values.otherEmails.length +
      formik.values.others.length +
      formik.values.parentEmails.length +
      formik.values.parents.length +
      formik.values.playerEmails.length +
      formik.values.players.length
    )
  }, [formik.values])

  const noResultsText = useCallback(
    (field: string) => {
      const existingEmails = [
        ...formik.values[field as keyof EmailValues],
      ].filter(Boolean)
      let newEmails: string[] = []
      const regexResult = searchInputValue.match(EMAIL_REGEX)
      if (regexResult != null) {
        newEmails = Array.from(
          new Set([...existingEmails, ...regexResult])
        ).filter((email) => !existingEmails.includes(email))
      }

      return newEmails.length
        ? `Press enter to add email address: ${newEmails.join(', ')}`
        : undefined
    },
    [searchInputValue]
  )

  const onSearchChange = useCallback((search: string) => {
    setSearchInputValue(search)
  }, [])

  const onSearchSubmit = useCallback(
    (inputValue: string, field: string) => {
      const existingEmails = [
        ...formik.values[field as keyof EmailValues],
      ].filter(Boolean)
      let newEmails: string[] = []
      const regexResult = inputValue.match(EMAIL_REGEX)
      if (regexResult != null) {
        newEmails = Array.from(
          new Set([...existingEmails, ...regexResult])
        ).filter((email) => !existingEmails.includes(email))
        if (newEmails.length) {
          formik.setFieldValue(field, [
            ...formik.values[field as keyof EmailValues],
            ...newEmails,
          ])
          setSearchInputValue('')
        }
      }
    },
    [formik, searchInputValue]
  )

  const userSectionsByType = useMemo(() => {
    const sections = [
      { title: 'Contacts', data: friends },
      { title: 'Teammates', data: teammates },
      { title: 'Groupmates', data: groupmates },
    ]
    return sections.filter((section) => section.data.length > 0)
  }, [friends, groupmates, teammates])

  useEffect(() => {
    if (props.visible) {
      dispatch(fetchFriends())
      dispatch(fetchTeammates())
    }
  }, [dispatch, props.visible])

  const RecipientsList = ({
    title,
    field,
    fieldEmail,
  }: {
    title: string
    field: string
    fieldEmail: keyof EmailValues
  }) => {
    return (
      <>
        <RecipientListSectionTitle>{title}</RecipientListSectionTitle>
        {formik.values[fieldEmail as keyof EmailValues].map(
          (email: string, index: number) => (
            <RecipientListItemContainer key={index}>
              <RecipientListItemName>{email}</RecipientListItemName>
              <RecipientListItemRemove
                appearance='minimal'
                collapse
                onClick={() => {
                  formik.setFieldValue(
                    fieldEmail,
                    formik.values[fieldEmail as keyof EmailValues].filter(
                      (e: string) => e !== email
                    )
                  )
                }}
                variant='alternate'
              >
                <Icon
                  fill={Colors.SHUTTLE_GRAY}
                  height={14}
                  name='X'
                  width={14}
                />
              </RecipientListItemRemove>
            </RecipientListItemContainer>
          )
        )}
        {formik.values[field as keyof MemberValues].map(
          (user: TeamMember, index: number) => (
            <RecipientListItemContainer key={user.id}>
              <RecipientListItemName>
                {user.fullName ?? `${user.firstName} ${user.lastName}`}
              </RecipientListItemName>
              <RecipientListItemRemove
                appearance='minimal'
                collapse
                variant='alternate'
                onClick={() => {
                  formik.setFieldValue(
                    field,
                    formik.values[field as keyof MemberValues].filter(
                      (u: TeamMember) => u.id !== user.id
                    )
                  )
                }}
              >
                <Icon
                  fill={Colors.SHUTTLE_GRAY}
                  height={14}
                  name='X'
                  width={14}
                />
              </RecipientListItemRemove>
            </RecipientListItemContainer>
          )
        )}
      </>
    )
  }

  return (
    <Modal
      backdropIgnoresClicks
      onClose={props.onClose}
      visible={props.visible}
      contentWidth={685}
    >
      <Modal.Header>
        <b>Add {isGroup ? 'Group' : 'Team'} Members</b>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Instructions>
          Add contacts and teammates to your {isGroup ? 'group' : 'team'} by
          checking people off on the list below, <br /> or add their email into
          the field and hit enter.
        </Instructions>
        <Container>
          {isGroup ? (
            <MemberSelectContainer>
              <SelectUserListSectioned
                listStyle={{ flex: '1 1 auto' }}
                onChange={(users) => formik.setFieldValue('others', users)}
                onSearchChange={onSearchChange}
                noResultsText={(() => noResultsText('otherEmails'))()}
                onSearchSubmit={() =>
                  onSearchSubmit(searchInputValue, 'otherEmails')
                }
                rowSize='small'
                rowStyle={{ paddingLeft: 6, paddingRight: 6 }}
                searchable
                searchbarContainerStyle={{ flex: '0 0 auto' }}
                searchbarPlaceholder='Search or type email address'
                searchbarValue={searchInputValue}
                selectedUsers={formik.values.others}
                sections={userSectionsByType}
              />
            </MemberSelectContainer>
          ) : (
            <StyledTabs
              contentStyle={{
                display: 'flex',
                flexDirection: 'column',
                height: 360,
                padding: 0,
              }}
            >
              <Tabs.Item title='Add Players'>
                <MemberSelectContainer>
                  <SelectUserListSectioned
                    listStyle={{ flex: '1 1 auto' }}
                    onChange={(users) => formik.setFieldValue('players', users)}
                    onSearchChange={onSearchChange}
                    noResultsText={(() => noResultsText('playerEmails'))()}
                    onSearchSubmit={() =>
                      onSearchSubmit(searchInputValue, 'playerEmails')
                    }
                    rowSize='small'
                    rowStyle={{ paddingLeft: 6, paddingRight: 6 }}
                    searchable
                    searchbarContainerStyle={{ flex: '0 0 auto' }}
                    searchbarPlaceholder='Search or type email address'
                    searchbarValue={searchInputValue}
                    selectedUsers={formik.values.players}
                    sections={userSectionsByType}
                  />
                </MemberSelectContainer>
              </Tabs.Item>
              <Tabs.Item title='Add Coaches'>
                <MemberSelectContainer>
                  <SelectUserListSectioned
                    listStyle={{ flex: '1 1 auto' }}
                    onChange={(users) => formik.setFieldValue('coaches', users)}
                    onSearchChange={onSearchChange}
                    noResultsText={(() => noResultsText('coachEmails'))()}
                    onSearchSubmit={() =>
                      onSearchSubmit(searchInputValue, 'coachEmails')
                    }
                    rowSize='small'
                    rowStyle={{ paddingLeft: 6, paddingRight: 6 }}
                    searchable
                    searchbarContainerStyle={{ flex: '0 0 auto' }}
                    searchbarPlaceholder='Search or type email address'
                    searchbarValue={searchInputValue}
                    selectedUsers={formik.values.coaches}
                    sections={userSectionsByType}
                  />
                </MemberSelectContainer>
              </Tabs.Item>
              <Tabs.Item title='Add Family'>
                <MemberSelectContainer>
                  <SelectUserListSectioned
                    listStyle={{ flex: '1 1 auto' }}
                    onChange={(users) => formik.setFieldValue('parents', users)}
                    onSearchChange={onSearchChange}
                    noResultsText={(() => noResultsText('parentEmails'))()}
                    onSearchSubmit={() =>
                      onSearchSubmit(searchInputValue, 'parentEmails')
                    }
                    rowSize='small'
                    rowStyle={{ paddingLeft: 6, paddingRight: 6 }}
                    searchable
                    searchbarContainerStyle={{ flex: '0 0 auto' }}
                    searchbarPlaceholder='Search or type email address'
                    searchbarValue={searchInputValue}
                    selectedUsers={formik.values.parents}
                    sections={userSectionsByType}
                  />
                </MemberSelectContainer>
              </Tabs.Item>
            </StyledTabs>
          )}
          <RecipientList>
            <RecipientListHeader>
              Recipients
              <RecipientListCount>{selectedMembersCount}</RecipientListCount>
            </RecipientListHeader>

            <RecipientListBody>
              {selectedMembersCount > 0 ? (
                <RecipientListItems>
                  {isGroup ? (
                    <RecipientsList
                      title='Members'
                      field='others'
                      fieldEmail='otherEmails'
                    />
                  ) : (
                    <>
                      <RecipientsList
                        title='Players'
                        field='players'
                        fieldEmail='playerEmails'
                      />
                      <RecipientsList
                        title='Coaches'
                        field='coaches'
                        fieldEmail='coachEmails'
                      />
                      <RecipientsList
                        title='Family'
                        field='parents'
                        fieldEmail='parentEmails'
                      />
                    </>
                  )}
                </RecipientListItems>
              ) : (
                <RecipientListEmpty>
                  <p>No recipients selected</p>
                </RecipientListEmpty>
              )}
            </RecipientListBody>
            <RecipientMessageContainer>
              <TextArea
                onChange={(e) =>
                  formik.setFieldValue('inviteNote', e.target.value)
                }
                placeholder='Write a message to your recipients'
                value={formik.values.inviteNote}
              />
            </RecipientMessageContainer>
          </RecipientList>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <form onSubmit={formik.handleSubmit}>
          <SendButton disabled={selectedMembersCount === 0} type='submit'>
            Send Invites
          </SendButton>
        </form>
      </Modal.Footer>
    </Modal>
  )
}

const Instructions = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${Colors.ALTO};
  text-align: center;
`
const Container = styled.div`
  display: flex;
  @media all and (max-width: 599px) {
    flex-direction: column;
  }
`
const MemberSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
  padding: 0;
  overflow: scroll;
`
const StyledTabs = styled(Tabs)`
  && {
    flex: 1 1 auto;
  }
  @media all and (min-width: 375px) and (max-height: 667px) {
    flex: 0 0 270px;
  }
`

const RecipientList = styled.div`
  align-self: stretch;
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  // padding: 10px;
  @media all and (min-width: 600px) {
    border-top: none;
    border-left: 1px solid ${Colors.ALTO};
  }
`
const RecipientListHeader = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px;
`
const RecipientListCount = styled.span`
  margin-left: 6px;
`
const RecipientListBody = styled.div`
  flex: 1 0 auto;
`
const RecipientListEmpty = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`

const RecipientMessageContainer = styled.div`
  // margin-top: 16px;
  padding: 10px;
`
const RecipientListItems = styled.div``
const RecipientListSectionTitle = styled.div`
  font-weight: 700;
  // margin-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  &:not(:first-child) {
    margin-top: 6px;
  }
`
const RecipientListItemRemove = styled(Button)`
  && {
    border-radius: 50%;
    min-height: 1px;
    min-width: 1px;
    height: 24px;
    width: 24px;

    &:hover,
    &:active {
      background-color: ${Colors.WHITE};
    }
    opacity: 0;
    visibility: hidden;
  }
`
const RecipientListItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 10px;

  &:hover {
    background-color: ${Colors.CATSKILL_WHITE};

    ${RecipientListItemRemove} {
      opacity: 1;
      visibility: visible;
    }
  }
`
const RecipientListItemName = styled.div``

const SendButton = styled(Button)``
