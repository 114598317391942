import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEqual } from 'lodash'

import { Colors } from '@sportsyou/core'
import {
  Avatar,
  Button,
  Card,
  ContentLayout,
  Dropdown,
  FadeInContainer,
  Icon,
  StrictModeDroppable as Droppable,
  useDialog,
  Widget,
} from '@sportsyou/react-dom-ui'
import {
  Friend as FriendProps,
  Team as TeamProps,
  User as UserProps,
} from '@sportsyou/api'

import { hideSidebar } from 'web/store/slices/LayoutSidebarSlice'
import { JoinTeamModal, SideNavigation } from 'web/components'

import { RootState } from 'web/store/rootReducer'

import {
  saveTeamSort,
  selectAllTeamsAndGroups,
} from 'web/store/slices/TeamsSlice'
import { SMALL_SCREEN_BREAKPOINT } from 'web/constants'
import { TeamType, useCreateTeam } from 'web/hooks'

interface Props {
  /** Whether the content should display fullscreen */
  fullscreen?: boolean
  /** Wether the content should stretch to fill the screen.
   * `Fullscreen` must be set to true
   */
  stretchContent?: boolean
  initialTab?: 'feed' | 'friends' | 'calendar' | 'media' | 'members'
  team?: TeamProps
  user?: UserProps
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type MainLayoutProps = Props & NativeAttributes

/**
 * Main Layout
 *
 * This layout includes a sidebar with navigation and widgets and the main
 * content. It also handles hiding and showing the sidebar on mobile
 * breakpoints.
 */
export const MainLayout: React.FC<React.PropsWithChildren<MainLayoutProps>> = ({
  children,
  fullscreen,
  stretchContent,
}: MainLayoutProps) => {
  const INITIAL_LIST_LIMIT = 5

  const { createTeam } = useCreateTeam()
  const { id } = useParams()
  const { sendBanner } = useDialog()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const mQuery = window.matchMedia(`(max-width: ${SMALL_SCREEN_BREAKPOINT})`)

  // const [groups, setGroups] = useState<Array<TeamProps>>([])
  const [isTeamListExpanded, setIsTeamListExpanded] = useState(false)
  const [isFriendListExpanded, setIsFriendListExpanded] = useState(false)
  const [isJoinTeamVisible, setIsJoinTeamVisible] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(mQuery.matches)

  const { isVisible: isSidebarVisible = !isSmallScreen } = useSelector(
    (state: RootState) => state.sidebar
  )
  const { user: currentUser, friends } = useSelector(
    (state: RootState) => state.user
  )
  const allTeamsAndGroups = useSelector(selectAllTeamsAndGroups)

  const signupCode = localStorage.getItem('signupCode') ?? ''

  useEffect(() => {
    if (signupCode) {
      setIsJoinTeamVisible(true)
      setTimeout(() => {
        localStorage.removeItem('signupCode')
      }, 10_000)
    }
  }, [])

  useEffect(() => {
    mQuery.addEventListener('change', (e) => setIsSmallScreen(e.matches))

    return () => {
      mQuery.removeEventListener('change', (e) => setIsSmallScreen(e.matches))
    }
  }, [mQuery])

  // filter and set groups
  // useEffect(() => {
  //   const _groups = teams.filter(team => team.type === 'group')
  //   setGroups(_groups)
  // }, [teams])

  // hanlder for each widget item
  const onClickItem = useCallback(
    (id?: string, type?: 'contact' | 'group' | 'team') => {
      if (type === 'contact') {
        navigate(`/profile/${id}`, {
          replace: true,
          state: { id },
        })
      }
      if (type === 'team' || type === 'group') {
        navigate(`/${type}s/${id}`, {
          replace: true,
          state: { id },
        })
      }
      id && console.log(id)
      // Assuming the sidebar is `fixed` and this is a smaller screen, we can
      // safely hide the sidebar
      if (isSmallScreen) {
        dispatch(hideSidebar())
      }
    },
    [dispatch, isSmallScreen, navigate]
  )

  const onDragItemEnd = useCallback(
    async (dropResult: any) => {
      const originalIndex = dropResult.source.index
      const newIndex = dropResult.destination.index
      if (originalIndex === newIndex) return
      const previousOrder = allTeamsAndGroups.map((team) => team.id!)
      const teamSort = [...previousOrder]
      const [moved] = teamSort.splice(originalIndex, 1)
      teamSort.splice(newIndex, 0, moved)
      dispatch(saveTeamSort(teamSort))
      sendBanner({
        autoDismiss: true,
        dismissTime: 3000,
        message: 'Team/Group order updated successfully',
        status: 'success',
      })
    },
    [allTeamsAndGroups, dispatch, sendBanner]
  )

  type ListType = 'contact' | 'group' | 'team'

  // handler for expanding widget list
  const onClickToggleList = (type: ListType) => {
    if (type === 'contact') {
      setIsFriendListExpanded(!isFriendListExpanded)
    } else if (type === 'team') {
      setIsTeamListExpanded(!isTeamListExpanded)
    }
  }

  const onClickCreate = (type: 'group' | 'team') => {
    createTeam({
      isVisible: true,
      shouldNavigateToTeam: true,
      type,
    })
  }

  const onClickNavigateToContacts = () => {
    navigate('/contacts')
  }

  const onCloseSideBar = () => {
    if (isSmallScreen) {
      dispatch(hideSidebar())
    }
  }

  const renderWidgetItem = (
    type: 'contact' | 'group' | 'team',
    item: FriendProps | TeamProps,
    index: number,
    draggable?: boolean
  ) => {
    return (
      <WidgetItem
        $active={id === item.id}
        $index={index}
        $isLast={type !== 'contact' && index === allTeamsAndGroups.length - 1}
        $draggable={draggable}
        aria-label={
          (item as TeamProps).name ?? (item as FriendProps).fullName ?? ''
        }
        onClick={() => onClickItem(item.id ?? undefined, type)}
        title={(item as TeamProps).name ?? (item as FriendProps).fullName ?? ''}
        key={index}
      >
        {draggable && (
          <DragIcon fill={Colors.SHUTTLE_GRAY} name='Drag' size={16} />
        )}
        <Avatar
          diameter={24}
          name={
            (item as TeamProps).name ?? (item as FriendProps).fullName ?? 'SY'
          }
          uri={
            (item as TeamProps).profileImage?.[0]?.viewUrl ??
            (item as FriendProps).profileImage?.[0]?.viewUrl ??
            undefined
          }
        />
        <span
          style={{
            fontSize: 14,
            marginLeft: 6,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {(item as TeamProps).name ?? (item as FriendProps).fullName ?? ''}
        </span>
      </WidgetItem>
    )
  }

  const renderWidgetList = (type: 'contact' | 'team' | 'group' = 'team') => {
    if (type === 'contact') {
      // check if list is expanded and set limit
      const limit = isFriendListExpanded ? friends?.length : INITIAL_LIST_LIMIT
      return friends?.length === 0 ? (
        // empty state for list
        <Widget.Text style={{ color: Colors.SHUTTLE_GRAY, fontSize: 14 }}>
          Your {type}s will appear here.
        </Widget.Text>
      ) : (
        // filter list depending on if list is expanded or not
        friends
          ?.filter((_, index: number) => index < limit)
          // render each item
          .map((friend: FriendProps, index: number) =>
            renderWidgetItem(type, friend, index)
          )
      )
    }

    return allTeamsAndGroups.length === 0 ? (
      // empty state for list
      <Widget.Text style={{ color: Colors.SHUTTLE_GRAY, fontSize: 14 }}>
        You are not on any teams/groups.
      </Widget.Text>
    ) : (
      <DragDropContext onDragEnd={onDragItemEnd}>
        <Droppable droppableId='droppable'>
          {(provided: any) => (
            <FadeInContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {allTeamsAndGroups.map((team: TeamProps, index: number) => {
                return (
                  <Draggable
                    draggableId={team.id ?? ''}
                    index={index}
                    key={team.id}
                  >
                    {(provided: any, snapshot: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {renderWidgetItem(
                          team.type as TeamType,
                          team,
                          index,
                          true
                        )}
                      </div>
                    )}
                  </Draggable>
                )
              })}
              <span>{provided.placeholder}</span>
            </FadeInContainer>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <>
      <ContentLayout
        fullscreen={fullscreen}
        stretchContent={stretchContent}
        // TODO: add offset prop to handle navbar offsets in fullscreen
        style={fullscreen ? { minHeight: `calc(100vh - 65px)` } : undefined}
      >
        <ContentLayout.Sidebar
          fixed={isSmallScreen}
          isVisible={isSidebarVisible}
          onClose={onCloseSideBar}
          showCloseButton={isSmallScreen}
          width={240}
          // style={{ paddingBottom: 10, paddingTop: 10 }}
        >
          <Card
            style={{
              marginBottom: 10,
              marginTop: 10,
              overflow: 'hidden',
            }}
          >
            <Card.Body style={{ padding: 0 }}>
              <SideNavigation
                onClickItem={
                  isSmallScreen ? () => dispatch(hideSidebar()) : undefined
                }
                user={currentUser}
              />
            </Card.Body>
          </Card>
          <Button
            fullWidth
            onClick={() => setIsJoinTeamVisible(!isJoinTeamVisible)}
          >
            Join Team/Group
          </Button>
          <StyledWidget
            bodyStyle={{
              padding: allTeamsAndGroups.length === 0 ? undefined : 0,
            }}
          >
            <Widget.Header style={{ justifyContent: 'space-between' }}>
              <StyledLink title={'myTeams'} to={'/teams'}>
                <b>My Teams/Groups</b>
              </StyledLink>
              <Dropdown
                title='Create New Team/Group'
                hideChevron
                placement='bottomEnd'
              >
                <Dropdown.Toggle>
                  <WidgetHeaderButton
                    appearance='minimal'
                    collapse
                    title='Create New Team'
                    variant='alternate'
                  >
                    Create
                  </WidgetHeaderButton>
                </Dropdown.Toggle>
                <Dropdown.Item
                  onClick={() => onClickCreate('team')}
                  testId='widget-create-team-button'
                >
                  Create Team
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onClickCreate('group')}
                  testId='widget-create-group-button'
                >
                  Create Group
                </Dropdown.Item>
              </Dropdown>
            </Widget.Header>
            {renderWidgetList('team')}
          </StyledWidget>

          <StyledWidget
            bodyStyle={{ padding: friends?.length === 0 ? undefined : 0 }}
            containerStyle={{ marginBottom: 10 }}
          >
            <Widget.Header style={{ justifyContent: 'space-between' }}>
              <StyledLink title={'Contacts'} to={'/contacts'}>
                <b>Contacts</b>
              </StyledLink>
              <WidgetHeaderButton
                appearance='minimal'
                collapse
                onClick={onClickNavigateToContacts}
                title='Contacts'
                variant='alternate'
              >
                Add
              </WidgetHeaderButton>
            </Widget.Header>
            <div>{renderWidgetList('contact')}</div>

            {friends?.length > INITIAL_LIST_LIMIT && (
              <ExpandButton
                aria-label={`${
                  isFriendListExpanded ? 'Shrink' : 'Expand'
                } Contact List`}
                title={`${
                  isFriendListExpanded ? 'Shrink' : 'Expand'
                } Contact List`}
                onClick={() => onClickToggleList('contact')}
              >
                {isFriendListExpanded ? 'Less' : 'More'}
              </ExpandButton>
            )}
          </StyledWidget>
        </ContentLayout.Sidebar>
        <ContentLayout.Content style={{ marginTop: 10 }} title={'content'}>
          {children}
        </ContentLayout.Content>
      </ContentLayout>
      <JoinTeamModal
        isVisible={isJoinTeamVisible}
        onClose={() => setIsJoinTeamVisible(!isJoinTeamVisible)}
        signupCode={signupCode}
      />
    </>
  )
}

const StyledWidget = styled(Widget)`
  margin-top: 10px;
  // overflow: hidden;
`
const WidgetHeaderButton = styled(Button)`
  font-size: 13px;
  && {
    min-height: 1px;
  }
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
    text-decoration: underline;
  }
`
const WidgetItem = styled.div<{
  $active: boolean
  $draggable?: boolean
  $index: number
  $isLast: boolean
}>`
  align-items: center;
  ${({ $active }) => $active && `background-color: ${Colors.CATSKILL_WHITE};`}
  ${({ $isLast }) =>
    $isLast &&
    `border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;
  `}
  cursor: pointer;
  display: flex;
  height: 32px;
  padding-left: ${({ $draggable }) => ($draggable ? 0 : 10)}px;
  padding-right: 10px;
  position: relative;
  transition: background-color 90ms ease-in-out;

  & > svg {
    transition: opacity 90ms ease-in-out;
  }
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
    & > svg {
      opacity: 1;
    }
  }
  &::before {
    content: '';
    background-color: ${Colors.HAVELOCK_BLUE};
    display: ${({ $active }) => ($active ? 'block' : 'none')};
    width: 2px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
`
const DragIcon = styled(Icon)`
  opacity: 0.24;
  flex: 0 0 auto;
  margin-left: 2px;
  margin-right: 2px;
`
const ExpandButton = styled.button`
  background: none;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${Colors.PUNCH};
  cursor: pointer;
  font-weight: 700;
  margin: 4px 5px;
  padding: 6px;

  transition: background-color 90ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
    text-decoration: underline;
  }
`
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export default MainLayout
