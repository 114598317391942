import { createSlice } from '@reduxjs/toolkit'

import { Upload, VideoClip, VideoPlaylist } from '@sportsyou/api'

export interface ExtendedVideoPlaylist extends VideoPlaylist {
  postId?: string
}

export interface LightboxItem extends Omit<Upload, '__typename'> {
  active?: boolean
  autoPlay?: boolean
  canDelete?: boolean
  clip?: VideoClip
}

export interface LightboxInterface {
  index?: number
  isOpen?: boolean
  items: LightboxItem[]
  playlist?: ExtendedVideoPlaylist
}

const initialState: LightboxInterface = {
  index: 0,
  isOpen: false,
  items: [],
  playlist: undefined,
}

const lightboxSlice = createSlice({
  name: 'lightbox',
  initialState,
  reducers: {
    setIndex(state, action) {
      state.index = action.payload
    },
    setLightboxIndex(state, action) {
      state.index = action.payload
    },
    setLightboxItems(state, action) {
      state.items = action.payload
    },
    clearLightboxItems(state) {
      state.items = []
    },
    showLightboxWithItems(state, action) {
      state.index = action.payload.index ?? initialState.index
      state.isOpen = true
      state.items = action.payload.items
      state.playlist = action.payload.playlist
    },
    showLightbox(state) {
      state.isOpen = true
    },
    hideLightbox(state) {
      state.index = initialState.index
      state.isOpen = initialState.isOpen
      state.items = initialState.items
      state.playlist = initialState.playlist
    },
  },
})

export const {
  clearLightboxItems,
  hideLightbox,
  setIndex,
  setLightboxIndex,
  setLightboxItems,
  showLightbox,
  showLightboxWithItems,
} = lightboxSlice.actions
export default lightboxSlice.reducer
