import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { ChatParticipant, Upload, mutationUploadDelete } from '@sportsyou/api'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'
import { Colors, getThumbnail, isFile } from '@sportsyou/core'
import {
  BORDER_RADIUS,
  Button,
  Checkbox,
  FileInput,
  FileTypeIcon,
  Icon,
  Spinner,
  TextArea,
  Tooltip,
} from '@sportsyou/react-dom-ui'

import { setPressEnterToSend } from '../../../store/slices/ChatBoxSlice'

interface ChatMessageInputProps {
  autoGrow?: boolean
  boxMode?: boolean
  chatId?: string
  disableAttachment?: boolean
  hideTypingIndicator?: boolean
  initialRows?: number
  isNewChatModal?: boolean
  isSomeoneTyping?: string
  maximumRows?: number
  messageText?: string
  minimumRows?: number
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onClickSendButton?: ({
    messageText,
    uploads,
  }: {
    messageText: string
    uploads: Upload[]
  }) => Promise<boolean>
  onClickViewedBy?: (viewedBy: ChatParticipant[]) => void
  onFocus?: () => void
  onMessageInputKeyPress?: (key: string) => void
  onMessageUploadsChange?: (uploads: Upload[]) => void
  placeholder?: string
  pressEnterToSend?: boolean
  selectAttachmentsButtonText?: string
  showSendUI?: boolean
  showViewedBy?: boolean
  uploads?: ExtendedUpload[]
  viewedBy?: ChatParticipant[]
}

const InputContainerStyle = { width: '100%' }
const TextAreaStyle = { minWidth: 1 }

export default function ChatMessageInput({
  autoGrow,
  boxMode,
  chatId,
  disableAttachment,
  hideTypingIndicator,
  initialRows,
  isNewChatModal,
  isSomeoneTyping,
  maximumRows,
  messageText: initialMessageText,
  minimumRows,
  onChange,
  onClickSendButton,
  onClickViewedBy,
  onFocus,
  onMessageInputKeyPress,
  onMessageUploadsChange,
  placeholder = 'Send a message...',
  pressEnterToSend,
  selectAttachmentsButtonText,
  showSendUI,
  showViewedBy,
  uploads: initialUploads,
  viewedBy = [],
}: ChatMessageInputProps) {
  const dispatch = useDispatch()

  const [isSending, setIsSending] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [messageText, setMessageText] = useState('')
  const [pendingUploads, setPendingUploads] = useState<ExtendedUpload[]>([])
  const [uploads, setUploads] = useState<ExtendedUpload[]>(initialUploads || [])

  const textInputRef = useRef<HTMLTextAreaElement>(null)

  const { fetch: removeMedia } = useFetchApi(mutationUploadDelete)

  const reset = useCallback(() => {
    setIsSending(false)
    setIsUploading(false)
    setMessageText('')
    setPendingUploads([])
    setUploads([])
  }, [])

  useEffect(() => {
    if (initialMessageText) {
      setMessageText(initialMessageText)
    }
  }, [initialMessageText])

  useEffect(() => {
    if (initialUploads) {
      setUploads(initialUploads)
    }
  }, [initialUploads])

  useEffect(() => {
    setIsUploading(pendingUploads.length > 0)
  }, [pendingUploads])

  useEffect(() => {
    if (chatId) {
      // Clear message input when chatId changes
      reset()
    }
  }, [chatId, reset])

  const cannotSend = useMemo(() => {
    return isUploading || (!isUploading && !messageText && !uploads.length)
  }, [isUploading, messageText, uploads])

  const onClickPressEnterToSend = useCallback(() => {
    dispatch(setPressEnterToSend(!pressEnterToSend))
  }, [dispatch, pressEnterToSend])

  const handleOnClickSend = useCallback(async () => {
    if (onClickSendButton) {
      setIsSending(true)
      const success = await onClickSendButton({
        messageText,
        uploads,
      })
      if (success) {
        setMessageText('')
        setUploads([])
      }
      setIsSending(false)
    }
    textInputRef.current?.focus()
  }, [messageText, onClickSendButton, uploads])

  const handleOnKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && (pressEnterToSend || boxMode)) {
        !e.shiftKey && !isSending && !cannotSend && handleOnClickSend()
      } else {
        onMessageInputKeyPress?.(e.key)
      }
    },
    [
      boxMode,
      cannotSend,
      handleOnClickSend,
      isSending,
      onMessageInputKeyPress,
      pressEnterToSend,
    ]
  )

  const onMessageInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(e)
      setMessageText(e.target?.value ?? '')
    },
    [onChange]
  )

  const onUploaderUploadStart = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      console.log('onUploaderUploadStart', { _uploads, currentUpload })
      if (pendingUploads.length === 0) {
        setIsUploading(true)
        setPendingUploads(_uploads)
      }
    },
    [pendingUploads]
  )

  const onUploaderUploadProgress = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      setPendingUploads((pendings) => {
        const newPendings = [...pendings]
        const currentUploadIndex = newPendings.findIndex(
          (up) => up.id === currentUpload.id
        )
        if (currentUploadIndex > -1) {
          newPendings.splice(currentUploadIndex, 1, currentUpload)
        }

        return newPendings
      })
    },
    []
  )

  const onUploaderError = useCallback(
    (_uploads: ExtendedUpload[], cancelledUpload: ExtendedUpload): void => {
      console.log('onUploaderError', { _uploads, cancelledUpload })
      setPendingUploads((pendings) =>
        pendings.filter((u) => u.id !== cancelledUpload?.id)
      )
    },
    [onMessageUploadsChange, uploads]
  )

  const onUploaderUploadDone = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      console.log('onUploaderUploadDone', { _uploads, currentUpload })
      setUploads((doneUploads) => {
        const newDoneUploads = [...doneUploads, currentUpload]
        onMessageUploadsChange?.(newDoneUploads)
        return newDoneUploads
      })
      setPendingUploads((pendings) =>
        pendings.filter((u) => u.id !== currentUpload?.id)
      )
    },
    []
  )

  const onUploaderUploadComplete = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      console.log('onUploaderUploadComplete', { _uploads })
      setPendingUploads([])
      setIsUploading(false)
    },
    [onMessageUploadsChange, uploads]
  )

  const onUploaderCancel = useCallback(
    (cancelledUpload: ExtendedUpload | undefined): void => {
      console.log('onUploaderCancel', { cancelledUpload })
      setUploads((doneUploads) => {
        const newDoneUploads = [...doneUploads]
        const cancelledUploadsIndex = newDoneUploads.findIndex(
          (up) => up.id === cancelledUpload?.id
        )
        if (cancelledUploadsIndex > -1) {
          newDoneUploads.splice(cancelledUploadsIndex, 1)
          onMessageUploadsChange?.(newDoneUploads)
        }

        return newDoneUploads
      })
      setPendingUploads((pendings) =>
        pendings.filter((up) => up.id !== cancelledUpload?.id)
      )
      cancelledUpload?.id && removeMedia({ id: cancelledUpload.id })
    },
    [onMessageUploadsChange, uploads]
  )

  const renderSelectFileButton = useMemo(() => {
    if (disableAttachment) return null

    const iconProps = {
      height: 18,
      // fill: Colors.SHUTTLE_GRAY,
      width: 18,
    }
    if (boxMode) {
      return (
        <SelectFileButton
          appearance='minimal'
          boxMode
          collapse
          variant='alternate'
        >
          <Icon name='Camera' />
        </SelectFileButton>
      )
    }
    if (isNewChatModal) {
      return (
        <SelectFileButton
          appearance='minimal'
          textStyle={{ fontSize: 14, fontWeight: 400 }}
          collapse
          variant='alternate'
        >
          <Icon
            name='Paperclip'
            {...iconProps}
            style={{ marginLeft: 4, marginRight: 4 }}
          />
          Add Attachments
        </SelectFileButton>
      )
    }
    return (
      <SelectFileButton
        appearance='minimal'
        textStyle={{ fontSize: 14, fontWeight: 400 }}
        collapse
        variant='alternate'
      >
        <Icon name='Camera' {...iconProps} />
        <Icon
          name='Paperclip'
          {...iconProps}
          style={{ marginLeft: 4, marginRight: 4 }}
        />
        {selectAttachmentsButtonText ?? 'Send Attachments'}
      </SelectFileButton>
    )
  }, [boxMode, isNewChatModal, selectAttachmentsButtonText])

  return (
    <MessageInput>
      {showViewedBy || (!hideTypingIndicator && !boxMode) ? (
        <MessageInputHeader>
          <MessageInputHeaderItem>
            {!hideTypingIndicator && !boxMode && (
              <SomeoneTypingIndicator>
                {!!isSomeoneTyping && (
                  <span>{`${isSomeoneTyping} is typing...`}</span>
                )}
              </SomeoneTypingIndicator>
            )}
          </MessageInputHeaderItem>
          <MessageInputHeaderItem>
            {showViewedBy && (
              <ViewedBy
                onClick={() => onClickViewedBy?.(viewedBy)}
                text={
                  viewedBy.length
                    ? viewedBy.map((u) => u?.fullName).join(', ')
                    : 'Not seen yet'
                }
              >
                <ViewedByText>Seen By {viewedBy.length}</ViewedByText>
              </ViewedBy>
            )}
          </MessageInputHeaderItem>
        </MessageInputHeader>
      ) : null}
      <MessageInputContainer>
        {boxMode && !disableAttachment && (
          <FileInput
            onlySelectFileButton
            onUploaderCancel={onUploaderCancel}
            onUploaderError={onUploaderError}
            onUploaderUploadComplete={onUploaderUploadComplete}
            onUploaderUploadDone={onUploaderUploadDone}
            onUploaderUploadProgress={onUploaderUploadProgress}
            onUploaderUploadStart={onUploaderUploadStart}
            selectFileButton={renderSelectFileButton}
            uploads={uploads as ExtendedUpload[]}
          />
        )}

        <MessageInputText
          autoGrow={autoGrow ?? true}
          containerStyle={InputContainerStyle}
          disabled={isSending}
          initialRows={initialRows ?? 1}
          maximumRows={maximumRows ?? 10}
          minimumRows={minimumRows ?? 1}
          onChange={onMessageInputChange}
          onFocus={onFocus}
          onKeyPress={handleOnKeyPress}
          placeholder={placeholder}
          ref={textInputRef}
          textareaStyle={TextAreaStyle}
          value={messageText}
        />
      </MessageInputContainer>

      {(!!uploads.length || !!pendingUploads.length) && (
        <UploadsContainer isNewChatModal={isNewChatModal ?? false}>
          {uploads.map((upload) => {
            return (
              <UploadContainer key={upload.id} title={upload.fileName!}>
                {isFile(upload?.contentType as string) ? (
                  <UploadFileContainer>
                    <FileTypeIcon
                      contentType={upload.contentType!}
                      fileName={upload.fileName!}
                      nameCase='pascal'
                      size={26}
                    />
                  </UploadFileContainer>
                ) : (
                  <img src={getThumbnail(upload)} alt={upload.fileName!} />
                )}
                {!isSending ? (
                  <div
                    className='remove'
                    onClick={() => {
                      onUploaderCancel(upload)
                    }}
                    title='Remove'
                  >
                    <Icon name='X' />
                  </div>
                ) : null}
              </UploadContainer>
            )
          })}
          {pendingUploads.map((upload) => (
            <UploadContainer key={upload.id}>
              {/* <Progress value={100 * (upload.progress?.uploadProgress ?? 0)} /> */}
              <Spinner size={16} fill={Colors.SHUTTLE_GRAY} />
              {!isSending && (
                <div
                  className='remove'
                  onClick={() => {
                    onUploaderCancel(upload)
                  }}
                  title='Remove'
                >
                  <Icon name='X' />
                </div>
              )}
            </UploadContainer>
          ))}
        </UploadsContainer>
      )}

      <MessageInputOptions>
        {!boxMode && !disableAttachment && (
          <>
            <FileInput
              onlySelectFileButton
              onUploaderCancel={onUploaderCancel}
              onUploaderError={onUploaderError}
              onUploaderUploadComplete={onUploaderUploadComplete}
              onUploaderUploadDone={onUploaderUploadDone}
              onUploaderUploadStart={onUploaderUploadStart}
              selectFileButton={renderSelectFileButton}
              uploads={uploads as ExtendedUpload[]}
            />
            {showSendUI && (
              <>
                <PressEnterToSendOption
                  checked={pressEnterToSend}
                  onChange={onClickPressEnterToSend}
                >
                  Press Enter to Send
                </PressEnterToSendOption>
                {(!pressEnterToSend || uploads.length > 0) && (
                  <SendButton
                    disabled={isSending || cannotSend}
                    onClick={handleOnClickSend}
                  >
                    {isSending ? (
                      <Spinner size={14} fill={Colors.WHITE} />
                    ) : (
                      'Send'
                    )}
                  </SendButton>
                )}
              </>
            )}
          </>
        )}
      </MessageInputOptions>
    </MessageInput>
  )
}

const MessageInput = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MessageInputText = styled(TextArea)`
  min-width: 1px;
`

const MessageInputOptions = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 6px;
`

const MessageInputContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`

const PressEnterToSendOption = styled(Checkbox)`
  align-items: center;
  border-radius: ${BORDER_RADIUS};
  display: flex;
  font-size: 14px;
  padding: 5px 10px;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`

const SendButton = styled(Button)`
  margin-left: auto;
`

const UploadsContainer = styled.div<{ isNewChatModal: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: ${({ isNewChatModal }) => (isNewChatModal ? '10px' : undefined)};
  overflow-y: scroll;
  width: 100%;
`

const UploadContainer = styled.div`
  align-items: center;
  background-color: ${Colors.ALTO};
  border-radius: 8px;
  color: ${Colors.DUSTY_GRAY};
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 5px 5px 0 0;
  overflow: hidden;
  position: relative;
  width: 40px;

  .remove {
    align-items: center;
    background-color: ${Colors.BLACK};
    border-radius: 50%;
    color: ${Colors.WHITE};
    cursor: pointer;
    display: none;
    height: 20px;
    justify-content: center;
    position: absolute;
    padding: 2px;
    right: 0;
    top: 0;
    width: 20px;
  }

  &:hover {
    .remove {
      display: flex;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const UploadFileContainer = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const SelectFileButton = styled(Button)<{ boxMode?: boolean }>`
  margin-right: 6px;
  padding: ${({ boxMode }) => (boxMode ? undefined : '5px 10px;')};
`

const MessageInputHeader = styled.div`
  align-items: center;
  color: ${Colors.DUSTY_GRAY};
  display: flex;
  flex-direction: row;
  font-size: 10px;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;

  span {
    text-decoration: none !important;
  }
`
const MessageInputHeaderItem = styled.div``
const SomeoneTypingIndicator = styled.div``
const ViewedBy = styled(Tooltip)``
const ViewedByText = styled.span`
  cursor: pointer;
`

// ChatMessageInput.whyDidYouRender = true
