import { createSlice } from '@reduxjs/toolkit'
import { SMALL_SCREEN_BREAKPOINT } from '../../constants'

export interface SidebarSliceProps {
  isVisible: boolean
}

//
const mQuery = window.matchMedia(`(max-width: ${SMALL_SCREEN_BREAKPOINT})`)

const initialState: SidebarSliceProps = {
  isVisible: !mQuery.matches,
}

const SidebarSlice = createSlice({
  name: 'Sidebar',
  initialState,
  reducers: {
    showSidebar(state, action) {
      state.isVisible = true
    },
    hideSidebar(state) {
      state.isVisible = false
    },
    toggleSidebar(state) {
      state.isVisible = !state.isVisible
    },
  },
})

export const { hideSidebar, showSidebar, toggleSidebar } = SidebarSlice.actions
export default SidebarSlice.reducer
