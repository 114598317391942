import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'
import { isEqual } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  MutationTeamMemberSetAdminRequest,
  Team,
  TeamMember as TeamMemberProps,
  mutationTeamMemberChangeRole,
  mutationTeamMemberRemove,
  mutationTeamMemberSetAdmin,
  queryTeamSignupCode,
} from '@sportsyou/api'
import { capitalize, Colors, formatTeamCode } from '@sportsyou/core'
import {
  Avatar,
  BORDER_RADIUS,
  Button,
  Dropdown,
  FONT_SIZE_H3,
  Icon,
  Searchbar,
  SingleTagProps,
  StrictModeDroppable as Droppable,
  TagGroup,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import { getAliasesAsString, getUsername } from 'web/utils/user'

import { removeTeam, selectCurrentUser, updateTeam } from 'web/store/slices'
import { EditJerseyNumberModal, PlayerAliasModal } from 'web/components'
import { CHAT_MESSAGE_FILTER, useChat } from 'web/hooks'
import DragHandle from '../DragHandle/DragHandle'

export interface TeamMemberListProps {
  contentClassName?: string
  contentStyle?: React.CSSProperties
  headerClassName?: string
  headerStyle?: React.CSSProperties
  id?: string
  members?: Array<TeamMemberProps>
  onRefreshTeam?: () => void
  onRemoveTeamMember?: (userId: string) => void
  playerAliasModalInitiallyVisible?: boolean
  team: Team
}

export const TeamMemberList: React.FC<TeamMemberListProps> = ({
  contentStyle,
  contentClassName,
  headerClassName,
  headerStyle,
  id,
  ...props
}: TeamMemberListProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { sendBanner, sendConfirm } = useDialog()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentUser = useSelector(selectCurrentUser)

  const chatClient = useChat({
    establishConnection: false,
    filter: CHAT_MESSAGE_FILTER.LISTEN_FOR_MESSAGES_ONLY,
  })

  const { fetch: changeRole } = useFetchApi(mutationTeamMemberChangeRole)
  const { fetch: getCode } = useFetchApi(queryTeamSignupCode)
  const { fetch: makeAdmin } = useFetchApi(mutationTeamMemberSetAdmin)
  const { fetch: removeMember } = useFetchApi(mutationTeamMemberRemove)

  const debounced = useDebouncedCallback((value) => {
    setSearchParam(value)
    setIsSearching(false)
  }, 500)

  const [currentPlayerAliases, setCurrentPlayerAliases] = useState<string[]>(
    () => {
      const member = props.team?.members?.find(
        (m) => m?.userId === currentUser?.id
      )
      const _aliases = (member?.aliases ?? [])
        .map((a) => a ?? '')
        .filter((a) => a)
      return !!props.playerAliasModalInitiallyVisible ? _aliases : []
    }
  )

  const [isCopying, setIsCopying] = useState<boolean>(false)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isPlayerAliasModalVisible, setIsPlayerAliasModalVisible] = useState(
    !!props.playerAliasModalInitiallyVisible
  )
  const [jerseyNumberModalSelectedMember, setJerseyNumberModalSelectedMember] =
    useState<TeamMemberProps | undefined>()
  const [searchParam, setSearchParam] = useState('')
  const [team, setTeam] = useState<Team>(props.team)
  const [teamCode, setTeamCode] = useState<string>('')

  // formated team code with '-' every 4 characters
  const displayTeamCode = useMemo(() => formatTeamCode(teamCode), [teamCode])

  const isLargeTeam = useMemo(() => !!team.isLargeTeam, [team])

  useEffect(() => {
    if (props.team && !isEqual(props.team, team)) {
      setTeam(props.team)
    }
  }, [props.team])

  useEffect(() => {
    const fetchData = async () => {
      const { data, ok } = await getCode({ teamId: id })
      if (data && ok) setTeamCode(data)
    }
    if (team.isAdmin) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, team])

  useEffect(() => {
    if (isCopying) {
      setTimeout(() => {
        setIsCopying(false)
      }, 3000)
    }
  }, [isCopying])

  const isUserVisibleOnLargeTeam = useCallback(
    (user: TeamMemberProps) => {
      return !isLargeTeam || user.isAdmin || user.userId === currentUser?.id
    },
    [currentUser?.id, isLargeTeam]
  )

  /**
   * All members of the team.
   * This returns all team members.
   */
  const allMembers = useMemo(
    () =>
      ((team.members as Array<TeamMemberProps>) ?? []).map(
        (member: TeamMemberProps) => member
      ),
    [team.members]
  )

  /**
   * Members of the team.
   * If this is a large team, it will filter out any member that should not be
   * in the data set.
   */
  const members = useMemo(
    () =>
      ((team?.members as Array<TeamMemberProps>) ?? []).filter(
        (member: TeamMemberProps) => isUserVisibleOnLargeTeam(member)
      ),
    [isUserVisibleOnLargeTeam, team?.members]
  )

  const filteredMembers = useMemo(
    () =>
      searchParam
        ? (isLargeTeam ? allMembers : members ?? []).filter(
            (member) =>
              // isUserVisibleOnLargeTeam(member) &&
              getUsername(member)
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase()) ||
              getAliasesAsString(member)
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase())
          )
        : [],
    [allMembers, isLargeTeam, members, searchParam]
  )

  const coaches = useMemo(
    () =>
      members?.filter(
        (member: TeamMemberProps) =>
          isUserVisibleOnLargeTeam(member) && member.role === 'coach'
      ),
    [isUserVisibleOnLargeTeam, members]
  )

  const filteredCoaches = useMemo(
    () =>
      searchParam
        ? (isLargeTeam
            ? allMembers.filter(
                (member: TeamMemberProps) => member.role === 'coach'
              )
            : coaches ?? []
          ).filter(
            (member) =>
              isUserVisibleOnLargeTeam(member) &&
              (getUsername(member)
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase()) ||
                getAliasesAsString(member)
                  .toLocaleLowerCase()
                  .includes(searchParam.toLowerCase()))
          )
        : [],
    [allMembers, coaches, isLargeTeam, isUserVisibleOnLargeTeam, searchParam]
  )

  const players = useMemo(
    () =>
      members?.filter(
        (member: TeamMemberProps) =>
          isUserVisibleOnLargeTeam(member) && member.role === 'player'
      ),
    [isUserVisibleOnLargeTeam, members]
  )

  const filteredPlayers = useMemo(
    () =>
      searchParam
        ? (isLargeTeam
            ? allMembers.filter(
                (member: TeamMemberProps) => member.role === 'player'
              )
            : players ?? []
          ).filter(
            (member) =>
              // isUserVisibleOnLargeTeam(member) &&
              getUsername(member)
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase()) ||
              getAliasesAsString(member)
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase())
          )
        : [],
    [allMembers, isLargeTeam, players, searchParam]
  )

  const family = useMemo(
    () =>
      members?.filter(
        (member: TeamMemberProps) =>
          isUserVisibleOnLargeTeam(member) && member.role === 'parent'
      ),
    [isUserVisibleOnLargeTeam, members]
  )

  const filteredFamily = useMemo(
    () =>
      searchParam
        ? (isLargeTeam
            ? allMembers.filter(
                (member: TeamMemberProps) => member.role === 'parent'
              )
            : family ?? []
          ).filter(
            (member) =>
              // isUserVisibleOnLargeTeam(member) &&
              getUsername(member)
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase()) ||
              getAliasesAsString(member)
                .toLocaleLowerCase()
                .includes(searchParam.toLowerCase())
          )
        : [],
    [allMembers, family, isLargeTeam, searchParam]
  )

  const adminCount = useMemo(
    () =>
      members?.filter((member) => member.adminRoles?.includes('admin'))
        .length ?? 0,
    [members]
  )
  const adminsByUserId = useMemo(
    () =>
      members
        ?.filter((member) => member.adminRoles?.includes('admin'))
        .map((member) => member.userId),
    [members]
  )
  const currentUserIsTheOnlyAdmin = useMemo(
    () => adminCount === 1 && adminsByUserId?.includes(currentUser?.id),
    [adminCount, adminsByUserId, currentUser?.id]
  )
  const isTeam = useMemo(() => team?.type === 'team', [team])
  const teamType = useMemo(() => team?.type ?? 'team', [team])
  const teamTypeCapitalized = useMemo(() => capitalize(teamType), [teamType])

  const getTags = useCallback(
    (item: TeamMemberProps) => {
      let data: Array<SingleTagProps> = []
      if (item.adminRoles && item.adminRoles.length > 0) {
        item.adminRoles.forEach((item) => {
          if (data.length === 0) {
            data.push({
              fill: item === 'admin' ? Colors.HAVELOCK_BLUE : Colors.PERANO,
              title: capitalize(item as string),
            })
          }
        })
      }

      if (item.aliases && item.aliases.length > 0) {
        item.aliases.forEach((alias) =>
          data.push({
            fill: Colors.MYSTIC,
            title: alias as string,
          })
        )
      }

      // If admin is in another role we can add the tag here
      if (
        adminsByUserId?.includes(item.userId) &&
        !item.adminRoles?.includes('admin')
      ) {
        data.unshift({
          fill: Colors.HAVELOCK_BLUE,
          title: 'Admin',
        })
      }
      return data
    },
    [adminsByUserId]
  )

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value === '') {
      setSearchParam(value)
    } else {
      setIsSearching(true)
      debounced(value)
    }
  }

  const onClickChat = async (member: TeamMemberProps) => {
    const { userId } = member
    if (!userId) return
    chatClient.startChatWithUser({ showUI: true, userId })
  }

  const onClickCopyCode = () => {
    teamCode && navigator.clipboard.writeText(teamCode)
    setIsCopying(true)
  }

  const onClickProfile = (id?: string) => {
    id &&
      navigate(`/profile/${id}`, {
        state: { id },
      })
  }

  const handleMakeAdmin = useCallback(
    async (
      item: TeamMemberProps,
      role: 'admin' | 'photographer',
      enable: boolean
    ) => {
      const { userId } = item
      const { ok } = await makeAdmin({
        teamId: id,
        userId,
        adminRole: role,
        isAdmin: enable,
      } as MutationTeamMemberSetAdminRequest)
      if (ok) {
        props.onRefreshTeam?.()
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: `Successfully ${
            enable ? 'added' : 'removed'
          } ${role} role for ${getUsername(item)}`,
        })
      } else {
        sendBanner({
          autoDismiss: true,
          status: 'danger',
          message: `Failed to ${
            enable ? 'add' : 'remove'
          } ${role} role for ${getUsername(item)}`,
        })
      }
    },
    [id, makeAdmin, props, sendBanner]
  )

  const onChangeAdminRole = useCallback(
    async (item: TeamMemberProps, role: 'admin' | 'photographer') => {
      const { adminRoles } = item
      const enable =
        role === 'admin'
          ? !adminRoles?.includes('admin')
          : !adminRoles?.includes('photographer')
      if (role === 'photographer' && enable) {
        sendConfirm({
          confirmText: 'Make Photographer',
          message:
            'When you make someone a photographer, they will be able to post to the team feed, upload photos and videos to Media, and create Albums.',
          onConfirm: async () => {
            await handleMakeAdmin(item, role, enable)
          },
        })
      } else {
        await handleMakeAdmin(item, role, enable)
      }
    },
    [handleMakeAdmin, sendConfirm]
  )

  const onChangeJerseyNumber = useCallback(
    (item: TeamMemberProps) => {
      setJerseyNumberModalSelectedMember(item)
      props.onRefreshTeam?.()
    },
    [props]
  )

  const onRemoveMember = useCallback(
    async (userId: string) => {
      const removeCurrentUser = currentUser.id === userId
      if (removeCurrentUser && team?.isAdmin && adminCount === 1) {
        sendBanner({
          status: 'info',
          message: `You are the last admin of this ${teamType}. Please make another admin before leaving.`,
        })
        return
      }

      const confirmMessage = removeCurrentUser
        ? `Are you sure you want to leave the ${teamType}?`
        : `Are you sure you want to remove the member from the ${teamType}?`
      sendConfirm({
        confirmText: removeCurrentUser ? 'Leave' : 'Remove',
        isDestructive: true,
        message: confirmMessage,
        onConfirm: async () => {
          const { data, error, ok } = await removeMember({
            teamId: id,
            teamMemberId: team.members?.find((m) => m?.userId === userId)?.id,
          })
          if (data && ok) {
            props.onRemoveTeamMember?.(userId)
            sendBanner({
              autoDismiss: true,
              status: 'success',
              message: removeCurrentUser
                ? `You have left ${props.team?.name}`
                : `Member was removed from ${props.team?.name}`,
            })
            if (removeCurrentUser) {
              dispatch(removeTeam(props.team!))
              navigate(`/${teamType}s`)
            } else {
              dispatch(
                updateTeam({
                  ...props.team!,
                  members: props.team?.members?.filter(
                    (m) => m?.userId !== userId
                  ),
                })
              )
            }
          }
          if (error) {
            sendBanner({
              autoDismiss: true,
              status: 'danger',
              message: `Error. Failed to remove member.`,
            })
          }
        },
      })
    },
    [
      adminCount,
      currentUser.id,
      dispatch,
      id,
      navigate,
      props,
      removeMember,
      sendBanner,
      sendConfirm,
      team?.isAdmin,
      team.members,
      teamType,
    ]
  )

  const onDragItemEnd = useCallback(
    async (dropResult: DropResult) => {
      if (dropResult?.destination?.droppableId) {
        setTeam({
          ...team,
          members: team?.members?.map((member) => {
            if (member?.id === dropResult.draggableId) {
              return {
                ...member,
                role: dropResult.destination?.droppableId,
              }
            }
            return member
          }),
        })
        const { data: ok } = await changeRole({
          newRole: dropResult.destination.droppableId,
          teamMemberId: dropResult.draggableId,
        })
        props.onRefreshTeam?.()
        if (ok) {
          sendBanner({
            autoDismiss: true,
            message: 'Role changed',
            status: 'success',
          })
        }
      }
    },
    [changeRole, sendBanner, team]
  )

  const onSaveJerseyNumber = useCallback(() => {
    props.onRefreshTeam?.()
  }, [props])

  const onClosePlayerAliasModal = useCallback(() => {
    setIsPlayerAliasModalVisible(false)
    if (props.playerAliasModalInitiallyVisible) {
      // remove searchParams to prevent modal from automatically showing again
      searchParams.delete('playerAliasModalInitiallyVisible')
      setSearchParams(searchParams)
    }
  }, [props.playerAliasModalInitiallyVisible, searchParams, setSearchParams])

  const renderMenuButton = useMemo(
    () => (
      <MenuButton appearance='minimal' collapse variant='alternate'>
        <Icon fill={Colors.SHUTTLE_GRAY} name={'Dots'} height={18} width={18} />
      </MenuButton>
    ),
    []
  )

  const teamActions = useCallback(
    (item: TeamMemberProps) =>
      [
        {
          action: () => {
            const _aliases = (item.aliases ?? [])
              .map((a) => a ?? '')
              .filter((a) => a)

            setCurrentPlayerAliases(_aliases)
            setIsPlayerAliasModalVisible(true)
          },
          isVisible: currentUser.id === item.userId,
          title: `${
            item.aliases && item.aliases.length > 0 ? 'Edit' : 'Add'
          } Player Info`,
        },
        {
          action: () => onChangeJerseyNumber(item),
          isVisible: !!props.team?.isAdmin,
          title: `${item.jerseyNumber ? 'Edit' : 'Add'} Jersey Number`,
        },
        {
          action: () => onChangeAdminRole(item, 'admin'),
          isVisible: props.team?.isAdmin && item.userId !== currentUser?.id,
          title: `${
            adminsByUserId?.includes(item.userId) ? 'Remove' : 'Make'
          } Admin`,
        },
        {
          isVisible: !item.isAdmin && props.team?.isAdmin,
          action: () => onChangeAdminRole(item, 'photographer'),
          title: `${
            item.adminRoles?.includes('photographer') ? 'Remove' : 'Make'
          } Photographer`,
        },
        {
          action: () => onRemoveMember(item.userId!),
          isVisible:
            !currentUserIsTheOnlyAdmin && item.userId === currentUser?.id,
          title: `Leave ${teamTypeCapitalized}`,
        },

        {
          isVisible: item.userId !== currentUser?.id && props.team?.isAdmin,
          action: () => onRemoveMember(item.userId!),
          title: `Remove from ${teamTypeCapitalized}`,
        },
      ].filter((action) => action.isVisible),
    [
      adminsByUserId,
      currentUser.id,
      currentUserIsTheOnlyAdmin,
      onChangeAdminRole,
      onChangeJerseyNumber,
      onRemoveMember,
      props.team?.isAdmin,
      teamTypeCapitalized,
    ]
  )

  const renderItem = (item: TeamMemberProps, index: number) => {
    let aliasData: Array<SingleTagProps> = getTags(item)

    const isDraggable =
      isTeam && (team.isAdmin || currentUser.id === item.userId)
    return (
      <Draggable
        draggableId={item.id ?? `${index}`}
        index={index}
        isDragDisabled={!isDraggable}
        key={item.id}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Item>
              <DragHandle
                {...provided.dragHandleProps}
                disabled={!isDraggable}
              />
              <Avatar
                diameter={40}
                name={item.fullName ?? ''}
                onClick={() => onClickProfile(item.userId ?? undefined)}
                uri={item.profileImage?.[0]?.viewUrl ?? ''}
              />
              <MemberDetailsContainer>
                <MemberNameContainer>
                  <UserName
                    onClick={() => onClickProfile(item.userId ?? undefined)}
                  >
                    {item.fullName}
                  </UserName>
                  {item.jerseyNumber && (
                    <MemberJerseyNumber>
                      #{item.jerseyNumber}
                    </MemberJerseyNumber>
                  )}
                </MemberNameContainer>
                {aliasData.length > 0 && (
                  <TagGroup items={aliasData} maxWidth={250} />
                )}
              </MemberDetailsContainer>
              <ItemActions>
                {!isLargeTeam && !!item.canChat && (
                  <ChatButton
                    appearance='minimal'
                    collapse
                    onClick={() => onClickChat(item)}
                    variant='alternate'
                  >
                    <Icon
                      fill={Colors.SHUTTLE_GRAY}
                      name='BubbleSolid'
                      height={18}
                      width={18}
                    />
                  </ChatButton>
                )}
                {teamActions(item).length > 0 ? (
                  <Dropdown
                    hideChevron
                    placement='bottomEnd'
                    title={renderMenuButton}
                    triggerStyle={{
                      marginLeft: 10,
                      minWidth: 1,
                      paddingLeft: 4,
                      paddingRight: 4,
                    }}
                  >
                    {teamActions(item).map((item, index) =>
                      item.isVisible ? (
                        <Dropdown.Item key={index} onClick={item.action}>
                          {item.title}
                        </Dropdown.Item>
                      ) : null
                    )}
                  </Dropdown>
                ) : null}
              </ItemActions>
            </Item>
          </div>
        )}
      </Draggable>
    )
  }

  const renderMemberSection = ({
    droppableId,
    filteredItems,
    items,
    title,
  }: {
    droppableId: string
    filteredItems: TeamMemberProps[]
    items: TeamMemberProps[]
    title: string
  }) => {
    const rows = (searchParam !== '' ? filteredItems : items) ?? []

    // if (rows.length === 0 && isLargeTeam) {
    //   return null
    // }

    return (
      <Section>
        <ContainerHeader>
          <H3>
            {title} ({rows.length})
          </H3>
        </ContainerHeader>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {rows.length > 0 && (
                <ContainerBody>
                  {rows.map((c, i) => renderItem(c, i))}
                </ContainerBody>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Section>
    )
  }

  return (
    <>
      <Header className={headerClassName} style={headerStyle}>
        <StyledSearchbar
          isSearching={isSearching}
          onChange={onChangeSearch}
          value={searchParam}
        />
        {team.isAdmin ? (
          <AccessCodeContainer>
            <AccessCodeLabel>Access Code</AccessCodeLabel>
            <AccessCode readOnly value={displayTeamCode} />
            <CopyButton
              onClick={onClickCopyCode}
              textStyle={{
                alignItems: 'center',
                display: 'flex',
                fontSize: 14,
                fontWeight: 700,
              }}
              variant='success'
            >
              {isCopying ? (
                <Icon
                  name={'Checkmark'}
                  fill={Colors.WHITE}
                  height={16}
                  width={16}
                  style={{ marginRight: 6 }}
                />
              ) : (
                'Copy'
              )}
            </CopyButton>
          </AccessCodeContainer>
        ) : null}
      </Header>
      <Container className={contentClassName} style={contentStyle}>
        <DragDropContext onDragEnd={onDragItemEnd}>
          {isTeam ? (
            <>
              {renderMemberSection({
                droppableId: 'coach',
                filteredItems: filteredCoaches,
                items: coaches,
                title: 'Coaches',
              })}
              {renderMemberSection({
                droppableId: 'player',
                filteredItems: filteredPlayers,
                items: players,
                title: 'Players',
              })}
              {renderMemberSection({
                droppableId: 'parent',
                filteredItems: filteredFamily,
                items: family,
                title: 'Family',
              })}
            </>
          ) : (
            <>
              {renderMemberSection({
                droppableId: 'member',
                filteredItems: filteredMembers,
                items: members,
                title: 'Members',
              })}
            </>
          )}
        </DragDropContext>
        {isLargeTeam ? (
          <Section>
            <ContainerHeader>
              <H3>
                Total Members <small>{team?.members?.length}</small>
              </H3>
            </ContainerHeader>
          </Section>
        ) : null}
      </Container>
      <EditJerseyNumberModal
        isVisible={!!jerseyNumberModalSelectedMember}
        members={members}
        onClose={() => setJerseyNumberModalSelectedMember(undefined)}
        onSave={onSaveJerseyNumber}
        selectedMember={jerseyNumberModalSelectedMember}
        teamId={id ?? undefined}
        teamType={teamType}
      />
      <PlayerAliasModal
        aliases={currentPlayerAliases}
        isVisible={isPlayerAliasModalVisible}
        onClose={onClosePlayerAliasModal}
        team={props.team!}
      />
    </>
  )
}

const Header = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 10px;
  }
`

const Container = styled.div`
  margin-bottom: 60px;
`
const Section = styled.section`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  & + & {
    margin-top: 10px;
  }
`
const ContainerHeader = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
`
const H3 = styled.h3`
  font-size: ${FONT_SIZE_H3};
`
const ContainerBody = styled.div`
  border-top: 1px solid ${Colors.ALTO};
`
const Item = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 140ms ease-in-out;

  &:hover {
    background-color: ${Colors.MYSTIC};
  }
`
const ItemActions = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`
const ChatButton = styled(Button)`
  && {
    border-radius: 50%;
  }
`
const MenuButton = styled(Button)`
  && {
    margin-left: 10px;
    border-radius: 50%;
  }
`
const AccessCodeLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  margin-left: 30px;
  line-height: 0.9;
`
const AccessCodeContainer = styled.div`
  display: flex;
  align-items: center;
  @media all and (max-width: 767px) {
    align-self: stretch;
    justify-content: flex-end;
  }
`
const AccessCode = styled.input`
  align-items: center;
  align-self: stretch;
  background-color: ${Colors.WHITE};
  border-bottom-left-radius: 6px;
  border-right: none;
  border-top-left-radius: 6px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  font-family: inherit;
  height: 35px;
  max-width: 130px;
  min-width: 90px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  padding: 6px 10px;
`
const CopyButton = styled(Button)`
  align-items: center;
  align-self: stretch;
  background-color: ${Colors.MOUNTAIN_MEADOW};
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border: none;
  color: ${Colors.WHITE};
  display: flex;
  height: 35px;
  min-height: 35px !important;
  min-width: 70px !important;
`
const MemberDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  margin-right: auto;
`
const UserName = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`
const MemberJerseyNumber = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
  text-decoration: none;
`
const MemberNameContainer = styled.div`
  align-items: center;
  display: flex;
`
const StyledSearchbar = styled(Searchbar)`
  min-height: unset !important;
  @media all and (max-width: 767px) {
    align-self: stretch;
  }
`

export default TeamMemberList
