import { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Button, Icon } from '@sportsyou/react-dom-ui'
import { Colors } from '@sportsyou/core'
import { useClickAway } from '@sportsyou/react-hooks'

interface UploadModalProps {
  isUploading?: boolean
  isVisible: boolean
  onClick: () => void
  onClose: () => void
  onDrop: (files: File[]) => void
}

export const UploadModal: React.FC<UploadModalProps> = (
  props: UploadModalProps
) => {
  const [isDragging, setIsDragging] = useState(false)

  const ref = useRef(null)

  useClickAway(ref, props.onClose)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        props.onClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [props, props.onClose])

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(true)
    },
    []
  )

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(false)
    },
    []
  )

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(false)
      const { files } = event.dataTransfer
      props.onDrop?.(Array.from(files))
    },
    [props]
  )

  return (
    <Container
      isDragging={isDragging}
      onClick={props.onClick}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      ref={ref}
    >
      <div className='inner-border'>
        <Icon
          name='Media'
          width={24}
          fill={Colors.SHUTTLE_GRAY}
          style={{
            backgroundColor: '#fff',
            width: '20px',
          }}
        />
        <Icon
          name='Media'
          width={24}
          fill={Colors.SHUTTLE_GRAY}
          style={{
            backgroundColor: '#fff',
            marginLeft: '10px',
            marginTop: '-43px',
            transform: 'rotate(10deg)',
            width: '20px',
            zIndex: -1,
          }}
        />
        <div>
          Drag and drop your photos
          <br /> or videos here
        </div>
        <div className='or-decorated'>
          <div className='dash-decoration'></div>
          or
          <div className='dash-decoration'></div>
        </div>
        <Button disabled={props.isUploading}>Choose File</Button>
      </div>
    </Container>
  )
}

const Container = styled.div<{ isDragging?: boolean }>`
  align-items: center;
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  box-shadow: 0px 4px 4px 2px rgb(0 0 0 / 10%);
  color: ${Colors.MINE_SHAFT};
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 53px;
  width: 450px;
  z-index: 11;

  .inner-border {
    align-items: center;
    border-radius: 8px;
    border: 1px dashed ${Colors.ALTO};
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 280px;
    padding: 30px 20px;
    position: relative;
    text-align: center;
    transition: all 0.3s ease-in-out;
    width: 100%;

    svg {
      margin-bottom: 20px;
    }
  }

  ${({ isDragging }) =>
    isDragging &&
    css`
      .inner-border {
        border: 1px dashed ${Colors.HAVELOCK_BLUE};
        background-color: #4a90e226;
      }
    `}

  .or-decorated {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }

  .dash-decoration {
    display: inline-block;
    font-size: 30px;
    border-top: 1px solid ${Colors.ALTO};
    width: 80px;
    height: 5px;
    margin: 0 10px;
  }

  & > button {
    margin: 10px 0;
  }
`

export default UploadModal
