import React, { FC, useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { Album, Upload } from '@sportsyou/api'
import { Colors, getThumbnail } from '@sportsyou/core'
import { Dropdown, Icon, Progress } from '@sportsyou/react-dom-ui'

export interface MediaListItemProps {
  canEdit?: boolean
  className?: string
  index?: number
  isAlbum?: boolean
  isGif?: boolean
  isInAlbum?: boolean
  isUploading?: boolean
  item?: Upload | Album | any
  onClick?: (props: MediaListItemProps) => void
  onDelete?: (props: MediaListItemProps) => void
  onDownload?: (props: MediaListItemProps) => void
  onError?: () => void
  onLoad?: () => void
  onShare?: (props: MediaListItemProps) => void
  onUpdate?: (props: MediaListItemProps) => void
  style?: React.CSSProperties
  teamId?: string
  thumbnail?: string
  type?: 'album' | 'image' | 'video'
}

export const MediaListItem: FC<MediaListItemProps> = (
  props: MediaListItemProps
) => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>()

  const src = useMemo(() => {
    if (props.thumbnail) {
      return props.thumbnail
    } else if (props.isAlbum && props.item?.coverImage?.viewUrl) {
      return getThumbnail(props.item.coverImage)
    } else if (props.item?.transcodes?.length) {
      return getThumbnail(props.item)
    } else if (
      /^image\/(jp?eg|png|gif|webp)/.test(props.item?.contentType ?? '') &&
      props.item?.file
    ) {
      return window.URL.createObjectURL(props.item.file as Blob)
    } else if (props.item?.viewUrl) {
      return props.item.viewUrl
    }
    return ''
  }, [props.thumbnail, props.isAlbum, props.item])

  const onClick = useCallback(() => {
    props.onClick?.(props)
  }, [props])

  const onClickDownload = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      props.onDownload?.(props)
    },
    [props]
  )

  const onClickDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      props.onDelete?.(props)
    },
    [props]
  )

  const handleOnBlur = () => {
    setDropdownVisible(false)
  }

  if (!props.item) {
    return null
  }

  return (
    <Container
      className={props.className}
      isUploading={props.isUploading}
      onClick={onClick}
      onMouseLeave={handleOnBlur}
      style={props.style}
      type={props.type}
    >
      {props.isAlbum ? (
        <AlbumNameContainer>
          <AlbumName>{props.item?.name}</AlbumName>
          <AlbumCount>({props.item?.mediaCount ?? 0})</AlbumCount>
        </AlbumNameContainer>
      ) : null}

      {props.canEdit &&
        !props.item.isPrivateAlbum &&
        !props.item.isProfileAlbum && (
          <OptionsDropdown
            onClick={(e) => e.stopPropagation()}
            onOpen={() => setDropdownVisible(true)}
            placement='bottomStart'
            visible={dropdownVisible}
            title={
              <EditButton>
                <Icon fill={Colors.WHITE} name='Dots' width={12} />
              </EditButton>
            }
            hideChevron
          >
            {!props.isAlbum && (
              <>
                <Dropdown.Item onClick={onClickDownload}>
                  Download
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            <Dropdown.Item onClick={onClickDelete}>Delete</Dropdown.Item>
          </OptionsDropdown>
        )}

      <InnerContainer>
        {props.isAlbum && props.item.isPrivateAlbum && (
          <TagTopLeft>Private</TagTopLeft>
        )}
        {props.isGif && <TagBottomLeft>Gif</TagBottomLeft>}
        {props.type === 'video' && (
          <CenteredIcon>
            <Icon
              fill={Colors.WHITE}
              name='Play'
              style={{
                marginLeft: 4, // added for "visual" center
              }}
            />
          </CenteredIcon>
        )}
        {props.isUploading && (
          <CenteredIcon>
            <Progress
              circleSize={50}
              fill={Colors.HAVELOCK_BLUE}
              thickness={3}
              value={props.item?.progress?.clientProgress}
              variant='circle'
              indeterminate={
                props.item?.progress?.clientProgress === 0 ||
                props.item?.progress?.clientProgress === 100
              }
            />
          </CenteredIcon>
        )}

        {!!src.length && <Image src={src} />}
      </InnerContainer>
    </Container>
  )
}

const Image = styled.img`
  bottom: 0;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

const EditButton = styled.button`
  background-color: rgb(0 0 0 / 50%);
  border-radius: 26px;
  border: none;
  cursor: pointer;
  display: none;
  height: 26px;
  margin: 10px;
  right: 0;
  top: 0;
  transition: background-color 90ms ease-in-out;
  width: 26px;
  z-index: 10;

  &:hover,
  &:active {
    background-color: rgb(0 0 0 / 75%);
  }
`

const Container = styled.div<{
  type?: 'album' | 'image' | 'video'
  isUploading?: boolean
}>`
  background-color: ${({ type }) =>
    type === 'video' ? Colors.BLACK : Colors.WHITE};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 100%; // 1:1 square
  position: relative;
  transition: box-shadow 120ms ease-in-out;

  &:hover {
    box-shadow: 0 6px 14px -2px rgb(0 0 0 / 20%);

    ${EditButton} {
      display: block;
    }
  }

  ${({ isUploading }) =>
    isUploading &&
    css`
      opacity: 0.5;
      cursor: default;

      img {
        cursor: default;
      }

      &:hover {
        box-shadow: none;

        ${EditButton} {
          display: none;
        }
      }
    `}
`

const InnerContainer = styled.div`
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

const AlbumNameContainer = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  bottom: 0;
  display: flex;
  height: 40px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  text-align: center;
  transition: height 120ms ease-in-out;
  width 100%;
  z-index: 10;
  gap: 4px;
`

const AlbumName = styled.span`
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AlbumCount = styled.span`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
`

const Tag = css`
  background-color: rgb(255 255 255 / 84%);
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  margin: 6px;
  padding: 1px 6px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
`

const TagBottomLeft = styled.div`
  ${Tag};
  bottom: 0;
  left: 0;
`

const TagTopLeft = styled.div`
  ${Tag};
  left: 0;
  top: 0;
`

const CenteredIcon = styled.div`
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const OptionsDropdown = styled(Dropdown)`
  overflow: show;
  position: absolute !important;
  z-index: 10;

  div {
    bottom: 0 !important;
    height: max-content;
    left: 0 !important;
    margin: 0 !important;
    right: 0 !important;
    top: 0;
    transform: translate(0, 0) !important;
  }
`

export default MediaListItem
