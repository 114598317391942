import {
  configureStore,
  getDefaultMiddleware,
  StoreEnhancer,
} from '@reduxjs/toolkit'
import { createInjectorsEnhancer } from 'redux-injectors'
import { save, load } from 'redux-localstorage-simple'
import createSagaMiddleware from 'redux-saga'

import { createReducer } from './reducers'
import alertsReducer from './slices/AlertsSlice'
import calendarReducer from './slices/CalendarSlice'
import chatBoxesReducer from './slices/ChatBoxSlice'
import chatCreateModalReducer from './slices/ChatCreateModalSlice'
import chatListReducer from './slices/ChatListSlice'
import chatReducer from './slices/ChatEventSlice'
import chatUnreadCountReducer from './slices/ChatUnreadCountSlice'
import communitiesReducer from './slices/CommunitiesSlice'
import feedReducer from './slices/FeedSlice'
import foldersReducer from './slices/FoldersSlice'
import lightboxReducer from './slices/LightboxSlice'
import postComposerReducer from './slices/PostComposerSlice'
import requestsReducer from './slices/RequestsSlice'
import sidebarReducer from './slices/LayoutSidebarSlice'
import sponsoredPostsReducer from './slices/SponsoredPostsSlice'
import sportsReducer from './slices/SportsSlice'
import teamsReducer from './slices/TeamsSlice'
import userReducer from './slices/UserSlice'

export function configureAppStore() {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware]

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[]

  const localStorageConfig = {
    disableWarnings: true,
    namespace: 'sy-web',
    namespaceSeparator: '::',
    states: [
      'alerts',
      'calendar',
      'chat',
      'chatBoxes',
      'chatList',
      'chatUnreadCount',
      'communities',
      'feed',
      'folders',
      'requests',
      'Sidebar',
      'sponsoredPosts',
      'sports',
      'teams',
      'user',
    ],
  }

  const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
    middleware: [
      ...getDefaultMiddleware(),
      ...middlewares,
      save(localStorageConfig),
    ],
    preloadedState: load(localStorageConfig),
    reducer: createReducer(sliceReducers),
  })

  return store
}

const sliceReducers: any = {
  alerts: alertsReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  chatBoxes: chatBoxesReducer,
  chatCreateModal: chatCreateModalReducer,
  chatList: chatListReducer,
  chatUnreadCount: chatUnreadCountReducer,
  communities: communitiesReducer,
  feed: feedReducer,
  folders: foldersReducer,
  lightbox: lightboxReducer,
  postComposer: postComposerReducer,
  requests: requestsReducer,
  sidebar: sidebarReducer,
  sponsoredPosts: sponsoredPostsReducer,
  sports: sportsReducer,
  teams: teamsReducer,
  user: userReducer,
}

export type RootState = ReturnType<typeof sliceReducers>
