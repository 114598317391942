import { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Community,
  mutationCommunityUpdate,
  MutationCommunityUpdateRequest,
  mutationFollowerAdd,
  MutationFollowerAddRequest,
  mutationFollowerRemove,
  MutationFollowerRemoveRequest,
  mutationPollVote,
  MutationPollVoteRequest,
  mutationPostDelete,
  mutationSetLike,
  mutationSetLikeDelete,
  MutationSetLikeDeleteRequest,
  MutationSetLikeRequest,
  mutationSetPostViewed,
  Poll,
  Post,
  queryCommunities,
  queryCommunityPosts,
  QueryCommunityPostsRequest,
} from '@sportsyou/api'
import { PollChoiceReturnData } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import {
  fetchPageById,
  fetchPages,
  selectPages,
} from '../../../store/slices/CommunitiesSlice'
import { setFeed, updatePost } from '../../../store/slices/FeedSlice'
import { RootState } from '../../../store/rootReducer'

interface UsePagesProps {
  communityId?: string
  communityUrl?: string
}

export interface FetchPostCriteria {
  communityId?: string | null
  direction?: 'older' | 'newer' | null
  /**
   * Set true to force call fetch
   */
  force?: boolean
  page?: number | null
  perPage?: number
  postId?: string
  startPostId?: string | null
}

interface FetchResult {
  criteria: FetchPostCriteria
  posts?: Post[]
}

export default function UsePages(props?: UsePagesProps) {
  const dispatch = useDispatch()

  const lastFetchResult = useRef<FetchResult>()

  const communities = useSelector(selectPages)
  const { posts: reduxPosts } = useSelector((state: RootState) => state.feed)

  const [adminCommunities, setAdminCommunities] = useState<Community[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { fetch: deletePost } = useFetchApi(mutationPostDelete)
  const { fetch: fetchCommunities } = useFetchApi(queryCommunities)
  const { fetch: fetchPagesPosts } = useFetchApi(queryCommunityPosts)
  const { fetch: followCommunity } = useFetchApi(mutationFollowerAdd)
  const { fetch: likePost } = useFetchApi(mutationSetLike)
  const { fetch: postViewed } = useFetchApi(mutationSetPostViewed)
  const { fetch: setPollVote } = useFetchApi(mutationPollVote)
  const { fetch: unfollowCommunity } = useFetchApi(mutationFollowerRemove)
  const { fetch: unlikePost } = useFetchApi(mutationSetLikeDelete)
  const { fetch: updatePage } = useFetchApi(mutationCommunityUpdate)

  const update = useCallback(
    async (community: MutationCommunityUpdateRequest) => {
      const { data, ok } = await updatePage(community)
      if (data && ok) {
        dispatch(fetchPages())
      }
    },
    [dispatch, updatePage]
  )

  const posts = useMemo(
    () =>
      props?.communityId
        ? reduxPosts[props?.communityId] ?? []
        : reduxPosts.pages ?? [],
    [props, reduxPosts]
  )

  const getPost = useCallback(
    (postId: string) => posts.find((post) => post.id === postId),
    [posts]
  )

  const page = useMemo(() => {
    return (
      communities.find(
        (community) =>
          community.id === props?.communityId ||
          community.communityUrl === props?.communityUrl
      ) || ({} as Community)
    )
  }, [communities, props?.communityId, props?.communityUrl])

  const fetchAdminCommunities = useCallback(async () => {
    setIsLoading(true)
    const { data } = await fetchCommunities({ adminsOnly: true })
    setAdminCommunities(data ?? [])
    setIsLoading(false)
  }, [fetchCommunities])

  const fetchPage = useCallback(
    async (idOrUrl: string) => {
      setIsLoading(true)
      await dispatch(fetchPageById(idOrUrl, true))
      setIsLoading(false)
    },
    [dispatch]
  )

  const fetchAll = useCallback(async () => {
    setIsLoading(true)
    await dispatch(fetchPages())
    setIsLoading(false)
  }, [dispatch])

  const fetchAllPosts = useCallback(
    async (criteria?: FetchPostCriteria) => {
      const direction = criteria?.direction ?? 'older'
      const perPage = criteria?.perPage ?? 10
      const startPostId = criteria?.startPostId

      setIsLoading(true)
      const { data } = await fetchPagesPosts({
        direction,
        includeFollowingPosts: true,
        perPage,
        startPostId,
      } as QueryCommunityPostsRequest)
      lastFetchResult.current = {
        criteria: { direction, perPage, startPostId },
        posts: data ?? undefined,
      }

      const _posts = !startPostId ? data || [] : [...posts, ...(data || [])]
      dispatch(
        setFeed({
          id: 'pages',
          posts: _posts,
        })
      )
      setIsLoading(false)
    },
    [dispatch, fetchPagesPosts, posts]
  )

  const fetchPosts = useCallback(
    async ({
      communityId,
      direction,
      perPage = 10,
      startPostId,
    }: FetchPostCriteria) => {
      if (!communityId) return
      setIsLoading(true)
      const { data } = await fetchPagesPosts({
        communityId,
        direction,
        perPage,
        startPostId,
      })
      lastFetchResult.current = {
        criteria: { communityId, direction, perPage, startPostId },
        posts: data ?? undefined,
      }

      const _posts = !startPostId ? data || [] : [...posts, ...(data || [])]
      dispatch(
        setFeed({
          id: communityId,
          posts: _posts,
        })
      )
      setIsLoading(false)
    },
    [dispatch, fetchPagesPosts, posts]
  )

  const follow = useCallback(
    async ({ adminRole, communityId, userId }: MutationFollowerAddRequest) => {
      const { data, error, ok } = await followCommunity({
        adminRole,
        communityId,
        userId,
      } as MutationFollowerAddRequest)
      // if (data && ok) {
      //   // TODO: handle event
      dispatch(fetchPages())
      // }
      // if (error) {
      //   // TODO: handle error
      // }
    },
    [dispatch, followCommunity]
  )

  const unfollow = useCallback(
    async ({ communityId, userId }: MutationFollowerRemoveRequest) => {
      const { data, error, ok } = await unfollowCommunity({
        communityId,
        userId,
      } as MutationFollowerRemoveRequest)
      // if (data && ok) {
      //   // TODO: handle event
      // }
      // if (error) {
      //   // TODO: handle error
      // }
    },
    [unfollowCommunity]
  )

  const setPagePostLiked = useCallback(
    (post: Post) => {
      let numberLikes = post.numberLikes || 0
      if (numberLikes >= 0) {
        numberLikes = !post.isLiked ? numberLikes + 1 : numberLikes - 1
      }
      dispatch(
        updatePost({
          ...post,
          isLiked: !post.isLiked,
          numberLikes,
        })
      )
      return post.isLiked
        ? unlikePost({ postId: post.id } as MutationSetLikeDeleteRequest)
        : likePost({ postId: post.id } as MutationSetLikeRequest)
    },
    [dispatch, likePost, unlikePost]
  )

  const setPagePostViewed = useCallback(
    (post: Post) => {
      if (post && !post.isCreator && !post.postViewed) {
        postViewed({ postId: post.id })
        dispatch(
          updatePost({
            ...post,
            postViewed: true,
          })
        )
      }
    },
    [dispatch, postViewed]
  )

  const deletePagePost = useCallback(
    async (postId: string) => {
      await deletePost({
        postId,
      })
      dispatch(fetchPages())
    },
    [deletePost, dispatch]
  )

  const removePollVote = useCallback(
    async (postId: string) => {
      setIsLoading(true)
      const { error, ok } = await setPollVote({
        postId,
      } as MutationPollVoteRequest)

      if (error) {
        console.log({ error })
      }

      if (ok) {
        // update ui
        const _posts = posts.map((post) => {
          if (post.id === postId) {
            const _poll: Poll = {
              ...post.poll,
              choices: post.poll?.choices?.map((c) => ({
                ...c,
                currentUserVote: false,
              })),
            }
            return { ...post, poll: _poll }
          }
          return post
        })

        const newPost = _posts.find((post) => post.id === postId)
        if (newPost) dispatch(updatePost(newPost))
      }
      setIsLoading(false)
    },
    [dispatch, posts]
  )

  const _setPollVote = useCallback(
    async (poll: PollChoiceReturnData, postId: string) => {
      if (!postId) return
      const { hasCurrentVote, id: choiceId, isActive } = poll
      if (!isActive) return
      if (!hasCurrentVote && choiceId) {
        setIsLoading(true)
        const { error, ok } = await setPollVote({
          postId,
          choiceId,
        } as MutationPollVoteRequest)
        if (error) {
          console.log({ error })
        }

        if (ok) {
          const _posts = posts.map((post) => {
            if (post.id === postId) {
              const _poll: Poll = {
                ...post.poll,
                choices: post.poll?.choices?.map((c, i) =>
                  choiceId === c?.id
                    ? { ...c, currentUserVote: true }
                    : { ...c, currentUserVote: false }
                ),
              }
              return { ...post, poll: _poll }
            }
            return post
          })

          const newPost = _posts.find((post) => post.id === postId)
          if (newPost) dispatch(updatePost(newPost))
        }
        setIsLoading(false)
      }
    },
    [dispatch, posts]
  )

  return {
    adminCommunities,
    communities,
    deletePagePost,
    fetchAdminCommunities,
    fetchAll,
    fetchAllPosts,
    fetchPage,
    fetchPosts,
    follow,
    getPost,
    isLoading,
    lastFetchResult: lastFetchResult.current,
    page,
    posts,
    removePollVote,
    setPollVote: _setPollVote,
    setPagePostLiked,
    setPagePostViewed,
    unfollow,
    update,
  }
}
