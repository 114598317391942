import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import { Upload } from '@sportsyou/api'

export interface PostComposerInterface {
  hidePostTypeButtons?: boolean
  initialView?: string
  isOpen: boolean
  message?: string
  post?: any
  uploads?: Upload[]
}

const initialState: PostComposerInterface = {
  hidePostTypeButtons: false,
  initialView: 'message',
  isOpen: false,
  message: undefined,
  post: undefined,
  uploads: undefined,
}

const PostComposerSlice = createSlice({
  name: 'PostComposer',
  initialState,
  reducers: {
    showPostComposer(state) {
      state.isOpen = true
    },
    hidePostComposer(state) {
      state.isOpen = false
    },
    setPostComposer(state, action) {
      state.hidePostTypeButtons = action.payload?.hidePostTypeButtons
      state.initialView = action.payload?.initialView
      state.message = action.payload?.message
      state.post = action.payload?.post
      state.uploads = action.payload?.uploads
    },
    resetPostComposer(state) {
      state.hidePostTypeButtons = false
      state.initialView = 'message'
      state.isOpen = false
      state.message = undefined
      state.post = undefined
      state.uploads = undefined
    },
  },
})

export const {
  hidePostComposer,
  resetPostComposer,
  setPostComposer,
  showPostComposer,
} = PostComposerSlice.actions
export default PostComposerSlice.reducer

export const selectPostComposer = (state: RootState) => state.postComposer
