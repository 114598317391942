export const formatPhoneNumber = (phoneNumber: string) => {
  const numbers = phoneNumber.replace(/\D+/g, '')

  let formattedNumber = ''

  if (numbers.length >= 1) {
    formattedNumber = '(' + numbers
    if (numbers.length >= 3) {
      formattedNumber = `(${numbers.slice(0, 3)}) `
    }
    if (numbers.length >= 4) {
      formattedNumber = `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}`
    }
    if (numbers.length >= 6) {
      formattedNumber = `(${numbers.slice(0, 3)}) ${numbers.slice(
        3,
        6
      )}-${numbers.slice(6, 10)}`
    }
  }

  return formattedNumber
}

export default formatPhoneNumber
