import {
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit'

import {
  mutationSetTeamsSort,
  queryTeams,
  queryTeamsSort,
  Team,
} from '@sportsyou/api'
import { RootState } from '../rootReducer'
import moment from 'moment'

export interface TeamsSliceProps {
  allTeamsAndGroups: Team[]
  sortPreference: string[]
  lastUpdated?: string
}

const initialState: TeamsSliceProps = {
  allTeamsAndGroups: [],
  sortPreference: [],
}

let storeLastUpdated: string | undefined

const TeamsSlice = createSlice({
  name: 'teams',
  initialState: initialState,
  reducers: {
    clearTeams(state) {
      state.allTeamsAndGroups = []
      storeLastUpdated = undefined
    },
    storeTeams(state: TeamsSliceProps, action: PayloadAction<Team[]>) {
      const now = new Date().toISOString()
      state.allTeamsAndGroups = action.payload
      state.lastUpdated = now
      storeLastUpdated = now
    },
    addTeam(state: TeamsSliceProps, action: PayloadAction<Team>) {
      // Prevent adding team if it already exists
      const currentTeamIds = state.allTeamsAndGroups.map((t) => t.id)

      if (currentTeamIds.includes(action.payload.id)) {
        updateTeam(action.payload)
        return
      }

      const now = new Date().toISOString()
      state.allTeamsAndGroups.push(action.payload)
      state.lastUpdated = now
      storeLastUpdated = now
    },
    removeTeam(state, action: PayloadAction<Team>) {
      const now = new Date().toISOString()
      const newState = state.allTeamsAndGroups.filter(
        (team) => team.id !== action.payload.id
      )
      state.allTeamsAndGroups = newState
      state.lastUpdated = now
      storeLastUpdated = now
    },
    updateTeam(state, action: PayloadAction<Team>) {
      const now = new Date().toISOString()
      const newState = state.allTeamsAndGroups.map((team) => {
        if (team.id === action.payload.id) {
          return action.payload
        }
        return team
      })
      state.allTeamsAndGroups = newState
      state.lastUpdated = now
      storeLastUpdated = now
    },
    setSortPreference(state, action: PayloadAction<string[]>) {
      state.sortPreference = action.payload
    },
  },
})

export const {
  addTeam,
  clearTeams,
  removeTeam,
  setSortPreference,
  storeTeams,
  updateTeam,
} = TeamsSlice.actions
export default TeamsSlice.reducer

const _selectAllTeamsAndGroups = (state: RootState) =>
  state.teams.allTeamsAndGroups
const _selectSortPreference = (state: RootState) => state.teams.sortPreference

export const selectAllTeamsAndGroups = createSelector(
  [_selectAllTeamsAndGroups, _selectSortPreference],
  (allTeamsAndGroups, sortPreference = []) => {
    // sort all teams by teamSort preference
    const sorted =
      [...(allTeamsAndGroups ?? [])].sort((a, b) => {
        const aIndex = sortPreference.indexOf(a?.id ?? '')
        const bIndex = sortPreference.indexOf(b?.id ?? '')
        if (aIndex === -1 && bIndex === -1) {
          return (a.name ?? '').localeCompare(b.name ?? '')
        }
        if (aIndex === -1) {
          return 1
        }
        if (bIndex === -1) {
          return -1
        }
        return aIndex - bIndex
      }) ?? []
    return sorted
  }
)

export const selectTeams = createSelector(selectAllTeamsAndGroups, (teams) =>
  teams.filter((team) => team.type === 'team')
)

export const selectGroups = createSelector(selectAllTeamsAndGroups, (teams) =>
  teams.filter((team) => team.type === 'group')
)

export const selectTeamsAndGroupsUserCanChat = createSelector(
  selectAllTeamsAndGroups,
  (teams) =>
    teams.filter(
      (team) => !team.isLargeTeam && (team.isAdmin || team.canTeamChat)
    )
)

export const selectTeamById = createSelector(
  [selectAllTeamsAndGroups, (state, teamId?) => teamId],
  (teams, teamId?) => teams.find((team) => team.id === teamId)
)

export const fetchTeams =
  (forceUpdate?: boolean) => async (dispatch: Dispatch) => {
    const THROTTLE_MINUTES = 5
    const shouldFetch =
      !!forceUpdate ||
      !storeLastUpdated ||
      moment().diff(moment(storeLastUpdated), 'minutes') > THROTTLE_MINUTES

    if (!shouldFetch) return

    const { data } = await queryTeams({
      featureId: 'all', // to load available plans/features
      teamType: 'all',
    })
    if (data) {
      dispatch(storeTeams(data))
    }
    return data
  }

export const fetchTeamsWithSort = () => async (dispatch: Dispatch) => {
  const [resTeams, resTeamSort] = await Promise.allSettled([
    queryTeams({
      featureId: 'all', // to load available plans/features
      teamType: 'all',
    }),
    queryTeamsSort(),
  ])

  if (resTeams.status === 'fulfilled' && resTeams.value.data) {
    dispatch(storeTeams(resTeams.value.data))
  }

  if (
    resTeams.status === 'fulfilled' &&
    resTeams.value.data?.length &&
    resTeamSort.status === 'fulfilled' &&
    resTeamSort.value.data
  ) {
    dispatch(setSortPreference(resTeamSort.value.data))
  }

  return resTeams.status === 'fulfilled' ? resTeams.value.data : undefined
}

// export const fetchTeamSort = () => async (dispatch: Dispatch) => {
//   const { data } = await queryTeamsSort()
//   if (data) {
//     dispatch(setSortPreference(data))
//   }
//   return data
// }

export const saveTeamSort =
  (teamIds: string[]) => async (dispatch: Dispatch) => {
    dispatch(setSortPreference(teamIds))
    await mutationSetTeamsSort({ teamIds })
  }
