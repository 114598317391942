export const SMALL_SCREEN_BREAKPOINT = '900px'
export const MOBILE_BREAKPOINT = 'max-width: 767px'
export const DESKTOP_BREAKPOINT = 'min-width: 768px'

/**
 * Fake user
 * We use this to fill input placeholders with more friendly data
 */
type fakeUser = {
  firstName: string
  fullName: string
  lastName: string
  email: string
  city: string
  state: string
}

const fakeUserData = {
  email: ['jessie@sportsyou.com'],
  firstName: ['Jessie'],
  fullName: ['Jessie Williams'],
  lastName: ['Williams'],
  city: ['Mill Neck'],
  state: ['New York'],
}

export const getFakeUser = (
  attribute?: keyof typeof fakeUserData
): fakeUser | string => {
  if (attribute) {
    return fakeUserData[attribute][
      Math.floor(Math.random() * fakeUserData[attribute].length)
    ]
  }
  const firstName =
    fakeUserData.firstName[
      Math.floor(Math.random() * fakeUserData.firstName.length)
    ]
  const lastName =
    fakeUserData.firstName[
      Math.floor(Math.random() * fakeUserData.firstName.length)
    ]
  const email =
    fakeUserData.email[Math.floor(Math.random() * fakeUserData.email.length)]

  const city =
    fakeUserData.city[Math.floor(Math.random() * fakeUserData.city.length)]

  const state =
    fakeUserData.state[Math.floor(Math.random() * fakeUserData.state.length)]

  const fullName = `${firstName} ${lastName}`

  return { city, firstName, fullName, lastName, email, state }
}

type ImageLinkProps = {
  alt: string
  src: string
  srcSet?: string
}
type LinkProps = {
  active: boolean
  href: string
  img?: ImageLinkProps
  target?: '_self' | '_blank' | '_parent' | '_top'
  text: string
  title?: string
}

// export const publicImagesPath = `${process.env.PUBLIC_URL}/images`
export const publicImagesPath = '/images'
const appleAppStoreFileName = 'sportsyou-apple-store'
const googlePlayStoreFileName = 'sportsyou-google-play'

const createSrcSet = (
  name: string,
  fileExtension: string,
  set: Array<string>
) => {
  return set
    .map(
      (item) => `${publicImagesPath}/${name}@${item}.${fileExtension} ${item}`
    )
    .join(', ')
}

export const MOBILE_APP_LINKS: Array<LinkProps> = [
  {
    active: true,
    href: 'https://itunes.apple.com/app/id1186906455',
    target: '_blank',
    title: 'Download the sportsYou App on the App Store',
    text: 'iOS App',
    img: {
      alt: 'apple-store',
      src: `${publicImagesPath}/${appleAppStoreFileName}.png`,
      srcSet: createSrcSet(appleAppStoreFileName, 'png', ['2x', '3x', '4x']),
    },
  },
  {
    active: true,
    href: 'https://play.google.com/store/apps/details?id=com.sportsyou.sportsYou',
    target: '_blank',
    title: 'Download the sportsYou App on Google Play',
    text: 'Android App',
    img: {
      alt: 'google-play-store',
      src: `${publicImagesPath}/${googlePlayStoreFileName}.png`,
      srcSet: createSrcSet(googlePlayStoreFileName, 'png', ['2x', '3x', '4x']),
    },
    // imgSrc
  },
]
export const IPAD_APP_STORE_URL =
  'https://apps.apple.com/us/app/sportsyou-for-ipad/id1436075462'

export const URLS = {
  AD_FEEDBACK: 'https://go.sportsyou.com/app/ad-feedback',
  CONTACT_US: 'mailto:info@sportsyou.com',
  PRIVACY_POLICY: '/legal/privacy-policy',
  SY_SHOP: 'https://shop.sportsyou.com',
  HELP_DOCS: 'https://help.sportsyou.com/en/',
  TERMS_OF_USE: '/legal/terms-of-use',
}

export const SOCIAL_URLS = {
  FACEBOOK: 'https://facebook.com/sportsYou',
  INSTAGRAM: 'https://instagram.com/sportsyou',
  TWITTER: 'https://twitter.com/sportsyou',
  YOUTUBE: 'https://www.youtube.com/sportsYouApp',
}

export const FOOTER_NAV_LINKS: Array<LinkProps> = [
  {
    active: false,
    href: '/blog/posts',
    text: 'Blog',
    title: 'sportsYou Blog',
  },
  {
    active: true,
    href: URLS.TERMS_OF_USE,
    text: 'Terms',
    title: 'sportsYou Terms of Service',
  },
  {
    active: true,
    href: URLS.PRIVACY_POLICY,
    text: 'Privacy Policy',
    title: 'sportsYou Privacy Policy',
  },
  {
    active: true,
    href: URLS.CONTACT_US,
    text: 'Contact',
    title: 'Contact Us at info@sportsyou.com',
  },
  {
    active: true,
    href: '/m/about',
    text: 'About',
    title: 'About sportsYou',
  },
  {
    active: true,
    href: '/m/careers',
    text: 'Careers',
    title: 'Careers at sportsYou',
  },
  {
    active: true,
    href: URLS.HELP_DOCS,
    text: 'Help',
    title: 'sportsYou Help',
  },
  {
    active: true,
    href: '/m/video-resources',
    text: 'Resources',
    title: 'sportsYou Video Resources',
  },
]

// web socket
export const WEB_SOCKET = Object.freeze({
  delay: Object.freeze({
    subscription: Object.freeze({
      notification: 2_000,
      teamSort: 3_000,
    }),
  }),
})

// chat
export const CHAT_DIRECTION_BILATERAL = 'bilateral'
export const CHAT_DIRECTION_UNILATERAL = 'unilateral'

export const CHAT_TYPE_DIRECT = 'direct'
export const CHAT_TYPE_GROUP = 'group'
export const CHAT_TYPE_TEAM = 'team'
export const MESSAGE_GROUP_CRITERIA_JOINER = '~'
export const NUMBER_OF_RECENT_FRIENDS = 10
export const NUMBER_OF_RECENT_TEAMGROUPS = 10

export const DEFAULT_BIRTHDAY_INPUT_FORMAT = 'MM/DD/YYYY'

export const EVENT_ATTENDING_MAYBE = 'maybe'
export const EVENT_ATTENDING_NO = 'no'
export const EVENT_ATTENDING_PENDING = 'pending'
export const EVENT_ATTENDING_YES = 'yes'
export const PERSONAL_CALENDAR_COLOR = '#4A90E2'
export const REMINDER_INTERVAL_TYPE_DAYS = 'days'
export const REMINDER_INTERVAL_TYPE_HOURS = 'hours'
export const REMINDER_INTERVAL_TYPE_MINUTES = 'minutes'
export const REMINDER_INTERVAL_TYPE_WEEKS = 'weeks'
export const REMINDER_TYPE_BOTH = 'both'
export const REMINDER_TYPE_EMAIL = 'email'
export const REMINDER_TYPE_NONE = 'none'
export const REMINDER_TYPE_NOTIFICATION = 'notification'
export const REPEAT_INTERVAL_DAILY = 'daily'
export const REPEAT_INTERVAL_MONTHLY = 'monthly'
export const REPEAT_INTERVAL_NONE = 'none'
export const REPEAT_INTERVAL_WEEKLY = 'weekly'
export const REPEAT_INTERVAL_YEARLY = 'yearly'
export const REPEAT_UPDATE_ALL = 'all'
export const REPEAT_UPDATE_FOLLOWING = 'following'
export const REPEAT_UPDATE_INSTANCE = 'instance'

export const TEAM_MEMBER_FIELD_LIST = `
  {
    members {
      adminRoles
      aliasFirstName
      aliasLastName
      aliases
      canChat
      firstName
      friendStatus
      fullName
      id
      isActive
      isAdmin
      isFriend
      jerseyNumber
      lastName
      profileImage {
        contentType
        fileSize
        height
        id
        signedUrl
        transcodeType
        viewUrl
        width
      }
      role
      userId
    }
  }
`

// user agent parsing
const USER_AGENT = (window.navigator && window.navigator.userAgent) || ''
export function isReal() {
  // Both document and window will never be undefined thanks to `global`.
  return document === window.document
}
export const IS_EDGE = /Edg/i.test(USER_AGENT)
export const IS_ANDROID = /Android/i.test(USER_AGENT)
export const IS_IPOD = /iPod/i.test(USER_AGENT)
export const TOUCH_ENABLED = Boolean(
  isReal() &&
    ('ontouchstart' in window ||
      window.navigator.maxTouchPoints ||
      //@ts-ignore
      (window.DocumentTouch && window.document instanceof window.DocumentTouch))
)
export const IS_CHROME =
  !IS_EDGE && (/Chrome/i.test(USER_AGENT) || /CriOS/i.test(USER_AGENT))
export const IS_SAFARI =
  /Safari/i.test(USER_AGENT) && !IS_CHROME && !IS_ANDROID && !IS_EDGE
export const IS_IPAD =
  /iPad/i.test(USER_AGENT) ||
  (IS_SAFARI && TOUCH_ENABLED && !/iPhone/i.test(USER_AGENT))
export const IS_IPHONE = /iPhone/i.test(USER_AGENT) && !IS_IPAD
export const IS_IOS = IS_IPHONE || IS_IPAD || IS_IPOD
export const IS_ANY_SAFARI = (IS_SAFARI || IS_IOS) && !IS_CHROME

export const EMAIL_REGEX = new RegExp(
  "([a-zA-Z0-9+._'-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9._-]+)",
  'gi'
)
export const INVALID_REGEX = new RegExp('[\\"(),:;<>\\[\\]\\/\\s]', 'g')
export const COMMENT_REGEX = new RegExp('<(.*?)>', 'g')
