import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { VirtuosoGrid } from 'react-virtuoso'
import styled from 'styled-components'

import {
  Album,
  ApiResponse,
  Community,
  MutationAlbumMediaAddRequest,
  MutationAlbumMediaRemoveRequest,
  MutationAlbumRenameRequest,
  MutationTeamUploadRequest,
  MutationUploadDeleteRequest,
  QueryAlbumMediaRequest,
  QueryAlbumRequest,
  QueryMediaRequest,
  QueryNumberOfAlbumsRequest,
  QueryNumberOfMediaRequest,
  QueryUploadUrlRequest,
  Upload,
  mutationAlbumDelete,
  mutationAlbumMediaAdd,
  mutationAlbumMediaRemove,
  mutationAlbumRename,
  mutationTeamUpload,
  mutationUploadDelete,
  queryAlbum,
  queryAlbumMedia,
  queryMedia,
  queryNumberOfAlbums,
  queryNumberOfMedia,
  queryUploadUrl,
} from '@sportsyou/api'
import {
  Button,
  EmptyState,
  Fab,
  Icon,
  RenameInput,
  Spinner,
  Tabs,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { capitalize, Colors, isImage, pluralize, sleep } from '@sportsyou/core'
import {
  ExtendedFile,
  ExtendedUpload,
  UploaderProps,
  useFetchApi,
  useMediaQuery,
  useUploader,
} from '@sportsyou/react-hooks'

import {
  MediaListItem,
  MediaListItemProps,
} from '../MediaListItem/MediaListItem'
import { fetchTeams, selectTeamById } from '../../../store/slices/TeamsSlice'
import { RootState } from '../../../store/rootReducer'
import { selectCurrentUser } from '../../../store/slices/UserSlice'
import { SMALL_SCREEN_BREAKPOINT } from '../../../constants'
import NewAlbumModal from './NewAlbumModal'
import UploadModal from './UploadModal'
import useFolders from '../../hooks/useFolders'
import useLightbox from '../Lightbox/useLightbox'

export type MediaListType = 'team' | 'user' | 'community'
export type MediaType = 'album' | 'photo' | 'video'
export interface MediaListProps {
  albumId?: string
  canEdit?: boolean
  community?: Community
  communityId?: string
  id?: string
  isCurrentUser?: boolean
  listType?: MediaListType
  mediaType?: MediaType
  onProfileMediaChange?: () => void
  teamId?: string
}

interface LocationState {
  fromAlbumDetail?: boolean
}

const tabsHeaderStyle = {
  border: '1px solid ' + Colors.ALTO,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
}

const tabsContentStyle = {
  padding: '10px 0 0',
}

const FIRST_PAGE = 1
const ITEMS_PER_PAGE = 24

export const MediaList: React.FC<MediaListProps> = (props: MediaListProps) => {
  const { downloadItem } = useFolders({})
  const { id, listType = 'user', teamId } = props
  const { sendBanner, sendConfirm } = useDialog()
  const {
    count: lightboxItemCount,
    index: lightboxCurrentIndex,
    setItems: setLightboxItems,
    showWithItems: showLightboxWithItems,
  } = useLightbox()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const uploader = useUploader.useUploader()

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT})`)

  const team = useSelector((state: RootState) =>
    selectTeamById(state, teamId ?? '')
  )
  const currentUser = useSelector(selectCurrentUser)
  const params = useParams()

  const [albums, setAlbums] = useState<Album[]>()
  const [countAlbums, setCountAlbums] = useState<number>(0)
  const [countImages, setCountImages] = useState<number>(0)
  const [countVideos, setCountVideos] = useState<number>(0)
  const [currentAlbum, setCurrentAlbum] = useState<Album>()
  const [currentAlbumItems, setCurrentAlbumItems] = useState<Upload[]>()
  const [currentMediaType, setCurrentMediaType] = useState<MediaType>(
    props.mediaType || 'photo'
  )
  const [currentPage, setCurrentPage] = useState<number>(FIRST_PAGE)
  const [images, setImages] = useState<Upload[]>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isNewAlbumModalVisible, setIsNewAlbumModalVisible] =
    useState<boolean>(false)
  const [isRenamingAlbum, setIsRenamingAlbum] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isUploadModalVisible, setIsUploadModalVisible] =
    useState<boolean>(false)
  const [ownerId] = useState(props.isCurrentUser ? undefined : id)
  const [uploads, setUploads] = useState<ExtendedUpload[]>([])
  const [videos, setVideos] = useState<Upload[]>()

  const uploadInputRef = useRef<HTMLInputElement>(null)

  const { fetch: addAlbumMedia } = useFetchApi(mutationAlbumMediaAdd)
  const { fetch: getAlbumCount } = useFetchApi(queryNumberOfAlbums)
  const { fetch: getAlbumMedia } = useFetchApi(queryAlbumMedia)
  const { fetch: getAlbums } = useFetchApi(queryAlbum)
  const { fetch: getMedia } = useFetchApi(queryMedia)
  const { fetch: getMediaCount } = useFetchApi(queryNumberOfMedia)
  const { fetch: removeAlbum } = useFetchApi(mutationAlbumDelete)
  const { fetch: removeAlbumMedia } = useFetchApi(mutationAlbumMediaRemove)
  const { fetch: removeMedia } = useFetchApi(mutationUploadDelete)
  const { fetch: renameAlbum } = useFetchApi(mutationAlbumRename)
  const { fetch: setMediaTeamMedia } = useFetchApi(mutationTeamUpload)
  const { fetch: getUploadUrl } = useFetchApi(queryUploadUrl)

  const isCurrentUser = useMemo(() => {
    let _isCurrentUser = false
    if (Object.keys(params).length === 0) {
      _isCurrentUser = true
    } else if (props.isCurrentUser) {
      _isCurrentUser = true
    } else if (currentUser.id === id) {
      _isCurrentUser = true
    }
    return _isCurrentUser
  }, [currentUser.id, id, params, props.isCurrentUser])

  const mediaPath = useCallback(
    (mediaType: MediaType, albumId?: string, profileId?: string): string => {
      const type = listType === 'team' ? 'teams' : 'profile'
      const root = props.community
        ? `/pages/${props.community.communityUrl}`
        : profileId
        ? `/${type}/${profileId}`
        : ''
      if (albumId) {
        return root + '/media/albums/' + albumId
      } else {
        return root + '/media/' + mediaType + 's'
      }
    },
    [listType, props.community]
  )

  const lightboxItems = useMemo(() => {
    if (currentAlbum) {
      return currentAlbumItems ?? []
    } else if (currentMediaType === 'video') {
      return videos ?? []
    }
    return images ?? []
  }, [currentAlbum, currentAlbumItems, currentMediaType, images, videos])

  const fetchAlbumData = useCallback(
    async (albumId: string, page: number = FIRST_PAGE) => {
      const { data } = await getAlbumMedia({
        id: albumId,
        page,
        perPage: ITEMS_PER_PAGE,
      } as QueryAlbumMediaRequest)
      return (data?.uploads as Upload[]) ?? []
    },
    [getAlbumMedia]
  )

  const loadMoreItems = useCallback(async () => {
    const _contentType: string = getApiContentType(currentMediaType)
    if (currentMediaType === 'album') {
      if (currentAlbum?.id) {
        const albumItems = await fetchAlbumData(
          currentAlbum?.id,
          currentPage + 1
        )
        if (albumItems?.length) {
          setCurrentPage(currentPage + 1)
          setCurrentAlbumItems((currentAlbumItems ?? []).concat(albumItems))
        }
      } else {
        const { data } = await getAlbums({
          ownerId,
          ownerType: listType,
          page: currentPage + 1,
          perPage: ITEMS_PER_PAGE,
        } as QueryAlbumRequest)
        if (data && data.length > 0) {
          setCurrentPage(currentPage + 1)
          setAlbums((albums ?? []).concat(data as Album[]))
        }
      }
    } else {
      const { data } = await getMedia({
        contentType: _contentType,
        ownerId,
        ownerType: listType,
        page: currentPage + 1,
        perPage: ITEMS_PER_PAGE,
      } as QueryMediaRequest)
      if (data && data.length > 0) {
        setCurrentPage(currentPage + 1)
        if (_contentType === 'image') {
          setImages((images ?? []).concat(data as Upload[]))
        } else if (_contentType === 'video') {
          setVideos((videos ?? []).concat(data as Upload[]))
        }
      }
    }
  }, [
    albums,
    currentAlbum?.id,
    currentAlbumItems,
    currentMediaType,
    currentPage,
    fetchAlbumData,
    getAlbums,
    getMedia,
    images,
    listType,
    ownerId,
    videos,
  ])

  const updateMediaCount = useCallback(
    async (contentType: string) => {
      if (!currentAlbum && contentType === 'album') {
        const { data } = await getAlbumCount({
          ownerId,
          ownerType: listType,
        } as QueryNumberOfAlbumsRequest)
        if (data) {
          setCountAlbums(data)
        }
      } else {
        const { data } = await getMediaCount({
          contentType: getApiContentType(contentType),
          ownerId,
          ownerType: listType,
        } as QueryNumberOfMediaRequest)
        if (data !== undefined) {
          if (contentType === 'image') {
            setCountImages(data)
          } else if (contentType === 'video') {
            setCountVideos(data)
          }
        }
      }
    },
    [getAlbumCount, getMediaCount, listType, ownerId]
  )

  const fetchData = useCallback(
    async (contentType?: MediaType) => {
      const _contentType: string = getApiContentType(
        contentType || currentMediaType
      )
      setIsLoading(true)
      setCurrentPage(FIRST_PAGE)
      updateMediaCount(_contentType)
      const getMediaParams: QueryMediaRequest = {
        ownerType: listType,
        ownerId,
        page: FIRST_PAGE,
        perPage: ITEMS_PER_PAGE,
        contentType: _contentType,
      }

      const getAlbumsParams: QueryAlbumRequest = {
        ownerId,
        ownerType: listType,
        page: FIRST_PAGE,
        perPage: ITEMS_PER_PAGE,
      }

      const { data } =
        _contentType === 'album'
          ? await getAlbums(getAlbumsParams)
          : await getMedia(getMediaParams)
      if (Array.isArray(data)) {
        if (_contentType === 'album') {
          setAlbums(data as Album[])
        } else if (_contentType === 'image') {
          setImages(data as Upload[])
        } else if (_contentType === 'video') {
          setVideos(data as Upload[])
        }
      }
      setIsLoading(false)
    },
    [currentMediaType, getAlbums, getMedia, listType, ownerId, updateMediaCount]
  )

  const updateUploads = useCallback(
    (newUploads?: ExtendedUpload[], failedUpload?: ExtendedUpload) => {
      setUploads((prevUploads) => {
        let _uploads: ExtendedUpload[] = [...(prevUploads ?? [])]
        if (newUploads && newUploads.length > 0) {
          newUploads.forEach((newUpload) => {
            const index = _uploads.findIndex(
              (_upload) => _upload.fileName === newUpload.fileName
            )
            if (index > -1) {
              _uploads[index] = {
                ..._uploads[index],
                id: newUpload.id,
                progress: newUpload.progress,
              }
            } else {
              _uploads.push(newUpload)
            }
          })
        }

        _uploads = _uploads.filter((upload) => {
          const uploadProgress = upload?.progress?.clientProgress ?? 0
          const isDone = uploadProgress >= 100
          return !isDone
        })

        if (failedUpload) {
          _uploads = _uploads.filter((up) => up.id !== failedUpload.id)
        }

        return _uploads
      })
    },
    [currentMediaType, fetchData]
  )

  const showAlbum = useCallback(
    async (albumId: string, album?: Album) => {
      navigate(mediaPath('album', albumId, id))
      setIsLoading(true)
      const albumItems = await fetchAlbumData(albumId)
      setCurrentAlbumItems(albumItems)
      if (!album) {
        album =
          ((
            await getAlbums({
              albumId,
              ownerId,
              ownerType: listType,
              page: FIRST_PAGE,
              perPage: ITEMS_PER_PAGE,
            } as QueryAlbumRequest)
          )?.data?.[0] as Album) ?? {}
      }
      setCurrentAlbum(album)
      setIsLoading(false)
    },
    [fetchAlbumData, getAlbums, id, listType, mediaPath, navigate, ownerId]
  )

  const showNewAlbumModal = useCallback(() => {
    setIsNewAlbumModalVisible(true)
  }, [])

  const hideNewAlbumModal = useCallback(() => {
    setIsNewAlbumModalVisible(false)
  }, [])

  const onCreateNewAlbum = useCallback(
    async (albumId: string) => {
      await showAlbum(albumId)
      fetchData()
      hideNewAlbumModal()
    },
    [fetchData, hideNewAlbumModal, showAlbum]
  )

  const onAlbumRenameClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    setIsRenamingAlbum(true)
  }, [])

  const onAlbumRenameCancel = useCallback(() => {
    setIsRenamingAlbum(false)
  }, [])

  const onCloseAlbum = useCallback(() => {
    onAlbumRenameCancel()
    setCurrentAlbum(undefined)
    navigate(mediaPath(currentMediaType, undefined, id), {
      state: { fromAlbumDetail: true },
    } as { state: LocationState })
  }, [currentMediaType, id, mediaPath, navigate, onAlbumRenameCancel])

  const onAlbumRenameSubmit = useCallback(
    async (name: string) => {
      setIsRenamingAlbum(false)
      if (currentAlbum) {
        const { data } = await renameAlbum({
          id: currentAlbum.id,
          albumName: name,
        } as MutationAlbumRenameRequest)
        if (data) {
          setCurrentAlbum({
            ...currentAlbum,
            name,
          })
        }
      }
    },
    [currentAlbum, renameAlbum]
  )

  const onClickAlbums = useCallback(() => {
    fetchData('album')
  }, [fetchData])

  const handleTabChange = useCallback(
    (index: number) => {
      if (index === 0) {
        setCurrentMediaType('photo')
        navigate(mediaPath('photo', undefined, id))
      } else if (index === 1) {
        setCurrentMediaType('video')
        navigate(mediaPath('video', undefined, id))
      } else if (index === 2) {
        setCurrentMediaType('album')
        navigate(mediaPath('album', undefined, id))
      }
    },
    [id, mediaPath, navigate]
  )

  const onClickItem = useCallback(
    (item: MediaListItemProps) => {
      if (item.isAlbum) {
        showAlbum(item.item.id, item.item)
      } else {
        showLightboxWithItems({
          items: lightboxItems,
          index: item.index,
        })
      }
    },
    [showAlbum, showLightboxWithItems, lightboxItems]
  )

  const onDelete = useCallback(
    async (item: MediaListItemProps) => {
      let confirmMessage = `Are you sure you want to delete ${
        item.item.name ?? 'this'
      }? Deleting this file will also delete it from any posts, chats or folders it has been shared to.`

      if (currentAlbum) {
        if (props.teamId) {
          if (item.item.profileType === 'profileImage') {
            confirmMessage = `This will delete ${
              team?.name ?? ''
            }'s profile image. Are you sure you want to delete this item?`
          } else if (item.item.profileType === 'coverImage') {
            confirmMessage = `This will delete ${
              team?.name ?? ''
            }'s cover image. Are you sure you want to delete this item?`
          }
        } else {
          if (item.item.profileType === 'profileImage') {
            confirmMessage = `This will delete **your profile image**. Are you sure you want to delete this item?`
          } else if (item.item.profileType === 'coverImage') {
            confirmMessage = `This will delete **your cover image**. Are you sure you want to delete this item?`
          }
        }
      }

      setIsLoading(true)
      sendConfirm({
        confirmText: 'Delete',
        disableBackdropClick: true,
        isDestructive: true,
        onClose: () => setIsLoading(false),
        onConfirm: async () => {
          let res: ApiResponse
          let error: string | object | undefined = undefined

          try {
            if (item.isAlbum) {
              res = await removeAlbum({
                id: item.item.id,
              } as MutationAlbumRenameRequest)
            } else {
              if (item.isInAlbum) {
                // removing an album item
                if (currentAlbum?.isProfileAlbum) {
                  // removing an item from profile albums
                  res = await removeMedia({
                    id: item.item.id,
                  } as MutationUploadDeleteRequest)

                  props.onProfileMediaChange?.()
                } else if (currentAlbum?.isPrivateAlbum) {
                  // removing an item from private albums
                  res = await removeMedia({
                    id: item.item.id,
                  } as MutationUploadDeleteRequest)
                } else {
                  // removing an item from regular albums
                  res = await removeAlbumMedia({
                    id: props.albumId,
                    uploads: [item.item.id],
                  } as MutationAlbumMediaRemoveRequest)
                }
              } else {
                // removing media from outside of album detail view
                res = await removeMedia({
                  id: item.item.id,
                } as MutationUploadDeleteRequest)
              }
            }

            dispatch(fetchTeams(true))
          } catch (err: any) {
            res = { ok: false }
            error = err
          }

          const { data, ok } = res
          error ??= res.error

          if (data && ok) {
            sendBanner({
              autoDismiss: true,
              status: 'success',
              message: `Deleted ${item.item.name ?? 'item'}`,
            })

            if (item.isInAlbum) {
              const albumItems = await fetchAlbumData(
                currentAlbum?.id as string
              )
              if (albumItems) {
                setCurrentAlbumItems(albumItems)
                setCurrentAlbum({
                  ...currentAlbum,
                  mediaCount: albumItems.length,
                })
              }
            }
            fetchData()
          }

          if (error) {
            console.log({ error })
          }
        },
        message: confirmMessage,
        useMarkdown: true,
      })
    },
    [
      currentAlbum?.id,
      fetchAlbumData,
      fetchData,
      removeAlbum,
      removeAlbumMedia,
      removeMedia,
      sendBanner,
      sendConfirm,
    ]
  )

  const onDownload = useCallback(
    async (item: MediaListItemProps) => {
      downloadItem(item.item)
    },
    [downloadItem]
  )

  /**
   * Show a temporary item in the list after upload but before the data refreshes
   */
  const addTempItemAfterUpload = useCallback(
    async (upload: ExtendedUpload) => {
      let tempItem: Upload = {
        contentType: upload.contentType,
        fileName: upload.fileName,
        id: upload.id,
        viewUrl: upload.viewUrl,
      }

      try {
        const { data: _upload, ok } = await getUploadUrl({
          uploadId: upload.id,
        } as QueryUploadUrlRequest)
        if (ok && _upload) {
          tempItem = structuredClone(_upload)
        }
      } catch (error) {
        console.log({ error })
      }

      if (currentAlbum) {
        setCurrentAlbumItems((albumMedia) => [tempItem, ...(albumMedia ?? [])])
      } else {
        if (currentMediaType === 'photo') {
          setImages((images) => [tempItem, ...(images ?? [])])
        } else if (currentMediaType === 'video') {
          setVideos((videos) => [tempItem, ...(videos ?? [])])
        }
      }
    },
    [currentAlbum, currentMediaType, images, updateUploads, videos]
  )

  async function setMediaAsTeamMedia(uploadId: string) {
    if (props.teamId) {
      try {
        const res = await setMediaTeamMedia({
          id: props.teamId,
          uploads: [uploadId],
        } as MutationTeamUploadRequest)
        if (!res.ok) {
          console.error(
            'Fail to set upload as team upload',
            res.errors ?? res.error
          )
        }
      } catch (error) {
        console.error('Fail to set upload as team upload', error)
      }
    }
  }

  const onUploaderUploadStart = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      updateUploads(_uploads)
    },
    [updateUploads]
  )

  const onUploaderUploadProgress = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      updateUploads([currentUpload])
    },
    [updateUploads]
  )

  const onUploaderUploadDone = useCallback(
    async (
      _uploads: ExtendedUpload[],
      currentUpload: ExtendedUpload
    ): Promise<void> => {
      updateUploads(undefined, currentUpload)
      addTempItemAfterUpload(currentUpload)
      props.teamId && setMediaAsTeamMedia(currentUpload.id as string)
    },
    [
      addTempItemAfterUpload,
      fetchData,
      props.teamId,
      setMediaAsTeamMedia,
      updateUploads,
    ]
  )

  const onUploaderComplete = useCallback(
    async (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      const uploadsSucceeded = _uploads.filter(
        (up) => !(up.progress?.isCancelled || up.progress?.isError)
      )
      if (currentAlbum && uploadsSucceeded.length) {
        /**
         * add a delay to make sure uploads are converted to
         * team uploads at onUploaderUploadDone()
         */
        if (props.teamId) await sleep(1_000)
        await addAlbumMedia({
          id: currentAlbum.id,
          uploads: uploadsSucceeded.map((up) => up.id),
        } as MutationAlbumMediaAddRequest)
        const albumItems = await fetchAlbumData(currentAlbum.id as string)
        setCurrentAlbumItems(albumItems)
        setCurrentAlbum({ ...currentAlbum, mediaCount: albumItems.length })
        setUploads([])
      } else {
        await sleep(1_000)
        fetchData()
      }
    },
    [addAlbumMedia, currentAlbum, fetchAlbumData, fetchData, teamId]
  )

  const onUploaderCancel = useCallback(
    (_uploads: ExtendedUpload[], cancelledUpload: ExtendedUpload): void => {
      updateUploads(undefined, cancelledUpload)
      removeMedia({ id: cancelledUpload.id })
    },
    [updateUploads]
  )

  const onUploaderError = useCallback(
    async (
      _uploads: ExtendedUpload[],
      cancelledUpload: ExtendedUpload
    ): Promise<void> => {
      const { progress } = cancelledUpload
      const failedStep = progress?.clientProgress !== 100 ? 'upload' : 'process'
      updateUploads(undefined, cancelledUpload)
      await removeMedia({ id: cancelledUpload.id })
      sendBanner({
        autoDismiss: false,
        status: 'alert',
        message: `Item "${
          cancelledUpload.fileName ?? ''
        }" failed to ${failedStep}.`,
      })
    },
    [updateUploads]
  )

  const upload = useCallback(
    async (files: ExtendedFile[]): Promise<void> => {
      uploader.reset()
      const uploadParams: UploaderProps = {
        enableChunk: false,
        files,
        onCancel: onUploaderCancel,
        onComplete: onUploaderComplete,
        onError: onUploaderError,
        onProgress: onUploaderUploadProgress,
        onUploadDone: onUploaderUploadDone,
        onUploadStart: onUploaderUploadStart,
        shouldCreateHLS: true,
        uploadType: 'media',
      }
      uploader.create(uploadParams)
      const uploadPreviews = files.map((file) => ({
        contentType: file.type,
        fileName: file.name,
        viewUrl: /^image\/(jp?eg|png|gif|webp)/.test(file.type ?? '')
          ? window.URL.createObjectURL(file as Blob)
          : undefined,
        file,
        progress: {
          percentDone: 0,
          uploadProgress: 0,
        },
        uploaderProp: uploadParams,
      }))
      setUploads(uploadPreviews)
    },
    [
      onUploaderComplete,
      onUploaderCancel,
      onUploaderError,
      onUploaderUploadDone,
      onUploaderUploadProgress,
      onUploaderUploadStart,
      uploader,
    ]
  )

  function getApiContentType(contentType: string): string {
    let _contentType = contentType

    if (_contentType.startsWith('photo')) {
      _contentType = 'image'
    } else if (_contentType.startsWith('video')) {
      _contentType = 'video'
    }

    return _contentType
  }

  // check if album should be displayed
  useEffect(() => {
    const albumId = params.albumId ?? props.albumId
    albumId && showAlbum(albumId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const state = location.state as LocationState
    if (state?.fromAlbumDetail) {
      fetchData()
    }
  }, [location])

  useEffect(() => {
    // load more items if the user reached the end of the lightbox
    if (lightboxCurrentIndex === lightboxItems.length - 1) {
      loadMoreItems()
    }
    // update the lightbox if more items have loaded
    if (lightboxItems.length !== lightboxItemCount) {
      setLightboxItems(lightboxItems)
    }
  }, [
    lightboxCurrentIndex,
    lightboxItemCount,
    lightboxItems,
    loadMoreItems,
    setLightboxItems,
  ])

  useEffect(() => {
    fetchData(currentMediaType)
  }, [fetchData, currentMediaType])

  useEffect(() => {
    setIsUploading(uploads.length > 0)
  }, [uploads])

  useEffect(() => {
    props.mediaType && setCurrentMediaType(props.mediaType)
  }, [props.mediaType])

  const renderState = useCallback(() => {
    if (isLoading) return null
    return isCurrentUser ? (
      <EmptyState
        icon='MediaES'
        header='Manage Your Media'
        description='Click Upload Media to add photos and videos.'
      />
    ) : (
      <p>No {capitalize(currentMediaType)}s</p>
    )
  }, [currentMediaType, isLoading, isCurrentUser])

  const renderUploader = (showUI = false) => {
    if (props.community) return null
    return (
      <>
        {props.canEdit ? (
          <>
            {showUI && (
              <UploadButton
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setIsUploadModalVisible(true)
                }}
                disabled={isUploading}
              >
                <Icon name='Plus' width={12} style={{ marginRight: 10 }} />{' '}
                Upload Media
              </UploadButton>
            )}

            <input
              accept={'image/*,video/*'}
              disabled={isUploading}
              hidden
              multiple
              onChange={(e) => {
                if (e.target.files) {
                  upload(Array.from(e.target.files))
                }
                e.target.value = ''
              }}
              ref={uploadInputRef}
              title={'Upload Media'}
              type={'file'}
            />
          </>
        ) : null}
      </>
    )
  }

  const renderSpinner = () => {
    return (
      <>
        {isUploading && (
          <UploadingText>Uploading ({uploads.length})</UploadingText>
        )}
        {(isLoading || isUploading) && <Spinner />}
      </>
    )
  }

  const renderRow = useCallback(
    (index: number) => {
      // Render upload previews inline
      if (uploads.length > 0 && index < uploads.length) {
        return (
          <ItemWrapper>
            <MediaListItem
              index={index}
              isUploading
              item={uploads[index]}
              key={uploads[index].id}
              type={isImage(uploads[index].contentType!) ? 'image' : 'video'}
            />
          </ItemWrapper>
        )
      } else if (uploads.length > 0 && index >= uploads.length) {
        index = index - uploads.length
      }

      if (currentMediaType === 'photo') {
        return (
          <ItemWrapper>
            <MediaListItem
              canEdit={props.canEdit}
              index={index}
              item={images?.[index]}
              key={images?.[index].id}
              onClick={onClickItem}
              onDelete={onDelete}
              onDownload={onDownload}
              type={'image'}
            />
          </ItemWrapper>
        )
      } else if (currentMediaType === 'video') {
        return (
          <ItemWrapper>
            <MediaListItem
              canEdit={props.canEdit}
              index={index}
              item={videos?.[index]}
              key={videos?.[index].id}
              onClick={onClickItem}
              onDelete={onDelete}
              onDownload={onDownload}
              type={'video'}
            />
          </ItemWrapper>
        )
      } else if (currentMediaType === 'album') {
        return (
          <ItemWrapper>
            <MediaListItem
              canEdit={props.canEdit}
              index={index}
              isAlbum
              item={albums?.[index]}
              key={albums?.[index].id}
              onClick={onClickItem}
              onDelete={onDelete}
              onDownload={onDownload}
              type={'album'}
            />
          </ItemWrapper>
        )
      }
    },
    [
      albums,
      currentMediaType,
      images,
      onClickItem,
      onDelete,
      onDownload,
      props.canEdit,
      uploads,
      videos,
    ]
  )

  const renderAlbumItemRow = useCallback(
    (index: number) => {
      const item = currentAlbumItems?.[index]
      return (
        <ItemWrapper>
          <MediaListItem
            canEdit={props.canEdit}
            index={index}
            isInAlbum
            item={item}
            key={item?.id}
            onClick={onClickItem}
            onDelete={onDelete}
            onDownload={onDownload}
            type={isImage(item?.contentType as string) ? 'image' : 'video'}
          />
        </ItemWrapper>
      )
    },
    [currentAlbumItems, onClickItem, onDelete, onDownload, props.canEdit]
  )

  const renderMediaCount = useMemo(() => {
    if (currentMediaType === 'photo' && countImages > 0) {
      return (
        <Count>
          {countImages} {capitalize(pluralize(countImages, currentMediaType))}
        </Count>
      )
    } else if (currentMediaType === 'video' && countVideos > 0) {
      return (
        <Count>
          {countVideos} {capitalize(pluralize(countVideos, currentMediaType))}
        </Count>
      )
    } else if (currentMediaType === 'album' && countAlbums > 0) {
      return (
        <Count>
          {countAlbums} {capitalize(pluralize(countAlbums, currentMediaType))}
        </Count>
      )
    }
    return null
  }, [countAlbums, countImages, countVideos, currentMediaType])

  const renderUploadModal = () => {
    if (isUploadModalVisible) {
      return (
        <UploadModal
          isVisible
          onClick={() => {
            uploadInputRef.current?.click()
            setIsUploadModalVisible(false)
          }}
          onClose={() => {
            setIsUploadModalVisible(false)
          }}
          onDrop={(files) => {
            upload(files)
            setIsUploadModalVisible(false)
          }}
        />
      )
    }
    return null
  }

  if (currentAlbum) {
    return (
      <Container>
        {renderUploadModal()}
        <AlbumHeader>
          <span>
            <BackButton
              appearance='minimal'
              collapse
              disabled={isUploading}
              onClick={onCloseAlbum}
              title='Back'
            >
              <Icon name='ChevronLeft' />
            </BackButton>
            {!currentAlbum.isPrivateAlbum && props.canEdit ? (
              <AlbumName>
                {isRenamingAlbum ? (
                  <RenameInput
                    onCancel={onAlbumRenameCancel}
                    onSubmit={onAlbumRenameSubmit}
                    value={currentAlbum.name ?? ''}
                  />
                ) : (
                  <>
                    {currentAlbum.name || 'Untitled'}{' '}
                    <EditAlbumNameButton onClick={onAlbumRenameClick}>
                      <Icon name='Pencil' width={12} />
                    </EditAlbumNameButton>
                  </>
                )}
              </AlbumName>
            ) : (
              <AlbumName>{currentAlbum.name || 'Untitled'}</AlbumName>
            )}
          </span>

          <TabsExtraContent>
            {renderSpinner()}
            <Count>
              {currentAlbum?.mediaCount ?? 0}{' '}
              {capitalize(
                pluralize(currentAlbum?.mediaCount ?? 0, 'Item', 'Items')
              )}
            </Count>
            {renderUploader(!isSmallScreen)}
          </TabsExtraContent>
        </AlbumHeader>
        <VirtuosoGrid
          components={{
            Item: ItemContainer,
            List: ListContainer,
            ScrollSeekPlaceholder: ({ height, width, index }) => (
              <MediaListItem />
            ),
          }}
          endReached={loadMoreItems}
          itemContent={renderAlbumItemRow}
          overscan={100}
          totalCount={(currentAlbumItems?.length ?? 0) + uploads.length}
          useWindowScroll
        />
        {isSmallScreen && (
          <Fab
            onClick={() => {
              uploadInputRef.current?.click()
            }}
          />
        )}
      </Container>
    )
  }

  return (
    <Container>
      {renderUploadModal()}
      <Tabs
        buttonStyle={{ minWidth: isSmallScreen ? 66 : 90 }}
        contentStyle={tabsContentStyle}
        headerStyle={tabsHeaderStyle}
        onChange={handleTabChange}
        index={
          currentMediaType === 'photo'
            ? 0
            : currentMediaType === 'video'
            ? 1
            : 2
        }
      >
        <Tabs.Item title='Photos'>
          {currentMediaType === 'photo' &&
          !!((images?.length ?? 0) + uploads.length) ? (
            <VirtuosoGrid
              components={{
                Item: ItemContainer,
                List: ListContainer,
                ScrollSeekPlaceholder: ({ height, width, index }) => (
                  <MediaListItem />
                ),
              }}
              endReached={loadMoreItems}
              itemContent={renderRow}
              overscan={100}
              totalCount={(images?.length ?? 0) + uploads.length}
              useWindowScroll
            />
          ) : (
            renderState()
          )}
        </Tabs.Item>
        <Tabs.Item title='Videos'>
          {currentMediaType === 'video' &&
          !!((videos?.length ?? 0) + uploads.length) ? (
            <VirtuosoGrid
              components={{
                Item: ItemContainer,
                List: ListContainer,
                ScrollSeekPlaceholder: ({ height, width, index }) => (
                  <MediaListItem />
                ),
              }}
              endReached={loadMoreItems}
              itemContent={renderRow}
              overscan={100}
              totalCount={(videos?.length ?? 0) + uploads.length}
              useWindowScroll
            />
          ) : (
            renderState()
          )}
        </Tabs.Item>
        <Tabs.Item onClick={onClickAlbums} title='Albums'>
          {currentMediaType === 'album' && !!albums?.length ? (
            <VirtuosoGrid
              components={{
                Item: ItemContainer,
                List: ListContainer,
                ScrollSeekPlaceholder: ({ height, width, index }) => (
                  <MediaListItem />
                ),
              }}
              endReached={loadMoreItems}
              itemContent={renderRow}
              overscan={100}
              totalCount={albums.length}
              useWindowScroll
            />
          ) : (
            renderState()
          )}
          <NewAlbumModal
            isOpen={isNewAlbumModalVisible}
            onClose={hideNewAlbumModal}
            onCreate={onCreateNewAlbum}
            teamId={teamId}
          />
        </Tabs.Item>
        <Tabs.ExtraContent>
          <TabsExtraContent>
            {renderSpinner()}
            {renderMediaCount}
            {props.canEdit ? (
              <>
                {currentMediaType === 'album'
                  ? !isSmallScreen && (
                      <Button onClick={showNewAlbumModal}>New Album</Button>
                    )
                  : renderUploader(!isSmallScreen)}
              </>
            ) : null}
          </TabsExtraContent>
        </Tabs.ExtraContent>
      </Tabs>
      {props.canEdit && isSmallScreen ? (
        <Fab
          onClick={() => {
            props.canEdit && currentMediaType === 'album'
              ? showNewAlbumModal()
              : uploadInputRef.current?.click()
          }}
        />
      ) : null}
    </Container>
  )
}

export default MediaList

const Container = styled.div`
  background-color: transparent;
  border-radius: 8px;
  height: 100%;
  position: relative;
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media all and (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const TabsExtraContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`

const Count = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`

const AlbumHeader = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 4px 0;
`

const AlbumName = styled.span`
  margin-left: 10px;
  font-weight: bold;
`

const EditAlbumNameButton = styled.span`
  cursor: pointer;
  margin-left: 10px;
  color: ${Colors.DUSTY_GRAY};
`

const UploadingText = styled.span`
  margin-right: 10px;
  color: ${Colors.DUSTY_GRAY};
`

const BackButton = styled(Button)`
  margin-left: 10px;
`

const ItemContainer = styled.div`
  align-content: stretch;
  box-sizing: border-box;
  display: flex;
  flex: none;
  width: 25%;

  @media (max-width: 1024px) {
    width: 33%;
  }

  @media (max-width: 500px) {
    width: 50%;
  }
`

const ItemWrapper = styled.div`
  flex: 1;
  white-space: nowrap;
  margin-bottom: 10px;
  margin-right: 10px;
`

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const UploadButton = styled(Button)`
  height: 20px;
  margin-left: 10px;
  min-height: 30px !important;
  padding: 0 10px;

  input {
    display: none;
  }
`
