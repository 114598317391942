import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  File,
  Folder,
  Upload,
  mutationFolderCreate,
  mutationFolderDelete,
  mutationFolderUpdate,
  queryFolders,
  queryUploadUrl,
} from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

import { add, update, remove, clear } from 'web/store/slices/FoldersSlice'
import { RootState } from 'web/store/rootReducer'

interface UseFoldersProps {
  parentFolderId?: string
}
interface UseFoldersReturn {
  allFolders: Folder[]
  children: Folder[]
  createFolderItem: (folder: Folder) => Promise<string | undefined>
  deleteFolderItem: (folder: Folder) => Promise<boolean>
  downloadItem: (upload: Upload | File, filename?: string) => void
  fetchFolders: (folderId: string) => void
  getChildren: (folderId: string) => Folder[]
  getFolder: (folderId: string) => Folder | undefined
  getRootFolder: () => Promise<Folder | undefined>
  getUpload: (uploadId: string) => Promise<Upload | undefined>
  move: (folderId: string, newParentId: string) => Promise<boolean>
  rename: (folder: Folder, newName: string) => void
  updateFolderItem: (folder: Folder) => void
}

export default function useFolders(props: UseFoldersProps): UseFoldersReturn {
  const { parentFolderId } = props
  const dispatch = useDispatch()

  const { fetch: createFolderItemRequest } = useFetchApi(mutationFolderCreate)
  const { fetch: deleteFolderItemRequest } = useFetchApi(mutationFolderDelete)
  const { fetch: fetchFoldersRequest } = useFetchApi(queryFolders)
  const { fetch: fetchFolderUploadRequest } = useFetchApi(queryUploadUrl)
  const { fetch: updateFolderItemRequest } = useFetchApi(mutationFolderUpdate)

  const foldersSelector = useSelector((state: RootState) => state.folders)

  const sortFolders = (folders?: Folder[]) => {
    const sortedFolders = [...(folders ?? [])]
    // sort by name
    sortedFolders.sort((a, b) => {
      const aName = a?.folderName?.toLowerCase() ?? ''
      const bName = b?.folderName?.toLowerCase() ?? ''
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    })
    // sort by type
    sortedFolders.sort((a, b) => {
      if (a.type === 'folder' && b.type !== 'folder') {
        return -1
      }
      if (a.type !== 'folder' && b.type === 'folder') {
        return 1
      }
      return 0
    })
    return sortedFolders
  }

  const children = useMemo(() => {
    return sortFolders(
      foldersSelector?.folders?.filter(
        (folder) => parentFolderId === folder.parentId
      ) ?? []
    )
  }, [foldersSelector?.folders, parentFolderId])

  const getChildren = useCallback(
    (folderId: string) => {
      return sortFolders(
        foldersSelector?.folders?.filter((f) => folderId === f.parentId) ?? []
      )
    },
    [foldersSelector?.folders]
  )

  const getParent = useCallback(
    (folder: Folder) => {
      return foldersSelector?.folders?.find((f) => folder.parentId === f.id)
    },
    [foldersSelector?.folders]
  )

  const getRootFolder = useCallback(async () => {
    const { data } = await fetchFoldersRequest({ parentFolderId: '' })
    if (data?.length) {
      dispatch(add(data))
    }
    return data?.[0]
  }, [dispatch, fetchFoldersRequest])

  const getFolder = useCallback(
    (folderId: string) => {
      return foldersSelector?.folders?.filter((f) => folderId === f.id)?.[0]
    },
    [foldersSelector]
  )

  const getUpload = useCallback(
    async (uploadId: string) => {
      const { data } = await fetchFolderUploadRequest({ uploadId })
      return data
    },
    [fetchFolderUploadRequest]
  )

  const fetchFolders = useCallback(
    async (parentFolderId: string) => {
      if (parentFolderId === '-1') {
        parentFolderId = (await getRootFolder())?.id ?? ''
      }
      const { data } = await fetchFoldersRequest({ parentFolderId })
      if (data?.length) {
        dispatch(add(data))
      }
    },
    [dispatch, fetchFoldersRequest, getRootFolder]
  )

  const updateSavedFolder = useCallback(
    (folder: Folder) => {
      const parentFolder = getParent(folder)
      console.log('updateSavedFolder', { folder, parentFolder })
      if (
        parentFolder?.isIncomingFolder &&
        (parentFolder?.childCount ?? 0) <= 1
      ) {
        dispatch(clear())
        fetchFolders(parentFolder.parentId!)
      }
    },
    [dispatch, fetchFolders, getParent]
  )

  const createFolderItem = useCallback(
    async (folder: Folder) => {
      const { data: folderId } = await createFolderItemRequest({
        folderName: folder.folderName ?? 'New Folder',
        parentFolderId: folder.parentId,
        uploadId: folder.uploadId,
      })
      if (folderId) {
        const { data } = await fetchFoldersRequest({ folderId })
        if (data?.length) {
          dispatch(add(data))
        }
      }
      return folderId
    },
    [createFolderItemRequest, dispatch, fetchFoldersRequest]
  )

  const deleteFolderItem = useCallback(
    async (folder: Folder) => {
      const { ok } = await deleteFolderItemRequest({ folderId: folder.id })
      if (ok) {
        dispatch(remove([folder]))
        updateSavedFolder(folder)
      }
      return ok
    },
    [deleteFolderItemRequest, dispatch, updateSavedFolder]
  )

  const updateFolderItem = useCallback(
    async (folder: Folder) => {
      const { ok } = await updateFolderItemRequest({
        folderId: folder.id,
        name: folder.folderName,
        parentId: folder.parentId,
      })
      if (ok) {
        dispatch(update([folder]))
      }
      return ok
    },
    [updateFolderItemRequest, dispatch]
  )

  const rename = useCallback(
    async (folder: Folder, newName: string) => {
      return updateFolderItem({ ...folder, folderName: newName })
    },
    [updateFolderItem]
  )

  const move = useCallback(
    async (folderId: string, parentId: string) => {
      return updateFolderItem({ id: folderId, parentId })
    },
    [updateFolderItem]
  )

  const downloadItem = useCallback(
    async (upload: Upload | File, filename?: string) => {
      const fileName = filename ?? upload.fileName ?? ''
      const url = `${upload.viewUrl}?response-content-disposition=${encodeURI(
        `attachment; filename=${fileName}`
      )}`
      const anchor = document.createElement('a')
      anchor.href = url
      // anchor.target = '_blank'
      anchor.download = fileName
      anchor.click()
    },
    []
  )

  return {
    allFolders: foldersSelector?.folders ?? [],
    children,
    createFolderItem,
    deleteFolderItem,
    downloadItem,
    fetchFolders,
    getChildren,
    getFolder,
    getRootFolder,
    getUpload,
    move,
    rename,
    updateFolderItem,
  }
}
