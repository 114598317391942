import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Chat, ChatParticipant, Profile } from '@sportsyou/api'
import { Colors, getProfileImage } from '@sportsyou/core'
import { Avatar, Modal, Tabs } from '@sportsyou/react-dom-ui'

interface ViewedByModalProps {
  chat: Chat
  isOpen: boolean
  notViewedBy?: ChatParticipant[]
  onClose: () => void
  viewedBy?: ChatParticipant[]
}

export default function ViewedByModal(props: ViewedByModalProps) {
  const navigate = useNavigate()

  const onClose = useCallback(() => {
    props.onClose()
  }, [props])

  const onClickUser = useCallback(
    (user?: ChatParticipant) => {
      navigate(`/profile/${user?.id}`)
      onClose()
    },
    [navigate, onClose]
  )

  const renderItem = (user: ChatParticipant) => {
    if (!user) {
      return null
    }
    const avatarImage = getProfileImage.getProfileAvatarImageUrl(
      user as Profile
    )
    const fullName = user.fullName || `${user.firstName} ${user.lastName}`
    return (
      <Row key={user.id} onClick={() => onClickUser(user)}>
        <UserContainer>
          <Avatar name={fullName} uri={avatarImage} diameter={40} />
          <UserName>{fullName}</UserName>
        </UserContainer>
      </Row>
    )
  }

  return (
    <Modal onClose={onClose} visible={props.isOpen}>
      <Modal.Header>
        <b>Viewed</b>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <MainContainer>
          <Tabs contentStyle={{ padding: 0 }}>
            <Tabs.Item title='Viewed'>
              {props.viewedBy?.map(renderItem)}
            </Tabs.Item>
            <Tabs.Item title='Not Viewed'>
              {props.notViewedBy?.map(renderItem)}
            </Tabs.Item>
          </Tabs>
        </MainContainer>
      </Modal.Body>
    </Modal>
  )
}

const MainContainer = styled.div`
  height: 300px;
  max-height: 300px;
  overflow-y: scroll;
`

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  border-bottom-color: ${Colors.MYSTIC};
  padding: 10px;

  border-bottom-width: 1px;
  width: 100%;

  &:hover {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`

const UserContainer = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
`

const UserName = styled.div`
  color: ${Colors.MINE_SHAFT};
  margin-left: 10px;
  margin-right: 10px;
`
