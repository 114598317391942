import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import styled, { css } from 'styled-components'

import { IconNames } from '@sportsyou/react-icons'

import { User as UserProps } from '@sportsyou/api'
import { Colors, randomInRange, sleep } from '@sportsyou/core'
import { Avatar, Badge, Icon } from '@sportsyou/react-dom-ui'

import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'

import {
  subscriptionUserTeamPreferencesChanged,
  SubscriptionUserTeamPreferencesChangedResponse,
  SubscriptionClient as subscriptions,
} from '@sportsyou/subscription'

import { Observable } from 'web/utils/Observable'

import { selectCurrentUser } from 'web/store/slices/UserSlice'
import { selectUnreadChats } from 'web/store/slices/ChatUnreadCountSlice'
import {
  fetchTeamsWithSort,
  selectAllTeamsAndGroups,
  setSortPreference,
} from 'web/store/slices/TeamsSlice'
import {
  fetchNewCommunityPosts,
  selectHasNewPosts,
  selectPages,
} from 'web/store/slices/CommunitiesSlice'

import { useAppFeatures } from 'web/hooks'
import { WEB_SOCKET } from 'web/constants'
import { RootState } from 'web/store/rootReducer'

export interface SideNavigationProps {
  onClickItem?: () => void
  user?: UserProps
}

const CustomSideNavItem = ({
  as,
  children,
  shouldMatchEntireUrl = true,
  to,
  ...props
}: {
  as?: string
  children: React.ReactNode
  shouldMatchEntireUrl?: boolean
  to: string
  title?: string
}) => {
  let resolved = useResolvedPath(to)
  let match = useMatch({
    path: resolved.pathname,
    end: shouldMatchEntireUrl,
  })

  if (as === 'button') {
    return (
      <StyledButton $active={!!match} {...props}>
        {children}
      </StyledButton>
    )
  }

  return (
    <StyledNavLink $active={!!match} to={to} {...props}>
      {children}
    </StyledNavLink>
  )
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  onClickItem,
  user,
}: SideNavigationProps) => {
  const dispatch = useDispatch()

  const { pages = {} } = useAppFeatures().features

  const hasNewCommunityPosts = useSelector(selectHasNewPosts)
  const unreadChats = useSelector(selectUnreadChats)
  const currentUser = useSelector(selectCurrentUser)
  const communities = useSelector(selectPages)
  const { allTeamsAndGroups } = useSelector(
    (state: RootState) => state.teams,
    isEqual
  )

  const numberOfTeams = useRef(0)

  useEffect(() => {
    dispatch(fetchNewCommunityPosts())
  }, [])

  useEffect(() => {
    numberOfTeams.current = allTeamsAndGroups.length
  }, [allTeamsAndGroups])

  useEffect(() => {
    let numTries = 0
    async function checkConnectedAndStart() {
      numTries++
      if (subscriptions.isConnected()) {
        await sleep(16)
        return
      } else {
        if (numTries > 2) {
          subscriptions.connect(undefined, async () => {
            await sleep(33)
            checkConnectedAndStart()
          })
        }
        setTimeout(checkConnectedAndStart, randomInRange(1_500, 4_000))
      }
    }

    async function sub() {
      const teamSortSubResult = await subscriptionUserTeamPreferencesChanged()
      const teamSortSubData =
        teamSortSubResult.data as Observable<SubscriptionUserTeamPreferencesChangedResponse>

      teamSortSubResult.error.addListener((err) => {
        subscriptions.unsubscribe(err.watchId)
      })

      teamSortSubData.addListener(async (subValue) => {
        console.log({ subValue, curTGLen: numberOfTeams.current })
        const { data: userTeamPrefs } = subValue
        if (
          userTeamPrefs.userId === currentUser.id &&
          typeof userTeamPrefs.teamIds?.length === 'number'
        ) {
          if (userTeamPrefs.teamIds?.length === numberOfTeams.current) {
            dispatch(setSortPreference(userTeamPrefs.teamIds as string[]))
          } else {
            dispatch(fetchTeamsWithSort())
          }
        }
      })
    }

    async function init() {
      await sleep(WEB_SOCKET.delay.subscription.teamSort)
      await checkConnectedAndStart()
      await sub()
    }

    init()

    return () => {
      // subscriptions.disconnect()
    }
  }, [subscriptions])

  type NavLink = {
    title: string
    badge?: number
    to: string
    avatarUrl?: string
    iconComponent?: React.ReactNode
    iconName?: IconNames
  }

  const navigationLinks = useMemo(() => {
    const links: NavLink[] = [
      {
        iconName: 'HomeAlt',
        // onClick has been removed on same domain links as it was interfering with modifier-click on browser
        title: 'Home',
        to: '/home',
      },
      {
        avatarUrl: user?.profileImage?.[0]?.viewUrl ?? '',
        title: 'Profile',
        to: '/profile',
      },
      {
        badge: unreadChats?.length,
        iconName: 'BubbleAlt',
        title: 'Chat',
        to: '/chat',
      },
      {
        iconName: 'CalendarAlt',
        title: 'Calendar',
        to: '/calendar',
      },
      {
        iconName: 'MediaAlt',
        title: 'Media',
        to: '/media',
      },
      {
        iconName: 'FolderAlt',
        title: 'Folders',
        to: '/folders',
      },
      {
        iconName: 'SearchAlt',
        title: 'Search Teammates',
        to: '/contacts',
      },
    ]

    // Add community pages link
    if (communities.length > 0 && pages) {
      if (pages?.isOn) {
        links.splice(1, 0, {
          badge: hasNewCommunityPosts ? 1 : 0,
          iconName: 'Pages',
          title: 'Pages',
          to: '/pages',
        })
      }
    }
    return links
  }, [
    hasNewCommunityPosts,
    user?.profileImage,
    unreadChats?.length,
    communities.length,
    pages,
  ])

  // function handleOnClick(
  //   fn?: (e?: MouseEvent<HTMLAnchorElement>) => void,
  //   e?: MouseEvent<HTMLAnchorElement>,
  // ) {
  //   fn?.(e)
  //   onClickItem?.()
  // }

  // function handleOnClickSyShop(e?: MouseEvent<HTMLAnchorElement>) {
  //   e?.preventDefault()
  //   e?.stopPropagation()
  //   window.open(URLS.SY_SHOP, '_blank')
  // }

  return (
    <nav style={{ display: 'flex', flexDirection: 'column' }}>
      {navigationLinks.map((link, index) =>
        link.title === 'Profile' ? (
          <CustomSideNavItem
            aria-label={link.title}
            as={'a'}
            key={index}
            title={link.title}
            to={link.to}
          >
            <AvatarIconContainer>
              <Avatar
                diameter={20}
                name={user?.fullName ?? 'sy'}
                uri={link.avatarUrl}
              />
            </AvatarIconContainer>
            {link.title}
          </CustomSideNavItem>
        ) : (
          <CustomSideNavItem
            aria-label={link.title}
            as={'a'}
            key={index}
            title={link.title}
            to={link.to}
            shouldMatchEntireUrl={link.title !== 'Pages'}
          >
            <IconContainer>
              {link.avatarUrl ? (
                <Avatar
                  diameter={24}
                  name={user?.fullName ?? 'sy'}
                  uri={link.avatarUrl}
                />
              ) : link.iconName ? (
                <Icon name={link.iconName ?? 'X'} size={40} />
              ) : (
                link.iconComponent
              )}
            </IconContainer>
            {link.title}{' '}
            {link.badge ? (
              <LinkBadge
                dot
                style={{ height: '16px', width: '16px', minWidth: '16px' }}
              />
            ) : null}
          </CustomSideNavItem>
        )
      )}
    </nav>
  )
}

const LinkStyles = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-weight: 500;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  transition: background-color 90ms ease-in-out;

  &:hover, &: active {
    background-color: ${Colors.MYSTIC};
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
  }
`
const StyledNavLink = styled(NavLink)<{ $active: boolean }>`
  ${LinkStyles};
  background-color: ${({ $active }) =>
    $active ? Colors.CATSKILL_WHITE : undefined};
  color: ${({ $active }) => ($active ? Colors.PUNCH : Colors.MINE_SHAFT)};
  text-decoration: none;

  &::before {
    background-color: ${({ $active }) => ($active ? Colors.PUNCH : undefined)};
  }

  svg {
    transition: all 120ms ease-in-out;
    fill: ${({ $active }) => ($active ? Colors.PUNCH : Colors.MINE_SHAFT)};
    // stroke-width: 0.4px;
    // stroke: ${({ $active }) => ($active ? Colors.PUNCH : Colors.MINE_SHAFT)};
  }
`
const StyledButton = styled.button<{ $active: boolean }>`
  ${LinkStyles};
  background: none;
  border: none;

  background-color: ${({ $active }) =>
    $active ? Colors.CATSKILL_WHITE : undefined};

  color: ${({ $active }) => ($active ? Colors.PUNCH : Colors.MINE_SHAFT)};

  &::before {
    background-color: ${({ $active }) => ($active ? Colors.PUNCH : undefined)};
  }
`
const IconContainer = styled.span`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: 6px;
  padding: 2px;
  width: 24px;
  & > * {
    flex: 0 0 22px;
    max-height: 22px;
  }
`
const AvatarIconContainer = styled.span`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: 6px;
  padding: 2px;
  width: 24px;
`
const LinkBadge = styled(Badge)`
  position: absolute;
  top: 8px;
  right: 5px;
`

export default SideNavigation
