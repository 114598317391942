/**
 * Forgot Password Modal with Email and SMS flows
 */

import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  MutationUserRequestResetPasswordRequest,
  mutationUserRequestResetPassword,
} from '@sportsyou/api'
import { capitalize, isEmailValid, isPhoneNumberValid } from '@sportsyou/core'
import { Button, Link, Modal, TextInput } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import ForgotPasswordEmail from './modules/email-flow'
import ForgotPasswordSMS from './modules/sms-flow'

interface Props {
  className?: string
  /** Clickable link to initiate modal */
  linkText?: string
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ForgotPasswordModalProps = Props & NativeAttributes

const DEFAULT_VALIDATION_MESSAGE = 'Please enter a valid email or phone number'

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  className,
  linkText = 'Forgot your password?',
  style,
}: ForgotPasswordModalProps) => {
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [inputValue, setInputValue] = useState<string>('')
  const [isInitialStep, setIsInitialStep] = useState(true)
  const [isVisible, setIsVisible] = useState(false)
  const [loginType, setLoginType] = useState<'email' | 'sms'>()
  const [requestParam, setRequestParam] =
    useState<MutationUserRequestResetPasswordRequest>()
  const [showBackButton, setShowBackButton] = useState(false)
  const [showValidationMessage, setShowValidationMessage] =
    useState<boolean>(false)
  const [validationMessage, setValidationMessage] = useState(
    DEFAULT_VALIDATION_MESSAGE
  )

  const requestResetPassword = useFetchApi(async () =>
    mutationUserRequestResetPassword(requestParam)
  )

  useEffect(() => {
    setShowValidationMessage(false)
    setButtonDisabled(inputValue === '')

    if (isPhoneNumberValid(inputValue).valid) {
      setLoginType('sms')
    } else if (isEmailValid(inputValue)) {
      setLoginType('email')
    }

    if (loginType === 'sms') {
      const phoneNumberValidation = isPhoneNumberValid(inputValue)
      setRequestParam(
        phoneNumberValidation.valid
          ? {
              phoneNumber: phoneNumberValidation.sanitizedPhoneNumber,
              phoneNumberCountryCode: 'US',
            }
          : undefined
      )
    } else if (loginType === 'email') {
      setRequestParam(
        isEmailValid(inputValue)
          ? { email: inputValue.toLowerCase() }
          : undefined
      )
    }

    return () => {}
  }, [inputValue, loginType])

  useEffect(() => {
    if (validationMessage !== DEFAULT_VALIDATION_MESSAGE) {
      setValidationMessage(DEFAULT_VALIDATION_MESSAGE)
    }
  }, [validationMessage])

  // Reset all state
  const resetState = () => {
    if (isVisible) return
    console.log('Reset state variables')
    setIsInitialStep(true)
    setShowValidationMessage(false)
    setInputValue('')
    setLoginType(undefined)
  }

  const handleOnToggleModal = () => {
    setIsVisible(!isVisible)
    // TODO: Create new method for modal.onClosing on next version
    setTimeout(() => {
      resetState()
    }, 160) // 160 is default transition time for modal
  }

  const handleOnInitResetPassword = async () => {
    // check for valid email or phone number
    const emailValidation = isEmailValid(inputValue)
    const phoneNumberValidation = isPhoneNumberValid(inputValue)

    if (emailValidation || phoneNumberValidation.valid) {
      // email or phone number is valid, set `loginType` for next step which will be used
      // to render the next component in the flow
      setLoginType(emailValidation ? 'email' : 'sms')
    } else {
      // email and phone number is invalid, block from continuing and reset some state
      setLoginType(undefined)
      setShowValidationMessage(true)
      setButtonDisabled(true)
      return
    }

    // If we made it this far, either email or phone number is valid and we need to
    // initiate password reset from the api.
    await requestResetPassword.fetch().then((response) => {
      if (response.ok) {
        // Api returned ok, lets update state the render next step
        setIsInitialStep(false)
      } else {
        // There was an error, show validation message
        console.error(response.error?.toString())
        setValidationMessage('Error submitting your request')
        setShowValidationMessage(true)
      }
    })
  }

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  // Reset state to initial step for resetting password
  const handleOnClickBack = () => {
    setIsInitialStep(true)
  }

  // This function is reused to render the same style button in different positions.
  // Optimized into a single callback to prevent extra unnecessary rendering.

  const renderModalActionButton = useCallback(
    (type: 'back' | 'close', onClick: () => void) => (
      <ModalActionButton
        appearance='minimal'
        collapse
        data-type={type}
        onClick={onClick}
        textStyle={{ fontWeight: 400 }}
      >
        {type === 'back' ? '< ' : ''}
        {capitalize(type)}
      </ModalActionButton>
    ),
    []
  )

  // Initial form to start reset process
  const renderForm = () => (
    <>
      <Header>Forgot Your Password?</Header>
      <SubText>
        Please enter your email address or phone number below and we'll help you
        reset your password.
      </SubText>

      <TextInput
        autoFocus
        label={'EMAIL OR PHONE NUMBER'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleOnChangeInput(e)
        }
        placeholder={'Enter Email or Phone Number'}
        status={showValidationMessage ? 'error' : undefined}
        showValidationMessage={showValidationMessage}
        validationMessage={validationMessage}
        value={inputValue}
      />
      <StyledButton
        disabled={buttonDisabled}
        onClick={handleOnInitResetPassword}
      >
        Reset Password
      </StyledButton>
    </>
  )

  // Render proper component based in loginType
  const renderResetFlow = () =>
    loginType === 'sms' ? (
      <ForgotPasswordSMS
        onSuccess={handleOnToggleModal}
        onCodeVerified={() => setShowBackButton(false)}
        phoneNumber={isPhoneNumberValid(inputValue).sanitizedPhoneNumber}
      />
    ) : (
      <ForgotPasswordEmail email={inputValue} />
    )

  return (
    <>
      <Link className={className} onClick={handleOnToggleModal} style={style}>
        {linkText}
      </Link>
      <Modal
        backdropIgnoresClicks
        contentWidth={400}
        onClose={handleOnToggleModal}
        visible={isVisible}
      >
        <Modal.Body includeCloseButton>
          {!isInitialStep &&
            showBackButton &&
            renderModalActionButton('back', handleOnClickBack)}
          <Container>
            {isInitialStep ? renderForm() : renderResetFlow()}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 45px auto;
  max-width: 400px;
  padding: 0 20px;
  width: 100%;
`
const Header = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`
const SubText = styled.p`
  text-align: center;
`
const StyledButton = styled(Button)`
  margin-top: 20px;
`
const ModalActionButton = styled(Button)`
  min-height: unset;
  position: absolute;
  top: 20px;
  padding: 6px 8px;
  left: auto;
  right: auto;
  &[data-type='back'] {
    left: 20px;
  }
  &[data-type='close'] {
    right: 20px;
  }
`

export default ForgotPasswordModal
