import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import React, { useCallback, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import {
  Avatar,
  AvatarProps,
  Button,
  ButtonVariant,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { capitalize, Colors, getTimezone } from '@sportsyou/core'
import { Checkmark, X } from '@sportsyou/react-icons'
import {
  EventUpdates,
  Maybe,
  mutationFriendAccept,
  MutationFriendAcceptRequest,
  mutationFriendReject,
  MutationFriendRejectRequest,
  mutationNotificationViewed,
  MutationNotificationViewedRequest,
  mutationTeamInviteReject,
  MutationTeamInviteRejectRequest,
  mutationTeamJoin,
  MutationTeamJoinRequest,
  Notification,
  queryFriends,
  QueryFriendsRequest,
} from '@sportsyou/api'

import { selectCurrentUser, updateFriends } from 'web/store/slices/UserSlice'
import escapeMarkdownCharacters from 'web/utils/EscapeMarkdownCharacters'
import { useFetchApi } from '@sportsyou/react-hooks'
import { markAlertAsRead } from 'web/store/slices/AlertsSlice'
import {
  approveRequest,
  markRequestAsRead,
  rejectRequest,
} from 'web/store/slices/RequestsSlice'
import { addTeam } from 'web/store/slices/TeamsSlice'
import { VariantTypes } from 'libs/react-dom-ui/src/utils/prop-types'

interface Props {
  className?: string
  message?: React.ReactNode | string
  notification: Notification
  onClick?: () => void
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type NotificationItemProps = Props & NativeAttributes

export const NotificationItem: React.FC<NotificationItemProps> = ({
  className,
  notification,
  message,
  onClick,
  style,
}: NotificationItemProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { sendBanner } = useDialog()
  const navigate = useNavigate()

  const { fetch: setAlertViewed } = useFetchApi(mutationNotificationViewed)
  const { fetch: acceptFriendRequest } = useFetchApi(mutationFriendAccept)
  const { fetch: rejectFriendRequest } = useFetchApi(mutationFriendReject)
  const { fetch: acceptTeamInvite } = useFetchApi(mutationTeamJoin)
  const { fetch: rejectTeamInvite } = useFetchApi(mutationTeamInviteReject)
  const { fetch: getFriends } = useFetchApi(queryFriends)

  const currentUser = useSelector(selectCurrentUser)

  const initialRequestStatus = useMemo(() => {
    if (notification.friendRequestToken) {
      return notification.friendRequestStatus ?? notification.status
    }
    if (notification?.joinedAt) {
      return 'accepted'
    }
    return undefined
  }, [notification])

  const [isRequest] = useState(
    !!notification.teamConfirmToken || !!notification.friendRequestToken
  )
  const [isUnread, setIsUnread] = useState(!notification?.viewedAt)
  const [requestStatus, setRequestStatus] = useState(initialRequestStatus)

  const handleOnClickNotification = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.()
  }

  const handleOnMouseOverNotification = async (e: React.MouseEvent) => {
    if (!isUnread) return
    e.stopPropagation()
    if (isUnread) {
      // call api
      const { ok, error } = await setAlertViewed({
        id: notification?.id,
      } as MutationNotificationViewedRequest)

      if (ok) {
        // update local state
        setIsUnread(!isUnread)
        if (isRequest) {
          dispatch(markRequestAsRead(notification))
        } else {
          // update redux store
          dispatch(markAlertAsRead(notification))
        }
      }
      if (error) {
        console.log({ error })
      }
    }
  }

  /**
   * Handler to update local state and redux store
   */
  const updateRequest = useCallback(
    (approved: boolean) => {
      // update state
      setRequestStatus(approved ? 'accepted' : 'rejected')
      // update redux store
      if (approved) {
        dispatch(approveRequest(notification))
      } else {
        dispatch(rejectRequest(notification))
      }
    },
    [notification, dispatch]
  )

  /**
   * Handler for accpeting friend requests and team invites.
   * Calls api and update local state and redux store
   */
  const acceptRequest = useCallback(async () => {
    // is this a friend request?
    if (notification.friendRequestToken) {
      const { error, ok } = await acceptFriendRequest({
        inviteToken: notification.friendRequestToken,
      } as MutationFriendAcceptRequest)
      if (ok) {
        updateRequest(true)
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: `**${notification.sourceName}** is now a contact.`,
          useMarkdown: true,
        })
        const { data: friends } = await getFriends({} as QueryFriendsRequest)
        if (friends) {
          dispatch(updateFriends(friends))
        }
      }
      if (error) {
        console.log({ error })
      }
    }
    // team invite request
    else if (notification.teamConfirmToken) {
      const {
        data = {},
        error,
        ok,
      } = await acceptTeamInvite({
        confirmToken: notification.teamConfirmToken,
      } as MutationTeamJoinRequest)
      if (data && ok) {
        updateRequest(true)
        sendBanner({
          autoDismiss: true,
          status: 'success',
          useMarkdown: true,
          message: `Successfully joined the ${data?.type ?? 'team'} **${
            data?.name
          }**`,
        })
        dispatch(addTeam(data))
        navigate(`/${data.type ?? 'team'}s/${data.id}`)
      }
      if (error) {
        console.log({ error })
      }
    }
  }, [
    notification.friendRequestToken,
    notification.teamConfirmToken,
    notification.sourceName,
    acceptFriendRequest,
    updateRequest,
    sendBanner,
    getFriends,
    dispatch,
    acceptTeamInvite,
    navigate,
  ])

  /**
   * Handler for declining friend requests and team invites.
   * Calls api and update local state and redux store
   */
  const declineRequest = useCallback(async () => {
    if (notification.friendRequestToken) {
      const { error, ok } = await rejectFriendRequest({
        inviteToken: notification.friendRequestToken,
        targetId: notification.sourceId,
      } as MutationFriendRejectRequest)
      if (ok) {
        // update state and redux
        updateRequest(false)
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: 'Contact request declined.',
        })
      }
      if (error) {
        console.log({ error })
      }
    }
    //
    else if (notification.teamConfirmToken) {
      const { error, ok } = await rejectTeamInvite({
        confirmToken: notification.teamConfirmToken,
      } as MutationTeamInviteRejectRequest)
      if (ok) {
        // update state and redux
        updateRequest(false)
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: `${capitalize(
            notification.teamType ?? 'team'
          )} invitation declined.`,
        })
      }
      if (error) {
        console.log({ error })
      }
    }
  }, [
    notification.friendRequestToken,
    notification.sourceId,
    notification.teamConfirmToken,
    notification.teamType,
    rejectFriendRequest,
    rejectTeamInvite,
    sendBanner,
    updateRequest,
  ])

  const handleOnClickRequestAction = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, accepted: boolean) => {
      if (accepted) {
        acceptRequest()
      } else {
        event.preventDefault()
        event.stopPropagation()
        declineRequest()
      }
    },
    [acceptRequest, declineRequest]
  )

  function setStringPossessive(str: string | null | undefined) {
    if (typeof str === 'string') {
      str = str.trim()
      return str + (str.endsWith('s') ? '’' : '’s')
    } else {
      return undefined
    }
  }

  function eventUpdateUpdatesCount(updates: Maybe<EventUpdates>[]) {
    let count = 0
    let dateAdded = false

    updates.forEach((update) => {
      switch (update?.updateType) {
        case 'eventName':
        case 'eventLocation':
        case 'startTime':
        case 'endTime':
        case 'eventDescription':
          count++
          break

        case 'repeatChange':
          if (!count) {
            count++
          }
          break

        case 'startDate':
        case 'endDate':
        case 'allDay':
          if (!dateAdded) {
            count++
            dateAdded = true
          }
          break

        case 'miscChanges':
          count = 999
          break

        default:
      }
    })

    return count
  }

  const eventUpdateUpdatedItems = useCallback(
    (updates: Maybe<EventUpdates>[]) => {
      const items: string[] = []
      let dateAdded = false

      updates.forEach((update) => {
        switch (update?.updateType) {
          case 'eventName':
            items.push(t('notification-event-field-name'))
            break

          case 'eventLocation':
            items.push(t('notification-event-field-location'))
            break

          case 'startDate':
          case 'endDate':
          case 'allDay':
            if (!dateAdded) {
              items.push(t('notification-event-field-date'))
              dateAdded = true
            }
            break

          case 'startTime':
            items.push(t('notification-event-field-start-time'))
            break

          case 'endTime':
            items.push(t('notification-event-field-end-time'))
            break

          case 'repeatChange':
            items.splice(
              items.findIndex(function (e) {
                return e === 'date'
              }),
              1,
              t('notification-event-field-repeat-information')
            )
            break

          case 'eventDescription':
            items.push(t('notification-event-field-description'))
            break

          default:
        }
      })

      return items
        .join(', ')
        .replace(/(, )(?!.*(, ))/g, t('notification-event-field-and'))
    },
    [t]
  )

  const eventUpdateUpdatedDetail = useCallback(
    (updates: Maybe<EventUpdates>[], rowData: Notification) => {
      let detail: string | null | undefined = ''
      let startTimeChanged = false
      let endTimeChanged = false
      let startTime: string = ''
      let endTime: string = ''

      updates.forEach((update) => {
        switch (update?.updateType) {
          case 'eventName':
            detail = update.new
            break

          case 'eventLocation':
            detail = update.new
            break

          case 'startDate':
          case 'endDate':
          case 'allDay':
            detail = update.new
            break

          case 'noEnd':
            if (update.new === '1') {
              detail = t('notification-event-field-no-end')
            } else {
              detail = endTime
            }
            break

          case 'start':
            detail = update.new
            startTimeChanged = true
            startTime = moment(rowData?.eventStart ?? '')
              // .tz(props.timezone)
              .format('h:mm a z')
            break

          case 'end':
            detail = update.new
            endTimeChanged = true
            endTime = moment(rowData?.eventEnd ?? '')
              // .tz(props.timezone)
              .format('h:mm a z')
            break

          case 'eventDescription':
            detail = update.new
            break

          default:
        }
      })

      if (startTimeChanged && endTimeChanged) {
        detail = startTime + ' – ' + endTime
      }

      return escapeMarkdownCharacters(detail ?? '')
    },
    [t]
  )

  const getAvatarProps: AvatarProps = useMemo(() => {
    let name, uri
    if (notification.teamConfirmToken || notification.friendRequestToken) {
      name = notification.sourceName ?? notification.teamName ?? undefined
      uri =
        notification.sourceProfileImage?.viewUrl ??
        notification.teamProfileImage?.viewUrl ??
        undefined
    } else {
      name = notification.teamName ?? notification.sourceName ?? undefined
      uri = notification.teamId
        ? notification.teamProfileImage?.viewUrl ?? undefined
        : notification.sourceProfileImage?.viewUrl ?? undefined
    }
    return { name, uri }
  }, [
    notification.friendRequestToken,
    notification.sourceName,
    notification.sourceProfileImage,
    notification.teamConfirmToken,
    notification.teamId,
    notification.teamName,
    notification.teamProfileImage,
  ])

  const renderFriendRequestStatus = useMemo(() => {
    if (requestStatus && requestStatus !== 'pending') {
      return <StatusMessage>{capitalize(requestStatus)}</StatusMessage>
    }

    return (
      <>
        <CircleButton
          appearance='minimal'
          collapse
          variant='success'
          onClick={(e) => handleOnClickRequestAction(e, true)}
        >
          {/* {friendRequestAcceptText} */}
          <Checkmark
            fill={Colors.MOUNTAIN_MEADOW}
            style={{ verticalAlign: 'middle' }}
          />
        </CircleButton>
        <CircleButton
          appearance='minimal'
          collapse
          onClick={(e) => handleOnClickRequestAction(e, false)}
          variant='danger'
        >
          {/* {friendRequestDeclineText} */}
          <X fill={Colors.MONZA} style={{ verticalAlign: 'middle' }} />
        </CircleButton>
      </>
    )
  }, [handleOnClickRequestAction, requestStatus])

  const messageText = useMemo(() => {
    const item = notification
    const sourceUserName = escapeMarkdownCharacters(item.sourceName ?? '')
    const teamGroupName = escapeMarkdownCharacters(item.teamName ?? '')
    const teamGroupType = item.teamType
    const teamId = item.teamId
    const _acceptedTeamGroupInvite = !!item.joined
    const isNotiForTeamGroup = !!teamId
    const eventType = item.eventTypeId
    const eventTitle = escapeMarkdownCharacters(item.eventTitle ?? '')
    const dateString = moment(item.eventStart ?? '')
      // .tz(alert.timezone)
      .format('LL')
    const timeString = moment(item.eventStart ?? '')
      .tz(getTimezone())
      .format('h:mm a (z)')
    const albumName = escapeMarkdownCharacters(item.albumName ?? '')
    const eventDate =
      item.eventDetail && !item.eventDetail.allDay
        ? `${dateString} @ ${timeString}`
        : dateString
    const eventDetail = item.eventDetail

    let message = item.message ?? ''
    let _messageText = ''

    let options: any = {
      sourceUserName,
      teamGroupName,
      teamGroupType: t(`notification-team-field-${teamGroupType ?? 'team'}`),
    }

    switch (item.type) {
      case 'eventCreate':
        options = {
          sourceUserName,
          teamGroupName: setStringPossessive(teamGroupName),
          eventTitle,
          eventType: t('notification-event-field-' + eventType),
          eventDate,
        }
        if (isNotiForTeamGroup) {
          _messageText = t('notification-title-add-team-event', options)
        } else {
          _messageText = t('notification-title-add-event', options)
        }
        break
      case 'eventUpdate':
        const updateCnt =
          (eventDetail &&
            eventDetail.eventUpdates &&
            eventUpdateUpdatesCount(eventDetail.eventUpdates)) ||
          0
        const updated =
          (eventDetail &&
            eventDetail.eventUpdates &&
            eventUpdateUpdatedItems(eventDetail.eventUpdates)) ||
          ''
        const updatedDetail =
          (eventDetail &&
            eventDetail.eventUpdates &&
            eventUpdateUpdatedDetail(eventDetail.eventUpdates, item)) ||
          ''
        options = {
          sourceUserName,
          teamGroupName: setStringPossessive(teamGroupName),
          eventTitle,
          eventType: t('notification-event-field-' + eventType),
          eventDate,
          updated,
          dateString,
          updatedDetail,
        }
        if (updateCnt === 1) {
          if (updated === t('notification-event-field-repeat-information')) {
            if (isNotiForTeamGroup) {
              _messageText = t(
                'notification-title-update-team-event-short',
                options
              )
            } else {
              _messageText = t('notification-title-update-event-short', options)
            }
          } else if (updated === t('notification-event-field-date')) {
            if (isNotiForTeamGroup) {
              _messageText = t(
                'notification-title-update-team-event-date',
                options
              )
            } else {
              _messageText = t('notification-title-update-event-date', options)
            }
          } else {
            if (isNotiForTeamGroup) {
              _messageText = t('notification-title-update-team-event', options)
            } else {
              _messageText = t('notification-title-update-event', options)
            }
          }
        } else if (updateCnt === 2) {
          if (
            updated.indexOf(t('notification-event-field-start-time')) !== -1 &&
            updated.indexOf(t('notification-event-field-end-time')) !== -1
          ) {
            if (isNotiForTeamGroup) {
              _messageText = t(
                'notification-title-update-team-event-time',
                options
              )
            } else {
              _messageText = t('notification-title-update-event-time', options)
            }
          } else {
            if (isNotiForTeamGroup) {
              _messageText = t('notification-title-update-team-event', options)
            } else {
              _messageText = t('notification-title-update-event', options)
            }
          }
        } else if (updateCnt === 3) {
          if (isNotiForTeamGroup) {
            _messageText = t(
              'notification-title-update-team-event-short',
              options
            )
          } else {
            _messageText = t('notification-title-update-event-short', options)
          }
        } else {
          if (isNotiForTeamGroup) {
            _messageText = t(
              'notification-title-update-team-event-no-fields',
              options
            )
          } else {
            _messageText = t(
              'notification-title-update-event-no-fields',
              options
            )
          }
        }
        break
      case 'eventDelete':
        options = {
          eventOwnerName: setStringPossessive(
            isNotiForTeamGroup ? teamGroupName : sourceUserName
          ),
          eventType,
          eventTitle,
        }
        _messageText = t('notification-title-delete-event', options)
        break
      case 'album':
        options = {
          sourceUserName,
          teamGroupName,
          albumName,
        }
        _messageText = t('notification-title-album-team-create', options)
        break
      case 'poll':
        let expTime = ''
        let msg = message || ''
        const expPrefix = 'Poll will close at'
        if (msg.startsWith(expPrefix)) {
          expTime = msg.substring(expPrefix.length + 1)
          msg = expPrefix
        }
        options = { sourceUserName, expTime }
        if (msg === 'Poll will close at') {
          _messageText = t('notification-title-poll-will-close', options)
        } else {
          _messageText = t('notification-title-poll-closed', options)
        }
        break
      case 'post':
        switch (message) {
          case 'Posted a poll in the team':
          case 'Posted a poll in the group':
            _messageText = t('notification-title-poll-team-create', options)
            break
          case 'Posted in the team':
          case 'Posted in the group':
            _messageText = t('notification-title-post-team-create', options)
            break
          case 'Liked your post':
            if (isNotiForTeamGroup) {
              _messageText = t('notification-title-post-team-like', options)
            } else {
              _messageText = t('notification-title-post-like', options)
            }
            break
          case 'Commented on your post':
            _messageText = t('notification-title-post-comment', options)
            break
          case 'Your post has been approved':
            _messageText = t('notification-title-post-approved', options)
            break
          case 'Posted a playlist':
            _messageText = t(
              'notification-title-video-posted-playlist',
              options
            )
            break
          case 'Liked your playlist':
            _messageText = t('notification-title-video-liked-playlist', options)
            break
          default:
            if (message?.includes('Your scheduled post')) {
              if (!message?.includes('team')) {
                _messageText = t(
                  'notification-title-post-team-create-scheduled-1',
                  options
                )
              } else {
                if (message?.includes('teams')) {
                  let search = ' ' + teamGroupName + ' '
                  let ndx = message?.indexOf(search)
                  if (ndx > -1) {
                    ndx += teamGroupName?.length || 0
                    ndx += 6
                    let ndx2 = message?.indexOf(' ', ndx)
                    options.count = message?.substring(ndx, ndx2)
                  }
                  _messageText = t(
                    'notification-title-post-team-create-scheduled-3',
                    options
                  )
                } else {
                  _messageText = t(
                    'notification-title-post-team-create-scheduled-2',
                    options
                  )
                }
              }
            } else if (message?.includes('Commented on your post in the')) {
              _messageText = t('notification-title-post-team-comment', options)
            } else if (message?.includes('Commented on post by')) {
              if (isNotiForTeamGroup) {
                options.postBy = setStringPossessive(
                  message
                    ?.split('Commented on post by ')[1]
                    ?.split(' in the ')[0] ?? ''
                )
                _messageText = t(
                  'notification-title-post-team-comment-postby',
                  options
                )
              } else {
                options.postBy = setStringPossessive(
                  message?.split('Commented on post by ')[1] ?? ''
                )
                _messageText = t(
                  'notification-title-post-comment-postby',
                  options
                )
              }
            } else if (message?.includes('Commented on your playlist on')) {
              _messageText = t(
                'notification-title-video-commented-on-your-playlist',
                options
              )
            } else if (message?.includes('Commented on playlist by')) {
              options.postBy = setStringPossessive(
                message
                  ?.split('Commented on playlist by')[1]
                  ?.split(' on ')[0] ?? ''
              )
              _messageText = t(
                'notification-title-video-commented-on-playlist',
                options
              )
            } else {
              //@ts-ignore
              _messageText = message!
            }
        }
        break
      case 'team-postComplaint':
        _messageText = t('notification-title-post-complaint', options)
        break
      case 'reminder':
        options.startsIn = message?.split(' starts in ')[1] ?? ''
        options.eventType = t('notification-event-field-' + eventType)
        options.eventTitle = eventTitle

        if (isNotiForTeamGroup) {
          options.eventOwnerName = setStringPossessive(teamGroupName)
        } else if (item.sourceId === currentUser.id) {
          options.eventOwnerName = null
        } else {
          options.eventOwnerName = setStringPossessive(sourceUserName)
        }

        if (options.eventOwnerName) {
          _messageText = t('notification-title-other-reminder', options)
        } else {
          _messageText = t('notification-title-your-reminder', options)
        }
        break
      case 'team':
        if (message?.includes('Added you to')) {
          if (_acceptedTeamGroupInvite) {
            _messageText = t('notification-title-team-add', options)
          } else {
            _messageText = t('notification-title-team-invite', options)
          }
        } else if (message?.includes('access to sportsYou Video')) {
          _messageText = t('notification-title-video-access', options)
        }
        break
      case 'team-setAdmin':
        if (message && message.indexOf('photographer') > -1) {
          _messageText = t('notification-title-made-photographer', options)
        } else {
          _messageText = t('notification-title-made-admin', options)
        }
        break
      case 'friend-accept':
        _messageText = t('notification-title-friend-accept', options)
        break
      case 'friend':
        if (requestStatus === 'accepted') {
          _messageText = t('notification-title-friend-request-accept', options)
        } else if (requestStatus === 'rejected') {
          _messageText = t('notification-title-friend-request-reject', options)
        } else {
          _messageText = t('notification-title-friend-request', options)
        }
        break
      case 'file':
        _messageText = t('notification-title-file-share', options)
        break
      default:
        _messageText = `${escapeMarkdownCharacters(item.sourceName ?? '')} ${
          item.message
        } ${escapeMarkdownCharacters(item.teamName ?? '')}`
    }

    _messageText = _messageText?.replace(' **** ', ' ') ?? ''

    return _messageText
  }, [
    notification,
    t,
    eventUpdateUpdatedItems,
    eventUpdateUpdatedDetail,
    currentUser.id,
    requestStatus,
  ])

  return (
    <Container
      className={className}
      onClick={handleOnClickNotification}
      onMouseEnter={(e) => handleOnMouseOverNotification(e)}
      style={style}
      unread={isUnread}
    >
      <Avatar diameter={44} {...getAvatarProps} />
      <Message>
        <SmallText>
          <ReactMarkdown
            children={messageText}
            components={{ p: 'span' }}
            // components={{ p: 'div' }}
          />
          {notification.type === 'team-setAdmin' &&
            messageText.indexOf('photographer') > -1 && (
              <PhotographerRoleText>
                Post photos and videos to the feed, Media tab, and create Albums
                to share with your team
              </PhotographerRoleText>
            )}
        </SmallText>

        {!isRequest && (
          <Timestamp>{moment(notification.createdAt).fromNow()}</Timestamp>
        )}
      </Message>
      {isRequest && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            marginLeft: 'auto',
          }}
        >
          {renderFriendRequestStatus}
        </div>
      )}
    </Container>
  )
}

const Container = styled.div<{ unread: boolean }>`
  align-items: center;
  background-color: ${({ unread }) => (unread ? Colors.MYSTIC : Colors.WHITE)};
  display: flex;
  min-width: 1px;
  padding: 10px;
  position: relative;

  &::before {
    content: '';
    background: ${Colors.HAVELOCK_BLUE};
    height: 100%;
    position: absolute;
    width: ${({ unread }) => (unread ? '0.2em' : '0')};
    left: 0;
    top: 0;
  }
`
const Message = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  min-width: 1;
`
const SmallText = styled.div`
  flex: 1 1 auto;
  margin-left: 10px;
  margin-right: 6px;
  font-size: 14px;
`
const PhotographerRoleText = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  margin-top: 4px;
`
const Timestamp = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 12px;
  margin-left: auto;
  white-space: nowrap;
`
const StatusMessage = styled.div`
  color: ${Colors.BLACK};
  font-size: 12px;
  font-weight: 700;
`
const CircleButton = styled(Button)<{ variant: ButtonVariant }>`
  && {
    border-color: ${({ variant }) =>
      variant === 'danger' ? Colors.MONZA : Colors.MOUNTAIN_MEADOW};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    min-height: 1px;
    min-width: 1px;

    padding: 0;
    &:hover,
    &:active {
      background-color: ${({ variant }) =>
        variant === 'danger' ? Colors.MONZA : Colors.MOUNTAIN_MEADOW};
      border-color: inherit;

      & svg {
        fill: ${Colors.WHITE};
      }
    }
  }
  & + & {
    margin-left: 10px;
  }
`

export default NotificationItem
