import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { capitalize, Colors } from '@sportsyou/core'
import {
  Button,
  Checkbox,
  CheckboxEvent,
  Modal,
  TextArea,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'
import {
  mutationTeamUpdate,
  MutationTeamUpdateRequest,
  Sport,
  Team,
} from '@sportsyou/api'

import { updateTeam } from 'web/store/slices/TeamsSlice'

import SportsPicker from '../../SportsPicker/SportsPicker'

interface Props {
  isNewTeam?: boolean
  isVisible: boolean
  onClose: () => void
  onSave: () => void
  team?: Team
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type TeamSettingsModalProps = Props & NativeAttributes

export const TeamSettingsModal: React.FC<TeamSettingsModalProps> = ({
  isNewTeam,
  isVisible,
  onClose,
  onSave,
  team,
}: TeamSettingsModalProps) => {
  const { sendBanner, sendConfirm } = useDialog()
  const { fetch: updateTeamSettings } = useFetchApi(mutationTeamUpdate)

  const ABOUT_MAX_LENGTH = 3000
  const INITIAL_TEAM_PREFS = {
    anyoneCanChat: team?.preferences?.anyoneCanChat ?? false,
    canChatAdmin: team?.preferences?.canChatAdmin ?? false,
    postsRequireApproval: team?.preferences?.postsRequireApproval ?? true,
    onlyAdminCanPost: team?.preferences?.onlyAdminCanPost ?? false,
  }

  const dispatch = useDispatch()

  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [sport, setSport] = useState(team?.sport ?? undefined)
  const [teamAbout, setTeamAbout] = useState(team?.about ?? '')
  const [teamName, setTeamName] = useState(team?.name ?? '')
  const [teamPrefs, setTeamPrefs] = useState(INITIAL_TEAM_PREFS)

  useEffect(() => {
    if (!isEqual(team?.sport, sport?.id)) {
      setSport(team?.sport ?? undefined)
    }
    if (team?.about !== teamAbout) {
      setTeamAbout(team?.about ?? '')
    }
    if (team?.name !== teamName) {
      setTeamName(team?.name ?? '')
    }
    if (!isEqual(team?.preferences, teamPrefs)) {
      const _teamPrefs = {
        anyoneCanChat: team?.preferences?.anyoneCanChat ?? false,
        canChatAdmin: team?.preferences?.canChatAdmin ?? false,
        postsRequireApproval: team?.preferences?.postsRequireApproval ?? true,
        onlyAdminCanPost: team?.preferences?.onlyAdminCanPost ?? false,
      }
      setTeamPrefs(_teamPrefs)
    }
  }, [team])

  const resetState = () => {
    setHasChanged(false)
    setSport(team?.sport ?? undefined)
    setTeamAbout(team?.about ?? '')
    setTeamName(team?.name ?? '')
    setTeamPrefs(INITIAL_TEAM_PREFS)
  }

  const handleOnChange = (e: CheckboxEvent) => {
    const { checked, value } = e.target
    setTeamPrefs((prevState) => ({
      ...prevState,
      [value]: checked,
    }))
    setHasChanged(true)
  }

  const handleOnChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: 'about' | 'team-name'
  ) => {
    const { value } = e.target
    setHasChanged(true)
    if (key === 'about') {
      setTeamAbout(value)
    } else if (key === 'team-name') {
      setTeamName(value)
    }
  }

  const handleOnChangeSport = (sport: Sport) => {
    setSport({ id: sport.id, name: sport.name })
    setHasChanged(true)
  }

  const handleOnClose = () => {
    if (hasChanged) {
      sendConfirm({
        message:
          'Your settings have not been saved, are you sure you want to close this window?',
        onConfirm: () => {
          onClose && onClose()
          resetState()
        },
      })
    } else {
      onClose && onClose()
    }
  }

  const handleOnClickSave = async () => {
    const { data, error, ok } = await updateTeamSettings({
      team: {
        about: teamAbout,
        id: team?.id,
        name: teamName,
        sportId: sport?.id ?? team?.sport?.id,
        ...teamPrefs,
      },
    } as MutationTeamUpdateRequest)

    if (data && ok) {
      sendBanner({
        autoDismiss: true,
        message: `${capitalize(
          team?.type ?? 'team'
        )} settings saved successfully`,
        status: 'success',
      })
      await dispatch(
        updateTeam({
          ...team,
          about: teamAbout,
          name: teamName,
          sport: sport,
          preferences: teamPrefs,
        })
      )

      setTeamAbout(teamAbout)
      setTeamName(teamName)
      setSport(sport)
      setTeamPrefs(teamPrefs)
      if (hasChanged) {
        setHasChanged(false)
      }
      onSave && onSave()
    }

    if (error) {
      console.log({ error })
    }
  }

  const renderCheckboxes = () => (
    <CheckboxesContainer>
      <StyledCheckbox
        checked={teamPrefs.canChatAdmin}
        description={`Unless selected, members will NOT be allowed to start chats with ${
          team?.type ?? 'team'
        } admins.`}
        descriptionStyle={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.2,
        }}
        onChange={handleOnChange}
        textContainerStyle={{ marginLeft: 10 }}
        value='canChatAdmin'
      >
        Allow Members to Chat with Admins
      </StyledCheckbox>

      <StyledCheckbox
        checked={teamPrefs.anyoneCanChat}
        description={`Unless selected, members will NOT be allowed to start chats with other ${
          team?.type ?? 'team'
        } members. This includes Admins.`}
        descriptionStyle={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.2,
        }}
        onChange={handleOnChange}
        textContainerStyle={{ marginLeft: 10 }}
        value='anyoneCanChat'
      >
        Allow Members to Chat with Each Other
      </StyledCheckbox>

      <StyledCheckbox
        checked={teamPrefs.postsRequireApproval}
        description={`All non-admin posts will need approval before appearing in the ${
          team?.type ?? 'team'
        }'s feed.`}
        descriptionStyle={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.2,
        }}
        onChange={handleOnChange}
        textContainerStyle={{ marginLeft: 10 }}
        value='postsRequireApproval'
      >
        Require Approval of Non-Admin Posts
      </StyledCheckbox>

      <StyledCheckbox
        checked={teamPrefs.onlyAdminCanPost}
        description={`Only admins and photographers can post in the ${
          team?.type ?? 'team'
        }'s feed.`}
        descriptionStyle={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.2,
        }}
        onChange={handleOnChange}
        textContainerStyle={{ marginLeft: 10 }}
        value='onlyAdminCanPost'
      >
        Only Admins Can Post
      </StyledCheckbox>
    </CheckboxesContainer>
  )

  return (
    <Modal visible={isVisible} onClose={handleOnClose}>
      {!isNewTeam && (
        <Modal.Header>
          <b>{capitalize(team?.type ?? 'team')} Settings</b>
        </Modal.Header>
      )}
      <Modal.Body
        includeCloseButton={isNewTeam}
        style={
          isNewTeam
            ? {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }
            : {}
        }
      >
        {isNewTeam ? (
          <>
            <Body>
              <h2>{capitalize(team?.type ?? 'team')} Settings</h2>
              <p>
                These settings can always be adjusted from the gear icon on the{' '}
                {team?.type ?? 'team'} page.
              </p>

              {renderCheckboxes()}
            </Body>
            <Footer>
              <Button onClick={handleOnClickSave}>Save Settings</Button>
              <DismissButton
                appearance='minimal'
                onClick={handleOnClose}
                variant='alternate'
              >
                Dismiss
              </DismissButton>
            </Footer>
          </>
        ) : (
          <>
            {/* <h3 style={{ marginBottom: 6, marginTop: 0 }}>Settings</h3> */}
            {renderCheckboxes()}

            <div style={{ display: 'flex', marginTop: 10 }}>
              <TextInput
                containerStyle={{
                  flex: '1 1 50%',
                  marginRight: 5,
                }}
                inputContainerStyle={{
                  minWidth: 1,
                }}
                label={`${capitalize(team?.type ?? 'team')} Name`}
                maxLength={100}
                onChange={(e) => {
                  handleOnChangeInput(e, 'team-name')
                }}
                placeholder={`My ${capitalize(team?.type ?? 'team')}`}
                // value={formik.values.name}
                value={teamName}
              />
              <SportsPicker
                onChange={(sport) => handleOnChangeSport(sport)}
                label='Sport'
                placeholder='Select'
                selectedSport={sport ?? undefined}
                style={{ flex: '1 1 50%', marginLeft: 5 }}
                triggerStyle={{ minWidth: 1 }}
              />
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'stretch',
                marginTop: 10,
              }}
            >
              <TextArea
                label='About'
                maxLength={ABOUT_MAX_LENGTH}
                onChange={(e) => handleOnChangeInput(e, 'about')}
                value={teamAbout}
              ></TextArea>
              <span
                style={{
                  alignSelf: 'flex-end',
                  color: Colors.SHUTTLE_GRAY,
                  fontSize: 12,
                  marginTop: 4,
                }}
              >
                {ABOUT_MAX_LENGTH - teamAbout.length}
              </span>
            </div>

            {/* // <TextInput
            //   placeholder="Select a sport"
            //   label="Sport"
            //   // value={formik.values.name}
            //   onChange={e => handleOnChangeInput(e, bo)}
            // /> */}
          </>
        )}
      </Modal.Body>
      {!isNewTeam && (
        <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end ' }}>
          <Button appearance='minimal' onClick={handleOnClose}>
            Cancel
          </Button>
          <SaveButton onClick={handleOnClickSave}>Save</SaveButton>
        </Modal.Footer>
      )}
    </Modal>
  )
}

const Body = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
  width: 360px;
`
const CheckboxesContainer = styled.div`
  text-align: left;
`
const StyledCheckbox = styled(Checkbox)`
  border-radius: 8px;
  font-size: 16px;
  padding: 8px;
  font-weight: 500;
  &:hover {
    background-color: ${Colors.CATSKILL_WHITE};
  }
  & + & {
    margin-top: 4px;
  }
`
const Footer = styled.footer`
  flex-direction: column;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
`
const DismissButton = styled(Button)`
  margin-top: 10px;
`
const SaveButton = styled(Button)`
  margin-left: 6px;
`

export default TeamSettingsModal
