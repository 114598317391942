import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Select, TextInput } from '@sportsyou/react-dom-ui'

const reminderTypes = ['None', 'Email', 'Notification', 'Both']
type ReminderTypes = (typeof reminderTimeTypes)[number]

const reminderTimeTypes = ['Minutes', 'Hours', 'Days', 'Weeks']
type ReminderTimeTypes = (typeof reminderTimeTypes)[number]

export type ReminderType = {
  reminderDisplayValue?: string
  reminderInterval?: ReminderTimeTypes
  reminderType?: ReminderTypes
}

export interface ReminderPickerProps {
  className?: string
  id?: string
  name?: string
  onChange: (value: ReminderType) => void
  reminderDisplayValue?: string
  reminderInterval?: ReminderTimeTypes
  reminderType?: ReminderTypes
  style?: React.CSSProperties
}

export const ReminderPicker: React.FC<ReminderPickerProps> = ({
  className,
  id,
  name,
  onChange,
  reminderDisplayValue,
  reminderInterval,
  reminderType,
  style,
}: ReminderPickerProps) => {
  const onChangeReminderType = useCallback(
    (value: ReminderTypes) => {
      onChange({
        reminderDisplayValue,
        reminderInterval,
        reminderType: value,
      })
    },
    [onChange, reminderDisplayValue, reminderInterval]
  )

  const onChangeReminderInterval = useCallback(
    (value: ReminderTypes) => {
      onChange({
        reminderDisplayValue,
        reminderInterval: value,
        reminderType,
      })
    },
    [onChange, reminderDisplayValue, reminderType]
  )

  const onChangeDisplayValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        reminderDisplayValue: e.target.value,
        reminderInterval,
        reminderType,
      })
    },
    [onChange, reminderInterval, reminderType]
  )

  return (
    <Container className={className} id={id} style={style}>
      <Select
        initialValue={reminderType}
        label='Reminder'
        style={{ flex: '1 1 auto' }}
        triggerStyle={{ minWidth: 1 }}
      >
        {reminderTypes.map((type, index) => (
          <Select.Option
            isSelected={type === reminderType}
            key={index}
            onClick={onChangeReminderType}
            value={type}
          >
            {type}
          </Select.Option>
        ))}
      </Select>
      {reminderType !== 'None' && (
        <>
          <TextInput
            containerStyle={{
              flex: '0 0 90px',
              marginLeft: 10,
              marginRight: 10,
            }}
            inputContainerStyle={{ minWidth: 1 }}
            label='Time'
            maxLength={4}
            min={1}
            onChange={onChangeDisplayValue}
            placeholder='0'
            type='number'
            value={reminderDisplayValue}
          />
          <Select
            initialValue={reminderInterval}
            label='Interval'
            style={{ flex: '1 1 auto' }}
            triggerStyle={{ minWidth: 1 }}
          >
            {reminderTimeTypes.map((type, index) => (
              <Select.Option
                isSelected={type === reminderInterval}
                key={index}
                onClick={onChangeReminderInterval}
                value={type}
              >
                {type}
              </Select.Option>
            ))}
          </Select>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  align-self: stretch;
  display: flex;
`

export default ReminderPicker
