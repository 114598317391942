import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Post as PostProps } from '@sportsyou/api'
import { RootState } from '../rootReducer'

export interface FeedsProps {
  main: PostProps[]
  pages: PostProps[]
  [key: string]: PostProps[]
}

export interface FeedSliceProps {
  posts: FeedsProps
  refreshScheduledPostFeed: boolean
}

export interface FeedsPayload {
  id: string
  posts: PostProps[]
}

const initialState: FeedSliceProps = {
  posts: {
    main: [],
    pages: [],
  },
  refreshScheduledPostFeed: false,
}

const FeedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    initializeFeed(state: FeedSliceProps, action: PayloadAction<PostProps[]>) {
      state.posts.main = action.payload
    },
    updatePost(state: FeedSliceProps, action: PayloadAction<PostProps>) {
      state.posts.main = state.posts.main.map((post) =>
        post.id === action.payload.id ? action.payload : post
      )
      state.posts.pages = state.posts.pages.map((post) =>
        post.id === action.payload.id ? action.payload : post
      )
      const communityId = action.payload.community?.id
      if (communityId) {
        const communityPosts = state.posts?.[communityId] ?? []
        if (Array.isArray(communityPosts)) {
          state.posts[communityId] = communityPosts.map((post) =>
            post.id === action.payload.id ? action.payload : post
          ) ?? [action.payload]
        } else {
          state.posts[communityId] = [action.payload]
        }
      }
    },
    removeFeed(state: FeedSliceProps, action: PayloadAction<string>) {
      if (!state.posts[action.payload]) return
      // Grab post ids from feed that is going to be removed, we'll use this
      // to remove the psots from the `pages` feed.
      const postsToRemoveById = state.posts[action.payload].map(
        (post) => post.id
      )
      state.posts.pages = state.posts.pages.filter(
        (post) => post.id && !postsToRemoveById.includes(post.id)
      )
      // delete state.posts[action.payload]
    },
    setFeed(state: FeedSliceProps, action: PayloadAction<FeedsPayload>) {
      if (action.payload.id === 'main') {
        state.posts.main = action.payload.posts
      } else if (action.payload.id === 'pages') {
        state.posts.pages = action.payload.posts
      } else {
        state.posts[action.payload.id] = action.payload.posts
        // update pages feed
        if (action.payload.id.startsWith('co-')) {
          state.posts.pages = [
            ...action.payload.posts,
            ...(state.posts.pages ?? []),
          ]
            .filter(
              (post, index, self) =>
                index === self.findIndex((p) => p.id === post.id)
            )
            .sort(
              (a, b) =>
                new Date(b.createdAt ?? '').getTime() -
                new Date(a.createdAt ?? '').getTime()
            )
        }
      }
    },
    clearFeed(state) {
      state.posts = initialState.posts
    },
    removePost(
      state,
      action: PayloadAction<{ feedId: string; postId: string }>
    ) {
      state.posts[action.payload.feedId] = state.posts[
        action.payload.feedId
      ].filter((post) => post.id !== action.payload.postId)
    },
    setRefreshScheduledPostFeed(state) {
      state.refreshScheduledPostFeed = true
    },
    clearRefreshScheduledPostFeed(state) {
      state.refreshScheduledPostFeed = false
    },
  },
})

export const {
  clearFeed,
  clearRefreshScheduledPostFeed,
  initializeFeed,
  removeFeed,
  removePost,
  setFeed,
  setRefreshScheduledPostFeed,
  updatePost,
} = FeedSlice.actions
export default FeedSlice.reducer

export const selectMainFeed = (state: RootState) => state.feed.posts.main
export const selectRefreshScheduledPostFeed = (state: RootState) =>
  state.feed.refreshScheduledPostFeed
