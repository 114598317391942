import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'
import { Button, Modal } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'
import {
  mutationPostRemoveFromTimeline,
  MutationPostRemoveFromTimelineRequest,
  PostFeedbackReason,
} from '@sportsyou/api'

interface Props {
  isVisible: boolean
  onClose: () => void
  onSubmit: (id: string) => void
  postId?: string
  reasons?: Array<PostFeedbackReason>
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type HideAdModalProps = Props & NativeAttributes

export const HideAd: React.FC<HideAdModalProps> = ({
  isVisible,
  onClose,
  onSubmit,
  postId,
  reasons,
}: HideAdModalProps) => {
  const { fetch: removePostFromTimeline } = useFetchApi(
    mutationPostRemoveFromTimeline
  )

  const [reasonIndex, setReasonIndex] = useState<number>()

  if (!reasons || !postId) return null

  const handleOnClose = () => {
    setReasonIndex(undefined)
    onClose()
  }

  const handleOnHide = async () => {
    if (typeof reasonIndex === 'undefined') return
    const reasonCode = reasons[reasonIndex].id
    const { error, ok } = await removePostFromTimeline({
      postId,
      reasonCode,
    } as MutationPostRemoveFromTimelineRequest)
    if (ok) {
      handleOnClose()
      onSubmit(postId)
    }
    if (error) {
      console.log({ error })
    }
  }

  return (
    <Modal onClose={handleOnClose} visible={isVisible}>
      <Modal.Header>
        <b>Hide</b>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: 20, margin: 0 }}>
          <b>Ad Hidden</b>
        </p>
        <p
          style={{
            color: Colors.SHUTTLE_GRAY,
            fontSize: 16,
            marginBottom: 0,
            marginTop: 4,
          }}
        >
          Why don't you want to see this?
          {/* Please tell us why you hid this ad */}
        </p>
        <ReasonsContainer>
          {reasons?.map((reason, index) => (
            <LargeButton
              onClick={() => setReasonIndex(index)}
              key={reason.id}
              isSelected={index === reasonIndex}
            >
              {reason.description}
            </LargeButton>
          ))}
        </ReasonsContainer>
      </Modal.Body>
      <Modal.Footer
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          appearance='minimal'
          onClick={handleOnClose}
          style={{ marginRight: 6 }}
        >
          Cancel
        </Button>
        <Button
          disabled={typeof reasonIndex === 'undefined'}
          onClick={handleOnHide}
        >
          Hide
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const ReasonsContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
`
const LargeButton = styled.button<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? Colors.MYSTIC : Colors.WHITE};
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
  padding: 16px 10px;
  text-align: left;
  transition: 120ms ease-in-out;
  &:first-of-type {
    margin-top: 10px;
  }
  & + & {
    margin-top: 4px;
  }
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`

export default HideAd
