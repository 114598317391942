import styled, { css } from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Icon } from '@sportsyou/react-dom-ui'
import { CSSProperties } from 'react'

export interface DragHandleProps {
  className?: string
  disabled?: boolean
  style?: CSSProperties
}

export const DragHandle: React.FC<DragHandleProps> = (
  props: DragHandleProps
) => {
  return (
    <Handle {...props}>
      <Icon fill={Colors.SHUTTLE_GRAY} name='Drag' />
    </Handle>
  )
}

const Handle = styled.div<DragHandleProps>`
  align-items: center;
  cursor: grab;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  opacity: 0.3;
  width: 24px;

  &:active {
    cursor: grabbing;
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          &,
          &:hover,
          &:active {
            opacity: 0;
            cursor: default;
          }
        `
      : ''}
`

export default DragHandle
