import moment from 'moment'
import { Notification } from '@sportsyou/api'
import { getTimezone, setPossessive } from '@sportsyou/core'

export type BrowserNotificationResponse = {
  message?: string
  title?: string
  url?: string
}

export interface ExtendedNotification extends Notification {
  isAccepted?: boolean
  isRejected?: boolean
}

export const generateBrowserNotificationMessage = (
  notification: ExtendedNotification,
  currentUserId?: string
): BrowserNotificationResponse => {
  let message = notification.message ?? undefined,
    title,
    url

  const {
    albumId,
    albumName,
    // eventAllDay,
    eventDetail,
    // eventEnd,
    eventId,
    eventStart,
    eventTitle,
    eventTypeId = 1,
    id,
    isAccepted,
    isRejected,
    joined,
    // joinedAt,
    postId,
    sourceId,
    sourceName,
    // targetId,
    teamId,
    teamName = '',
    teamType = 'team',
  } = notification

  const isNotiForTeamGroup = teamId ? true : false

  let _eventTitle = eventTitle,
    _eventOwnerName
  const _eventType = eventTypeId === 1 ? 'event' : 'game'

  const timezone = getTimezone()

  const dateString = moment(eventStart ?? '')
    .tz(timezone)
    .format('LL')
  const timeString = moment(eventStart ?? '')
    .tz(timezone)
    .format('h:mm a (z)')

  // console.log({ timezone })

  const _eventDate =
    eventDetail && !eventDetail.allDay
      ? `${dateString} @ ${timeString}`
      : dateString

  // let myAlert

  switch (notification.type) {
    case 'album':
      message = `${sourceName} created a new album (${albumName}) in ${teamName}`
      url = `/${teamType}s/${teamId}/media/albums/${albumId}`
      break

    /**
     * Events
     */
    case 'eventCreate':
      _eventOwnerName = setPossessive(teamName ?? '')
      if (isNotiForTeamGroup) {
        _eventTitle = `${_eventOwnerName} ${_eventType} ${_eventTitle}`
      }
      message = `invited you to ${_eventTitle}, ${_eventDate}`
      url = `/calendar/${eventId}`
      break
    // TODO
    case 'eventUpdate':
      break
    case 'eventDelete':
      _eventOwnerName = isNotiForTeamGroup
        ? setPossessive(teamName ?? '')
        : setPossessive(sourceName ?? '')
      message = `${_eventOwnerName} ${_eventType} ${_eventTitle} has been deleted`
      break

    case 'file':
      message = `${sourceName} shared a file with you`
      url = '/folders/'
      break

    /**
     * Friends
     */
    case 'friend':
      message =
        !isAccepted || isRejected
          ? `${sourceName} wants to be your contact`
          : undefined
      break
    case 'friend-accept':
      message = `${sourceName} has accepted your contact request`
      url = `/profile/${sourceId}`
      break

    /**
     * Posts
     */
    case 'poll':
      const expPrefix = 'Poll will close at'
      let expTime = ''
      let _message = message
      if (message?.startsWith(expPrefix)) {
        expTime = message.substring(expPrefix.length + 1)
        _message = expPrefix
      }
      _eventOwnerName = isNotiForTeamGroup ? 'you' : sourceName
      switch (_message) {
        case 'Poll will close at':
          message = `The poll from ${_eventOwnerName} will close at ${expTime}`
          break
        case 'Poll is closed':
          message = `The poll from ${_eventOwnerName} is closed`
          break
        default:
      }
      break
    case 'post':
      switch (message) {
        case 'Posted a poll in the team':
        case 'Posted a poll in the group':
          message = `${sourceName} created a poll in ${teamName}`
          break
        case 'Posted in the team':
        case 'Posted in the group':
          message = `${sourceName} posted in ${teamName}`
          break
        case 'Liked your post':
          message = isNotiForTeamGroup
            ? `${sourceName} liked your post in ${teamName}`
            : `${sourceName} liked your post`
          break
        case 'Liked your playlist':
          message = `${sourceName} liked your playlist in ${teamName}`
          break
        case 'Commented on your post':
          message = `${sourceName} commented on your post`
          break
        case 'Your post has been approved':
          message = `Your post in ${teamName} has been approved`
          break
        case 'Posted a playlist':
          message = `${sourceName} has posted a playlist in ${teamName}`
          break
        default:
          if (message?.includes('Commented on your post in the')) {
            message = `${sourceName} commented on your post in ${teamName}`
          } else if (message?.includes('Commented on post by')) {
            let postBy
            if (isNotiForTeamGroup) {
              postBy = message
                .split('Commented on post by ')[1]
                .split(' in the ')[0]
            } else {
              postBy = message.split('Commented on post by ')[1]
            }
            postBy = setPossessive(postBy)
            message = `${sourceName} commented on ${postBy} post${
              (isNotiForTeamGroup && ' in ' + teamName) || ''
            }`
          } else if (message?.includes('Commented on your playlist on')) {
            message = `${sourceName} commented on your playlist on ${teamName}`
          } else if (message?.includes('Commented on playlist by')) {
            let postBy = message
              .split('Commented on playlist by ')[1]
              .split(' on ')[0]
            postBy = setPossessive(postBy)
            message = `${sourceName} commented on ${postBy} playlist on ${teamName}`
          }
      }
      // if (isNotiForTeamGroup) {
      url = `/post/${postId}`
      // }
      break

    case 'reminder':
      const isMyAlert = id === currentUserId
      const _startsIn = message?.split(' starts in ')[1]
      if (isNotiForTeamGroup) {
        _eventOwnerName = setPossessive(teamName ?? '')
      } else if (isMyAlert) {
        _eventOwnerName = 'Your'
      } else {
        _eventOwnerName = setPossessive(sourceName ?? '')
      }
      message = `${_eventOwnerName} ${_eventType} ${_eventTitle} starts in ${_startsIn}`
      url = `/caldendar/${eventId}`
      break

    /**
     * Teams
     */
    case 'team':
      if (joined) {
        if (message?.includes('Added you to')) {
          message = `${sourceName} invited you to the ${teamType} ${teamName}`
        } else {
          message = message?.includes('give access to sportsYou Video')
            ? `${sourceName} has given you access to sportsYou Video on ${teamName}. Log into your account on sportsyou.com in your desktop browser to get started.`
            : undefined
        }
      }
      break
    case 'team-setAdmin':
      if (message && message.indexOf('photographer') > -1) {
        message = `${sourceName} made you a photographer of ${teamName}`
      } else {
        message = `${sourceName} made you an admin of ${teamName}`
      }
      url = `/${teamType === 'team' ? 'team' : 'group'}s/${teamId}`
      break

    default:
      break
  }

  return {
    message,
    title,
    url,
  }
}
