import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button, Modal, TextInput, useDialog } from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'
import {
  mutationTeamMemberSetAlias,
  MutationTeamMemberSetAliasRequest,
  Team,
} from '@sportsyou/api'

import { updateTeam } from 'web/store/slices/TeamsSlice'
import { selectCurrentUser } from 'web/store/slices/UserSlice'
import { getFakeUser } from 'web/constants'

interface Props {
  aliases?: Array<string>
  isVisible: boolean
  onCancel?: () => void
  onClose: () => void
  onSave?: () => void
  team: Team
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type PlayerAliasModalProps = Props & NativeAttributes

export const PlayerAliasModal: React.FC<PlayerAliasModalProps> = ({
  aliases: initialAliases = [],
  onCancel,
  onClose,
  onSave,
  isVisible,
  team,
}: PlayerAliasModalProps) => {
  const { fetch: setAliases, isFetching } = useFetchApi(
    mutationTeamMemberSetAlias
  )
  const { sendBanner } = useDialog()
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  const [playerInfo1, setPlayerInfo1] = useState<string>(
    initialAliases[0] ?? ''
  )
  const [playerInfo2, setPlayerInfo2] = useState<string>(
    initialAliases[1] ?? ''
  )
  const [showSecondInput, setShowSecondInput] = useState(
    initialAliases.length > 1 ?? false
  )

  useEffect(() => {
    setPlayerInfo1(initialAliases[0] ?? '')
    setPlayerInfo2(initialAliases[1] ?? '')

    setShowSecondInput(initialAliases.length === 2)
  }, [initialAliases])

  const handleOnClose = () => {
    onClose()
    setPlayerInfo1(initialAliases[0] ?? '')
    setPlayerInfo2(initialAliases[1] ?? '')
  }

  const handleOnSave = async () => {
    const aliases = [playerInfo1, playerInfo2].filter((alias) => !!alias)

    const { error, ok } = await setAliases({
      aliases,
      teamId: team.id,
    } as MutationTeamMemberSetAliasRequest)

    if (ok) {
      sendBanner({
        autoDismiss: true,
        status: 'success',
        message: 'Player info updated successfully',
      })

      const _members = [...team.members!].map((m) => {
        console.log(m?.userId, currentUser.id, m?.userId === currentUser.id)
        return {
          ...m,
          aliases: m?.userId === currentUser.id ? aliases : m?.aliases,
        }
      })

      dispatch(
        updateTeam({
          ...team!,
          members: _members,
        })
      )

      handleOnClose()
    }

    if (error) {
      console.log({ error })
    }
  }

  return (
    <Modal visible={isVisible} onClose={handleOnClose} contentWidth={'unset'}>
      <Modal.Header>
        <b>{initialAliases.length > 0 ? 'Edit' : 'Add'} Player Info</b>
      </Modal.Header>
      <Modal.Body>
        <TextInput
          aria-placeholder={getFakeUser('fullName') as string}
          label='Player name'
          maxLength={35}
          onChange={(e) => setPlayerInfo1(e.target.value)}
          placeholder={getFakeUser('fullName') as string}
          type='text'
          value={playerInfo1}
        />
        <div style={{ marginTop: 10 }} />
        {!showSecondInput ? (
          <Button appearance='minimal' onClick={() => setShowSecondInput(true)}>
            Add another player
          </Button>
        ) : (
          <TextInput
            aria-placeholder={getFakeUser('fullName') as string}
            label='Player name'
            maxLength={35}
            onChange={(e) => setPlayerInfo2(e.target.value)}
            placeholder={getFakeUser('fullName') as string}
            type='text'
            value={playerInfo2}
          />
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CancelButton appearance='minimal' onClick={handleOnClose}>
          Cancel
        </CancelButton>
        <Button disabled={isFetching} onClick={handleOnSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const CancelButton = styled(Button)`
  margin-right: 10px;
`

export default PlayerAliasModal
