import { CSSProperties } from 'react'
import styled from 'styled-components'

import { ChatBoxProps } from '../../../store/slices/ChatBoxSlice'
import ChatRoom from '../../pages/Chat/ChatRoom'

interface ChatBoxPropsExtended extends ChatBoxProps {
  className?: string
  focused?: boolean
  isSmallScreen?: boolean
  style?: CSSProperties
}

// a chat box fixed to the bottom of the screen
export default function ChatBox(props: ChatBoxPropsExtended) {
  const { chatId, className, focused, isSmallScreen, minimized, style } = props

  if (!chatId) {
    return null
  }

  return (
    <Container
      className={className}
      isSmallScreen={isSmallScreen}
      minimized={minimized}
      style={style}
    >
      <ChatRoom
        boxMode
        chatId={chatId}
        focused={focused || !minimized}
        minimized={minimized}
      />
    </Container>
  )
}

const Container = styled.div<{ isSmallScreen?: boolean; minimized?: boolean }>`
  // box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.4);
  display: inline-flex;
  flex-direction: column;
  height: ${({ isSmallScreen, minimized }) =>
    isSmallScreen ? '100%' : minimized ? undefined /* '50px' */ : '380px'};
  margin-left: ${({ isSmallScreen }) => (isSmallScreen ? undefined : '10px')};
  width: ${({ isSmallScreen }) => (isSmallScreen ? '100%' : '260px')};
  // border-radius: 8px;
`
