import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { querySports, Sport } from '@sportsyou/api'

import { RootState } from '../rootReducer'

export interface SportsSliceProps {
  sports?: Sport[]
}

const initialState: SportsSliceProps = {
  sports: [],
}

const SportsSlice = createSlice({
  name: 'sports',
  initialState,
  reducers: {
    setSports(state, action: { payload: Sport[] }) {
      state.sports = action.payload
    },
  },
})

export const { setSports } = SportsSlice.actions
export default SportsSlice.reducer

export const selectSports = (state: RootState) => state.sports.sports

export const fetchSports = () => async (dispatch: Dispatch) => {
  const { data } = await querySports()
  if (data) {
    dispatch(setSports(data))
  }
}
