import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { isEqual } from 'lodash'

import {
  Activity,
  BORDER_RADIUS,
  Button,
  CoverImage,
  Dropdown,
  EmptyState,
  FadeInContainer,
  Icon,
  Tabs,
  useDialog,
} from '@sportsyou/react-dom-ui'
import {
  Event as EventProps,
  Friend as FriendProps,
  Image,
  MutationTeamConvertRequest,
  MutationTeamMemberRemoveRequest,
  Profile as ProfileProps,
  Team as TeamProps,
  TeamMember as TeamMemberProps,
  Upload,
  User as UserProps,
  mutationFriendCreate,
  mutationFriendReject,
  mutationTeamConvert,
  mutationTeamDelete,
  mutationTeamMemberRemove,
  mutationTeamSetPhoto,
  mutationUserSetPhoto,
  queryCanFriendUser,
  queryProfile,
  queryUser,
  mutationFriendCancel,
  TeamMember,
  queryTeam,
  QueryTeamRequest,
} from '@sportsyou/api'
import { capitalize, Colors, getImageByTranscodeType } from '@sportsyou/core'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'

import { EditProfile } from '../components/Forms/EditProfile'
import { FriendList } from '../components/FriendList/FriendList'
import { MediaList, MediaType } from '../components/MediaList/MediaList'
import { TeamMemberList } from '../components/TeamMemberList/TeamMemberList'

import { ProfileCalendarTab, ProfileFeedTab } from './modules/Profile'
import { publicImagesPath, SMALL_SCREEN_BREAKPOINT } from '../../constants'

import { RootState } from '../../store/rootReducer'
import {
  fetchTeams,
  removeTeam,
  updateTeam,
} from '../../store/slices/TeamsSlice'
import {
  selectCurrentUser,
  selectProfileById,
  setProfile,
  updateFriends,
  updateUser,
} from '../../store/slices/UserSlice'
import { showChatCreateModal } from '../../store/slices/ChatCreateModalSlice'

import { CHAT_MESSAGE_FILTER, useChat } from '../hooks/useChat'
import { useAddMembers } from '../hooks/useAddMembers'
import { useAnalytics } from '../hooks/useAnalytics'

import CalendarImport from '../pages/Teams/CalendarImport'

import { ProfileCalendarTabRef } from './modules/Profile/Calendar'

interface DropdownMenuItem {
  isDestructive?: boolean
  onClick?: () => void
  title?: string
}

export type TabName = 'feed' | 'contacts' | 'calendar' | 'media' | 'members'

/* builds a relative path to the user or team profile */
export const profilePath = (profileId = '', section?: string): string => {
  let path = `/profile/${profileId}`
  if (profileId.startsWith('te-')) {
    path = `/teams/${profileId}`
  }
  if (section) {
    return `${path}/${section}`
  }
  return path
}

const tabToIndex = (tabName?: TabName, isFriend?: boolean): number => {
  if (['contacts', 'members'].includes(tabName ?? '')) {
    return 1
  }
  if (tabName === 'calendar') {
    return 2
  }
  if (tabName === 'media') {
    return isFriend ? 2 : 3
  }

  return 0 // feed
}

const DEFAULT_COVER_IMAGE = `${publicImagesPath}/default-coverimage.png`

type AvatarParams = { name?: string; url?: string }

export interface ProfileLayoutProps {
  albumId?: string
  events?: Array<EventProps>
  intro?: string
  mediaType?: MediaType
  onRefreshTeam?: () => void
  onTeamSettingsClick?: () => void
  playerAliasModalInitiallyVisible?: boolean
  profileId?: string
  tab?: TabName
  team?: TeamProps
  user?: UserProps
}

export const ProfileLayout: React.FC<
  React.PropsWithChildren<ProfileLayoutProps>
> = (props: ProfileLayoutProps) => {
  const { addMembers } = useAddMembers()
  const { setUserProperties } = useAnalytics()
  const { sendBanner, sendConfirm } = useDialog()
  const dispatch = useDispatch()
  const mQuery = window.matchMedia(`(max-width: ${SMALL_SCREEN_BREAKPOINT})`)
  const navigate = useNavigate()
  const chatClient = useChat({
    establishConnection: false,
    filter: CHAT_MESSAGE_FILTER.LISTEN_FOR_NONE,
  })
  const params = useParams()

  const teamType = useMemo(() => props.team?.type ?? 'team', [props.team])
  const teamTypeCapitalized = useMemo(() => capitalize(teamType), [teamType])

  const currentUser = useSelector(selectCurrentUser)
  const { friends: friendsFromStore } = useSelector(
    (state: RootState) => state.user
  )
  const profile = useSelector((state: RootState) =>
    selectProfileById(state, props.profileId)
  )

  const { fetch: addFriend } = useFetchApi(mutationFriendCreate)
  const { fetch: cancelFriendRequest } = useFetchApi(mutationFriendCancel)
  const { fetch: canFriendUser } = useFetchApi(queryCanFriendUser)
  const { fetch: convertTeam } = useFetchApi(mutationTeamConvert)
  const { fetch: deleteTeam } = useFetchApi(mutationTeamDelete)
  const { fetch: getProfile } = useFetchApi(queryProfile)
  const { fetch: getTeam } = useFetchApi(queryTeam)
  const { fetch: getUser } = useFetchApi(queryUser)
  const { fetch: leaveTeam } = useFetchApi(mutationTeamMemberRemove)
  const { fetch: removeFriend } = useFetchApi(mutationFriendReject)
  const { fetch: setProfileImage } = useFetchApi(mutationUserSetPhoto)
  const { fetch: setTeamImage } = useFetchApi(mutationTeamSetPhoto)

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const profileCalendarTabRef = useRef<ProfileCalendarTabRef>(null)

  const [isFriendRequestSent, setIsFriendRequestSent] = useState<boolean>(false)
  const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false)
  const [isCalendarImportModalVisible, setIsCalendarImportModalVisible] =
    useState<boolean>(false)
  const [isCoverImageLoading, setIsCoverImageLoading] = useState<boolean>(false)
  const [isEditingIntro, setIsEditingIntro] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSmallScreen, setIsSmallScreen] = useState(mQuery.matches)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [user, setUser] = useState<ProfileProps>(profile)
  // Creates a unique key for the page. We use this to reload the page when
  // routing to another profile page.
  // TODO: Reserach a better solution which my involve a rewrite of the `Tabs` component.
  const [pageKey, setPageKey] = useState(Math.random())
  const [isEditProfileVisible, setIsEditProfileVisible] =
    useState<boolean>(false)

  const [avatarParams, setAvatarParams] = useState<AvatarParams>(() => ({
    name: profile?.fullName ?? '',
    url:
      getImageByTranscodeType(
        (profile?.profileImage as Image[]) ?? [],
        'profileImage'
      ) ?? '',
  }))

  const [coverImageSrc, setCoverImageSrc] = useState<string>(
    () =>
      getImageByTranscodeType(
        (profile?.coverImage as Image[]) ?? [],
        'coverImage'
      ) ?? DEFAULT_COVER_IMAGE
  )

  const refreshCalendars = useCallback(() => {
    profileCalendarTabRef.current?.fetchEvents()
  }, [])

  const fetchProfileData = useCallback(async () => {
    if (props.profileId?.startsWith('us-')) {
      const { data, error } = await getProfile({ id: props.profileId })
      if (error) {
        console.log({ error })
      }
      if (data) {
        console.log('user_profile', { data })
        dispatch(setProfile(data))
        setUser(data)
        setAvatarParams({
          name: data?.fullName ?? '',
          url: data?.profileImage?.[0]?.viewUrl ?? '',
        })
        const coverImages = (data.coverImage ?? []).map((img) => img as Image)
        // TODO: `transcodeType` for cover-image might be missing
        setCoverImageSrc(
          getImageByTranscodeType(coverImages, 'coverImage') ??
            DEFAULT_COVER_IMAGE
        )
      }
    } else if (props.profileId?.startsWith('te-')) {
      setAvatarParams({
        name: props.team?.name ?? `SY ${teamTypeCapitalized}`,
        url: props.team?.profileImage?.[0]?.viewUrl ?? '',
      })
      setCoverImageSrc(
        props.team?.coverImage?.[0]?.viewUrl ?? DEFAULT_COVER_IMAGE
      )
    }
    setIsLoading(false)
  }, [
    dispatch,
    getProfile,
    props.profileId,
    props.team?.coverImage,
    props.team?.name,
    props.team?.profileImage,
    teamTypeCapitalized,
  ])

  const isCurrentUser = useMemo(
    () => currentUser.id === props.profileId,
    [currentUser.id, props.profileId]
  )

  const isTeam = useMemo(
    () => !!props.profileId?.startsWith('te-'),
    [props.profileId]
  )

  const isFriend = useMemo(() => {
    if (isTeam || isCurrentUser) {
      return false
    } else {
      return !!user?.friends?.some((f) => f?.id === currentUser.id)
    }
  }, [isTeam, isCurrentUser, user?.friends, currentUser.id])

  const canEditCoverImage = useMemo(() => {
    const { id: userId } = currentUser
    const currentProfileId = props.profileId ?? props.user?.id

    if (props.team?.isAdmin || props.team?.adminRoles?.includes('admin')) {
      return true
    } else if (props.team?.admins) {
      const adminIds = props.team.admins.map((a) => a?.userId)
      return adminIds.includes(userId)
    }

    return userId === currentProfileId
  }, [props.user?.id, props.profileId, props.team, currentUser])

  const canEditMedia = useMemo(() => {
    if (isTeam) {
      if (props.team?.isPhotographer) {
        return true
      }
      return !!props.team?.canEdit
    }
    if (isCurrentUser) {
      return true
    }
    return false
  }, [isTeam, isCurrentUser, props.team?.isPhotographer, props.team?.canEdit])

  const numberOfTabs = useMemo((): number => {
    let tabCount = 0 // semi-colon is necessary because of next line

    // mirroring entry for each Tabs.Item rendering condition
    ;(isTeam || isFriend || isCurrentUser) && tabCount++ // feed tab
    props.team && tabCount++ // members tab
    !!user && tabCount++ // friends tab
    ;(isTeam || isCurrentUser) && tabCount++ // calendar tab
    ;(isTeam || isFriend || isCurrentUser) && tabCount++ // media tab

    return tabCount
  }, [isCurrentUser, isFriend, isTeam, props.team, user])

  useEffect(() => {
    setAvatarParams({
      name: profile?.fullName ?? '',
      url:
        getImageByTranscodeType(
          (profile?.profileImage as Image[]) ?? [],
          'profileImage'
        ) ?? '',
    })
  }, [profile.fullName, profile.profileImage])

  useEffect(() => {
    setCoverImageSrc(
      getImageByTranscodeType(profile?.coverImage as Image[], 'coverImage') ??
        DEFAULT_COVER_IMAGE
    )
  }, [profile.coverImage])

  useEffect(() => {
    if (isCurrentUser) {
      setAvatarParams({
        name: currentUser.fullName ?? '',
        url: getImageByTranscodeType(
          (currentUser.profileImage as Image[]) ?? [],
          'profileImage'
        ),
      })
    }
  }, [currentUser.fullName, currentUser.profileImage, isCurrentUser])

  useEffect(() => {
    fetchProfileData()
  }, [fetchProfileData])

  // We can detect a change in profiles when the id changes, updated the
  // `pageKey` so we can reset the `Tabs` index to 0
  useEffect(() => {
    setPageKey(Math.random())
  }, [props.profileId])

  useEffect(() => {
    mQuery.addEventListener('change', (e) => setIsSmallScreen(e.matches))
    return () => {
      mQuery.removeEventListener('change', (e) => setIsSmallScreen(e.matches))
    }
  }, [mQuery])

  useEffect(() => {
    if (isEditingIntro) {
      setTimeout(() => {
        textAreaRef.current?.focus()
      }, 100)
    }
  }, [isEditingIntro])

  useEffect(() => {
    async function checkCanFriend() {
      const { data } = await canFriendUser({
        targetUserId: props.profileId,
      })
      if (data) {
        setIsFriendRequestSent(data.lastStatus === 'sent')
      }
    }
    if (!isTeam && !isCurrentUser && !isFriend) {
      checkCanFriend()
    }
  }, [props.profileId, user, isCurrentUser, canFriendUser, isTeam, isFriend])

  // TODO: Can this be a useMemo instead?
  useEffect(() => {
    const _tab = (params.tab ?? props.tab ?? 'feed') as TabName
    /**
     * make sure tabIndex stays within currently available tab index
     * as number of tabs changes while loading necessary data
     */
    const _tabIndex = Math.max(
      Math.min(tabToIndex(_tab, isFriend), numberOfTabs - 1),
      0
    )
    setTabIndex(_tabIndex)
  }, [isFriend, numberOfTabs, params.tab, props.tab])

  const onClickDelete = useCallback(async () => {
    sendConfirm({
      confirmText: 'Delete',
      isDestructive: true,
      onConfirm: async () => {
        const { ok } = await deleteTeam({ teamId: props.team?.id })
        if (ok) {
          setUserProperties() // update analytics
          sendBanner({
            autoDismiss: true,
            dismissTime: 6000,
            message: `${teamTypeCapitalized} deleted succesfully`,
            status: 'success',
          })
          dispatch(removeTeam(props.team!))
          navigate('/home')
        } else {
          sendBanner({
            autoDismiss: true,
            dismissTime: 6000,
            message: `There was an error deleting the ${teamType}`,
            status: 'danger',
          })
        }
      },
      message: `Are you sure you want to delete this ${teamType}? This action can not be undone.`,
      title: `Delete ${teamTypeCapitalized}`,
    })
  }, [
    deleteTeam,
    dispatch,
    navigate,
    props.team,
    sendBanner,
    sendConfirm,
    teamType,
    teamTypeCapitalized,
  ])

  const onClickLeaveTeam = useCallback(() => {
    sendConfirm({
      confirmText: 'Leave',
      isDestructive: true,
      message: `Are you sure you want to leave the ${
        props.team?.type ?? 'team'
      }?`,
      onConfirm: async () => {
        const { data, error, ok } = await leaveTeam({
          teamId: props.profileId,
        } as MutationTeamMemberRemoveRequest)
        if (data && ok) {
          // update analytics
          if (props.team?.isAdmin) setUserProperties()
          sendBanner({
            autoDismiss: true,
            status: 'success',
            message: `You left ${props.team?.name}`,
          })
          dispatch(removeTeam(props.team!))
          navigate('/teams')
        }
        if (error) {
          console.log({ error })
        }
      },
    })
  }, [
    dispatch,
    leaveTeam,
    navigate,
    props.profileId,
    props.team,
    sendBanner,
    sendConfirm,
  ])

  const onClickStartFriendChat = useCallback(async () => {
    chatClient.startChatWithUser({
      showUI: true,
      userId: props.profileId!,
    })
  }, [chatClient, props.profileId])

  const onClickStartTeamChat = useCallback(async () => {
    let canChat = props.team?.canTeamChat && !props.team.isLargeTeam

    const { data } = await getTeam({
      id: props.team?.id,
      includeDeactivatedUsers: false,
      includeLargeTeamMembers: false,
    } as QueryTeamRequest)

    if (data && !isEqual(data, props.team)) {
      // update store
      dispatch(updateTeam(data))
      canChat = !!data.isAdmin || !!data.canTeamChat
    }

    if (canChat) {
      dispatch(
        showChatCreateModal({
          showTeamPicker: false,
          teamId: props.team?.id,
        })
      )
    } else {
      sendBanner({
        autoDismiss: true,
        message: `Chat has been disabled for this ${data?.type ?? 'team'}.`,
      })
    }
  }, [dispatch, props.team?.id])

  const onClickToggleFriendship = useCallback(async () => {
    if (isFriend) {
      const onConfirm = async () => {
        const { data, ok } = await removeFriend({
          targetId: props.profileId!,
        })
        if (data && ok) {
          sendBanner({
            autoDismiss: true,
            dismissTime: 6000,
            status: 'success',
            message: `${user?.fullName} has been removed from your contacts list.`,
          })
          const newFriends = friendsFromStore.filter(
            (f) => f.id !== props.profileId!
          )
          dispatch(updateFriends(newFriends))
          navigate('/home')
        }
      }
      sendConfirm({
        onConfirm,
        cancelText: 'Cancel',
        confirmText: 'Remove',
        message: `Are you sure you want to remove this contact?`,
        title: 'Remove contact?',
      })
    } else {
      if (isFriendRequestSent) {
        const { data, ok } = await cancelFriendRequest({
          targetId: props.profileId!,
        })
        if (data && ok) {
          sendBanner({
            autoDismiss: true,
            dismissTime: 6000,
            status: 'success',
            message: 'Contact request cancelled.',
          })
          setIsFriendRequestSent(false)
        }
      } else {
        const { data, ok } = await addFriend({
          friendId: props.profileId!,
        })
        if (data && ok) {
          sendBanner({
            autoDismiss: true,
            dismissTime: 6000,
            status: 'success',
            message: `Contact request sent to ${user?.fullName}!`,
          })
          setIsFriendRequestSent(true)
        }
      }
    }
  }, [
    addFriend,
    cancelFriendRequest,
    dispatch,
    friendsFromStore,
    isFriend,
    isFriendRequestSent,
    navigate,
    props.profileId,
    removeFriend,
    sendBanner,
    sendConfirm,
    user?.fullName,
  ])

  const dropdownMenuItems = useMemo(() => {
    const items: Array<DropdownMenuItem | 'divider'> = []
    if (!props.team) {
      if (!props.profileId || props.profileId === props.user?.id) {
        items.push({
          onClick: () => navigate('/settings/notifications'),
          title: 'Notification Settings',
        })
        items.push({
          onClick: () => navigate('/settings'),
          title: 'Account Settings',
        })
      } else {
        if (isSmallScreen) {
          if (isFriend) {
            items.push({
              onClick: onClickStartFriendChat,
              title: 'New Chat',
            })
          }

          if (!isTeam && !isCurrentUser) {
            items.push({
              onClick: onClickToggleFriendship,
              title: `${isFriend ? 'Remove Contact' : 'Add Contact'}`,
            })
          }
        }
      }
    } else {
      if (props.team.isAdmin) {
        items.push({
          onClick: props.onTeamSettingsClick,
          title: `${teamTypeCapitalized} Settings`,
        })
      }

      if (isSmallScreen) {
        if (props.team.canEdit) {
          items.push({
            onClick: () =>
              addMembers({
                id: props.team?.id!,
                isVisible: true,
                team: props.team,
              }),
            title: 'Add Members',
          })
        }

        if (props.team.canTeamChat && !props.team.isLargeTeam) {
          items.push({
            onClick: () => onClickStartTeamChat(),
            title: `${teamTypeCapitalized} Chat`,
          })
        }
      }

      items.push({
        onClick: () => navigate(`/calendar/subscribe?teamId=${props.team?.id}`),
        title: `Subscribe to ${teamTypeCapitalized} Calendar`,
      })

      const numOfAdmins =
        props.team.members?.filter((member) => member?.isAdmin).length ?? 0
      const currentTeamUser = props.team.members?.find(
        (member) => member?.userId === (props.user ?? currentUser).id
      )

      if (props.team.isAdmin) {
        items.push({
          onClick: () => setIsCalendarImportModalVisible(true),
          title: `Import External Calendar`,
        })

        items.push({
          onClick: () => {
            sendConfirm({
              onConfirm: async () => {
                const { id, name, type } = props.team ?? {}
                const convertTo = type === 'team' ? 'group' : 'team'
                const { data, error, ok } = await convertTeam({
                  teamId: id,
                  convertTo,
                } as MutationTeamConvertRequest)

                if (data && ok) {
                  sendBanner({
                    autoDismiss: true,
                    dismissTime: 6000,
                    status: 'success',
                    message: `${name} has been successfully changed to a ${convertTo}.`,
                  })

                  dispatch(fetchTeams())
                  if (props.onRefreshTeam) {
                    await props.onRefreshTeam()
                    await fetchProfileData()
                  }
                  navigate(`/${convertTo}s/${id}`)
                }
                if (error) {
                  console.log({ error })
                }
              },
              message: `Are you sure you want to convert ${
                props.team?.name
              } to a ${props.team?.type === 'team' ? 'group' : 'team'}?`,
            })
          },
          title: `Convert to ${capitalize(
            props.team.type === 'team' ? 'group' : 'team'
          )}`,
        })
      }

      if (
        props.team.isAdmin ||
        !currentTeamUser?.isAdmin ||
        (currentTeamUser?.isAdmin && numOfAdmins > 1)
      ) {
        items.push('divider')
      }

      if (
        !currentTeamUser?.isAdmin ||
        (currentTeamUser?.isAdmin && numOfAdmins > 1)
      ) {
        items.push({
          isDestructive: true,
          onClick: onClickLeaveTeam,
          title: `Leave ${teamTypeCapitalized}`,
        })
      }
      if (props.team.isAdmin) {
        items.push({
          isDestructive: true,
          onClick: onClickDelete,
          title: `Delete ${teamTypeCapitalized}`,
        })
      }
    }
    return items
  }, [
    addMembers,
    convertTeam,
    currentUser,
    dispatch,
    fetchProfileData,
    isCurrentUser,
    isFriend,
    isSmallScreen,
    isTeam,
    navigate,
    onClickDelete,
    onClickLeaveTeam,
    onClickStartFriendChat,
    onClickStartTeamChat,
    onClickToggleFriendship,
    props,
    sendBanner,
    sendConfirm,
    teamTypeCapitalized,
  ])

  type ImageUploadType = 'avatar' | 'cover-image'

  const handleOnTranscodeComplete = async (
    upload: Upload,
    type: ImageUploadType
  ) => {
    if (props.team) {
      const { data, error, ok } = await setTeamImage({
        imageType: type === 'avatar' ? 1 : 2,
        teamId: props.profileId,
        uploadId: upload.id,
      })
      if (data && ok) {
        // update local state
        if (upload.viewUrl) {
          if (type === 'avatar') {
            setAvatarParams((prevState) => ({
              ...prevState,
              url: upload.viewUrl ?? undefined,
            }))
          } else if (type === 'cover-image') {
            setCoverImageSrc(upload.viewUrl)
          }
        }
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: data,
        })
      }

      if (error) {
        sendBanner({
          status: 'danger',
          message: 'There was an error, please try again later',
        })
        console.log({ error })
      } else {
        dispatch(fetchTeams(true))
      }
    } else {
      const { data, error, ok } = await setProfileImage({
        imageType: type === 'avatar' ? 1 : 2,
        uploadId: upload.id,
      })
      if (data && ok) {
        // update local state
        if (upload.viewUrl) {
          if (type === 'avatar') {
            setAvatarParams((prevState) => ({
              ...prevState,
              url: upload.viewUrl ?? undefined,
            }))
            if (props.user) {
              const { data: userData, ok: userOk } = await getUser({
                id: props.user.id,
              })
              if (userOk && userData) {
                dispatch(updateUser(userData))
              }
            }
          } else if (type === 'cover-image') {
            setCoverImageSrc(upload.viewUrl)
          }
        }
        sendBanner({
          autoDismiss: true,
          status: 'success',
          message: data,
        })
      }

      if (error) {
        sendBanner({
          status: 'danger',
          message: 'There was an error, please try again later',
        })
        console.log({ error })
      }
    }

    if (type === 'avatar') {
      setIsAvatarLoading(false)
    }
    if (type === 'cover-image') {
      setIsCoverImageLoading(false)
    }
  }
  const handleOnUploadCancel = (type: ImageUploadType) => {}
  const handleOnUploaderError = (type: ImageUploadType) => {}
  const handleOnUploadStart = (
    uploads: ExtendedUpload[],
    type: ImageUploadType
  ) => {
    if (type === 'avatar') {
      setIsAvatarLoading(true)
    }
    if (type === 'cover-image') {
      setIsCoverImageLoading(true)
    }
  }
  const handleOnUploadDone = (type: ImageUploadType) => {}

  return (
    <StyledContainer key={pageKey}>
      {isLoading && <Activity />}
      <Header>
        <CoverImage
          avatarImageSrc={avatarParams.url}
          avatarImageUploaderProps={{
            cancel: () => handleOnUploadCancel('avatar'),
            error: () => handleOnUploaderError('avatar'),
            start: (uploads) => handleOnUploadStart(uploads, 'avatar'),
            done: () => handleOnUploadDone('avatar'),
            transcode: (upload) =>
              upload && handleOnTranscodeComplete(upload, 'avatar'),
          }}
          canEdit={canEditCoverImage}
          coverImageSrc={coverImageSrc}
          coverImageUploaderProps={{
            cancel: () => handleOnUploadCancel('cover-image'),
            error: () => handleOnUploaderError('cover-image'),
            start: (uploads) => handleOnUploadStart(uploads, 'cover-image'),
            done: () => handleOnUploadDone('cover-image'),
            transcode: (upload) =>
              upload && handleOnTranscodeComplete(upload, 'cover-image'),
          }}
          isUploadingAvatar={isAvatarLoading}
          isUploadingCoverImage={isCoverImageLoading}
          name={avatarParams.name}
          // onClickAvatar={() => onClickEditImage('avatar')}
          // onClickCoverImage={() => onClickEditImage('cover-image')}
          variant={
            props.team ? (props.team.type as 'group' | 'team') : 'profile'
          }
        />
      </Header>
      <StyledTabs
        headerStyle={tabsHeaderStyle}
        contentStyle={tabsContentStyle}
        buttonStyle={{ minWidth: isSmallScreen ? 66 : 90 }}
        index={tabIndex}
      >
        {isTeam || isFriend || isCurrentUser ? (
          <Tabs.Item
            onClick={() => navigate(profilePath(props.profileId, 'feed'))}
            title='Feed'
          >
            <ProfileFeedTab
              id={props.profileId}
              isLoading={isLoading}
              isSmallScreen={isSmallScreen}
              onClickEditIntro={() => setIsEditProfileVisible(true)}
              team={props.team}
              user={currentUser.id === user.id ? currentUser : user}
            />
          </Tabs.Item>
        ) : null}

        {props.team ? (
          <Tabs.Item
            onClick={() => navigate(profilePath(props.profileId, 'members'))}
            title='Members'
          >
            {!!props.team && (
              <TeamMemberList
                contentStyle={{ marginBottom: 160 }}
                id={props.profileId}
                members={props.team.members as TeamMember[]}
                onRefreshTeam={props.onRefreshTeam}
                onRemoveTeamMember={async () => {
                  if (props.onRefreshTeam) {
                    await props.onRefreshTeam?.()
                    fetchProfileData()
                  }
                }}
                playerAliasModalInitiallyVisible={
                  props.playerAliasModalInitiallyVisible
                }
                team={props.team}
              />
            )}
          </Tabs.Item>
        ) : null}

        {(isCurrentUser || isFriend) && !isTeam && (
          <Tabs.Item
            onClick={() => navigate(profilePath(props.profileId, 'contacts'))}
            title='Contacts'
          >
            {user.friends?.length ? (
              <FriendList
                searchable
                friends={user.friends as FriendProps[]}
                id={props.profileId}
                onRemoveFriend={() => fetchProfileData()}
              />
            ) : (
              <EmptyState
                header='View Your Contacts'
                icon='UserSolid'
                description='Once you add Contacts, they will show up here.'
              />
            )}
          </Tabs.Item>
        )}

        {(isTeam || isCurrentUser) && (
          <Tabs.Item
            onClick={() =>
              navigate(
                isTeam ? profilePath(props.profileId, 'calendar') : '/calendar'
              )
            }
            title='Calendar'
          >
            <ProfileCalendarTab ref={profileCalendarTabRef} team={props.team} />
          </Tabs.Item>
        )}

        {isTeam || isFriend || isCurrentUser ? (
          <Tabs.Item
            onClick={() =>
              navigate(profilePath(props.profileId, 'media/photos'))
            }
            title='Media'
          >
            <MediaList
              albumId={props.albumId}
              canEdit={canEditMedia}
              id={user?.id ?? props.team?.id ?? undefined}
              isCurrentUser={isCurrentUser}
              listType={isTeam ? 'team' : 'user'}
              mediaType={props.mediaType}
              onProfileMediaChange={fetchProfileData}
              teamId={props.team?.id as string}
            />
          </Tabs.Item>
        ) : null}

        <Tabs.ExtraContent style={{ alignItems: 'center', display: 'flex' }}>
          {!isSmallScreen && (
            <>
              {isFriend && (
                <TabsRightButton
                  appearance='minimal'
                  onClick={onClickStartFriendChat}
                  textStyle={{ alignItems: 'center', display: 'flex' }}
                  variant='alternate'
                >
                  <Icon name='Bubble' style={{ marginRight: 6 }} width={14} />
                  New Chat
                </TabsRightButton>
              )}
              {!isTeam && !isCurrentUser && (
                <FriendButton
                  isFriend={isFriend}
                  isFriendRequestSent={isFriendRequestSent}
                  onClickToggleFriendship={onClickToggleFriendship}
                />
              )}
            </>
          )}

          {user?.canEdit && (
            <TabsRightButton
              appearance='minimal'
              collapse={isSmallScreen}
              onClick={() => setIsEditProfileVisible(true)}
              style={{ marginRight: 10 }}
              textStyle={{ alignItems: 'center', display: 'flex' }}
              variant='alternate'
            >
              <Icon
                height={14}
                name='Pencil'
                style={!isSmallScreen ? { marginRight: 6 } : undefined}
                width={14}
              />
              {!isSmallScreen && 'Edit Profile'}
            </TabsRightButton>
          )}
          {!isSmallScreen && (
            <>
              {props.team?.canTeamChat && !props.team?.isLargeTeam && (
                <TabsRightButton
                  onClick={onClickStartTeamChat}
                  textStyle={{ alignItems: 'center', display: 'flex' }}
                >
                  <Icon name='Bubble' style={{ marginRight: 6 }} size={14} />
                  {teamTypeCapitalized} Chat
                </TabsRightButton>
              )}
              {props.team?.canEdit && (
                <TabsRightButton
                  onClick={() =>
                    addMembers({
                      id: props.team?.id!,
                      isVisible: true,
                      team: props.team,
                    })
                  }
                  textStyle={{ alignItems: 'center', display: 'flex' }}
                >
                  <Icon name='Plus' style={{ marginRight: 6 }} size={14} />
                  Add Members
                </TabsRightButton>
              )}
            </>
          )}
          {dropdownMenuItems.length > 0 && (
            <Dropdown
              itemStyle={{ whiteSpace: 'nowrap' }}
              menuStyles={{ width: 'fit-content' }}
              placement='bottomEnd'
              title={
                <Button
                  appearance='minimal'
                  collapse
                  style={{ marginLeft: 6, marginRight: 10 }}
                  variant='alternate'
                >
                  <Icon name='Gear' width={18} />
                </Button>
              }
              triggerStyle={{
                marginLeft: 6,
                paddingLeft: 20,
                paddingRight: 20,
                minWidth: 1,
              }}
              hideChevron
            >
              {dropdownMenuItems.map((item, index) =>
                item === 'divider' ? (
                  <Dropdown.Divider key={index} />
                ) : (
                  <Dropdown.Item
                    isDestructive={item.isDestructive}
                    key={index}
                    onClick={item.onClick}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {item.title}
                  </Dropdown.Item>
                )
              )}
            </Dropdown>
          )}
        </Tabs.ExtraContent>
      </StyledTabs>
      <EditProfile
        autoFocusIntro={isEditingIntro}
        isVisible={isEditProfileVisible}
        onClose={() => {
          setIsEditingIntro(false)
          setIsEditProfileVisible(false)
        }}
        type={'user'}
        id={user?.id ?? undefined}
      />
      <CalendarImport
        isOpen={isCalendarImportModalVisible}
        onClose={() => setIsCalendarImportModalVisible(false)}
        onRefreshCalendars={refreshCalendars}
        team={props.team}
      />
    </StyledContainer>
  )
}

const FriendButton = ({
  isFriend,
  isFriendRequestSent,
  onClickToggleFriendship,
}: {
  isFriend: boolean
  isFriendRequestSent: boolean
  onClickToggleFriendship: any
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  const onMouseOver = useCallback(() => {
    setIsMouseOver(true)
  }, [])

  const onMouseOut = useCallback(() => {
    setIsMouseOver(false)
  }, [])

  const title = () => {
    if (isFriendRequestSent) {
      return isMouseOver ? 'Cancel Invitation' : 'Invitation Sent'
    } else if (isFriend) {
      return isMouseOver ? 'Remove Contact' : 'Contact'
    } else {
      return isMouseOver ? 'Add Contact' : 'Add Contact'
    }
  }

  return (
    <TabsRightButton
      appearance='minimal'
      onClick={onClickToggleFriendship}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      style={{ marginRight: 10, minWidth: 110 }}
      textStyle={{ alignItems: 'center', display: 'flex' }}
      variant='alternate'
    >
      <Icon name='User' style={{ marginRight: 6 }} width={14} />
      {title()}
    </TabsRightButton>
  )
}

const StyledContainer = styled(FadeInContainer)`
  min-height: 335px;
`

const Header = styled.header`
  border: 1px solid ${Colors.ALTO};
  border-bottom: 0;
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  overflow: hidden;
  @media print {
    border: none;
  }
`
const StyledTabs = styled(Tabs)`
  header {
    @media print {
      display: none;
    }
  }
`
const TabsRightButton = styled(Button)`
  &:disabled {
    background-color: transparent !important;
  }

  & + & {
    margin-left: 6px;
  }
`
const tabsHeaderStyle = {
  border: `1px solid ${Colors.ALTO}`,
  borderTop: 0,
  borderBottomLeftRadius: BORDER_RADIUS,
  borderBottomRightRadius: BORDER_RADIUS,
  marginBottom: 0,
}
const tabsContentStyle = {
  padding: '10px 0 10px',
}

export default ProfileLayout
