import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Modal, TextArea } from '@sportsyou/react-dom-ui'

interface Props {
  commentId?: string
  onClose: () => void
  onReport: (reason: string) => void
  isVisible: boolean
  targetId?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ReportUserModalProps = Props & NativeAttributes

export const ReportUserModal: React.FC<ReportUserModalProps> = ({
  commentId,
  isVisible,
  onClose,
  onReport,
  targetId,
}: ReportUserModalProps) => {
  const [reason, setReason] = useState('')
  const handleOnClose = () => {
    setReason('')
    onClose && onClose()
  }
  const handleOnReport = async () => {
    onReport && (await onReport(reason))
    handleOnClose()
  }
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    setReason(value)
  }
  return (
    <Modal visible={isVisible} onClose={handleOnClose}>
      <Modal.Header>
        <b>Report User</b>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to alert the administrator about this user?</p>
        <p>Please explain your reason for reporting them.</p>

        <TextArea
          label='Reason for Reporting Comment'
          onChange={handleOnChange}
          style={{ width: '100%' }}
          value={reason}
        ></TextArea>
      </Modal.Body>
      <Modal.Footer
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CancelButton
          appearance='minimal'
          onClick={handleOnClose}
          variant='alternate'
        >
          Cancel
        </CancelButton>
        <Button disabled={reason === ''} onClick={handleOnReport}>
          Report
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const CancelButton = styled(Button)`
  margin-right: 6px;
`

export default ReportUserModal
