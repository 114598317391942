import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Folder } from '@sportsyou/api'

export interface FoldersSliceProps {
  folders: Folder[]
  isRenaming?: string
}

const initialState: FoldersSliceProps = {
  folders: [],
  isRenaming: undefined,
}

const FoldersSlice = createSlice({
  name: 'folders',
  initialState: initialState,
  reducers: {
    init(state: FoldersSliceProps, action: PayloadAction<Folder[]>) {
      state.folders = action.payload ?? []
    },
    clear(state) {
      state.folders = []
    },
    update(state: FoldersSliceProps, action: PayloadAction<Folder[]>) {
      const updatedFolders = action.payload ?? []
      updatedFolders.forEach((folder) => {
        const index = state.folders.findIndex((f) => f.id === folder.id)
        if (index > -1) {
          state.folders[index] = folder
        }
      })
    },
    add(state: FoldersSliceProps, action: PayloadAction<Folder[]>) {
      // merge existing folders with new folders by id
      const newFolders = action.payload ?? []
      newFolders.forEach((folder) => {
        const index = state.folders.findIndex((f) => f.id === folder.id)
        if (index > -1) {
          state.folders[index] = { ...state.folders[index], ...folder }
        } else {
          state.folders.push(folder)
        }
      })
    },
    remove(state: FoldersSliceProps, action: PayloadAction<Folder[]>) {
      state.folders = state.folders.filter(
        (folder) => action.payload.findIndex((t) => t.id === folder.id) === -1
      )
    },
    startRenaming(state: FoldersSliceProps, action: PayloadAction<string>) {
      state.isRenaming = action.payload
    },
    stopRenaming(state: FoldersSliceProps) {
      state.isRenaming = undefined
    },
  },
})

export const { add, clear, init, remove, startRenaming, stopRenaming, update } =
  FoldersSlice.actions
export default FoldersSlice.reducer
